import { Input, message, Popconfirm, Tabs, Tag, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import dayjs from "dayjs";
import React, { useCallback, useEffect,useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "@tanstack/react-query";
import styled, {keyframes} from "styled-components";
import {
  AddedMessageModalContent,
  AddedReceivedProfileModalContent,
  AddedTemplateModalContent,
  AddPhoneModalContent,
  AntdButton,
  AntdModal,
  AntdPagination,
  AntdTable,
  ByteText,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTab,
  AntdTable2
} from "../components";
import { device } from "../device";
import {
  AlimtalkControllerReadAllTemplateParams,
  AlimtalkTemplateCreateDTO,
  AlimtalkTemplateReadResponseData,
  AlimtalkTemplateUpdateDTO,
  SmsControllerReadAllTemplateParams,
  SmsTemplateCreateDTO,
  SmsTemplateReadResponseData,
  SmsTemplateUpdateDTO,
  CompanyCertPhoneCreateDTO,
  MemberReadResponseDTO,
  CompanyCertPhoneEntity,
  CompanyCertKakaoEntity,
} from "../generated";
import { privateApi } from "../react-query";
import { useMessageSetStore,useAddSignupStore } from "../stores";
import { isSuccess } from "../utils";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { MdAddCircle } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import { RiMailAddLine, RiPlayListAddFill } from "react-icons/ri";
import getPhoneNumber from "../utils/getPhoneNumber";


const { TabPane } = Tabs;

interface IProps {}

const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherGetSmss = (params: SmsControllerReadAllTemplateParams) =>
  privateApi.sms.smsControllerReadAllTemplate(params);

const fetcherGetSms = (id: string) =>
  privateApi.sms.smsControllerReadOneTemplate(id);

const fetcherAddSms = (params: SmsTemplateCreateDTO) =>
  privateApi.sms.smsControllerCreateTemplate(params);

const fetcherUpdateSms = (
  params: SmsTemplateUpdateDTO & {
    id: string;
  }
) =>
  privateApi.sms.smsControllerUpdateTemplate(params.id, {
    templateContent: params.templateContent,
    isAd: params.isAd,
  });

const fetcherGetTemplates = (params: AlimtalkControllerReadAllTemplateParams) =>
  privateApi.alimtalk.alimtalkControllerReadAllTemplate(params);

const fetcherGetTemplate = (id: string) =>
  privateApi.alimtalk.alimtalkControllerReadOneTemplate(id);

const fetcherAddTemplate = (params: AlimtalkTemplateCreateDTO) =>
  privateApi.alimtalk.alimtalkControllerCreateTemplate(params);

const fetcherUpdateTemplate = (
  params: AlimtalkTemplateUpdateDTO & {
    id: string;
  }
) =>
  privateApi.alimtalk.alimtalkControllerUpdateTemplate(params.id, {
    templateContent: params.templateContent,
  });

const fetcherDeleteMessage = (id: string) =>
  privateApi.sms.smsControllerDeleteTemplate(id);

const fetcherDeleteProfile = (id: string) =>
  privateApi.company.companyControllerDeleteKakaoProfile(id);

const fetcherAddSignup = (params: CompanyCertPhoneCreateDTO) =>
  privateApi.company.companyControllerCreatePhoneProfile(params)

export const MessageSet: React.FC<IProps> = () => {
  const store = useMessageSetStore((s) => s);
  const store2 = useAddSignupStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [userrole, setUserRole] = useState("")
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)

  },[windowsize2]);
  const {
    isLoading: loadingGetMember,
    data: dataGetMember,
    refetch: refetchGetMember,
  } = useQuery(["fetcherGetMember/setMessage"], fetcherGetMember, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("memberControllerReadCurrentMember onSuccess: ", e);
        // console.log(e.data.body.data.userId);
        setUserRole(e.data.body.data.userRole)
      }
    },
    onError: (e) => {
      console.log("memberControllerReadCurrentMember onError: ", e);
    },
  });

  const {
    isLoading: loadingGetSmss,
    data: dataGetSmss,
    refetch: refetchGetSmss,
  } = useQuery(
    ["fetcherGetSmss/setMessage" + `/${store.formSms.form.query.page}`],
    () =>
      fetcherGetSmss({
        page: store.formSms.form.query.page,
        pageSize: store.formSms.form.query.pageSize,
      }),
    {
      enabled: store.tab === "SMS",
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerReadAllTemplate onSuccess: ", e);
          if (store.selectedSms) {
            store.onChangeSelectedSms(undefined);
          }
        }
      },
      onError: (e) => {
        console.log("smsControllerReadAllTemplate onError: ", e);
      },
    }
  );

  const {
    isLoading: loadingGetTemplates,
    data: dataGetTemplates,
    refetch: refetchGetTemplates,
  } = useQuery(
    ["fetcherGetTemplates/setMessage" + `/${store.formSms.form.query.page}`],
    () =>
      fetcherGetTemplates({
        // plusFriendId:
        //   dataGetMember?.data.body.data.companyInformation
        //     .certKakaoInformation[0]?.plusFriendId + "",
        page: store.formSms.form.query.page,
        pageSize: store.formSms.form.query.pageSize,
      }),
    {
      enabled:
        Boolean(
          dataGetMember?.data.body.data.companyInformation
            .certKakaoInformation[0]?.plusFriendId
        ) && store.tab === "ALARM",
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerReadAllTemplate onSuccess: ", e);
          if (store.selectedSms) {
            store.onChangeSelectedSms(undefined);
          }
        }
      },
      onError: (e) => {
        console.log("smsControllerReadAllTemplate onError: ", e);
      },
    }
  );

  const { mutate: mutateGetSms } = useMutation(fetcherGetSms, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("smsControllerReadOneTemplate onSuccess: ", e);
        store.onChangeSelectedSms(e.data.body.data[0]);
      }
    },
    onError: (e) => {
      console.log("smsControllerReadOneTemplate onError: ", e);
    },
  });

  const { mutate: mutateGetTemplate } = useMutation(fetcherGetTemplate, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("alimtalkControllerReadOneTemplate onSuccess: ", e);
        store.onChangeSelectedTemplate(e.data.body.data[0]);
      }
    },
    onError: (e) => {
      console.log("alimtalkControllerReadOneTemplate onError: ", e);
    },
  });

  const { isLoading: loadingAddSms, mutate: mutateAddSms } = useMutation(
    fetcherAddSms,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerCreateTemplate onSuccess: ", e);
          store.modalAddMessage.onClose();
          message.info("추가되었습니다");
          if (store.formSms.form.query.page === 1) {
            refetchGetSmss();
          } else {
            store.formSms.onChangeForm({
              query: {
                page: 1,
                pageSize: 10,
              },
            });
            setTimeout(() => {
              refetchGetSmss();
            }, 100);
          }
        }
      },
      onError: (e) => {
        console.log("smsControllerCreateTemplate onError: ", e);
      },
    }
  );

  const { isLoading: loadingUpdateSms, mutate: mutateUpdateSms } = useMutation(
    fetcherUpdateSms,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerUpdateTemplate onSuccess: ", e);
          message.info("저장되었습니다");
          if (store.selectedSms?.templateIdx) {
            mutateGetSms(store.selectedSms.templateIdx + "");
          }
        }
      },
      onError: (e) => {
        console.log("smsControllerUpdateTemplate onError: ", e);
      },
    }
  );

  const { isLoading: loadingUpdateTemplate, mutate: mutateUpdateTemplate } =
    useMutation(fetcherUpdateTemplate, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("alimtalkControllerUpdateTemplate onSuccess: ", e);
          message.info("저장되었습니다");
          if (store.selectedTemplate?.templateIdx) {
            mutateGetTemplate(store.selectedTemplate.templateIdx + "");
          }
        }
      },
      onError: (e) => {
        console.log("alimtalkControllerUpdateTemplate onError: ", e);
      },
    });

  const { isLoading: loadingAddTemplate, mutate: mutateAddTemplate } =
    useMutation(fetcherAddTemplate, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("alimtalkControllerCreateTemplate onSuccess: ", e);
          message.info("추가되었습니다");
          store.modalAddTemplate.onClose();
          refetchGetMember();
          if (store.formSms.form.query.page === 1) {
            refetchGetTemplates();
          } else {
            store.formSms.onChangeForm({
              query: {
                page: 1,
                pageSize: 10,
              },
            });
            setTimeout(() => {
              refetchGetTemplates();
            }, 100);
          }
        }
      },
      onError: (e) => {
        console.log("alimtalkControllerCreateTemplate onError: ", e);
      },
    });

  const { isLoading: loadingDeleteMessage, mutate: mutateDeleteMessage } =
    useMutation(fetcherDeleteMessage, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerDeleteTemplate onSuccess: ", e);
          message.info("삭제되었습니다");
          refetchGetSmss();
        }
      },
      onError: (e) => {
        console.log("smsControllerDeleteTemplate onError: ", e);
      },
    });

  const { mutate: mutateDeleteProfile } = useMutation(fetcherDeleteProfile, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("companyControllerDeleteKakaoProfile onSuccess: ", e);
        message.info("삭제되었습니다");
        refetchGetMember();
      }
    },
    onError: (e) => {
      console.log("companyControllerDeleteKakaoProfile onError: ", e);
    },
  });

  const { isLoading: loadingDeletePhone, mutate: mutateDeletePhone } =
    useMutation(privateApi.company.companyControllerDeletePhoneProfile, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("companyControllerDeletePhoneProfile onSuccess: ", e);
          message.info("삭제되었습니다.");
          refetchGetMember();
        }
      },
      onError: (e) => {
        console.log("companyControllerDeletePhoneProfile onError: ", e);
      },
    });

  const rowSelectionFromSms = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: SmsTemplateReadResponseData[]
    ) => {
      if (selectedRows[0]) {
        mutateGetSms(selectedRows[0].templateIdx + "");
      }
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const rowSelectionFromTemplate = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: AlimtalkTemplateReadResponseData[]
    ) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      if (selectedRows[0]) {
        mutateGetTemplate(selectedRows[0].templateIdx + "");
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "", // Column configuration not to be checked
      name: record.name,
    }),
  };

  /**
   * [문자설정/알림톡설정] 문자내용 or 템플릿내용 변경시
   */
  const onChangeSmsMessage = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = e;
      store.formSms.onChangeForm({
        message: value,
      });
    },
    []
  );

  /**
   * [문자설정] 문자추가 모달 팝업 확인 클릭시
   */
  const onConfirmModalAddMessage = () => {
    const { templateContent, templateName, isAd } =
      store.formSms.form.addSmsQuery;
    if (templateContent && templateName) {
      mutateAddSms({
        templateName,
        templateContent,
        isAd,
      });
    }
  };

  /**
   * [광고성 문자] 업데이트 이벤트
   */
  const onChangeCheckbox = useCallback((e: CheckboxChangeEvent) => {
    const {
      target: { checked },
    } = e;
    store.formSms.onChangeForm({
      isAd: checked ? 1 : 0,
    });
  }, []);

  /**
   * [문자 수정] 버튼 클릭
   */
  const onSaveSms = () => {
    if (store.selectedSms) {
      const {
        form: { message, isAd },
      } = store.formSms;

      mutateUpdateSms({
        id: store.selectedSms.templateIdx + "",
        templateContent: message,
        isAd: isAd ? 1 : 0,
      });
    }
  };

  /**
   * [템플릿 수정] 버튼 클릭
   */
  const onSaveTemplate = () => {
    if (store.selectedTemplate) {
      const {
        form: { message, isAd },
      } = store.formSms;

      mutateUpdateTemplate({
        id: store.selectedTemplate.templateIdx + "",
        templateContent: message,
      });
    }
  };

  const onChangeSmsPagination = useCallback(
    (page: number, pageSize?: number) => {
      store.onChangeSelectedSms(undefined);
      store.onChangeSelectedTemplate(undefined);
      store.formSms.onChangeForm({
        query: {
          page,
          pageSize,
        },
      });
      if (store.tab === "SMS") {
        setTimeout(() => {
          // refetchGetSmss();
        }, 100);
      } else {
        setTimeout(() => {
          // refetchGetTemplates();
        }, 100);
      }
    },
    []
  );

  /**
   * [알림톡설정] 템플릿 추가 모달 팝업 확인 클릭시
   */
  const onConfirmModalAddTemplate = () => {
    const {
      selectedTemplateOnModal,
      templateNameOnModal,
      templateContentOnModal,
      templateCode1,
      templateCode2,
    } = store.formSms.form;
    if (
      selectedTemplateOnModal &&
      templateNameOnModal.length > 0 &&
      templateContentOnModal.length > 0 &&
      templateCode1 !== "all" &&
      templateCode2 !== "all"
    ) {
      mutateAddTemplate({
        categoryCode: templateCode2,
        plusFriendId: selectedTemplateOnModal.plusFriendId,
        templateName: templateNameOnModal,
        templateContent: templateContentOnModal,
      });
    }
  };

    const { isLoading: loadingAddSignup, mutate: mutateAddSignUp } = useMutation(
      fetcherAddSignup,
      {
        onSuccess: (e) => {
          if (isSuccess(e.data.header)) {
            console.log("smsControllerCreateTemplate onSuccess: ", e);
            store.modalAddReceivedPhone.onClose();
            refetchGetMember();
          }
        },
        onError: (e) => {
          console.log("smsControllerCreateTemplate onError: ", e);
        },
      }
    );
  
    const addsignup = () => {
      mutateAddSignUp({
        imp_uid : store2.form.form.imp_uid,
        carrierCertFilePhone :store2.form.form.carrierCertFilePhone,
        carrierCertFileName :store2.form.form.filePhoneCert.fileName,
        employmentCertFileName: store2.form.form.employmentCertFileName
      })
     
      store2.onReset()          
      }
  useEffect(() => {
    return () => {
      store.onReset();
    };
  }, []);

  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>
            <CellGroup
              className={`top ${store.clicked === 1? store.isOpenTable ? "open-active" : "close-active" : ""}`}
            >
              {store.tab === "SMS" ? (

                <LayoutCell>
                  <Tabs
                    onChange={(e) => {
                      store.onChangeTab(e as "SMS");
                    }}
                    activeKey={store.tab}
                    defaultActiveKey="1"
                  >
                    <TabPane tab="문자 설정" key="SMS"></TabPane>
                    <TabPane tab="알림톡 설정" key="ALARM"></TabPane>
                  </Tabs>
                  <CellTitle className="space">
                    <Text>발신번호 관리</Text>
                      {userrole !== "900" ?  userrole !== "901" ? (
                      <></>
                      ) : (<AntdButton
                      isPrimary
                      style={{ color: "white", display: "flex", alignItems: "center" }}
                      onClick={store.modalAddReceivedPhone.onOpen}
                      className="btnBlue"
                    >
                      <MdAddCircle size={18} style={{ marginLeft: "6px" }} />
                      발신번호 등록
                    </AntdButton>)
                    
                    : (<AntdButton
                      isPrimary
                      style={{ color: "white", display: "flex", alignItems: "center" }}
                      onClick={store.modalAddReceivedPhone.onOpen}
                      className="btnBlue"
                    >
                      발신번호 등록
                      <MdAddCircle size={18} style={{ marginLeft: "6px" }} />
                    </AntdButton>)
                    }
                  
                  </CellTitle>
                  <TableBox>
                    <ExtendedAntdTable2
                      className={`top-table ${
                        store.isOpenTable ? "open-active" : ""
                      }`}
                      loading={loadingGetMember}
                      pagination={false}
                      rowKey="idx"
                      tableLayout="fixed"
                      scroll={{ y: 330 }}
                      dataSource={

                        // dataGetMember?.data
                        //   ? store.isOpenTable
                        //     ? 
                        //       dataGetMember.data.body.data.companyInformation.certPhoneInformation
                            
                        //     : 
                        //       dataGetMember.data.body.data.companyInformation.certPhoneInformation
                            
                          
                        //   : []

                        // dataGetMember?.data
                        //   ? store.isOpenTable
                        //     ? [
                        //         dataGetMember.data.body.data.companyInformation.certPhoneInformation.at(0)
                        //     ]
                        //     : [

                        //       dataGetMember.data.body.data.companyInformation.certPhoneInformation.at(0)

                        //     ]
                      
                            
                            
                        //   : []


                        // dataGetMember?.data
                        //   ? store.isOpenTable
                        //     ?
                        //       dataGetMember.data.body.data.companyInformation.certPhoneInformation
                        //     : 
                        //         [
                        //           dataGetMember.data.body.data.companyInformation.certPhoneInformation[0],
                        //         ]
                        //   : []
                        dataGetMember?.data.body.data.companyInformation.certPhoneInformation
                          ? store.isOpenTable
                            ? 
                            dataGetMember.data.body.data.companyInformation.certPhoneInformation
                            :
                            
                            dataGetMember.data.body.data.companyInformation.certPhoneInformation.length === 0 ?
                            []
                            :[ dataGetMember.data.body.data.companyInformation.certPhoneInformation[0]]
                          : []
                      }
                      columns={[
                        {
                          title: "신청번호",
                          dataIndex: "phone",
                          key: "phone",
                          width: windowsize? 100 : 230,
                          render: (e, v: CompanyCertPhoneEntity) => {
                            return (
                              <Typography>
                                {getPhoneNumber(v.phone)}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: "신청방법",
                          dataIndex: "phoneCertType",
                          key: "phoneCertType",
                          align: "center",
                          render: (v) =>
                            v === "KCP" ? "휴대폰인증" : "서류인증",
                        },
                        {
                          title: "심사상태",
                          dataIndex: "phoneCertFlag",
                          key: "phoneCertFlag",
                          align: "center",
                          render: (v) => {
                            return v ? (
                              <Tag color="green">승인</Tag>
                            ) : (
                              <Tag color="orange">대기</Tag>
                            );
                          },
                        },
                        {
                          title: "삭제",
                          dataIndex: "idx",
                          key: "idx",
                          align: "center",
                          render: (v) => {
                            const onClick = () => {
                              if (
                                dataGetMember?.data.body.data.companyInformation
                                  .certPhoneInformation
                              ) {
                                mutateDeletePhone(v + "");
                              }
                            };
                            return (
                              <Popconfirm
                                disabled={loadingDeletePhone}
                                title="삭제하시겠습니까?"
                                onConfirm={onClick}
                                okText="네"
                                cancelText="아니오"
                              >
                                <AiOutlineCloseCircle
                                  size={16}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </Popconfirm>
                            );
                          },
                        },
                      ]}
                    />
                  {/* <TableIconBox onClick={store.onToggleIsOpenTable}>
                          {store.isOpenTable ? (
                            <AiOutlineCaretUp />
                          ) : (
                            <AiOutlineCaretDown />
                          )}
                        </TableIconBox> */}
                     
                  </TableBox>
                </LayoutCell>            
              ) : (
                <LayoutCell>
                  <Tabs
                    onChange={(e) => {
                      store.onChangeTab(e as "SMS");
                    }}
                    activeKey={store.tab}
                    defaultActiveKey="1"
                  >
                    <TabPane tab="문자 설정" key="SMS"></TabPane>
                    <TabPane tab="알림톡 설정" key="ALARM"></TabPane>
                  </Tabs>
                  <CellTitle className="space">
                    <Text>발신프로필 관리</Text>
                    <AntdButton
                      isPrimary
                      style={{ color: "white", display: "flex", alignItems: "center" }}
                      onClick={store.modalAddReceivedProfile.onOpen}
                      className="btnBlue"
                    >
                      발신프로필 등록
                      <MdAddCircle size={18} style={{ marginLeft: "6px" }} />
                    </AntdButton>
                  </CellTitle>
                  <TableBox>
                    <ExtendedAntdTable2
                      className={`top-table ${
                        store.isOpenTable ? "open-active" : ""
                      }`}
                      key="profile-table"
                      loading={loadingGetMember}
                      pagination={false}
                      rowKey="idx"
                      tableLayout="fixed"
                      scroll={{y: 310 }}
                      dataSource={
                        dataGetMember?.data.body.data.companyInformation.certKakaoInformation
                          ? store.isOpenTable
                            ? 
                            dataGetMember.data.body.data.companyInformation.certKakaoInformation
                            :
                            
                            dataGetMember.data.body.data.companyInformation.certKakaoInformation.length === 0 ?
                            []
                            :[ dataGetMember.data.body.data.companyInformation.certKakaoInformation[0]]
                          : []
                      }
                      columns={[
                        {
                          title: "신청번호",
                          dataIndex: "phoneNo",
                          key: "phoneNo",
                          width: windowsize? 100 : 230,
                          render: (e, v: CompanyCertKakaoEntity) => {
                            return (
                              <Typography>
                                {getPhoneNumber(v.phoneNo)}
                              </Typography>
                            );
                          },
                        },
                        {
                          title: "발신프로필",
                          dataIndex: "plusFriendId",
                          key: "plusFriendId",
                          align: "center",
                        },
                        {
                          title: "심사상태",
                          dataIndex: "status",
                          key: "status",
                          align: "center",
                          render: (v) => {
                            return v === "YSC03" ? (
                              <Tag color="green">승인</Tag>
                            ) : (
                              <Tag color="orange">심사중</Tag>
                            );
                          },
                        },
                        {
                          title: "삭제",
                          dataIndex: "idx",
                          key: "idx",
                          align: "center",
                          render: (v) => {
                            const onClick = () => {
                              mutateDeleteProfile(v + "");
                            };
                            return (
                              <AiOutlineCloseCircle
                                size={16}
                                onClick={onClick}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            );
                          },
                        },
                      ]}
                    />
                        {/* <TableIconBox onClick={store.onToggleIsOpenTable}>
                          {store.isOpenTable ? (
                            <AiOutlineCaretUp />
                          ) : (
                            <AiOutlineCaretDown />
                          )}
                        </TableIconBox> */}
                  </TableBox>
                </LayoutCell>
              )}
         
            </CellGroup>
            <div style={{display : "flex", justifyContent : "center", marginBottom : "15px"}}>
            <TableIconBox2 onClick={store.onToggleIsOpenTable }>
            {store.isOpenTable ? (
                            <AiOutlineCaretUp />
                          ) : (
                            <AiOutlineCaretDown />
                          )}
            </TableIconBox2>

            </div>
         
            {store.tab === "SMS" ? (
              <CellGroup className={store.isOpenTable ? "hide" : ""}>
                <LayoutCell>
                  <CellTitle>저장된 문자</CellTitle>
                  <ExtendedAntdTable
                    className="bottom-table"
                    loading={loadingGetSmss}
                    pagination={false}
                    rowKey="templateIdx"
                    tableLayout="fixed"
                    scroll={{y: 330 }}
                    rowSelection={{
                      type: "radio",
                      selectedRowKeys: store.selectedSms?.templateIdx
                        ? [store.selectedSms?.templateIdx]
                        : [],
                      ...rowSelectionFromSms,
                    }}
                    onRow={
                      (v:SmsTemplateReadResponseData)=>{
                        return{
                          onClick: () =>{
                            rowSelectionFromSms.onChange([v.templateIdx],[v])
                          }
                        }
                      }
                    }
                    dataSource={
                      dataGetSmss?.data ? dataGetSmss?.data.body.data : []
                    }
                    columns={[
                      {
                        title: "메시지명",
                        dataIndex: "templateName",
                        key: "templateName",
                        width: windowsize? 60 : 125
                      },
                      {
                        title: "생성/수정자명",
                        dataIndex: "updateUserName",
                        key: "updateUserName",
                        align: "center",
                        width: windowsize? 75 : 120
                      },
                      {
                        title: "생성/수정일시",
                        dataIndex: "updateAt",
                        key: "updateAt",
                        align: "center",
                        width: windowsize? 75 : 120,
                        render: (v) => {
                          return (
                            <div style={{ textAlign: "center", fontSize: "12px", lineHeight: "1.3" }}>
                              {/* <>{dayjs(v).format("YYYY-MM-DD")}</>
                              <br />
                              <>{dayjs(v).format("HH:mm:ss")}</> */}
                              <>{dayjs(v).format("YYYY-MM-DD")}</>
                              <br />
                              <>{dayjs(v).format("HH:mm:ss")}</>
                            </div>
                          );
                        },
                      },
                      {
                        title: "삭제",
                        dataIndex: "templateIdx",
                        key: "templateIdx",
                        align: "center",
                        width:  windowsize? 50 :60,
                        render: (v) => {
                          const onClick = () => {
                            if (!loadingDeleteMessage) {
                              mutateDeleteMessage(v + "");
                            }
                          };
                          return (
                            <AiOutlineCloseCircle
                              size={16}
                              onClick={onClick}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          );
                        },
                      },
                    ]}
                  />
                  <ButtonSpace>
                    <AntdButton
                      isPrimary
                      className="sm btnGreen"
                      style={{ color: "white", display: "flex", alignItems: "center" }}
                      onClick={store.modalAddMessage.onOpen}
                    >
                      문자 추가
                      <RiMailAddLine className="icon set" size={18}  style={{ marginLeft: "6px" }}/>
                    </AntdButton>
                    {dataGetSmss?.data && dataGetSmss.data.body.total > 10 && (
                      <AntdPagination
                        className="test"
                        style={{ marginTop: 0 }}
                        disabled={loadingGetSmss}
                        loading={loadingGetSmss}
                        pageSize={10}
                        position="right"
                        current={store.formSms.form.query.page}
                        total={dataGetSmss.data.body.total}
                        onChange={onChangeSmsPagination}
                      />
                    )}
                  </ButtonSpace>
                </LayoutCell>
                <Divide />
                <LayoutCell className="last-cell">
                  <CellTitle>문자 내용</CellTitle>
                  <Input.TextArea
                    // rows={12}
                    disabled={!store.selectedSms}
                    value={store.formSms.form.message}
                    onChange={onChangeSmsMessage}
                  />
                  <ByteText
                    length={store.formSms.form.message.length}
                    checked={Boolean(store.formSms.form?.isAd)}
                    onChangeCheckbox={onChangeCheckbox}
                  />
                  <ButtonSpace className="end">
                    <AntdButton
                      loading={loadingUpdateSms}
                      disabled={
                        !store.selectedSms ||
                        store.formSms.form.message.length < 1
                      }      
                      style={{  display: "flex", alignItems: "center" }}
                      onClick={onSaveSms}
                      type="dashed"
                      className="btnDashedPurple"
                    >
                      문자 수정
                      <BiEditAlt className="icon set" size={18}  style={{ marginLeft: "6px" }}/>
                    </AntdButton>
                  </ButtonSpace>
                </LayoutCell>
              </CellGroup>
            ) : (
              <CellGroup className={store.isOpenTable ? "hide" : ""}>
                <LayoutCell>
                  <CellTitle>템플릿 관리</CellTitle>
                  <ExtendedAntdTable
                    loading={loadingGetTemplates}
                    className="bottom-table"
                    pagination={false}
                    rowKey="templateIdx"
                    tableLayout="fixed"
                    scroll={{ y: 330 }}
                    rowSelection={{
                      type: "radio",
                      selectedRowKeys: store.selectedTemplate?.templateIdx
                        ? [store.selectedTemplate?.templateIdx]
                        : [],
                      ...rowSelectionFromTemplate,
                    }}
                    onRow={
                      (v:AlimtalkTemplateReadResponseData)=>{
                        return{
                          onClick: () =>{
                            rowSelectionFromTemplate.onChange([v.templateIdx],[v])
                          }
                        }
                      }
                    }
                    dataSource={
                      dataGetTemplates?.data
                        ? dataGetTemplates.data.body.data
                        : []
                    }
                    columns={[
                      {
                        title: "발신프로필",
                        dataIndex: "plusFriendId",
                        key: "plusFriendId",
                        fixed: true,
                        width: windowsize? 70 : 100
                      },
                      {
                        title: "템플릿명",
                        dataIndex: "templateName",
                        key: "templateName",
                        width: windowsize? 60 : 100
                      },
                      {
                        title: "템플릿상태",
                        dataIndex: "nhnHookTemplateStatus",
                        key: "nhnHookTemplateStatus",
                        width: windowsize? 50 : 90,
                        align: "center",
                        render: (v) => {
                          if (v === "TSC01") {
                            // 요청
                            return <Tag color="blue" style={windowsize? {fontSize : "5px" ,padding: "2px"} : {}}>요청</Tag>;
                          } else if (v === "TSC02") {
                            // 검수
                            return <Tag color="orange" style={windowsize? {fontSize : "5px" ,padding: "2px"} : {}}>검수</Tag>;
                          } else if (v === "TSC03") {
                            // 등록
                            return <Tag color="green" style={windowsize? {fontSize : "5px" ,padding: "2px"} : {}}>등록</Tag>;
                          } else if (v === "TSC04") {
                            // 반려
                            return <Tag color="red" style={windowsize? {fontSize : "5px" ,padding: "2px"} : {}}>반려</Tag>;
                          } else {
                            return "-";
                          }
                        },
                      },
                      {
                        title: "생성/수정자명",
                        dataIndex: "updateUserName",
                        key: "updateUserName",
                        align: "center",
                        width: windowsize? 50 : 100
                      },
                      {
                        title: "생성/수정일시",
                        dataIndex: "updateAt",
                        key: "updateAt",
                        // width: 100,
                        width: windowsize? 60 : 100,
                        align: "center",
                        render: (v) => {
                          return (
                            <div style={{ textAlign: "center" }}>
                              <>{windowsize? dayjs(v).format("YY-MM-DD") : dayjs(v).format("YYYY-MM-DD")}</>
                              <br />
                              <>{dayjs(v).format("HH:mm:ss")}</>
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                  <ButtonSpace>
                    <AntdButton
                      isPrimary
                      className="sm btnYellow"
                      style={{ color: "white", display: "flex", alignItems: "center" }}
                      onClick={store.modalAddTemplate.onOpen}
                    >
                      템플릿 추가
                      <RiPlayListAddFill size={18}  style={{ marginLeft: "6px" }}/>
                    </AntdButton>
                    {dataGetTemplates?.data &&
                      dataGetTemplates.data.body.total > 10 && (
                        <AntdPagination
                          className="test"
                          style={{ marginTop: 0 }}
                          disabled={loadingGetSmss}
                          loading={loadingGetSmss}
                          pageSize={10}
                          position="right"
                          current={store.formSms.form.query.page}
                          total={dataGetTemplates.data.body.total}
                          onChange={onChangeSmsPagination}
                        />
                      )}
                  </ButtonSpace>
                </LayoutCell>
                <Divide />
                <LayoutCell className="last-cell">
                  <CellTitle>템플릿 내용</CellTitle>
                  <Input.TextArea
                    // rows={12}
                    disabled={
                      !store.selectedTemplate ||
                      store.selectedTemplate?.nhnHookTemplateStatus !== "TSC03"
                    }
                    value={store.formSms.form.message}
                    onChange={onChangeSmsMessage}
                  />
                  <ButtonSpace className="end">
                    <AntdButton
                      loading={loadingUpdateTemplate}
                      disabled={
                        !store.selectedTemplate ||
                        store.formSms.form.message.length < 1 ||
                        store.selectedTemplate?.nhnHookTemplateStatus !==
                          "TSC03"
                      }
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={onSaveTemplate}
                      type="dashed"
                      className="btnDashedPurple"
                    >
                      템플릿 수정
                      <BiEditAlt size={18} style={{ marginLeft: "6px" }}/>
                    </AntdButton>
                  </ButtonSpace>
                </LayoutCell>
              </CellGroup>
            )}
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      <AntdModal
        draggable
        title="발신번호 등록"
        style={{
          maxWidth: 450,
          width: "95%",
        }}
        visible={store.modalAddReceivedPhone.visible}
        onOk={addsignup}
        onCancel={store.modalAddReceivedPhone.onClose}
      >
        <AddPhoneModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="발신프로필 추가"
        style={{
          maxWidth: 450,
          width: "95%",
        }}
        visible={store.modalAddReceivedProfile.visible}
        onOk={store.modalAddReceivedProfile.onClose}
        onCancel={store.modalAddReceivedProfile.onClose}
      >
        <AddedReceivedProfileModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="문자추가"
        style={{
          maxWidth: 450,
          width: "95%",
        }}
        visible={store.modalAddMessage.visible}
        onOk={onConfirmModalAddMessage}
        onCancel={store.modalAddMessage.onClose}
        okButtonProps={{
          loading: loadingAddSms,
          disabled:
            loadingAddSms ||
            !store.formSms.form.addSmsQuery.templateName ||
            !store.formSms.form.addSmsQuery.templateContent,
        }}
      >
        <AddedMessageModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="템플릿 추가"
        style={{
          maxWidth: 800,
          width: "95%",
        }}
        visible={store.modalAddTemplate.visible}
        onOk={onConfirmModalAddTemplate}
        onCancel={store.modalAddTemplate.onClose}
        okButtonProps={{
          loading: loadingAddTemplate,
          disabled:
            loadingAddTemplate ||
            !store.formSms.form.selectedTemplateOnModal ||
            store.formSms.form.templateNameOnModal.length < 1 ||
            store.formSms.form.templateContentOnModal.length < 1 ||
            store.formSms.form.templateCode1 === "all" ||
            store.formSms.form.templateCode2 === "all",
        }}
      >
        <AddedTemplateModalContent />
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  /* height: 100%; */

  textarea.ant-input {
    height: 100px;
  }
  @media ${device.laptop.min} {
    textarea.ant-input {
      height: calc(100% - 100px);
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100vh - 81px - 20px - 12px);
  max-height: 620px;
  /* height: calc(100vh - 81px - 20px - 31px); */
  /* max-height: 720px; */
  @media ${device.laptop.max} {
    height: auto;
    max-height: auto;
    textarea {
      min-height: 30px;
    }
  }
`;
const close = keyframes`
  0% {
   height : 100%;
  }
  50% {
    
  }
  100%{
   
    height : 212px;
  }
`;
const open = keyframes`
  0% {
   
  }
  50% {
    
  }
  100%{
   
    height : 100%;
  }
`;
const mobileopen = keyframes`
  0% {
   
  }
  50% {
    
  }
  100%{
   
    height : 450px;
  }
`;
const mobileclose = keyframes`
  0% {
    height : 450px;
  }
  50% {
    
  }
  100%{
   
    height : 212px;
  }
`;

const CellGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  .ant-tabs-tab-btn {
    font-size: 13px;
  }
  .ant-tabs {
    margin-top: -15px;
  }
  &.top {
    height : 212px;
    & > div {
      width: 100%;
    }
    &.close-active{
      
      animation:${close} 1s ;
      animation-fill-mode: forwards;
    }
    &.open-active {
      // 웹일때 클릭시 길이 조절
      
      animation:${open} 1s ;
      animation-fill-mode: forwards;
    
    
    }
  }
  /* &:not(.top) {
    height: calc(100% - 212px - 20px);
    min-height: 260px;
  } */
  &.hide {
    display: none;
    //웹일때 아래 내용 없앨지 말지..
    // opacity : 0.3;
    //웹일때 아래 내용 투명도...
    // pointer-events: none;
    
  }
  @media ${device.laptop.min} {

    &:not(.top) {
      height: calc(100% - 212px - 30px);
      min-height: 260px;
    }
    &:last-child {
      & > div {
        &:first-child {
          /* max-width: calc(100% - 400px); */
          width: 60%;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
        }
        &:last-child {
          flex: initial;
          width: 40%;
        }
      }
    }
  }
  @media ${device.laptop.max} {
    flex-flow: column;
    &.top {
   
      &.close-active{
      
        animation:${mobileclose} 1s ;
        animation-fill-mode: forwards;
      }

      &.open-active {

        animation:${mobileopen} 1s !important;
        animation-fill-mode: forwards!important;
      
      }
    }
    &:not(.top) {
      & > div {
        &:first-child {
          position: relative;
          padding: 20px;
          border-radius: 6px;
        }
      }
    }
  }
`;

const CellTitle = styled.h5`
  position: relative;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 6px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  &.space {
    align-items: flex-end;
  }
`;

const Divide = styled.div`
  /* display: none; */
  width: 1px;
  margin: 0 2px;
  /* background-color: #dfdfdf; */
  @media ${device.laptop.max} {
    width: 0;
    margin: 2px;
    /* display: none; */
    /* width: 100%;
    height: 1px;
    margin: 20px 0; */
  }
`;

const TableBox = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const TableIconBox = styled.div`
  position : absolute;
  bottom : 0;
  left : 50%;
  transform: translate(-50%, 100%);
  background-color: var(--primary-color);
  color: white;
  padding: 1px 23px;
  cursor: pointer;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: 0.2s;
  &:hover {
    background-color: #284378;
  }
`;
const TableIconBox2 = styled.div`
background-color: var(--primary-color);
// transform: translate(-50%, 100%);
color: white;
cursor: pointer;
border-bottom-left-radius: 6px;
border-bottom-right-radius: 6px;
padding: 0px 23px;

transition: 1s;
&:hover {
  background-color: #284378;
}

  
`;

const ButtonSpace = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  gap: 6px;
  &.end {
    justify-content: flex-end;
  }

  @media ${device.laptop.max} {
    button {
      width: 100%;
      &.sm {
        max-width: 83px;
      }
    }
  }
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    /* min-height: 100vh; */
    min-height: 100%;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
`;

const ExtendedAntdTable = styled(AntdTable)`

  @media ${device.laptop.min} {
    &.open-active {
      height: calc(100% - 32px - 10px - 40px);
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
      }
      .ant-table-container {
        height: 100%;

        .ant-table-body {
          max-height: calc(100% - 57px) !important;
          height: calc(100% - 57px) !important;
          /* height: calc(94.5vh - 475px); */
        }
      }
    }

    &.bottom-table {
      height: calc(100% - 32px - 10px - 40px);
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
      }
      .ant-table-container {
        height: 100%;

        .ant-table-body {
          max-height: calc(100% - 35px) !important;
          //-57px
          height: calc(100% - 35px) !important;
          /* height: calc(94.5vh - 475px); */
        }
      }
    }
  }

  @media ${device.laptop.max} {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
    font-size: 9px;
    &.open-active {
      height: calc(100vh - 87px - 40px - 30px - 47px);
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
      }
      .ant-table-container {
        height: 100%;

        .ant-table-body {
          max-height: calc(100% - 40px) !important;
          height: calc(100% - 40px) !important;
          /* height: calc(94.5vh - 475px); */
        }
      }
    }
  }
`;
const ExtendedAntdTable2 = styled(AntdTable2)`

  @media ${device.laptop.min} {
    &.open-active {
      height: calc(100% - 32px - 10px - 40px);
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
      }
      .ant-table-container {
        height: 100%;

        .ant-table-body {
          // max-height: calc(100% - 57px) !important;
          max-height: 400px !important;
          height: calc(100% - 57px) !important;
          /* height: calc(94.5vh - 475px); */
        }
      }
    }

    &.bottom-table {
      height: calc(100% - 32px - 10px - 40px);
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
      }
      .ant-table-container {
        height: 100%;

        .ant-table-body {
          max-height: calc(100% - 57px) !important;
          height: calc(100% - 57px) !important;
          /* height: calc(94.5vh - 475px); */
        }
      }
    }
  }

  @media ${device.laptop.max} {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
    font-size: 9px;
    }
    &.open-active {
      height: calc(100% - 87px - 40px - 30px - 47px);
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
      }
      .ant-table-container {
        height: 100%;

        .ant-table-body {
          // max-height: calc(100% - 40px) !important;
          max-height: 270px !important;
          height: calc(100% - 40px) !important;
          /* height: calc(94.5vh - 475px); */
        }
      }
    }
  }

`;