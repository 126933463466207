import { Checkbox, Popover } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import styled from "styled-components";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  length: number;
  checked?: boolean;
  onChangeCheckbox?: (e: CheckboxChangeEvent) => void;
}

export const ByteTextformessage2: React.FC<IProps> = ({
  length,
  checked = false,
  onChangeCheckbox,
  ...props
}) => {
  return (
    <Container {...props}>
      

      <ByteItem className={length === 2000 ? "active" : ""}>
        {length} / {length > 90 ? 2000 : 90} Byte
      </ByteItem>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ByteItem = styled.div`
  font-size: 12px;
  &.active {
    color: #ff4444;
  }
`;

const PopupContent = styled.div`
  font-size: 12px;
  color: var(--primary-color);
`;
