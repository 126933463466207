import styled from "styled-components";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  title: string;
}

export const TabHeader: React.FC<IProps> = ({ title, ...props }) => {
  return (
    <Container {...props}>
      <Wrapper>{title}</Wrapper>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 10px;
  border-bottom: 3px solid #dfdfdf;
`;

const Wrapper = styled.h4`
  font-size: 18px;
  text-align: center;
`;
