import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect,useState } from "react";
import { NoticeResponseData } from "../generated";
import { AntdModal } from "../components/AntdModal";
import { Helmet } from "react-helmet";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { MdArrowBackIosNew } from "react-icons/md";
import styled from "styled-components";
import {
  AntdDatePicker,
  DashboardChargeStatus,
  DashboardMessageStats,
  DashboardServiceStats,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTitle,
  AntdTable_Notice
} from "../components";
import { device } from "../device";
import {
  MessageControllerReadStatsParams,
  MessageReadStatsResponseData,
} from "../generated";
import { privateApi } from "../react-query";
import { initMessageStats, useSummaryDashboardStore,useMyPageAccountStore } from "../stores";
import { IQueryFunctionContext } from "../types";
import { isSuccess } from "../utils";
import { Tooltip } from "antd";

const today = dayjs();
const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherMessageStats = (
  query: IQueryFunctionContext<MessageControllerReadStatsParams>
) => {
  const params = query.queryKey[1];
  return privateApi.message.messageControllerReadStats({
    startRequestDate: params.startRequestDate,
    endRequestDate: params.endRequestDate,
    statisticsType: params.statisticsType,
    page: params.page,
    pageSize: params.pageSize,
  });
};

const fetcherChargeStats = () =>
  privateApi.charge.chargeControllerReadHistoryStats({
    startRequestDate: today.add(-5, "month").format("YYYY-MM-DD"),
    endRequestDate: today.format("YYYY-MM-DD"),
    statisticsType: "monthly",
  });
 const fetcherGetNotices = () => privateApi.notice.noticeControllerRead();



export const SummaryDashboard = () => {
  const store = useSummaryDashboardStore((s) => s);
  const store2 = useMyPageAccountStore((s) => s);
  
  const { isLoading: loadingGetMember, data: dataGetMember } = useQuery(
    ["memberControllerReadCurrentMember"],
    fetcherGetMember,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerReadCurrentMember onSuccess: ", e);
          store2.formFilter.onChangeForm({
            userrole : e.data.body.data.userRole
          })
         
          console.log(store2.formFilter.form.userrole);
          
        }
      },
      onError: (e) => {
        console.log("memberControllerReadCurrentMember onError: ", e);
      },
    }
  );
  const { data: dataGetMessageStats } = useQuery(
    [
      "messageControllerReadStats",
      {
        statisticsType: "daily",
        page: 1,
        pageSize: 1000,
        startRequestDate: store.formFilter.form.startRequestDate,
        endRequestDate: store.formFilter.form.endRequestDate,
      } as MessageControllerReadStatsParams,
    ],
    fetcherMessageStats,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          const { startRequestDate, endRequestDate } = store.formFilter.form;
          const days = dayjs(endRequestDate).diff(startRequestDate, "day") + 1;
          console.log({ days });
          const date = dayjs(endRequestDate);
          const messageStats = new Array(days)
            .fill({ ...initMessageStats })
            .map((v, i) => {
              const requestDate = date.add(-i, "day").format("YYYY-MM-DD");
              const currentDate = e.data.body.data.find(
                (currentStats) =>
                  dayjs(currentStats.requestDate).format("YYYY-MM-DD") ===
                  requestDate
              );
              if (currentDate) {
                return {
                  ...currentDate,
                  requestDate,
                } as MessageReadStatsResponseData;
              }
              return {
                ...v,
                requestDate,
              };
            });
          store.onChangeMessageStats(messageStats);
          store.formMessageStats.onChangeForm({
            selectedMessageStats: undefined,
          });
          console.log("messageControllerReadStats onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("messageControllerReadStats onError: ", e);
      },
    }
  );
  const { isLoading: loadingGetChrageStats } = useQuery(
    ["chargeControllerReadHistoryStats"],
    fetcherChargeStats,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          const chargeStats = store.chargeStats.map((v) => {
            const currentDate = e.data.body.data.find(
              (current) =>
                dayjs(current.requestDate).format("YYYY-MM") === v.requestDate
            );
            if (currentDate) {
              return {
                ...v,
                chargedBalance: currentDate.chargedBalance,
              };
            }
            return v;
          });
          console.log({ chargeStats });
          store.onChangeChargeStats(chargeStats);
          console.log("chargeControllerReadHistoryStats onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("chargeControllerReadHistoryStats onError: ", e);
      },
    }
  );

  /**
   * [검색기간] 변경시
   */
  const onChangeDate = useCallback((e: [Dayjs, Dayjs]) => {
    if (e[0] && e[1]) {
      const startRequestDate = e[0].format("YYYY-MM-DD");
      const endRequestDate = e[1].format("YYYY-MM-DD");
      store.formFilter.onChangeForm({
        startRequestDate,
        endRequestDate,
      });
    }
  }, []);

  /**
   * [좌측 화살표 아이콘] 클릭시
   */
  const onClickPrevArrow = useCallback(() => {
    const { startRequestDate } = store.formFilter.form;
    store.formFilter.onChangeForm({
      startRequestDate: dayjs(startRequestDate)
        .add(-7, "days")
        .format("YYYY-MM-DD"),
      endRequestDate: dayjs(startRequestDate)
        .add(-1, "days")
        .format("YYYY-MM-DD"),
    });
    store.formMessageStats.onChangeForm({ selectedMessageStats: undefined });
  }, [store.formFilter]);

  /**
   * [우측 화살표 아이콘] 클릭시
   */
  const onClickNextArrow = useCallback(() => {
    const { endRequestDate } = store.formFilter.form;
    store.formFilter.onChangeForm({
      startRequestDate: dayjs(endRequestDate)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      endRequestDate: dayjs(endRequestDate).add(7, "days").format("YYYY-MM-DD"),
    });
    store.formMessageStats.onChangeForm({ selectedMessageStats: undefined });
  }, [store.formFilter]);


  const [selectedNotice, setSelectedNotice] = useState<
  NoticeResponseData | undefined>(undefined);
  const { data: dataGetNotices } = useQuery(
    ["noticeControllerReadCurrentMember"],
    fetcherGetNotices,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("noticeControllerReadCurrentMember onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("noticeControllerReadCurrentMember onError: ", e);
      },
    }
  );


  const onClickNoticeDetail = (idx: number) => {
    const notice = dataGetNotices?.data.body.data.find(
      (v) => v.noticeIdx === idx
    );
    if (notice) {
      setSelectedNotice(notice);
    }
  };

  useEffect(() => {
    return () => {
      store.onReset();
    };
  }, []);

  const data = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];
  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>

            <Space>

              <DateCell>
                <DateSpace>
                  <ArrowIcon className="left" onClick={onClickPrevArrow}>
                    <MdArrowBackIosNew size={16} />
                  </ArrowIcon>
                  <AntdDatePicker.RangePicker
                    allowClear={false}
                    onChange={onChangeDate as any}
                    value={[
                      dayjs(store.formFilter.form.startRequestDate),
                      dayjs(store.formFilter.form.endRequestDate),
                    ]}
                    // format={(e) => {
                    //   const { startRequestDate, endRequestDate } =
                    //     store.formFilter.form;
                    //   return `${startRequestDate} ~ ${endRequestDate}`;
                    // }}
                  />
                  {/* <AntdDatePicker
                    allowClear={false}
                    picker="week"
                    onChange={onChangeDate as any}
                    value={dayjs(store.formFilter.form.weekDate)}
                  /> */}
                  <ArrowIcon className="right" onClick={onClickNextArrow}>
                    <MdArrowBackIosNew size={16} />
                  </ArrowIcon>
                </DateSpace>
              </DateCell>

              <ExtendedLayoutCell>
                <LayoutTitle className="titLine">서비스 이용 통계</LayoutTitle>
                <DashboardServiceStats />
              </ExtendedLayoutCell>
              <ExtendedLayoutCell>
              <LayoutTitle className="titLine">성공률</LayoutTitle>
                <DashboardMessageStats />
              </ExtendedLayoutCell>
            </Space>

            <Space>
              <LayoutCell style={{}}>
                <LayoutTitle className="titLine">공지사항</LayoutTitle>
                <PriceGroup>
                <AntdTable_Notice
                  className="ant-table"
                  style={{
                    // minHeight: 440,
                    height: "calc(100% - 82px)",
                    borderTop: "1px solid #dfdfdf",
                    borderBottom: "1px solid #dfdfdf",
                    userSelect: "none",
                  }}
                  rowKey=""
                  tableLayout="fixed"
                  // scroll={{ x: 520, y: "100%" }}
                  dataSource={dataGetNotices?.data.body.data || []}
                  pagination={false}
                  onRow={(v: NoticeResponseData) => {
                    return {
                      onClick: () => {
                        onClickNoticeDetail(v.noticeIdx)
                      },
                    };
                  }}
                
                  columns={[
                    {
                      title: "번호",
                      dataIndex: "noticeIdx",
                      key: "noticeIdx",
                      align:"center",
                      width: 35,
                      render: noticeidx =>{
                       
                        if(dataGetNotices?.data.body.data[noticeidx-1].level==="1")
                        return <p><div>{noticeidx}</div></p>
                        else if(dataGetNotices?.data.body.data[noticeidx-1].level==="2")
                        return <p><div>{noticeidx}</div></p>
                        else if(dataGetNotices?.data.body.data[noticeidx-1].level==="3")
                        return <p><div>{noticeidx}</div></p>
                        else if(dataGetNotices?.data.body.data[noticeidx-1].level==="4")
                        return <p><div>{noticeidx}</div></p>

                      }
  
                    },
                    {
                      title: "제목",
                      dataIndex: "title",
                      key: "title",
                      align:"center",
                      width: 155,
                      render: title =>{

                        return(
                          <Tooltip
                          title={
                            <TagSpace>
                             {title}
                            </TagSpace>
                          }
                        >
                          <TagSpace className='overflow-hide'>
                            <p style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace:"nowrap",
                              width: "100%",
                              textAlign:"left"
                            }} >{title}</p>
                          </TagSpace>
                        </Tooltip>
                      
                        )
                      }
                    },
                    {
                      title: "날짜",
                      dataIndex: "createAt",
                      key: "createAt",
                      align:"center",
                      width: 65,
                      render: date => {
                        
                        return(

                          <p>{dayjs(date).format('YYYY-MM-DD')}</p>
                          
                        )
                          
                        
  
                      },
                    }
                  ]}
                />
                  {/* <List>
                  {dataGetNotices?.data.body.data.map((v) => (
                  <List.Item onClick={() => onClickNoticeDetail(v.noticeIdx)}>

                    <Typography.Text mark>[공지]</Typography.Text> {v.title}
                  </List.Item>
                    
    
          ))}

              
                  </List> */}

                  {/* <PriceItem>
                    <PriceItemLabel>
                      <p>{store.chargeStats[1].requestDate.slice(0, 4)}</p>
                      <p>{store.chargeStats[1].requestDate.slice(5)}월</p>
                    </PriceItemLabel>
                    <PriceItemValue>
                      <p>이용 금액</p>
                      <p>
                        <span>
                          {Formatter.통화(
                            store.chargeStats[1].chargedBalance * 1000
                          )}
                        </span>
                        원
                      </p>
                      <p>{store.chargeStats[1].requestDate}</p>
                    </PriceItemValue>
                  </PriceItem>
                  <PriceItem>
                    <PriceItemLabel>
                      <p>{store.chargeStats[0].requestDate.slice(0, 4)}</p>
                      <p>{store.chargeStats[0].requestDate.slice(5)}월</p>
                    </PriceItemLabel>
                    <PriceItemValue>
                      <p>실시간 이용 금액</p>
                      <p>
                        <span>
                          {Formatter.통화(
                            store.chargeStats[0].chargedBalance * 1000
                          )}
                        </span>
                        원
                      </p>
                      <p>{dayjs().format("YYYY.MM.DD HH:mm")} 기준</p>
                    </PriceItemValue>
                  </PriceItem> */}
                </PriceGroup>
              </LayoutCell>
              <LayoutCell>
                <LayoutTitle className="titLine">요금 내역 통계(최근6개월)</LayoutTitle>
                <DashboardChargeStatus />
              </LayoutCell>
            </Space>
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      <AntdModal
        visible={Boolean(selectedNotice)}
        draggable
        centered
        title={selectedNotice?.title || ""}
        style={{
          maxWidth: "600px",
          width: "100%",
        }}
        cancelButtonProps={{
          hidden: true,
        }}
        okText="확인"
        onOk={() => setSelectedNotice(undefined)}
      >
        <div>{selectedNotice?.content}</div>
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding-top:34px;
  @media ${device.laptop.max} {
    padding-top:0;
  }
`;

const ExtendedLayoutRow = styled(LayouRow)`
  height: 100%;
  @media ${device.laptop.max} {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
  }

  // TODO : 수정하기.
  /* @media ${device.laptop.max} {
    svg {
      height: 300px;
    }
  } */
`;

const ExtendedLayoutCell = styled(LayoutCell)`
  position: relative;
`;



const Content = styled.div`
  position: relative;
  width: 100%;
  @media ${device.laptop.max} {
    margin-top: 40px;
  }
`;

const Space = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  & > div {
    width: fit-content;
    margin-left: auto;
    max-height: 400px;
    max-width: 60%;
  }

  &:first-child {
    & > div {
      width: 100%;
      max-width: calc(60% - 15px);
      &:last-child {
        max-width: calc(40% - 15px);
      }
    }
  }
  &:last-child {
    & > div {
      width: 100%;
      &:first-child {
        max-width: calc(30%);
      }
      &:last-child {
        max-width: calc(70%);
      }
    }
  }

  @media ${device.laptop.max} {
    flex-flow: column;
    & > div {
      /* width: 100% !important; */
      max-width: 100% !important;
      max-height: auto;
    }
    &:first-child {
      & > div {
        width: 100%;
        &:last-child {
          max-width: 100%;
        }
      }
    }
    &:last-child {
      & > div {
        /* max-width: 100% !important; */
        width: 100%;
        &:first-child {
          max-width: 100%;
        }
      }
    }
  }
`;

const DateCell = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  padding-bottom: 12px;
`;

const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #898989;
    cursor: pointer;
  }
  &.right {
    svg {
      transform: rotateY(-180deg);
    }
  }
`;

const DateSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
`;

const PriceGroup = styled.div`
  width: 100%;
`;
const PriceItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  &:first-child {
    margin-bottom: 30px;
    opacity: 0.8;
  }
`;
const PriceItemLabel = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 20px 25px;
  border-radius: 6px;
  border: 2px solid #26395d;
  text-align: center;
  color: #26395d;
  font-weight: bold;
`;

const PriceItemValue = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  p {
    span {
      font-weight: 800;
      color: #26395d;
    }
  }
`;
const TagSpace = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 4px;
  &.overflow-hide {
    flex-flow: row;
    overflow: hidden;
  }
`;
