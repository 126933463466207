import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Form,
  Input,
  message,
  Popover,
  Radio,
  RadioChangeEvent,
  Select,
  Tag,
  Upload,
  AutoComplete,
  Typography
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RangePickerProps } from "antd/lib/date-picker";
import { RcFile } from "antd/lib/upload";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useRef,useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAccessToken } from "../auth";
import {
  AddedMessageModalContent,
  AddressBookModalContent,
  AntdButton,
  AntdDatePicker,
  AntdModal,
  AntdTable,
  ByteText,
  LayouRow,
  LayoutCell,
  LayoutContent,
  MessageOrTemplateModalContent,
} from "../components";
import { device } from "../device";
import {
  AttachedResponseDTO,
  CompanyCertKakaoCreateDTO,
  ContentType,
  CustormerControllerAddToListParams,
  CustormerReadResponseData,
  MessageSenderAlimtalkDTO,
  MessageSenderMmsDTO,
  MessageSenderSmsDTO,
  RecipientList,
  SmsTemplateCreateDTO,
} from "../generated";
import { privateApi } from "../react-query";
import {
  useAppStore,
  useMessageSendStore,
  useMessageSetStore,
  useAddressBookListStore
} from "../stores";
import { getByte, isSuccess, Pattern } from "../utils";

import {
  AiOutlineCloseCircle,
  AiOutlineQuestionCircle,
  AiOutlineReload,
} from "react-icons/ai";
import { BsExclamationCircle, BsClockHistory, BsQuestionCircleFill } from "react-icons/bs";
import { MdSend, MdEmail, MdOutlineAddBox, MdAdd } from "react-icons/md";
import { AiFillMessage } from "react-icons/ai";
import { RiFileExcel2Line } from "react-icons/ri";
import { FiUserPlus } from "react-icons/fi";
import { CgSoftwareDownload } from "react-icons/cg";
import getPhoneNumber from "../utils/getPhoneNumber";



interface IProps {}

/**
 *
 *  SMS : 90byte 미만
 *  MMS : 90byte 이상 or 첨부파일 업로드
 */

// const autoResizeTextarea = () => {
//   let textarea = document.querySelector('.autoTextarea') as any;

//   if (textarea) {
//     textarea.style.height = 'auto';
//     let height = textarea.scrollHeight; // 높이
//     textarea.style.height = `${height + 6}px`;
//   }
// };

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  // return current && current < dayjs().endOf('day');
  return current < dayjs().add(-1, "day").endOf("day");
};

const disabledDateTime = (v: Dayjs) => {
  console.log({ v });
  if (!v || dayjs(v).get("days") === dayjs().get("days")) {
    return {
      disabledHours: () => range(0, 24).filter((v) => v < dayjs().hour()),
      disabledMinutes: () => range(0, 60).filter((v) => v <= dayjs().minute()),
      // disabledSeconds: () => [55, 56],
    };
  } else {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  }
};

const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherGetMessage = () =>
  privateApi.message.messageControllerReadAll({
    sortBy: "requestDate",
    sortOrder: "DESC",
    messageType: "all",
    statisticsType: "daily",
    page: 1,
    pageSize: 1,
    startRequestDate: dayjs().add(-1, "day").format("YYYY-MM-DD"),
    endRequestDate: dayjs()
      .add(+1, "day")
      .format("YYYY-MM-DD"),
  });
const fetcherGetMessageWithId = (id : string) =>
  privateApi.message.messageControllerReadOne(id);

const fetcherSendSMS = (params: MessageSenderSmsDTO) =>
  privateApi.message.messageControllerSmsSender(params);

const fetcherSendMMS = (params: MessageSenderMmsDTO) =>
  privateApi.message.messageControllerMmsSender(params);

const fetcherSendAlimtalk = (params: MessageSenderAlimtalkDTO) =>
  privateApi.message.messageControllerAlimtalkSender(params);

const fetcherFileUpload = (params: any) => {
  return privateApi.message.messageControllerMmsAttached(params);
};

// REVIEW : 발신프로필 추가 API 작성 필요.
const fetcherCreateKakaoProfile = (params: CompanyCertKakaoCreateDTO) =>
  privateApi.company.companyControllerCreateKakaoProfile(params);

const fetcherUploadExcel = (params: { files: File }) =>
  privateApi.custormer.custormerControllerExcelAttached(params);

const fetcherAddSms = (params: SmsTemplateCreateDTO) =>
  privateApi.sms.smsControllerCreateTemplate(params);

const fetcherDownloadExcel = () =>
  privateApi.custormer.custormerControllerDownload({ format: "blob" });

const fetcherAddTodoList = (params: CustormerControllerAddToListParams) =>
  privateApi.custormer.custormerControllerAddToList({
    custormerPhone1: params.custormerPhone1,
  });
  interface List {
  
    custormerIdx: number;
 
    custormerName: string;
  
    custormerPhone1: string;
  
  }
export const MessageSend: React.FC<IProps> = () => {
  const appStore = useAppStore((s) => s);
  const store = useMessageSendStore((s) => s);
  const messageSetStore = useMessageSetStore((s) => s);


  const navigate = useNavigate();
  const [array,setArray] = useState<CustormerReadResponseData[]>([]);
  const [array2,setArray2] = useState<React.Key[]>([]);
  const [List,setList] = useState<List[]>([]);
  const [savedList,setSavedList] = useState<List[]>([]);
  const [lastList,setLastList] = useState<List[]>([]);
  const [reallastList,setrealLastList] = useState<List[]>([]);
  const [clicked,setClicked] = useState(false);
  const [sendclicked,setsendClicked] = useState(false);
  const [RecentReqId,setRecentReqId] = useState("");

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const {
    isLoading: loadingGetMember,
    data: dataGetMember,
    refetch,
  } = useQuery(["memberControllerReadCurrentMember"], fetcherGetMember, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("memberControllerReadCurrentMember onSuccess: ", e);
        const { certKakaoInformation, certPhoneInformation } =
          e.data.body.data.companyInformation;
        certPhoneInformation[0]?.phoneCertFlag === 1?
        store.formReqMessage.onChangeForm({
          selectedPhone : certPhoneInformation[0],
        })
        :
        store.formReqMessage.onChangeForm({
         
        });
        certKakaoInformation[0]?.status === "YSC03"?
        store.formReqMessage.onChangeForm({
          selectedProfile : certKakaoInformation[0],
        })
        :
        store.formReqMessage.onChangeForm({
         
        });
        return;
      }
      return message.error(e.data.header.resultMessage);
    },
    onError: (e) => {
      console.log("memberControllerReadCurrentMember onError: ", e);
    },
  });

  const {
    isLoading: loadingGetMessage,
    data: dataGetMessage,
    mutate: mutateGetMessage,
  } = useMutation(fetcherGetMessage, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("messageControllerReadAll onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("messageControllerReadAll onError: ", e);
    },
  });
  const {
    isLoading: loadingGetMessageWithId,
    data: dataGetMessageWithId,
    mutate: mutateGetMessageWithId,
  } = useMutation(fetcherGetMessageWithId, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("messageControllerReadAll onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("messageControllerReadAll onError: ", e);
    },
  });

  const { isLoading: loadingSendSms, mutate: mutateSendSms } = useMutation(
    fetcherSendSMS,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerSmsSender onSuccess: ", e);
          message.info("전송되었습니다");
          console.log(e.data.body.data.requestId)
          localStorage.setItem('RecentReqID', e.data.body.data.requestId);  
          localStorage.getItem('RecentReqID') !== null ? 
          mutateGetMessageWithId(localStorage.getItem('RecentReqID') as string)
            :
          mutateGetMessage();        }
      },
      onError: (e) => {
        console.log("messageControllerSmsSender onError: ", e);
      },
    }
  );

  const { isLoading: loadingSendMms, mutate: mutateSendMms } = useMutation(
    fetcherSendMMS,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerMmsSender onSuccess: ", e);
          message.info("전송되었습니다");
          console.log(e.data.body.data.requestId)
          localStorage.setItem('RecentReqID', e.data.body.data.requestId); 
          localStorage.getItem('RecentReqID') !== null ? 
          mutateGetMessageWithId(localStorage.getItem('RecentReqID') as string)
            :
          mutateGetMessage();
        }
      },
      onError: (e) => {
        console.log("messageControllerMmsSender onError: ", e);
      },
    }
  );

  const { isLoading: loadingSendAlimtalk, mutate: mutateSendAlimtalk } =
    useMutation(fetcherSendAlimtalk, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerAlimtalkSender onSuccess: ", e);
          message.info("전송되었습니다");
          console.log(e.data.body.data.requestId)
          localStorage.setItem('RecentReqID', e.data.body.data.requestId); 
          localStorage.getItem('RecentReqID') !== null ? 
          mutateGetMessageWithId(localStorage.getItem('RecentReqID') as string)
            :
          mutateGetMessage();        }
      },
      onError: (e) => {
        console.log("messageControllerAlimtalkSender onError: ", e);
      },
    });

  const { isLoading: loadingUpload, mutateAsync: mutateUpload } = useMutation(
    fetcherFileUpload,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerMmsAttached onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("messageControllerMmsAttached onError: ", e);
      },
    }
  );

  const { isLoading: loadingUploadExcel, mutate: mutateUploadExcel } =
    useMutation(fetcherUploadExcel, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerExcelAttached onSuccess: ", e);
          message.info("엑셀파일이 업로드되었습니다");
          store.onChangeReceivedNumbersFromExcel(e.data.body.data);
        }
      },
      onError: (e) => {
        console.log("custormerControllerExcelAttached onError: ", e);
      },
    });

  const { isLoading: loadingAddSms, mutate: mutateAddSms } = useMutation(
    fetcherAddSms,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerCreateTemplate onSuccess: ", e);
          store.modalAddMessage.onClose();
          message.info("추가되었습니다");
          // if (store.formSms.form.query.page === 1) {
          //   refetchGetSmss();
          // } else {
          //   store.formSms.onChangeForm({
          //     query: {
          //       page: 1,
          //       pageSize: 10,
          //     },
          //   });
          //   setTimeout(() => {
          //     refetchGetSmss();
          //   }, 100);
          // }
        }
      },
      onError: (e) => {
        console.log("smsControllerCreateTemplate onError: ", e);
      },
    }
  );

  const { isLoading: loadingExcelDownload, mutate: mutateExcelDownload } =
    useMutation(fetcherDownloadExcel, {
      onSuccess: (e) => {
        console.log("custormerControllerDownload onSuccess: ", e);
        const fileName = "temp.xlsx";

        // const blob = new Blob([e.data], { type: 'image/png' })
        const path = window.URL.createObjectURL(e.data as any);
        const link = document.createElement("a");
        link.href = path;
        link.download = fileName;
        link.click();
        link.remove();
      },
      onError: (e) => {
        console.log("custormerControllerDownload onError: ", e);
      },
    });

  const { isLoading: loadingAddTodoList, mutate: mutateAddTodoList } =
    useMutation(fetcherAddTodoList, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerAddToList onSuccess: ", e);
          store.onAddReceivedNumber({
            custormerIdx: e.data.body.data.custormerIdx,
            custormerName: e.data.body.data.custormerName,
            custormerPhone1: e.data.body.data.custormerPhone1,
          });
      //      store.onAddReceivedList({
      //       custormerIdx: e.data.body.data.custormerIdx,
      //       custormerName: e.data.body.data.custormerName,
      //       custormerPhone1: e.data.body.data.custormerPhone1,
      // })
        }
      },
      onError: (e) => {
        console.log("custormerControllerAddToList onError: ", e);
      },
    });


  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CustormerReadResponseData[]
    ) => {
      store.onChangeSelectedReceivedNumbers(selectedRows);
      setArray2(selectedRowKeys)
      setArray(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "", // Column configuration not to be checked
      name: record.name,
    }),
  };

  /**
   * [발송방법 radio] 변경시
   */
  const onChangeSendMethod = (e: RadioChangeEvent) => {
    if (dataGetMember?.data) {
      const { certKakaoInformation, certPhoneInformation } =
        dataGetMember?.data.body.data.companyInformation;
      if (e.target.value === "SMS") {
        if (certPhoneInformation.length < 1) {
          // TODO : 발신 번호 등록 팝업 띄우기
          return store.onChangeSendMethod(e.target.value);
        } else {
          certPhoneInformation[0].phoneCertFlag === 1?
          store.onChangeSendMethod(
            e.target.value,
            certPhoneInformation[0],
            undefined
          )
          :
          store.onChangeSendMethod(
            e.target.value,
            undefined
          )
        }
      } else {
        if (certKakaoInformation.length < 1) {
          // TODO : 발신 프로필 팝업 띄우기
          return store.onChangeSendMethod(e.target.value);
        } else {
          certKakaoInformation[0].status === "YSC03"?
          store.onChangeSendMethod(
            e.target.value,
            undefined,
            certKakaoInformation[0]
          )
          :
          store.onChangeSendMethod(
            e.target.value,
            undefined
           
          )
        }
      }
    }
  };

  /**
   * [발송유형 radio] 변경시
   */
  const onChangeSendType = (e: RadioChangeEvent) => {
    store.onChangeSendType(e.target.value);
  };

  /**
   * [주소록에서 추가] 클릭시
   */
  const onClickAddressBook = () => {
    store.modalAddressBook.onOpen();
  };

  /**
   * [문자내용] 업데이트
   */
  const onChangeFormMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = e;
      store.formReqMessage.onChangeForm({ message: value });
    },
    []
  );

  /**
   * [문자제목] 업데이트
   */
  const onChangeMmsTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      store.formReqMessage.onChangeForm({
        title: value,
      });
    },
    []
  );

  /**
   * [수신번호 추가] 업데이트
   */
  const onChangeFormaddedReceivedNumberInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      store.formReqMessage.onChangeForm({ addedReceivedNumberInput: value });
    },
    []
  );

  /**
   * [수신번호 추가] 클릭시
   */
  const onAddReceivedNumber = useCallback(() => {
    const addedReceivedNumberInput =
      store.formReqMessage.form.addedReceivedNumberInput + "";
    console.log({ addedReceivedNumberInput });
    if (addedReceivedNumberInput.length >= 10) {
      if (Pattern.checkOptional(Pattern.숫자).test(addedReceivedNumberInput)) {
        const currentReceivedNumbers = store.receivedNumbers.map(
          (v) => v.custormerPhone1
        );
        if (!currentReceivedNumbers.includes(addedReceivedNumberInput)) {
          mutateAddTodoList({ custormerPhone1: addedReceivedNumberInput });
          store.formReqMessage.onChangeForm({ addedReceivedNumberInput: "" });
          return;
        }
        return message.warn("이미 등록된 수신번호 입니다.");
      }
      return message.warn("수신번호 숫자로만 입력해주세요");
    }
    return message.warn("수신번호 10~11자리의 숫자를 입력해주세요");
  }, [
    store.formReqMessage.form.addedReceivedNumberInput,
    store.receivedNumbers,
  ]);
  /**
   * [최근목록] 클릭시
   */
  const onAddListedNumber = useCallback((e : string) => {
    const addedReceivedNumberInput = e+""
    console.log({ addedReceivedNumberInput });
    store.formReqMessage.onChangeForm({
      addedReceivedNumberInput : ""
    }
    )
    if (addedReceivedNumberInput.length >= 10) {
      if (Pattern.checkOptional(Pattern.숫자).test(addedReceivedNumberInput)) {
        const currentReceivedNumbers = store.receivedNumbers.map(
          (v) => v.custormerPhone1
        );
        if (!currentReceivedNumbers.includes(addedReceivedNumberInput)) {
          mutateAddTodoList({ custormerPhone1: addedReceivedNumberInput });
          // store.formReqMessage.onChangeForm({ addedReceivedNumberInput: "" });
          return;
        }
        return message.warn("이미 등록된 수신번호 입니다.");
      }
      return message.warn("수신번호 숫자로만 입력해주세요");
    }
    return message.warn("수신번호 10~11자리의 숫자를 입력해주세요");
  }, [
    
    store.receivedNumbers,
  ]);

  /**
   * [선택번호 삭제] 클릭시
   */
  const onRemoveSelectedReceivedNumbers = useCallback(() => {
    const customerIdxs = store.selectedReceivedNumbers.map(
      (v) => v.custormerIdx
    );
    if (customerIdxs.length > 0) {
      store.onRemoveSelectedReceivedNumbers(customerIdxs);
    }
  }, [store.selectedReceivedNumbers]);

  /**
   * [발신번호 선택] 변경시
   */
  const onChangeSelectedPhone = (idx: number) => {
    if (dataGetMember?.data) {
      const selectedPhone =
        dataGetMember.data.body.data.companyInformation.certPhoneInformation.find(
          (v) => v.idx === idx
        );
      store.formReqMessage.onChangeForm({
        selectedPhone,
      });
    }
  };

  /**
   * [발신프로필 선택] 변경시
   */
  const onChangeSelectedProfile = (idx: number) => {
    if (dataGetMember?.data) {
      const selectedProfile =
        dataGetMember.data.body.data.companyInformation.certKakaoInformation.find(
          (v) => v.idx === idx
        );
      store.formReqMessage.onChangeForm({
        selectedProfile,
      });
    }
  };

  /**
   * [광고성 문자] 변경시
   */
  const onCheckAds = useCallback((e: CheckboxChangeEvent) => {
    const {
      target: { checked },
    } = e;
    store.formReqMessage.onChangeForm({
      checkedAds: checked,
    });
  }, []);

  /** [첨부파일] 이미지 삭제시 */
  const onRemoveFile = useCallback(
    (index: number) => {
      store.formReqMessage.onChangeForm({
        files: store.formReqMessage.form.files.filter((v, i) => i !== index),
        previews: store.formReqMessage.form.previews.filter(
          (v, i) => i !== index
        ),
      });
    },
    [store.formReqMessage.form.files, store.formReqMessage.form.previews]
  );

  /**
   * [예약일시] 변경시
   */
  const onChangeReservationDate = useCallback((e: Dayjs | null) => {
    if (e) {
      if (e <= dayjs()) {
        store.formReqMessage.onChangeForm({
          reservationDate: dayjs().add(5, "minute"),
        });
        return message.warn("현재시간 이후로 선택가능합니다");
      }
    }
    store.formReqMessage.onChangeForm({
      reservationDate: e || undefined,
    });
  }, []);

  /**
   * [발송현황] refresh icon 클릭시
   */
  const onClickRefreshIcon = useCallback((e: any) => {
    e.preventDefault();
    mutateGetMessage();
    // console.log(RecentReqId)
    mutateGetMessageWithId(localStorage.getItem('RecentReqID') as string);
  }, []);

  /**
   * [엑셀파일 추가] 클릭시
   */
  const onClickUploadExcel = () => {
    inputFileRef.current?.click();
  };

  /**
   * [엑셀파일 추가] 클릭 이후 업로드시
   */
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = e;
    if (files && files?.length > 0) {
      const file = files[0];
      mutateUploadExcel({
        files: file,
      });
    }
    (e.target as any).value = null;
  };
  /**
   * 발송 클릭시
   */
  const onSend = async () => {
    const { form } = store.formReqMessage;
    if (form.message.replace(/ /g, "").length < 1) {
      return message.warn("메시지를 입력해주세요");
    }
    if (store.receivedNumbers.length < 1) {
      return message.warn("수신번호 목록을 등록해주세요");
    }
    let requestDate: string | undefined = undefined;
    if (store.sendType === "RESERVATION") {
      if (!form.reservationDate) {
        return message.warn("예약일시를 선택해주세요");
      }
      if (form.reservationDate <= dayjs()) {
        return message.warn("예약일시는 현재시각 이후로 선택해주세요");
      }
      requestDate = form.reservationDate.format("YYYY-MM-DD HH:mm");
    }

    if (store.sendMethod === "SMS") {
      if (!form?.selectedPhone) {
        return message.warn("발신번호를 선택해주세요");
      }

      // SMS or MMS
      if (form.files.length > 0 || getByte(form.message) > 90) {
        // console.log("MMS")
        // REVIEW : 제목 비필수로 변경됨.
        // if (form.title.replace(/ /g, "").length < 1) {
        //   return message.warn("(MMS의 경우) 문자 제목을 입력해주세요");
        // }

        let attachFileIds: number[] | undefined;
        if (form.files.length > 0) {
          console.log("form.files: ", form.files);

          const headers = new Headers();
          headers.append("Authorization", `Bearer ${getAccessToken()}`);

          let formData = new FormData();
          form.files.forEach((v) => {
            formData.append("files", v);
          });
          // MMS
          // const {
          //   data: {
          //     body: { data },
          //     header,
          //   },
          // } = await mutateUpload({
          //   files: form.files,
          // });

          const res = await fetch(
            privateApi.baseUrl + "/message/sender/upload",
            {
              method: "POST",
              body: formData,
              headers,
            }
          );
          const {
            body: { data },
            header,
          } = (await res.json()) as AttachedResponseDTO;

          if (!header.isSuccess) {
            return;
          }
          // REVIEW : 변경된사항
          // TODO : 수정하기.
          attachFileIds = data.map((v) => v.fileId);
        }
        mutateSendMms({
          attachFileId: attachFileIds,
          requestDate,
          body: form.message,
          title: form.title,
          isAd: form.checkedAds ? 1 : 0,
          sendNo: form.selectedPhone.phone,
          recipientList: store.receivedNumbers.map(
            (v) =>
              ({
                recipientNo: v.custormerPhone1,
                custormerIdx: v.registerDate === "" ? "" : v.custormerIdx || "",
                custormerName: v.custormerName || "",
              } as RecipientList)
          ),
        });
      } else {
        // console.log("SMS")
        // SMS
        mutateSendSms({
          body: form.message,
          requestDate,
          isAd: form.checkedAds ? 1 : 0,
          sendNo: form.selectedPhone.phone,
          recipientList: store.receivedNumbers.map(
            (v) =>
              ({
                recipientNo: v.custormerPhone1,
                // custormerIdx: v.registerDate === "" ? "" : v.custormerIdx || "",
                custormerIdx: v.custormerIdx === null ? null : v.custormerIdx || "",
                custormerName: v.custormerName || "",
              } as RecipientList)
              ),
            });
      }
    } else {
      // Alimtalk
      if (!store.selectedAlimtalkTemplate) {
        return message.warn("발신 템플릿을 선택해주세요");
      }
      mutateSendAlimtalk({
        plusFriendId: store.selectedAlimtalkTemplate.plusFriendId,
        templateCode: store.selectedAlimtalkTemplate.templateCode,
        requestDate,
        templateParameter: store.formReqMessage.form.updatedTemplateParameter
          ? JSON.parse(store.formReqMessage.form.updatedTemplateParameter)
          : undefined,
        recipientList: store.receivedNumbers.map(
          (v) =>
            ({
              recipientNo: v.custormerPhone1,
              custormerIdx: v.registerDate === "" ? "" : v.custormerIdx || "",
              custormerName: v.custormerName || "",
            } as RecipientList)
        ),
        body: form.message,
      });
    }

    setsendClicked(true)
    // TODO : 전송완료시, 3개의 mutation내에 필드값이 초기화 되도록 수정하기.
  };

  /**
   * [발신번호 등록] 클릭시
   */
  const onRegisterReqPhone = () => {
    navigate("/message/set");
    messageSetStore.modalAddReceivedPhone.onOpen();
  };
  /**
   * [발신프로필 등록] 클릭시
   */
  const onRegisterReqProfile = () => {
    navigate("/message/set");
    messageSetStore.onChangeTab("ALARM");
    messageSetStore.modalAddReceivedProfile.onOpen();
  };
  /**
   * [발신 템플릿 등록] 클릭시
   */
  const onRegisterReqTempelte = () => {
    navigate("/message/set");
    messageSetStore.onChangeTab("ALARM");
    messageSetStore.modalAddTemplate.onOpen();
  };
  /**
   * [문자 저장하기] 모달 팝업 확인 클릭시
   */
  const onConfirmModalAddMessage = () => {
    const { templateContent, templateName, isAd } =
      store.formReqMessage.form.addSmsQuery;
    if (templateContent && templateName) {
      mutateAddSms({
        templateName,
        templateContent,
        isAd,
      });
    }
  };

  useEffect(() => {
    console.log(localStorage.getItem('number'))
    // localStorage.removeItem('number')
    if(localStorage.getItem('number') === null || localStorage.getItem('number') === "undefined"){

      localStorage.setItem('number',JSON.stringify([]))
    
    }
    if(localStorage.getItem('RecentReqID') !== null || localStorage.getItem('RecentReqID') !== "undefined"){

     setRecentReqId(localStorage.getItem('RecentReqID') as string)
     console.log(localStorage.getItem('RecentReqID') as string)
     mutateGetMessageWithId(localStorage.getItem('RecentReqID') as string)
    
    }
      // localStorage.setItem('number',JSON.stringify([]))
    

      let saved = JSON.parse(localStorage.getItem('number') as string)
      setSavedList(saved)
   
  

    
 
    return () => {
      setTimeout(() => {
        store.onReset();
      });
    };
  }, []);

  useEffect(() => {
    setList(store.receivedNumbers.map((v)=>{
      return ({
        custormerName :  v.custormerName,
        custormerPhone1 :  v.custormerPhone1,
        custormerIdx : v.custormerIdx
      })
    }))
    setsendClicked(false)
  }, [sendclicked]);
  
  useEffect(() => {
    console.log(localStorage.getItem('number'))
    localStorage.setItem('number',JSON.stringify(savedList?.concat(List)))
    let saved = JSON.parse(localStorage?.getItem('number')as string)
    setLastList(saved)
   

    // console.log(List)
    
  }, [List]);
  useEffect(() => {

    console.log(List)
    console.log(savedList)
    
  }, [savedList]);
  useEffect(() => {

    let result : any = lastList.filter((v, i) => {

      return (
        lastList.findIndex((v2,j)=>{
          return (v.custormerName+v.custormerPhone1) === (v2.custormerName+v2.custormerPhone1)
          ;
        }) === i
      )
      })
    
    console.log(result)
    setrealLastList(result)
  }, [lastList]);
  useEffect(() => {

console.log(reallastList)
  }, [reallastList]);
  useEffect(() => {

    if(clicked === true){
      // store.onResetList()
      setSavedList([])
      setList([])
      setrealLastList([])
      localStorage.setItem('number',JSON.stringify([]))

      setClicked(false)

    }
  }, [clicked]);
  

  const renderTitle = (title: string) => (
    <div  style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
    <span>
      {title}
    </span>
    <span onClick={()=>{
      setClicked(true);
      
    }}>지우기</span>
    </div>
  );
  const renderItem = (title: string,name: string ,key : number) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {name}
        <span></span> {title}
        {/* <span></span> {key} */}
       
      </div>
    ),
    key : key
  });
  const options = [
    {
      label: renderTitle('최근사용 목록'),
      options :  reallastList.map((v)=>{
        return renderItem(v.custormerPhone1,v.custormerName,v.custormerIdx)
      })
    }
  ];

  useEffect(() => {

    rowSelection.onChange(array2,array)
    // clicked ? setClicked(false) : setClicked(true); 
  }, [array]);
  // useEffect(() => {
  //   onAddListedNumber(e)
  // }, [usedNumber]);
  return (
    <Container>
      <ExtendedLayoutRow>
        <input
          style={{
            display: "none",
          }}
          name=""
          id=""
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={inputFileRef}
          onChangeCapture={onFileChangeCapture}
        />
        <LayoutContent>
          <Content>
            <ExtendedLayoutCell>
              <Form layout="vertical">
                <Form.Item required label="발송방법">
                  <Radio.Group
                    value={store.sendMethod}
                    onChange={onChangeSendMethod}
                  >
                    <Radio value="SMS"> <MdEmail size={17} style={{ marginBottom: '-3px', color: '#3a85f2' }}/> 문자</Radio>
                    <Radio value="ALARAM_TALK"> <AiFillMessage size={17} style={{ marginBottom: '-3px', color: '#38181b', background: 'var(--talk-color)', padding: '2px', borderRadius: '2px' }}/> 알림톡</Radio>
                  </Radio.Group>
                </Form.Item>
                {store.sendMethod === "SMS" ? (
                  <Form.Item
                    required
                    label={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p>발신번호 선택</p>
                        {!appStore.isAccessKey && (
                          <div className="space" onClick={onRegisterReqPhone}>
                            발신번호 등록
                            <MdOutlineAddBox  
                              size={16}
                              style={{ marginLeft: 1 }}
                            />
                          </div>
                        )}
                      </div>
                    }
                    id="phone"
                  >
                    <Select
                      id="phone"
                      loading={loadingGetMember}
                      value={
                        store.formReqMessage.form?.selectedPhone?.idx as any
                      }
                      onChange={onChangeSelectedPhone}
                      options={
                        !dataGetMember?.data
                          ? []
                          : 
                          // dataGetMember.data.body.data.companyInformation.certPhoneInformation.map(
                          //     (v) => ({
                          //       label: v.phone,
                          //       value: v.idx,
                          //     })
                          //   )
                          dataGetMember.data.body.data.companyInformation.certPhoneInformation.filter(
                            (v)=>v.phoneCertFlag == 1
                          ).map(
                                (v) => ({
                                  label: getPhoneNumber(v.phone),
                                  value: v.idx,
                                })
                              )
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    required
                    label={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p>발신 프로필 선택</p>
                        {!appStore.isAccessKey && (
                          <div className="space" onClick={onRegisterReqProfile}>
                            <BsExclamationCircle />
                            발신 프로필 등록
                          </div>
                        )}
                      </div>
                    }
                    id="profile"
                  >
                    <Select
                      id="profile"
                      loading={loadingGetMember}
                      value={
                        store.formReqMessage.form?.selectedProfile?.idx as any
                      }
                      onChange={onChangeSelectedProfile}
                      options={
                        !dataGetMember?.data
                          ? []
                          : dataGetMember.data.body.data.companyInformation.certKakaoInformation.filter(
                            (v)=>v.status = "YSC03" ).map(
                              (v) => ({
                                label: v.plusFriendId,
                                value: v.idx,
                              })
                            )
                      }
                    />
                  </Form.Item>
                )}

                {store.sendMethod === "SMS" ? (
                  <Form.Item
                    // label="문자 제목"
                    label={
                      <div
                        style={{
                          display: "flex",
                          gap: 3,
                          alignItems: "center",
                        }}
                      >
                        문자제목
                        <Popover
                          placement="topLeft"
                          content={
                            <p
                              style={{
                                letterSpacing: "-.5px"
                            }}
                            >
                              <b>LMS / MMS 문자제목 미입력 시</b>,<br /> 문자 내용 일부가
                              제목으로 발송됩니다.
                            </p>
                          }
                        >
                          <BsQuestionCircleFill size={12} color="#bbb" />
                        </Popover>
                      </div>
                    }
                  >
                    <Input
                      disabled={
                        !store.formReqMessage.form!.files.length &&
                        getByte(store.formReqMessage.form.message) <= 90
                      }
                      value={store.formReqMessage.form.title}
                      onChange={onChangeMmsTitle}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item required label={
                  <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <p>발신 템플릿 선택</p>
                  {!appStore.isAccessKey && (
                    <div className="space" onClick={onRegisterReqTempelte}>
                      <BsExclamationCircle />
                      발신 템플릿 등록
                    </div>
                  )}
                </div>}
                  >

                    <ButtonInputSpace>
                      <Select
                        disabled
                        value={store?.selectedAlimtalkTemplate?.templateIdx}
                        options={
                          store.selectedAlimtalkTemplate
                            ? [
                                {
                                  label:
                                    store.selectedAlimtalkTemplate.templateName,
                                  value:
                                    store.selectedAlimtalkTemplate.templateIdx,
                                },
                              ]
                            : []
                        }
                      />
                      <AntdButton
                        isPrimary
                        disabled={!store.formReqMessage.form?.selectedProfile}
                        style={{ color: "white" }}
                        onClick={store.modalSelectTemplate.onOpen}
                      >
                        선택
                      </AntdButton>
                    </ButtonInputSpace>
                  </Form.Item>
                )}
                {store.sendMethod === "SMS" ? (
                  <Form.Item required label="문자 내용">
                    <Input.TextArea
                      // rows={4}
                      // className="autoTextarea"
                      className={
                        store.formReqMessage.form?.files.length > 0
                          ? "uploaded"
                          : ""
                      }
                      maxLength={2000}
                      value={store.formReqMessage.form.message}
                      onChange={onChangeFormMessage}
                      // onKeyDown={autoResizeTextarea}
                      // onKeyUp={autoResizeTextarea}
                    />
                    <ByteText
                      checked={store.formReqMessage.form.checkedAds}
                      onChangeCheckbox={onCheckAds}
                      length={getByte(store.formReqMessage.form.message)}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item required label="템플릿 내용">
                    <Input.TextArea
                      disabled
                      rows={8}
                      value={store.formReqMessage.form.message}
                    ></Input.TextArea>
                  </Form.Item>
                )}

                {store.sendMethod === "SMS" && (
                  <>
                    <SendMethodButtonGroup>
                      <AntdButton onClick={store.modalAddMessage.onOpen} className="btnLineblue">
                        메시지 저장하기
                      </AntdButton>
                      <AntdButton onClick={store.modalLoadMessage.onOpen} className="btnLineblue">
                        메시지 불러오기
                      </AntdButton>
                      <Upload
                        name="file"
                        // accept=".ipynb"
                        accept="image/jpg, image/jpeg, image/png"
                        // beforeUpload={(e) => {
                        // setUploading(true);
                        // }}
                        fileList={[]}
                        onChange={(info) => {
                          if (store.formReqMessage.form.files.length === 2) {
                            return message.warn(
                              "최대 첨부파일 갯수는 2건입니다"
                            );
                          }
                          if (info.file.status !== "uploading") {
                            console.log(info.file, info.fileList);
                          }
                          if (info.file.status === "uploading") {
                            // setFile(info.fileList);
                          }
                          if (info.file.status === "done") {
                            // setFile(info.fileList);
                            message.success(
                              `${info.file.name} file uploaded successfully`
                            );
                          } else if (info.file.status === "error") {
                            message.error(
                              `${info.file.name} file upload failed.`
                            );
                          } else {
                            const originFileObjs = info.fileList.map(
                              (v) => v.originFileObj as RcFile
                            );
                            const file = originFileObjs[0];
                            console.log({ file });
                            if (
                              !["image/jpg", "image/jpeg" ,"image/png"].includes(file.type)
                            ) {
                              return message.warn(
                                "첨부파일은 JPG/JPEG, PNG 형식만 가능합니다"
                              );
                            }

                            // REVIEW : 파일크기 검증 제거.
                            // 300KB 이하로만 가능.
                            // if (file.size > 300 * 1024) {
                            //   return message.warn(
                            //     "첨부파일 크기는 300KB이하로 첨부해주세요"
                            //   );
                            // }

                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              const base64data = reader.result;
                              store.formReqMessage.onChangeForm({
                                files: [
                                  ...store.formReqMessage.form.files,
                                  file,
                                ],
                                previews: [
                                  ...store.formReqMessage.form.previews,
                                  base64data as string,
                                ],
                              });
                            };
                            // console.log({ file });
                            // console.log("info.file.status: ", info.file.status);
                            return;
                          }
                        }}
                      >
                        <AntdButton className="btnLineblue">
                          첨부파일
                        </AntdButton>
                      </Upload>
                    </SendMethodButtonGroup>
                    {store.formReqMessage.form?.previews.length > 0 && (
                      <PreviewSpace>
                        {[
                          store.formReqMessage.form.previews.map((v, i) => (
                            <div key={i}>
                              <Preview src={v}>
                                <AiOutlineCloseCircle
                                  size={20}
                                  onClick={() => onRemoveFile(i)}
                                />
                              </Preview>
                              {store.formReqMessage.form?.files[i] && (
                                <FileName>
                                  {store.formReqMessage.form?.files[i].name}
                                </FileName>
                              )}
                            </div>
                          )),
                        ]}
                      </PreviewSpace>
                    )}
                  </>
                )}
              </Form>
            </ExtendedLayoutCell>
            <Divide />
            <LayoutCell className="cell-center">
              <Form layout="vertical">
                <Form.Item label="수신번호 추가">
                  <ButtonInputSpace>
                  <AutoComplete
                  options={options}
                  // maxLength={11}
                  onChange={(e)=>{
                    store.formReqMessage.onChangeForm({
                      addedReceivedNumberInput : e.replace(/[^0-9]/g, '')
                    }
                    )
                    // console.log(e)
                    
                    // console.log(store.formReqMessage.form.addedReceivedNumberInput)
                  }}
                  onSelect={(e : string)=>{
                    // console.log(e)
                    // setUsedNumber(e)
                    onAddListedNumber(e)
                  }}
                    value={store.formReqMessage.form.addedReceivedNumberInput}
                  >
                    <Input
                  //  onPressEnter={onAddReceivedNumber}
                      /> 
                
                    </AutoComplete>
                    <AntdButton
                      loading={loadingAddTodoList}
                      isPrimary
                      style={{ color: "white" }}
                      onClick={onAddReceivedNumber}
                      className="btnBlue"
                    >
                      추가
                    </AntdButton>
                  </ButtonInputSpace>
                </Form.Item>
                <Form.Item>
                  <ButtonSpace>
                    <AntdButton
                      onClick={onClickAddressBook}
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      className="btnLineblue"
                    >
                      <FiUserPlus size={16} style={{ marginRight: "4px" }}/>
                      주소록에서 추가
                    </AntdButton>
                    <div>
                      <AntdButton
                        style={{ width: "100%" }}
                        loading={loadingUploadExcel}
                        onClick={onClickUploadExcel}
                        className="btnExcelDw" 
                      >
                        <RiFileExcel2Line size={16} style={{  marginRight: "4px" }}/>
                        엑셀파일 추가
                      </AntdButton>
                      <Text>
                        <div
                          style={{
                            textAlign: "center",
                            margin: "0 auto",
                            cursor: "pointer",
                          }}
                          onClick={
                            loadingExcelDownload
                              ? undefined
                              : () => mutateExcelDownload()
                          }
                        >
                          <span>
                            엑셀양식 다운로드
                          </span>

                          <CgSoftwareDownload
                            size={16}
                            style={{ marginBottom: -2, marginLeft: 2 }}
                          />
                        </div>
                      </Text>
                    </div>
                  </ButtonSpace>
                </Form.Item>
                <Form.Item label="수신번호 목록" style={{ marginTop: 20 }}>
                  <ExtendedAntdTable
                    emptyText="수신번호를 추가해주세요."
                    className="center-table"
                    pagination={false}
                    rowKey="custormerIdx"
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                      selectedRowKeys: store.selectedReceivedNumbers.map(
                        (v) => v.custormerIdx
                      ),
                    }}
                    dataSource={store.receivedNumbers}
                    onRow={
                      (v:CustormerReadResponseData)=>{
                        return{
                          onClick: () =>{
            
                              array2.some((array2)=>array2==v.custormerIdx) ?
                              setArray2(array2.filter((array2)=>array2!==v.custormerIdx))
                              :setArray2(array2.concat(v.custormerIdx))
            
                              array.some((array)=>array.custormerIdx==v.custormerIdx) ? 
                              setArray(array.filter((array)=>array.custormerIdx!==v.custormerIdx))
                              :setArray(array.concat(v))
                          }
                        }
                        
                      }
                    }
                    columns={[
                      {
                        title: "이름",
                        dataIndex: "custormerName",
                        key: "custormerName",
                      },
                      {
                        title: "휴대폰번호",
                        dataIndex: "custormerPhone1",
                        key: "custormerPhone1",
                        render: (e, v: CustormerReadResponseData) => {
                          return (
                            <Typography>
                              {getPhoneNumber(v.custormerPhone1)}
                            </Typography>
                          );
                        },
                      },
                      {
                        title: "삭제",
                        dataIndex: "custormerIdx",
                        key: "custormerIdx",
                        align: "center",
                        width: 45,
                        render: (v) => {
                          const onClick = () =>
                          
                           store.onRemoveSelectedReceivedNumbers([v]);
                          return (
                            <div onClick={(e)=>{
                              e.stopPropagation();
                            }}>
                              <AiOutlineCloseCircle
                                size={16}
                                onClick={onClick}
                                style={{
                                  cursor: "pointer",
                                }}
                              />

                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </Form.Item>
                <TotalSpace>
                  <AntdButton
                    disabled={store.selectedReceivedNumbers.length < 1}
                    onClick={onRemoveSelectedReceivedNumbers}
                    type="dashed" danger
                  >
                    선택 번호 삭제
                  </AntdButton>
                  <Total>총 {store.receivedNumbers.length}명</Total>
                </TotalSpace>
              </Form>
            </LayoutCell>
            <Divide />
            <LayoutCell>
              <Form layout="vertical">
                <Form.Item required label="발송유형">
                  <Radio.Group
                    value={store.sendType}
                    onChange={onChangeSendType}
                  >
                    <Radio value="IMMEDIATELY"><MdSend size={17} style={{ marginBottom: '-3px', color: 'var(--primary-color)', padding: '1px', borderRadius: '2px'}}/> 즉시발송</Radio>
                    <Radio value="RESERVATION"><BsClockHistory size={17} style={{ marginBottom: '-3px', color: 'var(--primary-color)', padding: '2px', borderRadius: '2px' }}/> 예약발송</Radio>
                  </Radio.Group>
                </Form.Item>
                {store.sendType === "RESERVATION" && (
                  <Form.Item required label="예약일시">
                    <AntdDatePicker
                      format="YYYY-MM-DD HH:mm"
                      disabledDate={disabledDate as any}
                      // disabledTime={disabledDateTime as any}
                      onChange={onChangeReservationDate}
                      onOk={onChangeReservationDate}
                      value={store.formReqMessage.form.reservationDate}
                      showTime={
                        {
                          // defaultValue: store.formReqMessage.form.reservationDate,
                        }
                      }
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <AntdButton
                    className="btnSendit"
                    loading={
                      loadingSendSms ||
                      loadingSendMms ||
                      loadingSendAlimtalk ||
                      loadingUpload
                    }
                    style={{ 
                      width: "100%", 
                      height: "39px", 
                      fontSize: "14px", 
                    }}
                    onClick={onSend}
                  >
                    발송{" "}
                    {store.sendMethod === "ALARAM_TALK"
                      ? <span className='talkTxt'>(알림톡)</span>
                      : store.formReqMessage.form?.files.length > 0
                      ? "(MMS)"
                      : store.formReqMessage.form.message.length <= 90
                      ? "(SMS)"
                      : "(MMS)"}
                  </AntdButton>
                </Form.Item>
                <Status>
                  <StatusTitle>
                    발송현황
                    {dataGetMessage?.data &&
                      dataGetMessage.data.body.data.length > 0 && (
                        localStorage.getItem('RecentReqID') !== null ?
                        <span>
                          (
                          {dayjs(
                            dataGetMessage.data.body.data.filter(v=>v.requestId === localStorage.getItem('RecentReqID') as string )[0]?.requestDate
                          ).format("YYYY-MM-DD HH:mm")}{" "}
                          발송건)
                        </span>

                        :
                       
                            <span>
                              (
                              {dayjs(
                                dataGetMessage.data.body.data[0].requestDate
                              ).format("YYYY-MM-DD HH:mm")}{" "}
                              발송건)
                            </span>
                        
                      )}
                    {/* <span>(2022-06-13 11:03 발송건)</span> */}
                  </StatusTitle>
               
                  <StatusGroup>
                    <StatusText>
                      <Tag color="orange">
                        대기:{" "}
                        {localStorage.getItem('RecentReqID') !== null ?
                        dataGetMessageWithId?.data.body.data.filter(v=>v.resultMessage === "0").length

                        :
                        dataGetMessage?.data &&
                          dataGetMessage.data.body.data[0]?.pending
                      }

                        건
                      </Tag>
                    </StatusText>
                    <StatusText>
                      <Tag color="green">
                        성공:{" "}
                        
                        {localStorage.getItem('RecentReqID') !== null ?

                        dataGetMessageWithId?.data.body.data.filter(v=>v.resultMessage === "성공").length
                      
                      :
                      dataGetMessage?.data &&
                      dataGetMessage.data.body.data[0]?.sentSuccess
                      }

                        건
                      </Tag>
                    </StatusText>
                    <StatusText>
                      <Tag color="red">
                        실패:{" "}
                        {localStorage.getItem('RecentReqID') !== null ?

dataGetMessageWithId?.data.body.data.filter(v=>v.resultMessage === "실패").length

:
dataGetMessage?.data &&
dataGetMessage.data.body.data[0]?.sentFailed
}


                        건
                      </Tag>
                    </StatusText>
                  </StatusGroup>
                  {/* <StatusTitle>
                    발송현황
                    {dataGetMessage?.data &&
                      dataGetMessage.data.body.data.length > 0 && (
                        <span>
                          (
                          {dayjs(
                            dataGetMessage.data.body.data[0].requestDate
                          ).format("YYYY-MM-DD HH:mm")}{" "}
                          발송건)
                        </span>
                      )}
                  </StatusTitle> */}
                  {/* <StatusGroup>
                    <StatusText>
                      <Tag color="orange">
                        대기:{" "}
                        {(dataGetMessage?.data &&
                          dataGetMessage.data.body.data[0]?.pending) ||
                          0}
                        건
                      </Tag>
                    </StatusText>
                    <StatusText>
                      <Tag color="green">
                        성공:{" "}
                        {(dataGetMessage?.data &&
                          dataGetMessage.data.body.data[0]?.sentSuccess) ||
                          0}
                        건
                      </Tag>
                    </StatusText>
                    <StatusText>
                      <Tag color="red">
                        실패:{" "}
                        {(dataGetMessage?.data &&
                          dataGetMessage.data.body.data[0]?.sentFailed) ||
                          0}
                        건
                      </Tag>
                    </StatusText>
                  </StatusGroup> */}
                  <RefreshIconBox>
                    <AiOutlineReload
                      size={25}
                      onClick={
                        !loadingGetMessage ? onClickRefreshIcon : undefined
                      }
                    />
                  </RefreshIconBox>
                </Status>
              </Form>
            </LayoutCell>
          </Content>
          <AntdModal
            draggable
            title="메시지 불러오기"
            style={{
              maxWidth: "1200px",
              width: "100%",
            }}
            visible={store.modalLoadMessage.visible}
            onOk={store.onConfirmModalLoadMessage}
            onCancel={store.modalLoadMessage.onClose}
            okText="확인"
            cancelText="취소"
            okButtonProps={{
              disabled: !store.formReqMessage.form?.selectedTemplateOnModal,
            }}
          >
            <MessageOrTemplateModalContent mode="message" />
          </AntdModal>
          <AntdModal
            draggable
            title="템플릿 선택"
            style={{
              maxWidth: "1200px",
              width: "100%",
            }}
            visible={store.modalSelectTemplate.visible}
            onOk={store.onConfirmModalSelectTemplate}
            onCancel={store.modalSelectTemplate.onClose}
            okText="확인"
            cancelText="취소"
          >
            <MessageOrTemplateModalContent mode="template" />
          </AntdModal>
          <AntdModal
            draggable
            title="주소록"
            style={{
              maxWidth: "1200px",
              width: "100%",
            }}
            visible={store.modalAddressBook.visible}
            onOk={store.onConfirmModalAddressBook}
            onCancel={store.modalAddressBook.onClose}
            okButtonProps={{
              disabled:
                store.formReqMessage.form.selectedAddressBooks.length < 1,
            }}
            okText="확인"
            cancelText="취소"
          >
            <AddressBookModalContent />
          </AntdModal>
          <AntdModal
            draggable
            title="메시지 저장하기"
            style={{
              maxWidth: 450,
              width: "95%",
            }}
            visible={store.modalAddMessage.visible}
            onOk={onConfirmModalAddMessage}
            onCancel={store.modalAddMessage.onClose}
            okButtonProps={{
              loading: loadingAddSms,
              disabled:
                loadingAddSms ||
                !store.formReqMessage.form.addSmsQuery.templateName ||
                !store.formReqMessage.form.addSmsQuery.templateContent,
            }}
          >
            <AddedMessageModalContent isSavedMessage={true} />
          </AntdModal>
        </LayoutContent>
      </ExtendedLayoutRow>
    </Container>
  );
};

const Container = styled.div``;

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media ${device.laptop.max} {
    flex-flow: column;
  }
  textarea.ant-input {
    height: 100px;
  }
  @media ${device.laptop.min} {
    & > div {
      width: 33.3333%;
      /* height: 700px; */
      /* height: 719px; */
      /* height: calc(100vh - 81px - 20px - 31px); */
      /* max-height: 720px; */
    }
    textarea.ant-input {
      /* height: calc(100vh - 460px); */
      /* height: calc(100vh - 580px); */
      height: 293px;
      /* height: 280px; */
      &.uploaded {
        height: 180px;
        /* height: calc(100vh - 325px - 125px); */
      }
    }
  }
`;

const Divide = styled.div`
  margin: 0;
  margin: 0 -2px;
  display: none;
  @media ${device.laptop.max} {
    width: 100%;
  }
`;

const HintSpace = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const SendMethodButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-top: 12px;
  & > span,
  & > button {
    flex: 1;
  }
  button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  & > span {
    div,
    span,
    button {
      width: 100%;
    }
  }
  @media ${device.laptop.max} {
    margin-top: 10px;
    button {
      padding: 6px;
    }
  }
  @media (max-width: 1300px) {
    gap: 3px;
    button {
    }
  }
`;

const CheckboxSapce = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 3px;
`;

const ByteItem = styled.div`
  span {
    color: var(--primary-color);
  }
  &.limit-active {
    span {
      color: red;
    }
  }
`;

const ButtonInputSpace = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
  @media ${device.laptop.max} {
    button {
      flex: 1;
    }
  }
`;

const ButtonSpace = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
  & > div,
  & > button {
    flex: 1;
    width: 100%;
  }
  .btnExcelDw {
    border-color: #8aab99;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1f6e43;
    &:focus,
    &:active {
      background-color: #f5f9f6;
    }
    &:hover {
      background-color: #f5f9f6;
    }  
  }
}
`;

const TotalSpace = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 34px; */
`;

const Total = styled.span`
  font-weight: 600;
  font-size: 12px;
`;

const Status = styled.div`
  position: relative;
  padding: 10px;
  border: 1px solid #dfdfdf;
  min-height: 90px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-top: 20px;
`;

const StatusTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
  span {
    font-weight: 400;
    font-size: 13px;
  }

  @media ${device.laptop.max} {
    font-size: 12px;
    margin-bottom: 8px;
    gap: 0;
    span {
      font-size: 11px;
    }
    flex-flow: column;
    align-items: flex-start;
  }
`;

const StatusGroup = styled.div`
  display: flex;
  gap: 6px;
`;
const StatusText = styled.div`
  font-size: 13px;
  @media ${device.laptop.max} {
    font-size: 12px;
  }
`;

const RefreshIconBox = styled.div`
  position: absolute;
  top: 12px;
  right: 10px;
  svg {
    cursor: pointer;
  }
`;
const RecivedTemplateSpace = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    /* min-height: 100vh; */
    min-height: 100%;
  }
`;

const Preview = styled.div<{ src?: string }>`
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #dfdfdf;
  margin-top: 10px;
  border-radius: 4px;
  background-size: contain;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  & svg {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
    fill: #9f9f9f;
    &:hover {
      cursor: pointer;
    }
  }
`;

const FileName = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: #747474;
  max-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ExtendedLayoutCell = styled(LayoutCell)`
  /* height: calc(100vh - 81px - 20px - 12px); */
  @media ${device.laptop.max} {
    height: auto;
  }
  .ant-form-vertical .ant-form-item-label > label {
    width: 100%;
    color: var(--gray-dark-color);
    font-size: 13px;
    letter-spacing: -.5px;
    .space {
      display: flex;
      cursor: pointer;
      margin-left: auto;
      align-items: center;
      gap: 3px;
      justify-content: space-between;
      color: #888;
      font-size: 11px;
      font-weight:400;
      &:hover {
        color: var(--primary-color);
      }
    }
  }
`;

const Link = styled.p`
  cursor: pointer;

  display: flex;
  gap: 3px;
  align-items: center;
  white-space: nowrap;
`;

const ExtendedAntdTable = styled(AntdTable)`
  width: 100%;
  @media ${device.laptop.min} {
    &.center-table {
      .ant-table-container {
        .ant-table-body {
          height: 340px;
          max-height: 340px !important;
          /* height: calc(100vh - 412px); */
          /* height: calc(100vh - 533px); */
          /* max-height: initial !important; */
        }
      }
    }
    &.last-table {
      .ant-table-container {
        .ant-table-body {
          /* height: calc(100vh - 430px); */
          /* max-height: initial !important; */
        }
      }
    }
  }
  @media ${device.laptop.max} {
    &.center-table {
      .ant-table-container {
        .ant-table-body {
          max-height: 310px !important;
        }
      }
    }
  }
`;

const PreviewSpace = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const Text = styled.div`
  margin-top: 6px;
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 11px;
  color: #888;
  &:hover {
    color: var(--primary-color);
  }
`;


