import { MdMail } from "react-icons/md";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Input, message, Switch, Tag } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo,useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { BiWon } from "react-icons/bi";
import {
  AddedChildAccountModalContent,
  AntdButton,
  AntdModal,
  AntdPagination,
  AntdTable,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTitle,
  UpdatePasswordModalContent,
} from "../components";
import { device } from "../device";
import {
  MemberAllReadResponseData,
  MemberCreateFromCompanyDTO,
  MemberInvitationDTO,
  MemberUpdateDTO,
} from "../generated";
import { privateApi } from "../react-query";
import { useMyPageAccountStore } from "../stores";
import { isSuccess, Pattern } from "../utils";

const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherUpdatePassword = (params: MemberUpdateDTO) =>
  privateApi.member.memberControllerUpdateMember({
    userId: params.userId,
    password: params.password,
  });

export const MyPageAccountuser = () => {
  const store = useMyPageAccountStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);

  const { isLoading: loadingGetMember, data: dataGetMember } = useQuery(
    ["memberControllerReadCurrentMember"],
    fetcherGetMember,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerReadCurrentMember onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("memberControllerReadCurrentMember onError: ", e);
      },
    }
  );
  const { isLoading: loadingUpdatePassword, mutate: mutateUpdatePassword } =
    useMutation(fetcherUpdatePassword, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerUpdateMember onSuccess: ", e);
          message.info("패스워드가 변경되었습니다.");
          store.modalUpdatePassword.onClose();
        }
      },
      onError: (e) => {
        console.log("memberControllerUpdateMember onError: ", e);
      },
    });

  /**
   * [패스워드 변경] 확인 버튼 클릭시
   */
  const onModalClickUpdatePassword = () => {
    const { form } = store.formUpdatePassword;
    if (dataGetMember?.data.body.data.userId) {
      mutateUpdatePassword({
        userId: dataGetMember?.data.body.data.userId,
        password: form.password,
      });
    }
  };

  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>
            <Space>
              <ExtendedLayoutCell>
                <LayoutTitle>사업자 정보</LayoutTitle>
                <Table>
                  <tbody>
                    <tr>
                      <td>사업자등록번호</td>
                      <td>
                        {
                          dataGetMember?.data.body.data.companyInformation
                            .registrationNumber
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>사업자등록증</td>
                      <td>
                        {dataGetMember?.data.body.data.companyInformation
                          .companyCertFlag === 0 && (
                          <Tag color="orange">대기</Tag>
                        )}
                        {dataGetMember?.data.body.data.companyInformation
                          .companyCertFlag === 1 && (
                          <Tag color="blue">승인</Tag>
                        )}
                        {dataGetMember?.data.body.data.companyInformation
                          .companyCertFlag === 2 && <Tag color="red">반려</Tag>}
                      </td>
                    </tr>
                    <tr>
                      <td>사업장명</td>
                      <td>
                        {
                          dataGetMember?.data.body.data.companyInformation
                            .companyName
                        }
                      </td>
                    </tr>
                    {/* <tr>
                      <td>대표자</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>마스터아이디</td>
                      <td></td>
                    </tr> */}
                  </tbody>
                </Table>
              </ExtendedLayoutCell>
              <ExtendedLayoutCell>
                <LayoutTitle>잔액</LayoutTitle>
                <BalanceCell>
                  <div>
                    <Balance>
                      <ImgBox>
                        {/* <MdAttachMoney size={30} /> */}
                        <BiWon size={30} />
                      </ImgBox>
                      <span className="text">
                        <span className="strong">
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .balance
                          }
                        </span>
                        원
                      </span>
                      
                    </Balance>
                  </div>
                
                      
                 
                  
                </BalanceCell>
              </ExtendedLayoutCell>
            </Space>
             <ExtendedLayoutCell style={{marginBottom : "20px"}}>
                <LayoutTitle>요금제 정보</LayoutTitle>
                 <Table3>
                  <tbody>
                    <tr>
                      <td>요금제</td>
                      <td>{dataGetMember?.data.body.data.companyInformation.basicChargePlanInformation.chargePlanName}</td>
                      <td style={{fontWeight: "bold" ,width : "200px"}}>프로모션 적용 요금제</td>
                      <td>{dataGetMember?.data.body.data.companyInformation.chargePlanInformation.chargePlanName}</td>
                      <td style={{fontWeight: "bold"}}>만료기간</td>
                      <td>{dataGetMember?.data.body.data.companyInformation.promotionExpiryDate ? dataGetMember?.data.body.data.companyInformation.promotionExpiryDate : "해당사항 없음" }</td>
                    </tr>
                  </tbody>
                </Table3>
             </ExtendedLayoutCell>
            <ExtendedLayoutCell>
                <LayoutTitle>계정 정보</LayoutTitle>
                <Table2>
                  <tbody>
                    <tr>
                      <td>회원번호</td>
                      <td>{dataGetMember?.data.body.data.userIdx}</td>
                    </tr>
                    <tr>
                      <td>아이디</td>
                      <td style={{width:"200px"}}>{dataGetMember?.data.body.data.userId}</td>
                      <td style={{ textAlign: "left" }}>
                        <AntdButton
                          isPrimary
                          style={windowsize? { color: "white" , marginLeft : 10 } : { color: "white"}}
                          onClick={store.modalUpdatePassword.onOpen}
                        >
                          패스워드 변경
                        </AntdButton>
                      </td>
                    </tr>
                    <tr>
                      <td>사용자명</td>
                      <td>{dataGetMember?.data.body.data.userName}</td>
                    </tr>
                    <tr>
                      <td>사용자권한</td>
                      <td>
                        {dataGetMember?.data.body.data.userRole === "100" && (
                          <Tag color="magenta">마스터</Tag>
                        )}
                        {dataGetMember?.data.body.data.userRole === "101" && (
                          <Tag color="geekblue">일반</Tag>
                        )}
                        {dataGetMember?.data.body.data.userRole === "900" && (
                          <Tag color="magenta">마스터</Tag>
                        )}
                        {dataGetMember?.data.body.data.userRole === "901" && (
                          <Tag color="geekblue">일반</Tag>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>아이디 허용 여부</td>
                      <td>{dataGetMember?.data.body.data.companyAccessFlag === 1 && (
                                                  <Tag>허용</Tag>

                      )}
                     {dataGetMember?.data.body.data.companyAccessFlag === 0 && (
                                                 <Tag>불가</Tag>

                      )}
                      
                      </td>
                    </tr>
                  
                  </tbody>
                </Table2>
              </ExtendedLayoutCell>
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      
      <AntdModal
        draggable
        title="패스워드 변경"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalUpdatePassword.visible}
        onOk={onModalClickUpdatePassword}
        onCancel={store.modalUpdatePassword.onClose}
        okButtonProps={{
          disabled:
            loadingUpdatePassword ||
            store.formUpdatePassword.form.password.length < 1 ||
            store.formUpdatePassword.form.passwordConfirm.length < 1 ||
            store.formUpdatePassword.form.password !==
              store.formUpdatePassword.form.passwordConfirm,
        }}
        okText="확인"
        cancelText="취소"
      >
        <UpdatePasswordModalContent />
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;

  @media ${device.laptop.max} {
    .ant-table-cell {
      &:first-child {
        width: 200px !important;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    min-height: 100vh;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  @media ${device.laptop.min} {
    /* height: calc(100vh - 81px - 20px - 12px); */
  }
`;

const ExtendedLayoutCell = styled(LayoutCell)`
  &.bottom {
    @media ${device.laptop.min} {
      .antd-table-bottom {
        height: calc(42.5vh);
        /* height: 60%; */
      }
      /* height: calc(100vh - 410px); */
    }
  }
`;

const Space = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  margin-bottom: 30px;
  & > div {
    width: 100%;
  }

  @media ${device.laptop.max} {
    flex-flow: column;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  /* border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color); */
  min-height: 200px;
  tr {
  }
  td {
    color: #585858;
    padding: 8px 12px;
    &:first-child {
      font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 140px;
    }
  }
`;
const Table2 = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  /* border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color); */
  min-height: 300px;
  tr {
  }
  td {
    color: #585858;
    padding: 8px 12px;
    &:first-child {
      font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 140px;
    }
  }
  @media ${device.laptop.max} {
    td {
      
        padding : 0px;
        /* background-color: #26395d; */
        /* color: white; */
        width: 80px;
        font-size : 12px;
        
      
    }
`;

const FormSpace = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 30px;
  }

  @media ${device.laptop.max} {
    flex-flow: column;
    & > div {
      width: 100%;
      margin-bottom: 8px;
    }
    & > button {
      width: 100%;
    }
  }
`;
const FormSubSpace = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Link = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Table3 = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  /* border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color); */
  min-height: 30px;
  tr {
  }
  td {
    color: #585858;
    padding: 8px 12px;
    &:first-child {
      font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 150px;
    }
    &:nth-of-type(2) {
      /* background-color: #26395d; */
      /* color: white; */
      width: 170px;
    }
    &:nth-of-type(3) {
      font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 150px;
    }
    &:nth-of-type(4) {
      // font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 170px;
    }
    &:nth-of-type(5) {
      font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 150px;
    }
    &:nth-of-type(6) {
      // font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 150px;
    }

  }
  @media ${device.laptop.max} {
    td {
      
        padding : 0px;
        /* background-color: #26395d; */
        /* color: white; */
        width: 100px;
        font-size : 10px;
        &:first-child {
          font-weight: bold;
          /* background-color: #26395d; */
          /* color: white; */
          width: 100px;
        }
      }
    }
`;
const BalanceCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  min-height: 200px;
  flex-flow: column;
`;

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 3px solid var(--primary-color);
  padding: 3px;
  svg {
    color: var(--primary-color);
    display: block;
  }
`;

const Balance = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  font-size: 20px;
  .text {
    display: flex;
    align-items: baseline;
    color: var(--primary-color);
  }
  .strong {
    font-size: 35px;
    font-weight: 700;
  }
`;