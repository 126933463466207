import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import { setAccessToken, setRefreshToken } from "../auth";

interface IStore extends State {
  /** 토큰 */
  token?: string;
  /** 로그인 여부 */
  isLoggedIn: boolean;
  /** 로그인이  아닌 엑세스로 접근한경우 true반환 */
  isAccessKey: boolean;

  isPopup : boolean;
  /** 로그인 이벤트 */
  onLogin: (token: string) => void;
  /** 엑세스키 업데이트 */
  onChangeIsAccessKey: (v: boolean) => void;
  onChangeIsPopup: (v: boolean) => void;
  /** 로그아웃 이벤트 */
  onLogout: () => void;
  onReset: () => void;
}

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    isLoggedIn: false,
    isAccessKey: false,
    isPopup: false,
    token: undefined,
    onChangeIsAccessKey: (isAccessKey: boolean) => {
      set({
        isAccessKey,
      });
    },
    onChangeIsPopup: (isPopup: boolean) => {
      set({
        isPopup,
      });
    },
    onLogin: (token: string) => {
      set({
        isLoggedIn: true,
        token,
      });
    },
    onLogout: () => {
      set({ token: undefined, isLoggedIn: false });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** [로그인] 상태관리 */
export const useAppStore = create<IStore>()(devtools(initialStateCreator));
