import { Form, Select, TableProps, Tag, Tooltip,Input } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect,useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import {
  AntdButton,
  AntdDatePicker,
  AntdTable,
  AntdPagination,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTab,
  AntdModal,
  ResultSentMessageModalContent,
  
} from "../components";
import { device } from "../device";
import {
  MessageControllerReadAllParams,
  MessageControllerReadStatsParams,
  MessageReadResponseData,
  MessageReadStatsResponseData,
} from "../generated";
import { privateApi } from "../react-query";
import { useAnalysisSendStore,useSummaryDashboardStore } from "../stores";
import { isSuccess } from "../utils";
import { BiWindowClose } from "react-icons/bi";
import { GrMore } from "react-icons/gr";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
interface IProps {}

const defaultStatistics = [
  {
    alimtalkPending: "0",
    alimtalkSent: "0",
    alimtalkSentFailed: "0",
    alimtalkSentSuccess: "0",
    lmsPending: "0",
    lmsSent: "0",
    lmsSentFailed: "0",
    lmsSentSuccess: "0",
    mmsPending: "0",
    mmsSent: "0",
    mmsSentFailed: "0",
    mmsSentSuccess: "0",
    requestDate: "-",
    requested: "0",
    smsPending: "0",
    smsSent: "0",
    smsSentFailed: "0",
    smsSentSuccess: "0",
  },
];

const fetcherMessages = (params: MessageControllerReadAllParams) =>
  privateApi.message.messageControllerReadAll(params);

const fetcherMessageStats = (params: MessageControllerReadStatsParams) =>
  privateApi.message.messageControllerReadStats(params);
const fetcherMessageCancelResv = (id: string) =>
  privateApi.message.messageControllerResvCancel(id);

export const AnalysisSend: React.FC<IProps> = () => {
  const store = useAnalysisSendStore((s) => s);
  const store2 = useSummaryDashboardStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const [Num, setNum] = useState("")
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  const {
    isLoading: loadingMessageCancelResv,
    mutate: mutateMessageCancelResv,
  } = useMutation(
    (id : string) => fetcherMessageCancelResv(id),
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerReadAll onSuccess: ", e);
          mutateGetMessages({
        
            ...store.formFilter.form,
            page: 1,
          });
        }
      },
      onError: (e) => {
        console.log("messageControllerReadAll onError: ", e);
      },
    }
  );
  const {
    isLoading: loadingGetMessages,
    data: dataGetMessages,
    mutate: mutateGetMessages,
  } = useMutation(
    (params: MessageControllerReadAllParams) => fetcherMessages(params),
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerReadAll onSuccess: ", e);
          e.data.body.data[0];
        }
      },
      onError: (e) => {
        console.log("messageControllerReadAll onError: ", e);
      },
    }
  );

  const {
    isLoading: loadingGetMessageStats,
    data: dataGetMessageStats,
    mutate: mutateGetMessageStats,
  } = useMutation(
    (params: MessageControllerReadStatsParams) => fetcherMessageStats(params),
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerReadStats onSuccess: ", e);
         
          
        }
      },
      onError: (e) => {
        console.log("messageControllerReadStats onError: ", e);
      },
    }
  );

  /**
   * [검색기간] 변경시
   */
  const onChangeDate = useCallback((e: [Dayjs, Dayjs]) => {
    if (e[0] && e[1]) {
      const startRequestDate = e[0].format("YYYY-MM-DD");
      const endRequestDate = e[1].format("YYYY-MM-DD");
      store.formFilter.onChangeForm({
        startRequestDate,
        endRequestDate,
      });
    }
  }, []);

  /**
   * [메시지 구분] 변경시
   */
  const onChangeMessageType = useCallback(
    (messageType: "all" | "sms" | "lms" | "mms" | "alimtalk") => {
      store.formFilter.onChangeForm({
        messageType,
      });
    },
    []
  );


  /**
   * [검색] 클릭시
   * - 메시지 결과/통계 동시에 요청.
   */
  const onSearchMessage = (page?: number) =>{
    // console.log(store2.formMessageStats.form.type)

    if (page) {
      onChangePagination(page);
      mutateGetMessages({
        
        ...store.formFilter.form,
        page: 1,
      });
      mutateGetMessageStats({
        ...store.formFilter.form,
        page: 1,
        pageSize: 1000,
      });
    } else {

      if(store2.formFilter.form.clicked == 1)
      {
        if(store2.formMessageStats.form.type ==="SMS")
        {
          store.formFilter.onChangeForm({
            messageType : "sms",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "sms",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
    
          }
          
          );
          store2.onReset()
          store2.formFilter.onChangeForm({
            clicked : 0
          })
  
        }
        else if(store2.formMessageStats.form.type ==="MMS")
        {
          store.formFilter.onChangeForm({
            messageType : "mms",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "mms",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
    
          }
          );
          store2.onReset()
          store2.formFilter.onChangeForm({
            clicked : 0
          })
        }
        else if(store2.formMessageStats.form.type === "ALIMTALK")
        {
          store.formFilter.onChangeForm({
            messageType : "alimtalk",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "alimtalk",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
          }
          );
          store2.onReset()
          store2.formFilter.onChangeForm({
            clicked : 0
          })
        }
        else if(store2.formMessageStats.form.type === "LMS")
        {
          store.formFilter.onChangeForm({
            messageType : "lms",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "lms",
            startRequestDate : store2.formFilter.form.startRequestDate,
            endRequestDate : store2.formFilter.form.endRequestDate
          }
          );
          store2.onReset()
          store2.formFilter.onChangeForm({
            clicked : 0
          })
        }
        
        mutateGetMessageStats({
          ...store.formFilter.form,
          startRequestDate : store2.formFilter.form.startRequestDate,
          endRequestDate : store2.formFilter.form.endRequestDate,
          page: 1,
          pageSize: 1000,
        });
      }
      else if(store2.formFilter.form.clicked == 0)
      {
        if(store.formFilter.form.messageType ==="sms")
        {
          store.formFilter.onChangeForm({
            messageType : "sms",
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "sms",
          }
          
          );
         
  
        }
        else if(store.formFilter.form.messageType ==="mms")   {
          store.formFilter.onChangeForm({
            messageType : "mms",
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "mms",
          }
          
          );
         
  
        }
        else if(store.formFilter.form.messageType ==="all")   {
          store.formFilter.onChangeForm({
            messageType : "all",
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "all",
    
          }
          
          );
         
  
        }
        else if(store.formFilter.form.messageType ==="lms")   {
          store.formFilter.onChangeForm({
            messageType : "lms",
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "lms",
    
          }
          
          );
        
  
        }
        else if(store.formFilter.form.messageType ==="alimtalk")   {
          store.formFilter.onChangeForm({
            messageType : "alimtalk",
          })
          mutateGetMessages({
            ...store.formFilter.form,
            messageType : "alimtalk",
    
          }
          
          );
         
  
        }
        mutateGetMessageStats({
          ...store.formFilter.form,
          startRequestDate : store.formFilter.form.startRequestDate,
          endRequestDate : store.formFilter.form.endRequestDate,
          page: 1,
          
      })

      }
      }
      
  };

  /**
   * [페이징] 변경시
   */
  const onChangePagination = (page: number, pageSize?: number) => {
    store.formFilter.onChangeForm({
      page,
    });
    console.log(store.formFilter.form.messageType)
    // if(store2.formFilter.form.clicked == 1)
    //   {
    //     if(store2.formMessageStats.form.type ==="SMS")
    //     {
    //       store.formFilter.onChangeForm({
    //         messageType : "sms",
    //         startRequestDate : store2.formFilter.form.startRequestDate,
    //         endRequestDate : store2.formFilter.form.endRequestDate,
    //         page
    //       })
         
    //       mutateGetMessages({
    //         ...store.formFilter.form,
    //         messageType : "sms",
    //         startRequestDate : store2.formFilter.form.startRequestDate,
    //         endRequestDate : store2.formFilter.form.endRequestDate,
    //         page
    //       }
          
    //       );
    //     }
          
        
    //     else if(store2.formMessageStats.form.type ==="MMS")
    //     {
    //       store.formFilter.onChangeForm({
    //         messageType : "mms",
    //         startRequestDate : store2.formFilter.form.startRequestDate,
    //         endRequestDate : store2.formFilter.form.endRequestDate
    //       })
         
    //       store2.onReset()
    //       store2.formFilter.onChangeForm({
    //         clicked : 0
    //       })
    //     }
    //     else if(store2.formMessageStats.form.type === "ALIMTALK")
    //     {
    //       store.formFilter.onChangeForm({
    //         messageType : "alimtalk",
    //         startRequestDate : store2.formFilter.form.startRequestDate,
    //         endRequestDate : store2.formFilter.form.endRequestDate
    //       })
    //       store2.onReset()
    //       store2.formFilter.onChangeForm({
    //         clicked : 0
    //       })
    //     }
    //     else if(store2.formMessageStats.form.type === "LMS")
    //     {
    //       store.formFilter.onChangeForm({
    //         messageType : "lms",
    //         startRequestDate : store2.formFilter.form.startRequestDate,
    //         endRequestDate : store2.formFilter.form.endRequestDate
    //       })
    //       store2.onReset()
    //       store2.formFilter.onChangeForm({
    //         clicked : 0
    //       })
    //     }
    //   }
  };

  /**
   * [발송일자] 클릭시
   */
  const onClickRequestDate = useCallback(
    (date: string) => {
      store.formFilter.onChangeForm({
        page: 1,
        messageType: "all",
        startRequestDate: date,
        endRequestDate: date,
      });
      mutateGetMessages({
        ...store.formFilter.form,
        startRequestDate: date,
        endRequestDate: date,
        messageType: "all",
        page: 1,
      });
      mutateGetMessageStats({
        ...store.formFilter.form,
        startRequestDate: date,
        endRequestDate: date,
        page: 1,
        pageSize: 1000,
      });
    },
    [store.formFilter.form]
  );

  /**
   * 테이블 칼럼 [SMS,LMS,알림톡] 클릭시
   */
  const onClickMessageTypeColumn = useCallback(
    (messageType: string) => {
      store.formFilter.onChangeForm({
        page: 1,
        messageType: messageType as "sms",
      });
      mutateGetMessages({
        ...store.formFilter.form,
        messageType: messageType as "sms",
        page: 1,
      });
      mutateGetMessageStats({
        ...store.formFilter.form,
        page: 1,
        pageSize: 1000,
      });
    },
    [store.formFilter.form]
  );

  /** 테이블 [정렬] 변경시 */
  const onTableChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    if (extra.action === "sort") {
      const { column, field, order } = sorter as any;
      if (!column) {
        // 정렬된 값이 없으면 초기화.
        store.formFilter.onChangeForm({
          sortBy: "requestDate",
          sortOrder: "DESC",
          page: 1,
          hideSort: true,
        });
      } else if (["requestDate", "messageType"].includes(field)) {
        if (order) {
          store.formFilter.onChangeForm({
            page: 1,
            sortBy: field,
            hideSort: false,
            sortOrder: order === "ascend" ? "ASC" : "DESC",
          });
        }
      }
      const form = Object.assign(store.formFilter.form, {
        hideSort: undefined,
      }) as any;
      mutateGetMessages({
        ...form,
        sortBy: !column ? "requestDate" : field,
        sortOrder: !column ? "DESC" : order === "ascend" ? "ASC" : "DESC",
        page: 1,
      });
      mutateGetMessageStats({
        ...form,
        page: 1,
        pageSize: 1000,
      });
      return;
    }
  };

  useEffect(() => {
    console.log("reset")
    return () => {
      store.onReset();
    };
  }, []);
  
  useEffect(() => {
    onSearchMessage();
    console.log(store.formFilter.form.page)
    console.log("test"+store2.formMessageStats.form.type)
  }, [store.formFilter.form.page]);

  
    const setting = {
      dots: true,
      infinite : true,
      speed : 500,
      autoPlay : true,
    
  }
  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>
            <LayoutCell>
              <ExtendedLayoutCell>
                <SearchBox>
                  <Form
                    layout="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form.Item label="검색기간" style={{ maxWidth: 250 }}>
                      <AntdDatePicker.RangePicker
                        style={{ width: "100%" }}
                        allowClear={false}
                        onChange={onChangeDate as any}
                        value={[
                          dayjs(store.formFilter.form.startRequestDate),
                          dayjs(store.formFilter.form.endRequestDate),
                        ]}
                      />
                    </Form.Item>
                    <SearchSpace style={{width : 400}}>
                      <Form.Item
                        label="메시지 구분"
                        style={{ width: "100%", maxWidth: 120 }}
                      >
                        <Select
                          style={{ width: "100%" }}
                          onChange={onChangeMessageType}
                          value={store.formFilter.form.messageType}
                        >
                          <Select.Option value="all">전체</Select.Option>
                          <Select.Option value="sms">SMS</Select.Option>
                          <Select.Option value="lms">LMS</Select.Option>
                          <Select.Option value="mms">MMS</Select.Option>
                          <Select.Option value="alimtalk">알림톡</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="수신자 번호"
                        style={{ width: "100%", maxWidth: 200 }}
                      >
                        <Input
                      onChange={(e)=>{
                        store.formFilter.onChangeForm({
                          recipientNo : e.target.value,
                        });
                      }}
                      value={store.formFilter.form.recipientNo}
                        />
                                              </Form.Item>
                      <Form.Item label="" style={{ width: "100%" }}>
                        <SearchSpace>
                          <AntdButton
                            isPrimary
                            style={{ color: "white" }}
                            onClick={() => onSearchMessage(1)}
                            className="btnBlue"
                          >
                            검색
                          </AntdButton>
                        </SearchSpace>
                      </Form.Item>
                    </SearchSpace>
                  </Form>
                </SearchBox>
              </ExtendedLayoutCell>
                   <ExtendedLayoutCell className="top-cell">
                   <CellTitle>발송 통계</CellTitle>
             
                {windowsize?(
                  <>
                  <div className="carousel">
                  <Slider {...setting}>
                  <div>

                  <ExtendedAntdTable
                  className="top-table"
                  pagination={false}
                  rowKey="requestDate"
                  tableLayout="fixed"
                  dataSource={
                    dataGetMessageStats?.data &&
                    dataGetMessageStats.data.body.data.length > 0
                      ? dataGetMessageStats.data.body.data
                      : defaultStatistics
                  }
                  scroll={{y: 150 }}
                  bordered
                  size="small"
                  // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                  columns={[
                    {
                      title: "발송일자",
                      dataIndex: "requestDate",
                      key: "requestDate",
                      align: "center",
                      width: 85,
                      render: (v) => {
                        if (v === "-") {
                          return v;
                        }
                        const date = dayjs(v).format("YYYY-MM-DD");
                        return (
                          <Link onClick={() => onClickRequestDate(date)}>
                            {date}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "총 요청",
                      dataIndex: "",
                      key: "",
                      align: "center",
                      width : 50,
                      render: (_, value: MessageReadStatsResponseData) => {
                        return (
                          Number(value.smsSent) +
                          Number(value.lmsSent) +
                          Number(value.mmsSent) +
                          Number(value.alimtalkSent)
                        );
                      },
                    },
                    {
                      title: "SMS",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn(
                              column.title?.toString().toLocaleLowerCase() || ""
                            );
                          },
                        };
                      },
                      children: [
                        {
                          title: "요청",
                          dataIndex: "smsSent",
                          align: "center",
                        },
                        {
                          title: "성공",
                          dataIndex: "smsSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          dataIndex: "smsSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          dataIndex: "smsPending",
                          align: "center",
                        },
                      ],
                    }
                 
                  ]}
                    />
                  </div>
                  <div>
                  <ExtendedAntdTable
                  className="top-table"
                  pagination={false}
                  rowKey="requestDate"
                  tableLayout="fixed"
                  dataSource={
                    dataGetMessageStats?.data &&
                    dataGetMessageStats.data.body.data.length > 0
                      ? dataGetMessageStats.data.body.data
                      : defaultStatistics
                  }
                  scroll={{y: 150 }}
                  bordered
                  size="small"
                  // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                  columns={[
                    {
                      title: "발송일자",
                      dataIndex: "requestDate",
                      key: "requestDate",
                      align: "center",
                      width: 85,
                      render: (v) => {
                        if (v === "-") {
                          return v;
                        }
                        const date = dayjs(v).format("YYYY-MM-DD");
                        return (
                          <Link onClick={() => onClickRequestDate(date)}>
                            {date}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "총 요청",
                      dataIndex: "",
                      key: "",
                      align: "center",
                      width : 50,
                      render: (_, value: MessageReadStatsResponseData) => {
                        return (
                          Number(value.smsSent) +
                          Number(value.lmsSent) +
                          Number(value.mmsSent) +
                          Number(value.alimtalkSent)
                        );
                      },
                    },
                    {
                      title: "LMS",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn(
                              column.title?.toString().toLocaleLowerCase() || ""
                            );
                          },
                        };
                      },
                      children: [
                        {
                          title: "요청",
                          dataIndex: "lmsSent",
                          align: "center",
                        },
                        {
                          title: "성공",
                          dataIndex: "lmsSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          dataIndex: "lmsSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          dataIndex: "lmsPending",
                          align: "center",
                        },
                      ],
                    }
                 
                  ]}
                    />
                  </div>
                  <div>
                     <ExtendedAntdTable
                  className="top-table"
                  pagination={false}
                  rowKey="requestDate"
                  tableLayout="fixed"
                  dataSource={
                    dataGetMessageStats?.data &&
                    dataGetMessageStats.data.body.data.length > 0
                      ? dataGetMessageStats.data.body.data
                      : defaultStatistics
                  }
                  scroll={{y: 150 }}
                  bordered
                  size="small"
                  // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                  columns={[
                    {
                      title: "발송일자",
                      dataIndex: "requestDate",
                      key: "requestDate",
                      align: "center",
                      fixed: true,
                      width: 85,
                      render: (v) => {
                        if (v === "-") {
                          return v;
                        }
                        const date = dayjs(v).format("YYYY-MM-DD");
                        return (
                          <Link onClick={() => onClickRequestDate(date)}>
                            {date}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "총 요청",
                      dataIndex: "",
                      key: "",
                      align: "center",
                      width : 50,
                      render: (_, value: MessageReadStatsResponseData) => {
                        return (
                          Number(value.smsSent) +
                          Number(value.lmsSent) +
                          Number(value.mmsSent) +
                          Number(value.alimtalkSent)
                        );
                      },
                    },
                    {
                      title: "MMS",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn(
                              column.title?.toString().toLocaleLowerCase() || ""
                            );
                          },
                        };
                      },
                      children: [
                        {
                          title: "요청",
                          dataIndex: "mmsSent",
                          align: "center",
                        },
                        {
                          title: "성공",
                          dataIndex: "mmsSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          dataIndex: "mmsSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          dataIndex: "mmsPending",
                          align: "center",
                        },
                      ],
                    }
                 
                  ]}
                  />
                  </div>
                  <div>
                  <ExtendedAntdTable
                  className="top-table"
                  pagination={false}
                  rowKey="requestDate"
                  tableLayout="fixed"
                  dataSource={
                    dataGetMessageStats?.data &&
                    dataGetMessageStats.data.body.data.length > 0
                      ? dataGetMessageStats.data.body.data
                      : defaultStatistics
                  }
                  scroll={{y: 150 }}
                  bordered
                  size="small"
                  // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                  columns={[
                    {
                      title: "발송일자",
                      dataIndex: "requestDate",
                      key: "requestDate",
                      align: "center",
                      fixed: true,
                      width: 85,
                      render: (v) => {
                        if (v === "-") {
                          return v;
                        }
                        const date = dayjs(v).format("YYYY-MM-DD");
                        return (
                          <Link onClick={() => onClickRequestDate(date)}>
                            {date}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "총 요청",
                      dataIndex: "",
                      key: "",
                      align: "center",
                      width : 50,
                      render: (_, value: MessageReadStatsResponseData) => {
                        return (
                          Number(value.smsSent) +
                          Number(value.lmsSent) +
                          Number(value.mmsSent) +
                          Number(value.alimtalkSent)
                        );
                      },
                    },
                    {
                      title: "알림톡",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn("alimtalk");
                          },
                        };
                      },
                      children: [
                        {
                          title: "전체",
                          align: "center",
                          dataIndex: "alimtalkSent",
                        },
                        {
                          title: "성공",
                          dataIndex: "alimtalkSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          dataIndex: "alimtalkSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          dataIndex: "alimtalkPending",
                          align: "center",
                        },
                      ],
                    }
                 
                  ]}
                  />
                  </div>

                  </Slider>

                  </div>
                
               </>
                ):(
                  <ExtendedAntdTable
                  className="top-table"
                  pagination={false}
                  rowKey="requestDate"
                  tableLayout="fixed"
                  dataSource={
                    dataGetMessageStats?.data &&
                    dataGetMessageStats.data.body.data.length > 0
                      ? dataGetMessageStats.data.body.data
                      : defaultStatistics
                  }
                  scroll={{ x: 1100, y: 180 }}
                  bordered
                  size="small"
                  // scroll={{ x: 'calc(700px + 50%)', y: 240 }}
                  columns={[
                    {
                      title: "발송일자",
                      dataIndex: "requestDate",
                      key: "requestDate",
                      align: "center",
                      fixed: true,
                      width: 85,
                      render: (v) => {
                        if (v === "-") {
                          return v;
                        }
                        const date = dayjs(v).format("YYYY-MM-DD");
                        return (
                          <Link
                            onClick={() => onClickRequestDate(date)}
                            style={{ fontSize: "12px", lineHeight: "1.3" }}
                          >
                            {date}
                          </Link>
                        );
                      },
                    },
                    {
                      title: "총 요청",
                      dataIndex: "",
                      key: "",
                      align: "center",
                      render: (_, value: MessageReadStatsResponseData) => {
                        return (
                          Number(value.smsSent) +
                          Number(value.lmsSent) +
                          Number(value.mmsSent) +
                          Number(value.alimtalkSent)
                        );
                      },
                    },
                    {
                      title: "SMS",
                      className: "titSms",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn(
                              column.title?.toString().toLocaleLowerCase() || ""
                            );
                          },
                        };
                      },
                      children: [
                        {
                          title: "요청",
                          className: "sms",
                          dataIndex: "smsSent",
                          align: "center",
                        },
                        {
                          title: "성공",
                          className: "sms titSuccess",
                          dataIndex: "smsSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          className: "sms titDanger",
                          dataIndex: "smsSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          className: "sms titWarning",
                          dataIndex: "smsPending",
                          align: "center",
                        },
                      ],
                    },
                    {
                      title: "LMS",
                      dataIndex: "",
                      key: "",
                      className: "titLms",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn(
                              column.title?.toString().toLocaleLowerCase() || ""
                            );
                          },
                        };
                      },
                      children: [
                        {
                          title: "요청",
                          className: "lms",
                          dataIndex: "lmsSent",
                          align: "center",
                        },
                        {
                          title: "성공",
                          className: "lms titSuccess",
                          dataIndex: "lmsSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          className: "lms titDanger",
                          dataIndex: "lmsSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          className: "lms titWarning",
                          dataIndex: "lmsPending",
                          align: "center",
                        },
                      ],
                    },
                    {
                      title: "MMS",
                      dataIndex: "",
                      key: "",
                      className: "titMms",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn(
                              column.title?.toString().toLocaleLowerCase() || ""
                            );
                          },
                        };
                      },
                      children: [
                        {
                          title: "요청",
                          className: "mms",
                          dataIndex: "mmsSent",
                          align: "center",
                        },
                        {
                          title: "성공",
                          className: "mms titSuccess",
                          dataIndex: "mmsSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          className: "mms titDanger",
                          dataIndex: "mmsSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          className: "mms titWarning",
                          dataIndex: "mmsPending",
                          align: "center",
                        },
                      ],
                    },
                    {
                      title: "알림톡",
                      className: "titTalk",
                      onHeaderCell: (column) => {
                        return {
                          style: {
                            cursor: "pointer",
                          },
                          onClick: () => {
                            onClickMessageTypeColumn("alimtalk");
                          },
                        };
                      },
                      children: [
                        {
                          title: "전체",
                          className: "talk",
                          align: "center",
                          dataIndex: "alimtalkSent",
                        },
                        {
                          title: "성공",
                          className: "talk titSuccess",
                          dataIndex: "alimtalkSentSuccess",
                          align: "center",
                        },
                        {
                          title: "실패",
                          className: "talk titDanger",
                          dataIndex: "alimtalkSentFailed",
                          align: "center",
                        },
                        {
                          title: "대기",
                          className: "talk titWarning",
                          dataIndex: "alimtalkPending",
                          align: "center",
                        },
                      ],
                    },
                  ]}
                />
                
                )}
              </ExtendedLayoutCell>

              <ExtendedLayoutCell>
                <CellTitle>발송 내역</CellTitle>
                <ExtendedAntdTable
                  className="last-table"
                  loading={loadingGetMessages}
                  pagination={false}
                  rowKey="requestId"
                  tableLayout="fixed"
                  scroll={windowsize ? { y: 310 } : { x: 800, y: 310 }}
                  onChange={onTableChange}
                  dataSource={
                    dataGetMessages?.data ? dataGetMessages.data.body.data : []
                  }
                  columns={[
                    {
                      title: "등록일자",
                      dataIndex: "registerDate",
                      key: "registerDate",
                      align: "center",
                      width: windowsize ? 80 : 85,
                      render: (v) => {
                        const date = dayjs(v);
                        return (
                          <>
                           {/* <p>{dayjs(v).subtract(9,"hour").format("YYYY-MM-DD HH:mm:ss")}</p> */}
                            <p style={{ fontSize: "12px", lineHeight: "1.3" }}>{date.format("YYYY-MM-DD HH:mm:ss")}</p>
                          </>
                        );
                      },
                      sortDirections: ["ascend", "descend"],
                      sortOrder:
                        !store.formFilter.form.hideSort &&
                        store.formFilter.form.sortBy === "requestDate"
                          ? store.formFilter.form.sortOrder === "ASC"
                            ? "ascend"
                            : "descend"
                          : undefined,
                      sorter: (a, b) => a.age - b.age,
                    },
                    {
                      title: "발송일자",
                      dataIndex: "requestDate",
                      key: "requestDate",
                      align: "center",
                      width: windowsize ? 80 : 85,
                      render: (v) => {
                        const date = dayjs(v);
                        return (
                          <>
                           {/* <p>{dayjs(v).subtract(9,"hour").format("YYYY-MM-DD HH:mm:ss")}</p> */}
                            <p style={{ fontSize: "12px", lineHeight: "1.3" }}>{date.format("YYYY-MM-DD HH:mm:ss")}</p>
                          </>
                        );
                      },
                      sortDirections: ["ascend", "descend"],
                      sortOrder:
                        !store.formFilter.form.hideSort &&
                        store.formFilter.form.sortBy === "requestDate"
                          ? store.formFilter.form.sortOrder === "ASC"
                            ? "ascend"
                            : "descend"
                          : undefined,
                      sorter: (a, b) => a.age - b.age,
                    },
                    {
                      title: "구분",
                      dataIndex: "messageType",
                      key: "messageType",
                      align: "center",
                      width: 80,
                      sortDirections: ["ascend", "descend"],
                      sortOrder:
                        !store.formFilter.form.hideSort &&
                        store.formFilter.form.sortBy === "messageType"
                          ? store.formFilter.form.sortOrder === "ASC"
                            ? "ascend"
                            : "descend"
                          : undefined,
                      sorter: (a, b) => a.age - b.age,
                      render: (v) => {
                      //   if(v==="sms")
                      //   return (
                          
                      //     <Tag style={{ textTransform: "uppercase", background:"blue",color:"white"}}>
                      //      {v}
                      //  </Tag>
                      //   );
                      //   else if(v==="mms")
                      //   return (
                          
                      //     <Tag style={{ textTransform: "uppercase", background:"orange",color:"white"}}>
                      //      {v}
                      //     </Tag>
                      //   );
                      //   else if(v==="lms")
                      //   return (
                          
                      //     <Tag style={{ textTransform: "uppercase", background:"red",color:"white"}}>
                      //      {v}
                      //     </Tag>
                      //   );
                      //   else
                      //   return (
                          
                      //     <Tag style={{ textTransform: "uppercase", background:"green",color:"white"}}>
                      //       알림톡
                      //     </Tag>
                      //   );

                      // },
                      if(v==="alimtalk")
                        return (
                          
                          <Tag style={{color:"black" , width : "50px"}} >
                             알림톡
                           </Tag>
                        );
                        else
                      return (
                        <Tag style={{ textTransform: "uppercase",color:"black", width : "50px"}}>
                               {v}
                             </Tag>
                         );
                      }
                    },
                    {
                      title: "발송자",
                      dataIndex: "userName",
                      key: "userName",
                      align: "center",
                      width: windowsize ? 50 : 100,
                    },
                    {
                      title: "발송건수",
                      dataIndex: windowsize? "none" : "requested",
                      key: "requested",
                      align: "center",
                      width: windowsize ? 0 : 100,
                    },
                    {
                      title: windowsize ? "추가 정보" : "발송상태",
                      dataIndex: windowsize? "none" : "requested",
                      key: "requested",
                      align: "center",
                      width: windowsize ? 38 : 85,
                      render: (v, message: MessageReadResponseData) => {
                        // console.log(message.requestDate)
                        const date1= new Date(message.requestDate)
                        const date2= new Date()
                        console.log(date1.getTime())
                        console.log(date2.getTime())
                        return (
                          windowsize  ? 
                          date1 < date2 ?
                          <Tooltip
                            title={
                              <TagSpace >
                                <div style={{display: "flex" , flexDirection : "column"}}>
                                <div>
                                발송 건수 : {message.requested}
                                </div>
                                <div>
                                <p color="success">성공 : {message.sentSuccess}</p>
                                </div>
                                <div>
                                <p color="red">실패 : {message.sentFailed}</p>
                                </div>
                                <div>
                                <p color="orange">대기 : {message.pending}</p>

                                </div>

                                </div>
                              </TagSpace>
                            }
                          >
                            <GrMore />
                          </Tooltip>:

                              <p color="success">예약</p>


                          
                      :
                      date1 < date2 ?
                          
                            <div>
                              <p color="success">성공: {message.sentSuccess}</p>
                              <p color="red">실패: {message.sentFailed}</p>
                              <p color="orange">대기: {message.pending}</p>
                            </div>
                          
                          :
                          
                            <div>
                              <p color="success">예약</p>
                           
                            </div>

                          
                        );
                      },
                    },
                    {
                      title: "전송내용",
                      dataIndex: windowsize? "" : "body",
                      key: "body",
                      // ellipsis: {
                      //   showTitle: false,
                      // },
                      ellipsis: true,
                      showSorterTooltip: true,
                      width : windowsize? 0 : "auto",
                      render: (v) => {
                        return v;
                        return (
                          <Tooltip
                            placement="topLeft"
                            autoAdjustOverflow
                            title={v}
                            overlayInnerStyle={{
                              maxHeight: "50vh",
                              overflowY: "scroll",
                            }}
                            // overlayInnerStyle={{
                            //   maxWidth: "50%",
                            //   minWidth: "50vw",
                            //   maxHeight: "60vh",
                            //   overflowY: "scroll",
                            // }}
                            // overlayStyle={{
                            //   width: "100%",
                            //   minWidth: "50%",
                            //   // minWidth: "300px",
                            //   // minWidth: "50%",
                            //   // maxWidth: "300px",
                            // }}
                          >
                            {v}
                          </Tooltip>
                        );
                      },
                    },
                    {
                      title: "결과",
                      dataIndex: "",
                      key: "",
                      width: windowsize ? 65 : 100,
                      align: "center",
                      render: (_e, v: MessageReadResponseData) => {
                        const date1= new Date(v.requestDate)
                        const date2= new Date()
                        const onClick = () => {
                          // console.log(v)
                          store.onChangeSelectedSentMessage(v);
                        };
                        return (
                          windowsize ? 
                          date1 < date2 ?
                          <AntdButton onClick={onClick}>보기</AntdButton> 
                          :
                          <AntdButton onClick={()=>{
                            // console.log(v)
                            mutateMessageCancelResv(v.requestId)
                          }} style={{background : "lightgray" ,border :"1px solid gray"}}>취소</AntdButton>
                      
                          :
                          date1 < date2 ?

                          <AntdButton
                            onClick={onClick}
                            className="btnLineblue"
                            >
                              전송결과
                            </AntdButton>
                          :
                          <AntdButton onClick={()=>{
                            // console.log(v)
                            mutateMessageCancelResv(v.requestId)

                          }} style={{background : "lightgray" ,border :"1px solid gray"}}>예약취소</AntdButton>

                        );
                      },
                    },
                  ]}
                />
                {dataGetMessages?.data &&
                  dataGetMessages.data.body.total > 10 && (
                    <AntdPagination
                      position="right"
                      disabled={loadingGetMessages}
                      loading={loadingGetMessages}
                      pageSize={10}
                      size= {windowsize? "small" : "default" }  
                      current={store.formFilter.form.page}
                      total={dataGetMessages?.data.body.total}
                      onChange={onChangePagination}
                    />
                  )}
              </ExtendedLayoutCell>
            </LayoutCell>
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      <AntdModal
        className="my-modal"
        draggable
        centered
        width={500}
        okText="확인"
        // cancelText="취소"
        title="전송결과"
        visible={store.modalReqResult.visible}
        onOk={store.modalReqResult.onClose}
        onCancel={store.modalReqResult.onClose}
        cancelButtonProps={{
          hidden: true,
        }}
      >
        <ResultSentMessageModalContent />
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;

  .ant-modal {
    max-width: 500px !important;
  }
`;

const Content = styled.div`
  width: 100%;
  @media ${device.laptop.min} {
    /* max-height: calc(100vh - 81px - 20px - 12px); */
    min-height: 510px;
    & > div {
      height: 100%;
    }
  }
`;

const SearchBox = styled.div`
  width: 100%;
  form {
    width: 100%;
    min-width: 100%;
    display: flex;
    gap: 12px;
    & > div {
      &:last-child {
        min-width: 200px;
      }
    }
  }
  /* background-color: #f5f5f7; */
  /* background: rgb(100 107 116 / 5%); */
  /* padding: 15px 20px; */
  /* border-radius: 6px; */

  @media ${device.laptop.max} {
    form {
      display: block;
      & > div {
        max-width: 100% !important;
        &:last-child {
          min-width: 200px;
        }
      }
    }
  }
`;
const SearchSpace = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  button {
    margin-top: 24px;
  }
  @media ${device.laptop.max} {
    flex-flow: column;
    gap: 6px;
    & > div {
      max-width: 100% !important;
      button {
        margin-top: 0;
        width: 100%;
      }
    }
  }
`;

const ExtendedLayoutCell = styled.div`
  /* margin-top: 20px; */
  &:not(:first-child) {
    margin-top: 17px;
  }
  &.top-cell {
    height: 30%;
    td {
      &:first-child {
        /* border-left: 0 !important; */
        /* border-right: 0 !important; */
      }
      background-color: #fbfbfb;
      &:first-child,
      &:nth-of-type(2) {
        background-color: white;
      }
    }
  }
  &:last-child {
    /* height: calc(100% - 30% - 74px); */
  }
`;
const CellTitle = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 6px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    min-height: 100%;
    /* min-height: 100vh; */
  }
`;

const ExtendedAntdTable = styled(AntdTable)`
  .ant-table-thead > tr > th.titSms {
    background: var(--sms-label-lightcolor)!important;
    color: var(--sms-color)!important;
    font-weight: bold;
    font-size: 13px;
    transition: .4s;
    &:hover {
      filter: brightness(0.98);
    }
  }
  .ant-table-thead > tr > th.titLms {
    background: var(--lms-label-lightcolor)!important;
    color: var(--lms-color)!important;
    font-weight: bold;
    font-size: 13px;
    transition: .4s;
    &:hover {
      filter: brightness(0.98);
    }
  }
  .ant-table-thead > tr > th.titMms {
    background: var(--mms-label-lightcolor)!important;
    color: var(--mms-color)!important;
    font-weight: bold;
    font-size: 13px;
    transition: .4s;
    &:hover {
      filter: brightness(0.98);
    }
  }
  .ant-table-thead > tr > th.titTalk {
    background: var(--talk-label-lightcolor)!important;
    color: var(--talk-dark-color)!important;
    font-weight: bold;
    font-size: 13px;
    transition: .4s;
    &:hover {
      filter: brightness(0.98);
    }
  }
  .ant-table-thead > tr > th.sms {
    background: #f6fcff!important;
  }
  .ant-table-thead > tr > th.lms {
    background: #f1fff9!important;
  }
  .ant-table-thead > tr > th.mms {
    background: #faf3ff!important;
  }
  .ant-table-thead > tr > th.talk {
    background: #fffcef!important;
  }
  .ant-table-thead > tr > th.titTalk {
    color: var(--talk-dark-color)!important;
  }
  .ant-table-thead > tr > th.titSuccess {
    color: var(--success-color)!important;
  }
  .ant-table-thead > tr > th.titWarning {
    color: var(--warning-color)!important;
  }
  .ant-table-thead > tr > th.titDanger {
    color: var(--danger-color)!important;
  }
  @media ${device.laptop.min} {
    &.top-table {
      // height: calc(100% - 26px - 75px);
      //  height: calc(100% - 26px);
      overflow: hidden;
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        /* height: 100%; */
      }
      .ant-table-container {
        /* height: 100%; */

        .ant-table-body {
          /* max-height: calc(100% - 74px) !important;
          height: calc(100% - 74px) !important; */
          /* height: calc(94.5vh - 475px); */
        }
      }
    }

    &.last-table {
      overflow: hidden;
      /* height: calc(100% - 26px - 37px - 15px); */
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        /* height: 100%; */
      }
      .ant-table-container {
        /* height: 100%; */
        .ant-table-body {
          /* max-height: calc(100% - 37px) !important;
          height: calc(100% - 37px) !important; */
          /* height: calc(94.5vh - 475px); */
        }
      }
    }
  }
  @media ${device.laptop.max} {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
    font-size: 9px;
  }
`;

const Link = styled.span`
  cursor: pointer;
`;
const TagSpace = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 4px;
  &.overflow-hide {
    flex-flow: row;
    overflow: hidden;
  }
`;

