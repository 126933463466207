import { Input, Popover, Tag } from "antd";
import dayjs from "dayjs";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { device } from "../device";
import {
  AlimtalkControllerReadAllTemplateParams,
  AlimtalkTemplateReadResponseData,
  SmsControllerReadAllTemplateParams,
  SmsTemplateReadResponseData,
} from "../generated";
import { privateApi } from "../react-query";
import { useMessageSendStore } from "../stores";
import { IComponentProps } from "../types";
import { getTemplateFromParams, isSuccess } from "../utils";
import { AntdTable } from "./AntdTable";

interface IProps extends IComponentProps {
  /** 메시지불러오기="message" 혹은 템플릿선택="template" */
  mode: "message" | "template";
}

const fetcherGetSMSs = async (params: SmsControllerReadAllTemplateParams) =>
  privateApi.sms.smsControllerReadAllTemplate(params);

const fetcherGetSMS = async (id: string) =>
  privateApi.sms.smsControllerReadOneTemplate(id);

const fetcherGetAlimtalks = (params: AlimtalkControllerReadAllTemplateParams) =>
  privateApi.alimtalk.alimtalkControllerReadAllTemplate(params);

const fetcherGetAlimtalk = (id: string) =>
  privateApi.alimtalk.alimtalkControllerReadOneTemplate(id);

export const MessageOrTemplateModalContent: React.FC<IProps> = ({
  children,
  mode,
  ...props
}) => {
  const store = useMessageSendStore((s) => s);
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows:
        | SmsTemplateReadResponseData[]
        | AlimtalkTemplateReadResponseData[]
    ) => {
      console.log("selectedRows[0].data[0]: ", selectedRows[0]);
      if (mode === "message") {
        store.formReqMessage.onChangeForm({
          selectedTemplateOnModal: {
            templateIdx: selectedRows[0].templateIdx,
            templateContent: selectedRows[0].templateContent,
            templateName: selectedRows[0].templateName,
            SmsTemplateParamInformation: [],
            createAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            updateAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            isAd: 0,
            updateUserName: "",
          },
        });
      } else {
        const selectedTemplate =
          selectedRows[0] as AlimtalkTemplateReadResponseData;
        store.formReqMessage.onChangeForm({
          selectedAlimtalkTemplateOnModal: {
            categoryCode: selectedTemplate?.categoryCode,
            createAt: selectedTemplate?.createAt,
            nhnHookTemplateComment: selectedTemplate?.nhnHookTemplateComment,
            nhnHookTemplateStatus: selectedTemplate?.nhnHookTemplateStatus,
            nhnResultCode: selectedTemplate?.nhnResultCode,
            plusFriendId: selectedTemplate?.plusFriendId,
            templateCode: selectedTemplate?.templateCode,
            templateContent: selectedTemplate?.templateContent,
            templateIdx: selectedTemplate?.templateIdx,
            templateName: selectedTemplate?.templateName,
            updateAt: selectedTemplate?.updateAt,
            updateUserName: selectedTemplate?.updateUserName,
            AlimtalkTemplateParamInformation: [],
          },
        });
      }
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: AlimtalkTemplateReadResponseData) => {
      if (store.modalSelectTemplate.visible) {
        // 등록상태인 경우에만 선택가능.
        return {
          disabled: record.nhnHookTemplateStatus !== "TSC03", // Column configuration not to be checked
          name: record.nhnHookTemplateStatus,
        };
      }
      return {
        disabled: false, // Column configuration not to be checked
        name: undefined,
      };
    },
  };

  const { isLoading: loadingGetSMSs, data: dataGetSMSs } = useQuery(
    ["smsControllerReadAllTemplate"],
    () =>
      fetcherGetSMSs({
        page: 1,
        pageSize: 1000,
      }),
    {
      enabled: store.modalLoadMessage.visible,
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerReadAllTemplate onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("smsControllerReadAllTemplate onError: ", e);
      },
    }
  );

  const { isLoading: loadingGetAlimtalks, data: dataGetAlimtalks } = useQuery(
    ["alimtalkControllerReadAllTemplate"],
    () =>
      fetcherGetAlimtalks({
        page: 1,
        pageSize: 1000,
        plusFriendId:
          store.formReqMessage.form.selectedProfile?.plusFriendId || "",
      }),
    {
      enabled: store.modalSelectTemplate.visible,
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("alimtalkControllerReadAllTemplate onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("alimtalkControllerReadAllTemplate onError: ", e);
      },
    }
  );

  const {} = useQuery(
    [
      `smsControllerReadOneTemplate/${store.formReqMessage.form.selectedTemplateOnModal?.templateIdx}`,
    ],
    () =>
      fetcherGetSMS(
        store.formReqMessage.form.selectedTemplateOnModal?.templateIdx + ""
      ),
    {
      enabled: Boolean(
        store.formReqMessage.form.selectedTemplateOnModal?.templateIdx
      ),
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("smsControllerReadOneTemplate onSuccess: ", e);
          store.formReqMessage.onChangeForm({
            selectedTemplateOnModal: e.data.body.data[0],
            templateParams: e.data.body.data[0].SmsTemplateParamInformation.map(
              (v) => ({
                ...v,
                value: v.key,
              })
            ),
          });
        }
      },
      onError: (e) => {
        console.log("smsControllerReadOneTemplate onError: ", e);
      },
    }
  );

  const {} = useQuery(
    [
      `alimtalkControllerReadOneTemplate/${store.formReqMessage.form.selectedAlimtalkTemplateOnModal?.templateIdx}`,
    ],
    () =>
      fetcherGetAlimtalk(
        store.formReqMessage.form.selectedAlimtalkTemplateOnModal?.templateIdx +
          ""
      ),
    {
      enabled: Boolean(
        store.formReqMessage.form.selectedAlimtalkTemplateOnModal?.templateIdx
      ),
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("alimtalkControllerReadOneTemplate onSuccess: ", e);
          store.formReqMessage.onChangeForm({
            selectedAlimtalkTemplateOnModal: e.data.body.data[0],
            templateParams:
              e.data.body.data[0].AlimtalkTemplateParamInformation.map((v) => ({
                ...v,
                value: v.key,
              })),
          });
        }
      },
      onError: (e) => {
        console.log("alimtalkControllerReadOneTemplate onError: ", e);
      },
    }
  );

  /**
   * [치환문자] 변경시
   */
  const onChangeParamInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    store.formReqMessage.onChangeForm({
      templateParams: store.formReqMessage.form.templateParams.map((v) => {
        if (v.idx === +name) {
          return {
            ...v,
            value,
          };
        }
        return v;
      }),
    });
  };

  return (
    <Container {...props}>
      <Wrapper>
        <Cell>
          <CellTitle>
            {mode === "message" ? "저장된 메시지" : "템플릿 선택"}
          </CellTitle>
          <AntdTable
            rowKey="templateIdx"
            pagination={false}
            rowSelection={{
              type: "radio",
              selectedRowKeys: mode === "message" ? store.formReqMessage.form.selectedTemplateOnModal?.templateIdx
              ? [store.formReqMessage.form.selectedTemplateOnModal?.templateIdx]
              : [] :  store.formReqMessage.form.selectedAlimtalkTemplateOnModal?.templateIdx ? [store.formReqMessage.form.selectedAlimtalkTemplateOnModal?.templateIdx] : [] ,
         
              ...rowSelection,
            }}
            dataSource={
              mode === "message"
                ? dataGetSMSs?.data
                ?
                
                  dataGetSMSs.data.body.data
                
                  // ? [
                  //     ...dataGetSMSs.data.body.data,
                  //     {
                  //       ...dataGetSMSs.data.body.data[0],
                  //       templateContent: "임시...",
                  //       templateIdx: 2,
                  //       templateName: "----",
                  //     },
                  //   ]
                  : []
                : dataGetAlimtalks?.data
                ? dataGetAlimtalks.data.body.data
                : []
            }
            columns={
              mode === "message"
                ? [
                    {
                      title: "문자 제목",
                      dataIndex: "templateName",
                      key: "templateName",
                      // render: (e, v: any) => (
                      //   <></>
                      // ),
                    },
                  ]
                : [
                    {
                      title: "템플릿이름",
                      dataIndex: "templateName",
                      key: "templateName",
                      // render: (e, v: any) => (
                      //   <></>
                      // ),
                    },
                    {
                      title: "템플릿상태",
                      dataIndex: "nhnHookTemplateStatus",
                      key: "nhnHookTemplateStatus",
                      align: "center",
                      render: (status) => {
                        if (status === "TSC03") {
                          return <Tag color="green">등록</Tag>;
                        } else if (status === "TSC04") {
                          return <Tag color="red">반려</Tag>;
                        } else if (status === "TSC01") {
                          return <Tag color="blue">요청</Tag>;
                        } else if (status === "TSC02") {
                          return <Tag color="orange">검수</Tag>;
                        }
                        return <Tag>-</Tag>;
                      },
                    },
                  ]
            }
            onRow={
              mode === "message" ? 
              ((v:SmsTemplateReadResponseData)=>{
                return{
                  onClick: () =>{
                    rowSelection.onChange([v.templateIdx],[v])
                  }
                }
              }
  ) :  ((v:AlimtalkTemplateReadResponseData)=>{
    return{
      onClick: () =>{
        rowSelection.onChange([v.templateIdx],[v])
      }
    }
  }
)
            }
          />
        </Cell>
        <Divide />
        <Cell>
          <CellTitle>문자(템플릿) 내용</CellTitle>
          <Template>
            {getTemplateFromParams(
              store.formReqMessage.form.selectedTemplateOnModal
                ?.templateContent ||
                store.formReqMessage.form.selectedAlimtalkTemplateOnModal
                  ?.templateContent ||
                "",
              store.formReqMessage.form.templateParams
            )}
          </Template>
        </Cell>
        <Divide />
        <Cell>
          <CellTitle>
            치환문자 입력
            <Popover
              placement="topLeft"
              content={
                <div>
                  <p>##이름##을 입력할 경우,</p>
                  <p>주소록에 저장된 이름으로 자동 치환되어 발송됩니다.</p>
                </div>
              }
            >
              <AiOutlineQuestionCircle size={16} />
            </Popover>
          </CellTitle>
          {store.formReqMessage.form.templateParams?.map((v) => (
            <ParamItem key={v.idx}>
              <Input
                name={v.idx + ""}
                onChange={onChangeParamInput}
                value={v.value}
              />
            </ParamItem>
          ))}
        </Cell>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;

  @media ${device.laptop.max} {
    flex-flow: column;
    .ant-table-body {
      max-height: 160px !important;
    }
  }
`;

const Cell = styled.div`
  flex: 1;
  width: 33.3333%;
  & > div {
    width: 100%;
  }
  @media ${device.laptop.max} {
    width: 100%;
  }
`;
const Divide = styled.div`
  flex: 1;
  max-width: 1px;
  background-color: #dfdfdf;
  margin: 0 30px;

  @media ${device.laptop.max} {
    width: 0;
    margin: 6px 0;
    height: 1px;
    flex: none;
  }
`;

const CellTitle = styled.h4`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 6px;
`;

const Template = styled.div`
  white-space: pre-line;
  word-break: keep-all;
  background-color: #f3f3f3;
  border-radius: 6px;
  min-height: 350px;
  max-height: 400px;
  font-size: 13px;
  overflow-y: auto;
  padding: 10px;

  @media ${device.laptop.max} {
    min-height: 200px;
    max-height: 300px;
  }
`;

const ParamItem = styled.div`
  &:not(:first-child) {
    margin-top: 6px;
  }
`;
