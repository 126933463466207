import styled from "styled-components";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const LayoutTitle: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <Text>{children}</Text>
    </Container>
  );
};

const Container = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 6px;
`;
const Text = styled.p`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non */
`;
