import { Form, Input } from "antd";
import React, { useCallback } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import styled from "styled-components";
import { useMyPageAccountStore } from "../stores";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const AddedChildAccountModalContent: React.FC<IProps> = ({}) => {
  const store = useMyPageAccountStore((s) => s);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      store.formAddChildAccount.onChangeForm({
        [name]: value,
      });
    },
    []
  );

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="이메일">
          <Input
            autoFocus
            placeholder="이메일을 입력해주세요"
            name="email"
            maxLength={200}
            value={store.formAddChildAccount.form.email}
            onChange={onChange}
          />
        </Form.Item>
        <Form.Item required label="이름">
          <Input
            placeholder="이름을 입력해주세요"
            name="username"
            maxLength={100}
            value={store.formAddChildAccount.form.username}
            onChange={onChange}
          />
        </Form.Item>
      </Form>
      <WarningText>
        <p>
          <RiErrorWarningLine size={16} />
        </p>
        <p>
          입력하신 이메일로 초대메일이 발송됩니다. <br />
          (초대 메일 유효시간은 12시간입니다.)
        </p>
      </WarningText>
    </Container>
  );
};

const Container = styled.div``;

const WarningText = styled.p`
  display: flex;
  font-size: 12px;
  gap: 6px;
  color: #686868;
`;
