import dayjs from "dayjs";
import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import {
  ChargeControllerReadHistoryParams,
  PaymentHistoryResponseData,
} from "../generated";
import {
  IReducerForm,
  IReducerModal,
  reducerForm,
  reducerModal,
} from "../reducers";
import { getUUID } from "../utils";

const startRequestDate = dayjs().add(-1, "month").format("YYYY-MM-DD");
const endRequestDate = dayjs().format("YYYY-MM-DD");

interface IFormFilter extends ChargeControllerReadHistoryParams {}

interface IFormModalPayment {
  /** 충전금액 */
  payment: string;
  /** 결제방식 */
  method: "카드" | "휴대폰" | "가상계좌";
  /** 결제예정금액(부가세 포함) */
  expectedPayment: string;
  /** UUID (merchant_uid) */
  uuid?: string;
}

interface IFormModalAutoPayment {
  /** 정기결제 타입 (date=지정일,amount=기준금액) */
  type: "date" | "amount";
  /** 자동충전금액 */
  payment: string;
  /** 자동충전일 혹은 기준금액 */
  day: "1" | "5" | "10" | "15" | "20" | "25" | string;
  /** 결제예정금액(부가세 포함) */
  expectedPayment: string;
  /** UUID (merchant_uid) */
  uuid?: string;
  /** custormerUid (UUID) */
  custormerUid?: string;
}

interface IFormModalRepayment {
  payments: Array<PaymentHistoryResponseData & { balance: number }>;
}

interface IStore extends State {
  /** 검색 입력폼 */
  formFilter: IReducerForm<IFormFilter>;
  /** 충전 모달 입력폼 */
  formModalPayment: IReducerForm<IFormModalPayment>;
  /** 자동 충전 모달 입력폼 */
  formModalAutoPayment: IReducerForm<IFormModalAutoPayment>;
  /** 환불 모달창 결제 입력폼 */
  formModalRepayment: IReducerForm<IFormModalRepayment>;
  /** 모달창 (일반충전) */
  modalPayment: IReducerModal;
  /** 모달창 (자동충전) */
  modalAutoPayment: IReducerModal;
  /** 모달창 (환불) */
  modalRepayment: IReducerModal;
  onReset: () => void;
}

const initFormFilter: IFormFilter = {
  page: 1,
  pageSize: 10,
  chargedType: "all",
  startRequestDate,
  endRequestDate,
};

const initFormModalPayment: IFormModalPayment = {
  expectedPayment: "11000",
  method: "카드",
  payment: "10000",
  uuid: undefined,
};

const initFormModalAutoPayment: IFormModalAutoPayment = {
  type: "date",
  day: "5",
  expectedPayment: "11000",
  payment: "10000",
  uuid: undefined,
  custormerUid: undefined,
};

const initFormModalRepayment: IFormModalRepayment = {
  payments: [],
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    formFilter: reducerForm<IStore, IFormFilter>(
      set,
      "formFilter",
      initFormFilter
    ),
    formModalPayment: reducerForm<IStore, IFormModalPayment>(
      set,
      "formModalPayment",
      initFormModalPayment
    ),
    formModalAutoPayment: reducerForm<IStore, IFormModalAutoPayment>(
      set,
      "formModalAutoPayment",
      initFormModalAutoPayment
    ),
    formModalRepayment: reducerForm<IStore, IFormModalRepayment>(
      set,
      "formModalRepayment",
      initFormModalRepayment
    ),
    modalPayment: reducerModal<IStore>(
      set,
      "modalPayment",
      false,
      () => {
        setTimeout(() => {
          get().formModalPayment.onChangeForm(initFormModalPayment);
        }, 300);
      },
      () => {
        get().formModalPayment.onChangeForm({
          uuid: getUUID(),
        });
      }
    ),
    modalAutoPayment: reducerModal<IStore>(
      set,
      "modalAutoPayment",
      false,
      () => {
        setTimeout(() => {
          get().formModalAutoPayment.onChangeForm(initFormModalAutoPayment);
        }, 300);
      },
      () => {
        get().formModalAutoPayment.onChangeForm({
          uuid: getUUID(),
          custormerUid: getUUID(),
        });
      }
    ),
    modalRepayment: reducerModal<IStore>(set, "modalRepayment", false, () => {
      setTimeout(() => {
        get().formModalRepayment.onChangeForm(initFormModalRepayment);
      }, 300);
    }),
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** 내정보 > [계정관리] 페이지 상태관리 */
export const useChargePaymentStore = create<IStore>()(
  devtools(initialStateCreator)
);
