// @ts-ignore
import Fade from "react-reveal/Fade";
import { Divider, Form, Input, message, Radio, RadioChangeEvent,Tooltip} from "antd";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import styled from "styled-components";
import { AntdButton, LayouRow } from "../components";
import { useSignupStore } from "../stores";
import React, { useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { publicApi } from "../react-query";
import { MemberCreateAsCompanyDTO, MemberCreateDTO } from "../generated";
import { useMutation } from "@tanstack/react-query";
import { isSuccess, Pattern } from "../utils";
import { device } from "../device";

export interface IRequestCertiResponse {
  error_code?: string;
  error_msg?: string;
  imp_uid : string
  merchant_uid: string;
  pg_provider : string;
  pg_type : string;
  success: boolean;
}


const fetcherSignup = (params: MemberCreateDTO) =>
  publicApi.member.memberControllerSignup({
    imp_uid: params.imp_uid,
    password: params.password,
    userId: params.userId,
  });

const fetcherSignupByBusiness = (params: MemberCreateAsCompanyDTO) =>
  publicApi.member.memberControllerSignupAsCompany({
    companyCertFileName: params.companyCertFileName,
    companyName: params.companyName,
    password: params.password,
    presidentName: params.presidentName,
    registrationNumber: params.registrationNumber,
    residentNumber: params.residentNumber,
    userId: params.userId,
    carrierCertFileName: params.carrierCertFileName,
    imp_uid: params.imp_uid,
    carrierCertFilePhone: params.carrierCertFilePhone,
    companyPhone: params.companyPhone,
  });

const fetcherUploadBusinessRegistered = (params: { files: File }) =>
  publicApi.member.memberControllerCompanyCertAttached({
    files: params.files,
  });

const fetcherUploadPhoneCert = (params: { files: File }) =>
  publicApi.member.memberControllerPhoneCertAttached({ files: params.files });

let fileBusinessRegistered = "";
let filePhoneCert = "";
let currentFilePhone = "";

// function handleRequestCertificationToIMP() {
//   const {IMP} = window as any;
//   //리디안
//   IMP.init("imp18827175"); 
//   // IMP.init("imp10391932");

//   IMP.certification({ // param
//     merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
//     popup: true // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
//   },
//    rsp => { // callback
//     if (rsp.success) {
//       console.log("RSP1: " + rsp.imp_uid)
//       message.info("본인인증이 완료되었습니다.")
//       //rsp.imp_uid 를 서버로 보내야함.
//     } else {
//       message.error(rsp.error_msg)
//       // console.log(rsp)
//     }
//   });

// }



export const Signup = () => {
  const navigate = useNavigate();
  const store = useSignupStore((s) => s);

  const inputFileBusinessRegisteredRef = useRef<HTMLInputElement | null>(null);
  const inputFilePhoneCertRef = useRef<HTMLInputElement | null>(null);

  
function handleRequestCertificationToIMP() {
  const {IMP} = window as any;
  //리디안
  IMP.init("imp18827175"); 
  // IMP.init("imp10391932");
  const data ={
    merchant_uid :`mid_${new Date().getTime()}`,
    pg_provider : "danal",
    pg_type : "certification",
    popup : true
  }

  IMP.certification(data, function (rsp: IRequestCertiResponse) {
    console.log("rsp: ", rsp);
    if (rsp.success) {
     
      if (rsp.imp_uid) {
        console.log(rsp.imp_uid);
        message.info("본인인증이 완료되었습니다.")
        store.form.onChangeForm({
          imp_uid:rsp.imp_uid
        })
      }
    } else {
      message.error(rsp.error_msg);
      if (rsp.imp_uid) {
        // mutatePayFailed({
        //   reservationId: e.data.body?.id!,
        //   impUid: rsp.imp_uid as string,
        // });
      }
    }
  });
}
  const { isLoading: loadingSignup, mutate: mutateSignup } = useMutation(
    fetcherSignup,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerSignup onSuccess: ", e);
          message.info("회원가입되었습니다");
          navigate("/");
        }
      },
      onError: (e) => {
        console.log("memberControllerSignup onError: ", e);
      },
    }
  );

  const { isLoading: loadingSignupByBusiness, mutate: mutateSignupByBusiness } =
    useMutation(fetcherSignupByBusiness, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerSignupAsCompany onSuccess: ", e);
          message.info("회원가입되었습니다");
          navigate("/");
        }
      },
      onError: (e) => {
        console.log("memberControllerSignupAsCompany onError: ", e);
      },
    });

  const {
    isLoading: loadingUploadBusinessRegistered,
    mutate: mutateUploadBusinessRegistered,
  } = useMutation(fetcherUploadBusinessRegistered, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("memberControllerCompanyCertAttached onSuccess: ", e);
        store.form.onChangeForm({
          fileBusinessRegistered: {
            currentFileName: fileBusinessRegistered,
            fileName: e.data.body.data.fileName,
          },
        });
        message.info("첨부되었습니다");
      }
    },
    onError: (e) => {
      console.log("memberControllerCompanyCertAttached onError: ", e);
    },
  });

  const { isLoading: loadingUploadPhoneCert, mutate: mutateUploadPhoneCert } =
    useMutation(fetcherUploadPhoneCert, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerPhoneCertAttached onSuccess: ", e);
          store.form.onChangeForm({
            filePhoneCert: {
              currentFileName: filePhoneCert,
              fileName: e.data.body.data.fileName,
            },
          });
          message.info("첨부되었습니다");
        }
      },
      onError: (e) => {
        console.log("memberControllerPhoneCertAttached onError: ", e);
      },
    });

  /**
   * [개인/기업고객] 클릭시
   */
  const onClickType = useCallback((type: "개인" | "기업") => {
    store.onChangeType(type);
  }, []);

  /**
   * [법인/개인] 사업자 변경시 or
   * [전화번호/서류] 인증 변경시
   */
  const onChangeRadioType = useCallback((e: RadioChangeEvent) => {
    const {
      target: { name, value },
    } = e;
    if (name === "bizType") {
      // 사업자 변경시
      store.form.onChangeForm({
        [name as any]: value,
        regidentId: "",
      });
    } else {
      // 인증수단 변경시
      store.form.onChangeForm({
        [name as any]: value,
        phone: "",
        currentFilePhone: "",
        filePhoneCert: {
          currentFileName: "",
          fileName: "",
          
        },
        verified: false,
      });
    }
  }, []);

  /**
   * [사업자등록증 첨부하기] 클릭 이후 업로드시
   */
  const onFileBusinessRegisteredChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { files },
    } = e;
    if (files && files?.length > 0) {
      const file = files[0];
      fileBusinessRegistered = file.name;
      mutateUploadBusinessRegistered({
        files: file,
      });
    }
    (e.target as any).value = null;
  };

  /**
   * [서류인증 첨부하기] 클릭 이후 업로드시
   */
  const onFilePhoneCertChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { files },
    } = e;
    if (files && files?.length > 0) {
      const file = files[0];
      filePhoneCert = file.name;
      mutateUploadPhoneCert({
        files: file,
      });
    }
    (e.target as any).value = null;
  };

  /**
   * [사업자등록증 첨부하기] 클릭시
   */
  const onClickBusinessRegistered = useCallback(() => {
    inputFileBusinessRegisteredRef.current?.click();
  }, [inputFileBusinessRegisteredRef.current]);

  /**
   * [서류인증 첨부하기] 클릭시
   */
  const onClickPhoneCert = useCallback(() => {
    inputFilePhoneCertRef.current?.click();
  }, [inputFilePhoneCertRef.current]);

  /**
   * [아이디/패스워드/전화번호/사업자번호/대표자명/대표번호] 변경시
   */
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    store.form.onChangeForm({
      [name]: value,
    });
  };

  /**
   * [로그인] 클릭시
   */
  const onClickLogin = useCallback(() => {
    navigate("/");
  }, []);

  /**
   * [마스터 계정 만들기] 버튼 클릭시
   */
  const onSubmit = () => {
    const { form } = store.form;
    // 이메일 검증
    if (!Pattern.checkEmail.test(form.id)) {
      
      return message.warn("올바른 이메일형식으로 입력해주세요");
      
    }
    if (form.password.length < 4) {
      return message.warn("비밀번호는 4자리 이상으로 입력해주세요");
    }

    if (store.form.form.type === "개인") {

      // TODO : imp_uid추가하기.
      // console.log(form.imp_uid)
      mutateSignup({
        imp_uid: form.imp_uid,
        userId: form.id,
        password: form.password,
      });
    } else {
      const type = form.bizType === "법인" ? "사업자등록번호" : "생년월일";
      if(form.companyName.length < 1) {
        return message.warn("회사명을 입력해주세요");
      }
      if (type === "생년월일") {
        if (form.regidentId.length < 8) {
          return message.warn("생년월일은 8자리로 입력해주세요");
        }
      } else {
        if (form.regidentId.length < 13) {
          return message.warn("법인등록번호는 13자리로 입력해주세요");
        }
      }
      if (!Pattern.checkOptional(Pattern.숫자).test(form.regidentId)) {
        return message.warn(type + "은 숫자로만 입력해주세요");
      }
      if(form.ceoName.length < 1) {
        return message.warn('대표자명을 입력해주세요')
      }
      if(form.ceoPhone.length < 1) {
        return message.warn('대표번호를 입력해주세요')
      }
      if(!Pattern.checkOptional(Pattern.숫자).test(form.ceoPhone)) {
        return message.warn('대표번호는 숫자로 입력해주세요')
      }
      if(!form.fileBusinessRegistered.fileName) {
        return message.warn("사업자등록증을 첨부해주세요");
      }
      //  else {
      //   if(form.filePhoneCert.fileName.length < 1) {
      //     return message.warn("통신가입증명원 서류를 첨부해주세요");
      //   }
      //   if(form.currentFilePhone.length < 1) {
      //     return message.warn("통신가입증명원: 인증받을 전화번호를 입력해주세요");
      //   }
      // }
      // TODO: imp_uid, companyName필드가 없음.
      mutateSignupByBusiness({
        imp_uid: form.imp_uid,
        companyName: form.companyName,
        companyPhone: form.ceoPhone,
        presidentName: form.ceoName,
        companyCertFileName: form.fileBusinessRegistered.fileName,
        carrierCertFileName: form.filePhoneCert.fileName,
        carrierCertFilePhone: form.currentFilePhone,
        password: form.password,
        userId: form.id,
        registrationNumber: form.bizNo,
        residentNumber: form.regidentId,
      });
    }
  };

  useEffect(() => {
    return () => {
      setTimeout(() => {
        store.onReset();
      }, 300);
    };
  }, []);

  return (
    <Container>
      <div className="signUpBgTxtCont">
        <div className="signinImgCont">
          <img src="/images/sendit_signin.png" alt="" className="signinImg"/>
        </div>
      </div>
      <Fade>
        <LayouRow>
          <input
            style={{
              display: "none",
            }}
            name=""
            id=""
            type="file"
            accept="image/jpg, image/jpeg ,image/png ,image/pdf"
            ref={inputFileBusinessRegisteredRef}
            onChangeCapture={onFileBusinessRegisteredChangeCapture}
          />
          <input
            style={{
              display: "none",
            }}
            name=""
            id=""
            type="file"
            accept="image/jpg, image/jpeg ,image/png ,image/pdf"
            ref={inputFilePhoneCertRef}
            onChangeCapture={onFilePhoneCertChangeCapture}
          />
          <Wrapper className={store.form.form.type === "기업" ? "biz-active" : ""}>
            <Logo>
              <img src="/images/img-login.png" alt="" />
              {/* <AiOutlineSend size={16} /> */}
              회원가입
            </Logo>
            <TypeGroup>
              <TypeItem
                disabled={store.form.form.type === "개인"}
                className={store.form.form.type === "개인" ? "active" : ""}
                onClick={() => onClickType("개인")}
              >
                개인 고객
              </TypeItem>
              <TypeItem
                disabled={store.form.form.type === "기업"}
                className={store.form.form.type === "기업" ? "active" : ""}
                onClick={() => onClickType("기업")}
              >
                기업 고객
              </TypeItem>
            </TypeGroup>

            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
            >
              {store.form.form.type === "기업" && (
                <Form.Item>
                  <RadioSpace>
                    <Radio.Group
                      name="bizType"
                      value={store.form.form.bizType}
                      onChange={onChangeRadioType}
                    >
                      <Radio value="법인">법인사업자</Radio>
                      <Radio value="개인">개인사업자</Radio>
                    </Radio.Group>
                  </RadioSpace>
                </Form.Item>
              )}

              <Form.Item
                rules={[{ required: true, message: "아이디를 입력해주세요" }]}
              >
                <Input
                  autoFocus
                  // prefix={<AiOutlineUser className="site-form-item-icon" />}
                  placeholder="이메일"
                  name="id"
                  value={store.form.form.id}
                  onChange={onChangeInput}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "패스워드를 입력해주세요" }]}
              >
                <Input
                  // prefix={<AiOutlineLock className="site-form-item-icon" />}
                  type="password"
                  placeholder="패스워드"
                  name="password"
                  value={store.form.form.password}
                  onChange={onChangeInput}
                />
              </Form.Item>
              {store.form.form.type === "기업" && (
                <Form.Item
                  rules={[{ required: true, message: "회사명을 입력해주세요" }]}
                >
                  <Input
                    // prefix={<AiOutlineUser className="site-form-item-icon" />}
                    placeholder="회사명"
                    name="companyName"
                    value={store.form.form.companyName}
                    onChange={onChangeInput}
                  />
                </Form.Item>
              )}
              {store.form.form.type === "개인" ? (
                <>
                  <Form.Item>
                    <Space>
                      {/* <Input
                        placeholder="전화번호"
                        name="phone"
                        value={store.form.form.phone}
                        onChange={onChangeInput}
                      /> */}
                      <AntdButton
                        isPrimary
                        className="signup-form-button"
                        onClick={()=>
                          handleRequestCertificationToIMP()
                        }
                        disabled={store.form.form.imp_uid != "" ? true : false}
                      >
                        전화번호 인증하기
                      </AntdButton>
                      <p></p> 
                     </Space>
                  </Form.Item>
                  {store.form.form.imp_uid != "" ?  
                   <div style={{display: "flex" , justifyContent : "center"}}>
                  <p style={{margin : "auto"}}>인증이 완료되었습니다. 가입을 진행해주세요.</p>

                  </div> :   <div style={{display: "flex" , justifyContent : "center"}}>
                  <p style={{margin : "auto"}}></p>

                  </div>}
                  
                </>
              ) : (
                <>
                  <Form.Item>
                    {store.form.form.bizType === "법인" ? (
                      <Input
                        placeholder="법인등록번호(13자리 숫자만)"
                        maxLength={13}
                        name="regidentId"
                        value={store.form.form.regidentId}
                        onChange={onChangeInput}
                      />
                    ) : (
                      <Input
                        placeholder="생년월일(8자리 숫자만)"
                        maxLength={8}
                        name="regidentId"
                        value={store.form.form.regidentId}
                        onChange={onChangeInput}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Input
                      placeholder="사업자등록번호(숫자만)"
                      maxLength={13}
                      name="bizNo"
                      value={store.form.form.bizNo}
                      onChange={onChangeInput}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space2>
                      <Input
                        placeholder="대표자명"
                        style={{ maxWidth: 120 }}
                        name="ceoName"
                        value={store.form.form.ceoName}
                        onChange={onChangeInput}
                      />
                      <Input
                        placeholder="대표번호"
                        name="ceoPhone"
                        value={store.form.form.ceoPhone}
                        onChange={onChangeInput}
                      />
                    </Space2>
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Input
                        placeholder="사업자등록증"
                        disabled
                        value={
                          store.form.form.fileBusinessRegistered.currentFileName
                        }
                      />
                      <AntdButton
                        isPrimary
                        style={{
                          color: "white",
                        }}
                        loading={loadingUploadBusinessRegistered}
                        onClick={onClickBusinessRegistered}
                      >
                        첨부하기
                      </AntdButton>
                    </Space>
                  </Form.Item>
                  <Form.Item>
                  
                    <RadioSpace>
                      <Radio.Group
                        name="verifiedType"
                        value={store.form.form.verifiedType}
                        onChange={onChangeRadioType}
                      >
                        <Radio value="전화번호">전화번호인증</Radio>
                        <Radio value="서류">서류인증</Radio>
                      </Radio.Group>
                      <Tooltip 
                              overlayStyle={{maxWidth: '500px'}}
                              
                              title={
                                <div>
                                  <p>법인사업자일 경우 : 법인명의 휴대폰 번호로만 인증 가능합니다.</p>
                                  <p>개인사업자일 경우 : 대표자명의 휴대폰 번호로만 인증 가능합니다.</p>
                                </div>
                              }
                            >
                            <BsQuestionCircle/>
                            </Tooltip>
                    </RadioSpace>
                  
                  </Form.Item>
                  <Form.Item>
                    {store.form.form.verifiedType === "전화번호" ? (
                      <>
                      <Space>
                        {/* <Input
                          placeholder="전화번호"
                          name="phone"
                          value={store.form.form.phone}
                          onChange={onChangeInput}
                        /> */}
                        <AntdButton
                          isPrimary
                          className="signup-form-button"
                          onClick={()=>{
                            handleRequestCertificationToIMP()
                          }}
                          disabled={store.form.form.imp_uid != "" ? true : false}
                        >
                          전화번호 인증하기
                        </AntdButton>
                      </Space>
                      {store.form.form.imp_uid != "" ?  
                      <div style={{display: "flex" , justifyContent : "center" ,marginTop : "8px"}}>
                      <p style={{margin : "auto"}}>인증이 완료되었습니다. 가입을 진행해주세요.</p>
    
                      </div> :   <div style={{display: "flex" , justifyContent : "center",marginTop : "8px"}}>
                      <p style={{margin : "auto"}}></p>
    
                      </div>}
                      </>
                        
                    ) : (
                      <div>
                      <Input
                        placeholder="통신가입증명원: 인증받을(기입된) 전화번호"
                        name="currentFilePhone"
                        maxLength={11}
                        value={store.form.form.currentFilePhone}
                        onChange={onChangeInput}
                        style={{marginBottom: "3px"}}
                      />
                      <Space>
                        <Input
                          disabled
                          placeholder="통신가입증명원 첨부"
                          value={store.form.form.filePhoneCert.currentFileName}
                        />
                        <AntdButton
                          isPrimary
                          style={{
                            color: "white",
                          }}
                          loading={loadingUploadPhoneCert}
                          onClick={onClickPhoneCert}
                        >
                          첨부하기
                        </AntdButton>
                      </Space>
                      </div>
                    )}
                  </Form.Item>
                </>
              )}

              <WarningText>
                잠깐! 이미 가입된 회사의 직원이신가요? <br />
                담당자가 보낸 초대메일을 먼저 확인해 주세요. <br />
                {/* <span>자세히보기</span> */}
              </WarningText>
              <Form.Item>
                <AntdButton
                  isPrimary
                  htmlType="submit"
                  className="signup-form-button"
                  style={{
                    color: "white",
                    width: "100%",
                  }}
                  disabled={store.form.form.type === "개인" ? 
                  store.form.form.imp_uid != "" && store.form.form.id != "" && store.form.form.password != "" 
                  ? false : true : store.form.form.verifiedType === "전화번호" ? store.form.form.id != "" && store.form.form.password != ""
                  && store.form.form.companyName != "" && store.form.form.regidentId != "" && store.form.form.bizNo != "" && store.form.form.ceoName != "" 
                  && store.form.form.ceoPhone != "" && store.form.form.imp_uid != ""
                  && store.form.form.fileBusinessRegistered.currentFileName != "" ? false : true :
                  store.form.form.id != "" && store.form.form.password != "" && store.form.form.companyName != ""
                  && store.form.form.regidentId != "" && store.form.form.bizNo != "" && store.form.form.ceoName != "" 
                  && store.form.form.ceoPhone != "" && store.form.form.fileBusinessRegistered.currentFileName != "" 
                  && store.form.form.filePhoneCert.currentFileName != "" && store.form.form.currentFilePhone != ""
                  ? false : true
                }
                  onClick={onSubmit}
                >
                  가입하기
                </AntdButton>
              </Form.Item>
            </Form>
            <AgreeText>
              가입하면 <span onClick={()=>{
                window.open("https://admin.sendit.kr/sendit_tos.html" , '_blank')
              }}>이용약관</span>과 <span onClick={()=>{
                window.open("https://admin.sendit.kr/sendit_privacy_policy.html" , '_blank')
              }}>개인정보취급방침</span>
              에 <br />
              동의한것으로 봅니다.
            </AgreeText>
            <AlreadyText>
              이미 가입하셨나요? <span onClick={onClickLogin}>로그인</span>
            </AlreadyText>
          </Wrapper>
        </LayouRow>
      </Fade>
    </Container>
  );
};

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  background: var(--primary-color);
  .signUpBgTxtCont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    .signinImgCont {
      width: 100%;
      .signinImg {
        height: 490px;
        padding-left:80px;
      }
      &::after {
        content: " ";
        width: 100%;
        border-bottom: 2px dashed #577b89;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px;
        font-size: 20px;
        color: #ff9b52;
        font-weight: 500;
      }
    }
  }

  @media ${device.tablet.max} {
    .signUpBgTxtCont {
      display:none;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  right:0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  height: 100vh;
  min-height: 650px;
  flex-flow: column;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 32px 0px;
  transition: .2s;

  &.biz-active {
    min-height: 900px;
  }
  form {
    max-width: 400px;
    width: 100%;
    .ant-input {
      padding: 8px;
    }
    .signup-form-button {
      color: white;
      width: 100%;
      margin-top: 10px;
      height: 48px;
      fontWeight: 500;
      span {
        font-size: 16px!important;
      }
    }
  }

  @media ${device.tabletL.max} {
    max-width: 100%;
  }

  @media ${device.tablet.max} {
    form {
      padding: 0 36px;
      .signup-form-button {
        height: 42px;
        span {
          font-size: 14px!important;
        }
      }
    }
  }
`;

const Logo = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 600;
  border-bottom-right-radius: 38%;
  border-top-left-radius: 20%; */
  width: 184px;
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;

  svg {
    fill: var(--primary-color);
  }
  img {
    max-width: 194px;
    display: block;
    margin: 0 auto;
    margin-bottom: 13px;
  }

  @media ${device.tablet.max} {
    font-size: 23px;
    margin-bottom: 20px;
    img {
      max-width: 150px;
    }
  }
`;

const TypeGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: -12px;
  margin-bottom: 22px;
`;

const TypeItem = styled.button`
  padding: 2px 9px;
  font-size: 14px;
  cursor: pointer;
  background-color: inherit;
  border-radius: 6px;
  border: 1px solid #f5f5f7;
  color: gray;
  transition: 0.2s;
  &.active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  &:disabled {
    cursor: default;
  }
`;
const WarningText = styled.p`
  margin: 15px 0;
  color: gray;
  text-align: center;
  span {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
  }
`;

const AgreeText = styled.p`
  margin: 12px 0;
  color: gray;
  text-align: center;
  span {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
  }
`;
const AlreadyText = styled.p`
  color: gray;
  text-align: center;
  span {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
  }
`;
const Space = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const Space2 = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap : 8px;
`;

const RadioSpace = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
