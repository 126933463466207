import { useMemo,useState,useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components";
import { useSummaryDashboardStore } from "../stores";
import { IComponentProps } from "../types";
import dayjs from "dayjs";
import { Formatter } from "../utils";
import { device } from "../device";

interface IProps extends IComponentProps {}

export const DashboardChargeStatus: React.FC<IProps> = () => {
  const store = useSummaryDashboardStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  const dataSource = useMemo(() => {
    return store.chargeStats
      .map((v) => ({
        ...v,
        requestDate: dayjs(v.requestDate).format("YYYY MM월"),
        "이용금액(원)": +v.chargedBalance,
      }))
      .reverse();
  }, [store.chargeStats]);

  /** 평균 금액 */
  const average = useMemo(
    () =>
      Math.floor(
        store.chargeStats
          // .map((v) => +v.chargedBalance * 1000)
          .map((v) => +v.chargedBalance)
          .reduce((prev, next) => prev + next, 0) / 6
      ),
    [store.chargeStats]
  );

  return (
    <Container>
      <ResponsiveBar
        isInteractive
        data={store.chargeStats
          .map((v) => ({
            ...v,
            requestDate: windowsize? dayjs(v.requestDate).format("YY.MM") : dayjs(v.requestDate).format("YYYY MM월"),
            // "이용금액(원)": +v.chargedBalance * 1000,
            "이용금액(원)": +v.chargedBalance ,
          }))
          .reverse()}
        keys={["이용금액(원)"]}
        indexBy="requestDate"
        margin={windowsize?{ top:20, right: 60, bottom: 60, left: 40 } : { top: 50, right: 130, bottom: 50, left: 60 }}
        padding={windowsize? 0.7 : 0.3}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        // colors={{ scheme: "nivo" }}
        colors={({ id, data }) => "#527cbd"}
        valueFormat={(value) =>
          `${value >= 1000 ? `${(value / 1000).toFixed(1)}K` : value}`
        }
        theme={ windowsize? {fontSize : 9} : {fontSize : 11}}
        markers={[
          {
            legendPosition: "right",
            axis: "y",
            value: average,
            lineStyle: {
              stroke: "#ff8d00",
              strokeWidth: 2,
              // color: "#ff8d00",
              fontSize: 12,
            },
            textStyle: {
              fontSize: windowsize? 8 : 12,
              fontWeight: "bold",
              fill: "#ff8d00",
            },
            legend: `평균: ${Formatter.통화(average)}`,
            // legendOrientation: "vertical",
          },
        ]}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={
          [
            // {
            //   match: {
            //     id: "fries",
            //   },
            //   id: "dots",
            // },
            // {
            //   match: {
            //     id: "sandwich",
            //   },
            //   id: "lines",
            // },
          ]
        }
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "country",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "food",
          legendPosition: "middle",
          legendOffset: -40,
          format: (e) => {
            return Formatter.통화(e);
          },
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["brighter", 10]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: windowsize ? "bottom" : "top-right",
            direction: "column",
            justify: false,
            translateX: windowsize ? 20 : 100,
            translateY: windowsize ?50 : 0,
            itemsSpacing: 2,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 12,
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        animate
        barAriaLabel={function (e) {
          return e.indexValue + "원";
          // return (
          //   e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          // );
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  max-height: 300px;

  @media ${device.laptop.max} {
    max-height: inherit;
    & > div {
      height: 300px !important;
      & > div {
        & > svg {
          height: 300px;
        }
      }
    }
  }
`;