import dayjs from "dayjs";

export class Formatter {
  /** 통화 포멧팅 */
  static 통화(n?: number | string, sign?: string) {
    if (n !== 0 && !n) {
      return n;
    }
    const sansDec = Number(n).toFixed(0);
    const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return sign || "" + `${formatted}`;
  }

  /**
   * 남은시간 포멧팅
   *  @param s : (시간) 초
   *  @param sign : (시간) 문자형식
   */
  static 시간(s: number, sign: string = ":"): string {
    const min = Math.floor(s / 60);
    const sec = Math.floor(s % 60);

    const strMin = min < 10 ? `0${min}` : `${min}`;
    const strSec = sec < 10 ? `0${sec}` : `${sec}`;

    return strMin + `${sign}` + strSec;
  }

  /**
   * 일자(일시) 포멧팅
   * @param date : 날짜 입력.
   * @param format : 형식 (ex. YYYY-MM-DD)
   * @returns
   */
  static 날짜(date: Date | string, format: string = "YYYY-MM-DD"): string {
    return dayjs(date).format(format);
  }

  /**
   * 주민등록번호 포멧팅
   * @param n : 주민등록번호
   * @returns
   */
  static 주민등록번호(n: number | string): string | undefined {
    if (!n) {
      return undefined;
    }
    if (n.toString().length === 13) {
      return Number(n)
        .toString()
        .replace(/(\d{6})(\d{7})/, "$1-$2");
    }
    return n.toString();
  }

  /**
   * 만나이 계산
   * @param n : 주민등록번호
   * @returns
   */
  static 만나이(n: number | string): string | undefined {
    if (!n) {
      return undefined;
    }
    const today = dayjs();
    const b = n.toString().substr(0, 6);
    const yy = b.substr(0, 2);
    const mm = b.substr(2, 2);
    const dd = b.substr(4, 2);
    const yyyy =
      +yy < +today.get("year").toString().substr(2, 2) + 1
        ? "20" + yy
        : "19" + yy;
    const validate = +mm < 12 && +mm > 0 && +dd > 0 && +dd < 32;
    if (n.toString().length === 13 && validate) {
      const birth = dayjs(yyyy + mm + dd);
      let result = today.get("year") - birth.get("year");
      if (today < birth) {
        return (result--).toString();
      }
      return result.toString();
    }
    return "";
  }

  /**
   * 세율
   * @param n
   * @returns
   */
  static 세율(n: number | string): string | undefined {
    if (!n) {
      return undefined;
    }
    return (Number(n) * 0.01).toString();
  }

  /**
   * 월 변환 (number -> string) ex) 4 -> 04
   * @param n
   * @returns
   */
  static 월(n: number | string): string | undefined {
    const numberVal = Number(n);
    if (!n) {
      return undefined;
    }
    return numberVal < 10 ? "0" + numberVal : numberVal.toString();
  }
}

// export const numberWithCommas = (num: number | string): string => {
//   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };
