import { Checkbox, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useMemo } from "react";
import styled from "styled-components";
import { CustormerReadResponseData } from "../generated";
import { useAddressBookListStore } from "../stores";
import { IComponentProps } from "../types";
import { AntdTable } from "./AntdTable";

interface IProps extends IComponentProps {}

export const AddedAddressBooksModalContent: React.FC<IProps> = () => {
  const store = useAddressBookListStore((s) => s);

  const onChangeInput = (
    id: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { name, value },
    } = e;
    const updatedAddressBooks = store.uploadedExcelAddressBooks.map((v) => {
      if (v.custormerIdx === id) {
        return {
          ...v,
          [name]: value,
        };
      }
      return v;
    });
    store.onChangeUploadedExcelAddressBooks(updatedAddressBooks);
  };

  const onChangeCheckbox = (id: number, e: CheckboxChangeEvent) => {
    const {
      target: { name, checked },
    } = e;
    const updatedAddressBooks = store.uploadedExcelAddressBooks.map((v) => {
      if (v.custormerIdx === id) {
        return {
          ...v,
          [name as string]: checked ? 1 : 0,
        };
      }
      return v;
    });
    store.onChangeUploadedExcelAddressBooks(updatedAddressBooks);
  };

  const onClickAdsAgreeCheckbox = () => {
    const notAllChecked = store.uploadedExcelAddressBooks.some(
      (v) => !v.phone1AdFlag
    );
    const updatedAddressBooks = store.uploadedExcelAddressBooks.map((v) => ({
      ...v,
      phone1AdFlag: (notAllChecked ? 1 : 0) as 1,
    }));
    store.onChangeUploadedExcelAddressBooks(updatedAddressBooks);
  };

  const onClickIsPublicCheckbox = () => {
    const notAllChecked = store.uploadedExcelAddressBooks.some(
      (v) => !v.isPublic
    );
    const updatedAddressBooks = store.uploadedExcelAddressBooks.map((v) => ({
      ...v,
      isPublic: notAllChecked ? 1 : 0,
    }));
    store.onChangeUploadedExcelAddressBooks(updatedAddressBooks);
  };

  /** 광고성 수신동의 칼럼 체크박스 값 */
  const notAllCheckedAdsAgree = useMemo(
    () => store.uploadedExcelAddressBooks.some((v) => !v.phone1AdFlag),
    [store.uploadedExcelAddressBooks]
  );

  /** 공유주소록 칼럼 체크박스 값 */
  const notAllCheckedIsPublic = useMemo(
    () => store.uploadedExcelAddressBooks.some((v) => !v.isPublic),
    [store.uploadedExcelAddressBooks]
  );

  return (
    <Container>
      <AntdTable
        style={{
          minHeight: 300,
          borderTop: "1px solid #dfdfdf",
          borderBottom: "1px solid #dfdfdf",
        }}
        rowKey="custormerIdx"
        tableLayout="fixed"
        scroll={{ x: 520, y: 310 }}
        dataSource={store.uploadedExcelAddressBooks || []}
        pagination={false}
        columns={[
          {
            title: "이름",
            dataIndex: "custormerName",
            key: "custormerName",
            fixed: true,
            render: (e, v: CustormerReadResponseData) => (
              <Input
                name="custormerName"
                value={e}
                onChange={(e) => onChangeInput(v.custormerIdx, e)}
              />
            ),
          },
          {
            title: "이메일",
            dataIndex: "custormerEmail",
            key: "custormerEmail",
            fixed: true,
            render: (e, v: CustormerReadResponseData) => (
              <Input
                name="custormerEmail"
                value={e}
                onChange={(e) => onChangeInput(v.custormerIdx, e)}
              />
            ),
          },
          {
            title: "전화번호",
            dataIndex: "custormerPhone1",
            key: "custormerPhone1",
            render: (e, v: CustormerReadResponseData) => (
              <Input
                name="custormerPhone1"
                maxLength={11}
                value={e}
                onChange={(e) => onChangeInput(v.custormerIdx, e)}
              />
            ),
          },
          {
            title: "그룹",
            dataIndex: "custormerGroup",
            key: "custormerGroup",
            render: (e, v: CustormerReadResponseData) => (
              <Input
                name="custormerGroup"
                value={e}
                onChange={(e) => onChangeInput(v.custormerIdx, e)}
              />
            ),
          },
          {
            title: "태그",
            dataIndex: "custormerTag",
            key: "custormerTag",
            align: "center",
            render: (e, v: CustormerReadResponseData) => (
              <Input
                name="custormerTag"
                placeholder="#태그명"
                value={e}
                onChange={(e) => onChangeInput(v.custormerIdx, e)}
              />
            ),
          },
          {
            // title: "광고성수신동의",
            title: () => (
              <>
                <p>
                  <Checkbox
                    checked={!notAllCheckedAdsAgree}
                    onChange={onClickAdsAgreeCheckbox}
                  >
                    광고수신동의
                  </Checkbox>
                </p>
              </>
            ),
            dataIndex: "phone1AdFlag",
            key: "phone1AdFlag",
            align: "center",
            render: (e, v: CustormerReadResponseData) => {
              return (
                <Checkbox
                  name="phone1AdFlag"
                  onChange={(e) => onChangeCheckbox(v.custormerIdx, e)}
                  checked={Boolean(e)}
                />
              );
            },
          },
          {
            title: () => (
              <>
                <p>
                  <Checkbox
                    checked={!notAllCheckedIsPublic}
                    onChange={onClickIsPublicCheckbox}
                  >
                    공유주소록
                  </Checkbox>
                </p>
              </>
            ),
            dataIndex: "isPublic",
            key: "isPublic",
            align: "center",
            render: (e, v: CustormerReadResponseData) => {
              return (
                <Checkbox
                  name="isPublic"
                  onChange={(e) => onChangeCheckbox(v.custormerIdx, e)}
                  checked={Boolean(e)}
                />
              );
            },
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div``;
