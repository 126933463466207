import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { FaRegAddressBook } from "react-icons/fa";
import { Button, Form, Input, message, Popover, Select, Tag, Tooltip,Checkbox, Typography } from "antd";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  AddedAddressBooksModalContent,
  AddressBookFormModalContent,
  AntdButton,
  AntdModal,
  AntdPagination,
  AntdTable,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTitle,
  TagFormModalContent,
} from "../components";
import { device } from "../device";
import {
  AiFillBell,
  AiFillCloseCircle,
  AiOutlineDelete,
  AiOutlinePlusCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { privateApi } from "../react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Formatter, isSuccess, Pattern } from "../utils";
import { useAddressBookListStore,useMessageSendStore } from "../stores";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IQueryFunctionContext } from "../types";
import {
  CustormerControllerReadAllParams,
  CustormerCreateDTO,
  CustormerDeleteDTO,
  CustormerReadResponseData,
  CustormerTagCreateDTO,
  CustormerTagDeleteDTO,
  CustormerUpdateDTO,
  CustormerControllerDownloadXlsxParams,
  RequestParams
} from "../generated";
import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from 'react-router-dom';

import { GrMore } from "react-icons/gr";
import { CgSoftwareDownload } from "react-icons/cg";
import { RiFileExcel2Line, RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FiUserPlus } from "react-icons/fi";
import { HiHashtag } from "react-icons/hi";
import { BiEditAlt } from "react-icons/bi";
import getPhoneNumber from '../utils/getPhoneNumber';


const fetcherAddressBookTags = () =>
  privateApi.custormer.custormerControllerReadTag();


// const fetcherExcelDown = (
//   params: IQueryFunctionContext<CustormerControllerReadAllParams>
// ) => {
//   const query = params.queryKey[1];
//   return privateApi.custormer.custormerControllerDownloadXlsx({
//     searchingTags: query.searchingTags,
//     searchingValues: query.searchingValues,
//   });
// };

const fetcherAddressBooks = (
  params: IQueryFunctionContext<CustormerControllerReadAllParams>
) => {
  const query = params.queryKey[1];
  return privateApi.custormer.custormerControllerReadAll({
    page: query.page,
    pageSize: query.pageSize,
    searchingTags: query.searchingTags,
    searchingValues: query.searchingValues,
  });
};

const fetcherAddressBooks2 = (
  params: IQueryFunctionContext<CustormerControllerReadAllParams>
) => {
  const query = params.queryKey[1];
  return privateApi.custormer.custormerControllerReadAll({
    page: query.page,
    pageSize: query.pageSize,
    searchingTags: query.searchingTags,
    searchingValues: query.searchingValues,
  });
};

const fetcherDeleteAddressBooks = (params: CustormerDeleteDTO) =>
  privateApi.custormer.custormerControllerDelete({
    custormerIdxList: params.custormerIdxList,
  });

const fetcherUploadExcelAddressBooks = (parmas: { files: File }) =>
  privateApi.custormer.custormerControllerExcelAttached({
    files: parmas.files,
  });

const fetcherAddAddressBooks = (params: CustormerCreateDTO) =>
  privateApi.custormer.custormerControllerCreate({
    custormerList: params.custormerList,
  });

const fetcherUpdateAddressBook = (params: CustormerUpdateDTO & { id: string }) =>
  privateApi.custormer.custormerControllerUpdate(params.id, {
    isPublic: params.isPublic,
    custormerGroup: params.custormerGroup,
    custormerName: params.custormerName,
    custormerEmail : params.custormerEmail,
    custormerPhone1: params.custormerPhone1,
    phone1AdFlag: params.phone1AdFlag,
    tagName: params.tagName,
  });

const fetcherDeleteTag = (params: CustormerTagDeleteDTO & { id: string }) =>
  privateApi.custormer.custormerControllerDeleteTag(params.id, {
    isPublic: params.isPublic,
  });

const fetcherAddTag = (params: CustormerTagCreateDTO) =>
  privateApi.custormer.custormerControllerCreateTag({
    isPublic: params.isPublic,
    tagName: params.tagName,
  });

const fetcherUpdateTag = (params: CustormerTagCreateDTO & { id: string }) =>
  privateApi.custormer.custormerControllerUpdateTag(params.id, {
    isPublic: params.isPublic,
    tagName: params.tagName,
  });

const fetcherDownloadExcel = () =>
  privateApi.custormer.custormerControllerDownload({ format: "blob" });

  // const fetcherExcelDown = () =>
  // privateApi.custormer.custormerControllerDownloadXlsx();
  const fetcherExcelDown = (query: CustormerControllerDownloadXlsxParams) =>
  privateApi.custormer.custormerControllerDownloadXlsx(
    {
      searchingTags: query.searchingTags,
      searchingValues : query.searchingValues
    }
    ,
    {format : "blob"}
);

export const AddressBookList = () => {
  const navigate = useNavigate();
  const ref = useRef<any>();
  const selectRef = useRef<any>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [visiblePrivateAddressBook, setVisiblePrviateAddressBook] =
    useState<boolean>(true);
  const [visiblePublicAddressBook, setVisiblePublicAddressBook] =
    useState<boolean>(true);
  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const [form] = useForm();
  const [array,setArray] = useState<CustormerReadResponseData[]>([]);
  const [selectedList,setSelectedList] = useState<CustormerReadResponseData[]>([]);
  const [allClick, setAllclcik] = useState(true)
  const store = useAddressBookListStore((s) => s);
  const store2 = useMessageSendStore((s) => s);

  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  let name = 0;
// let filtered = [];
useEffect(() => {
   console.log(selectedList)
   rowSelection.onChange([],selectedList)
}, [selectedList]);
  const {
    isLoading: loadingGetTags,
    data: dataGetTags,
    refetch: refetchTags,
  } = useQuery(["custormerControllerReadTag"], fetcherAddressBookTags, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("custormerControllerReadTag onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("custormerControllerReadTag onError: ", e);
    },
  });

  const {
    isLoading: loadingGetAddressBooks,
    data: dataGetAddressBooks,
    refetch: refetchGetAddressBooks,
  } = useQuery(
    [
      "custormerControllerReadAll",
      {
        page: store.formFilter.form.page,
        pageSize: store.formFilter.form.pageSize,
        searchingTags: store.formFilter.form.searchField
          .filter((v) => v[0] === "#")
          .map((v) => +v.slice(1)),
        searchingValues: store.formFilter.form.searchField.filter(
          (v) => v[0] !== "#"
        ),
      } as CustormerControllerReadAllParams,
    ],
    fetcherAddressBooks,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerReadAll onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("custormerControllerReadAll onError: ", e);
      },
    }
  );
  const {
    isLoading: loadingGetAddressBooks2,
    data: dataGetAddressBooks2,
    refetch: refetchGetAddressBooks2,
  } = useQuery(
    [
      "custormerControllerReadAll",
      {
        searchingTags: store.formFilter.form.searchField
          .filter((v) => v[0] === "#")
          .map((v) => +v.slice(1)),
        searchingValues: store.formFilter.form.searchField.filter(
          (v) => v[0] !== "#"
        ),
      } as CustormerControllerReadAllParams,
    ],
    fetcherAddressBooks,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerReadAll222 onSuccess: ", e);
        //   const recentlist: any = e.data.body.data.filter(
        //     (v) => v.privateTaggedInformation.length === 0 && v.publicTaggedInformation.length === 0)
        // console.log(recentlist)


        // let result : any = recentlist.filter((v, i) => {

        //   return (
        //     recentlist.findIndex((v2,j)=>{
        //       return (v.custormerName+v.custormerPhone1) === (v2.custormerName+v2.custormerPhone1)
        //       ;
        //     }) === i
        //   )
        //   })
          // mutateDeleteAddressBooks2({
          //   custormerIdxList: recentlist.map((v) => ({
          //     custormerIdx: v.custormerIdx
          //   })),
          // });


          // const recentidx: any = e.data.body.data.filter(
          //   (v) => v.custormerName === "미등록" && v.privateTaggedInformation[0]?.tagIdx.tagName === "최근사용")
          //   .map((v)=>{
          //     return v.custormerIdx
          //   })
          // const recentnum: any = e.data.body.data.filter(
          //   (v) => v.custormerName === "미등록" && v.privateTaggedInformation[0]?.tagIdx.tagName === "최근사용")
          //   .map((v)=>{
          //     return v.custormerPhone1
          //   })
          // const updateName : any = e.data.body.data.filter(
          //   (v) => v.custormerPhone1.includes(recentnum[1] || recentnum[1]))


        // mutateUpdateAddressBook2({

        // })
        // console.log(recentidx)
        // console.log(recentnum)
        // console.log(updateName)
        }
        // refetchGetAddressBooks
      },
      onError: (e) => {
        console.log("custormerControllerReadAll onError: ", e);
      },
    }
  );

  // const {
  //   isLoading: loadingExcelDown,
  //   mutate: mutateExcelDown,
  // } = useMutation(fetcherExcelDown, {
  //   onSuccess: (e) => {
   
  //       console.log("custormerControllerDelete onSuccess: ", e);

      
  //   },
  //   onError: (e) => {
  //     console.log("custormerControllerDelete onError: ", e);
  //   },
  // });
  const {
    isLoading: loadingDeleteAddressBooks,
    mutate: mutateDeleteAddressBooks,
  } = useMutation(fetcherDeleteAddressBooks, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("custormerControllerDelete onSuccess: ", e);
        if (store.formFilter.form.page === 1) {
          refetchGetAddressBooks();
        } else {
          store.formFilter.onChangeForm({
            page: 1,
          });
        }

        store.onChangeSelectedAddressBooks([]);
        message.info("삭제되었습니다");
      }
    },
    onError: (e) => {
      console.log("custormerControllerDelete onError: ", e);
    },
  });

  const {
    isLoading: loadingUploadExcelAddressBooks,
    mutate: mutateUploadExcelAddressBooks,
  } = useMutation(fetcherUploadExcelAddressBooks, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("custormerControllerExcelAttached onSuccess: ", e);
        // TODO : isPublic, phone1AdFlag 엑셀 업로드 데이터에 맞게 수정하기.
        const timestamp = Date.now();
        store.onChangeUploadedExcelAddressBooks(
          e.data.body.data.map((v, i) => ({
            custormerIdx: timestamp + i,
            custormerGroup: v.custormerGroup || "",
            custormerName: v.custormerName || "",
            custormerPhone1: v.custormerPhone1 || "",
            custormerTag: v.custormerTag,
            phone1AdFlag: 1,
            isPublic: 1,
            custormerEmail: v.custormerEmail,
            privateTaggedInformation: [],
            publicTaggedInformation: [],
            registerDate: dayjs().format("YYYY-MM-DD"),
          }))
        );
        store.modalAddAddressBookByExcel.onOpen();
      }
    },
    onError: (e) => {
      console.log("custormerControllerExcelAttached onError: ", e);
    },
  });

  const { isLoading: loadingAddAddressBooks, mutate: mutateAddAddressBooks } =
    useMutation(fetcherAddAddressBooks, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerCreate onSuccess: ", e);
          message.info("주소록이 추가되었습니다");
          store.onChangeSelectedAddressBooks([]);
          if (store.formFilter.form.page === 1) {

            setTimeout(() => {
              refetchGetAddressBooks();
              refetchTags();
          }, 500);
          
          } else {
            store.formFilter.onChangeForm({
              page: 1,
              searchField: [],
            });
          }
          if (store.modalAddAddressBook.visible) {
            store.modalAddAddressBook.onClose();
          } else {
            store.modalAddAddressBookByExcel.onClose();
          }
        }
      },
      onError: (e) => {
        console.log("custormerControllerCreate onError: ", e);
      },
    });

  const {
    isLoading: loadingUpdateAddAddressBook,
    mutate: mutateUpdateAddressBook,
  } = useMutation(fetcherUpdateAddressBook, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        message.info("주소록이 수정되었습니다");
        refetchTags();
        refetchGetAddressBooks();
        store.modalUpdateAddressBook.onClose();
        console.log("custormerControllerUpdate onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("custormerControllerUpdate onError: ", e);
    },
  });

  const { isLoading: loadingDeleteTag, mutate: mutateDeleteTag } = useMutation(
    fetcherDeleteTag,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          message.info("태그가 삭제되었습니다");
          refetchTags();
          refetchGetAddressBooks();
          console.log("custormerControllerDeleteTag onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("custormerControllerDeleteTag onError: ", e);
      },
    }
  );

  const { isLoading: loadingAddTag, mutate: mutateAddTag } = useMutation(
    fetcherAddTag,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerCreateTag onSuccess :", e);
          message.info("태그가 추가되었습니다");
          refetchTags();
          refetchGetAddressBooks();
          store.modalAddTag.onClose();
        }
      },
      onError: (e) => {
        console.log("custormerControllerCreateTag onError :", e);
      },
    }
  );

  const { isLoading: loadingUpdateTag, mutate: mutateUpdateTag } = useMutation(
    fetcherUpdateTag,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerUpdateTag onSuccess: ", e);
          message.info("태그가 수정되었습니다");
          refetchTags();
          refetchGetAddressBooks();
          store.modalUpdateTag.onClose();
        }
      },
      onError: (e) => {
        console.log("custormerControllerUpdateTag onError: ", e);
      },
    }
  );

  const { isLoading: loadingExcelDownload, mutate: mutateExcelDownload } =
    useMutation(fetcherDownloadExcel, {
      onSuccess: (e) => {
        console.log("custormerControllerDownload onSuccess: ", e);
        const fileName = "temp.xlsx";

        // const blob = new Blob([e.data], { type: 'image/png' })
        const path = window.URL.createObjectURL(e.data as any);
        const link = document.createElement("a");
        link.href = path;
        link.download = fileName;
        link.click();
        link.remove();
      },
      onError: (e) => {
        console.log("custormerControllerDownload onError: ", e);
      },
    });
  const { isLoading: loadingExcelDownload2, mutate: mutateExcelDownload2 } =
    useMutation(fetcherExcelDown, {
      onSuccess: (e) => {
        console.log("address onSuccess: ", e);
        const fileName = "주소록.xlsx";

        // const blob = new Blob([e.data], { type: 'image/png' })
        const path = window.URL.createObjectURL(e.data as any);
        const link = document.createElement("a");
        link.href = path;
        link.download = fileName;
        link.click();
        link.remove();
      },
      onError: (e) => {
        console.log("address onError: ", e);
      },
    });
    
    const headerCheckbox = (
      <Checkbox
      checked={selectedList.length === dataGetAddressBooks2?.data.body.data.length && dataGetAddressBooks2?.data.body.data.length !== 0 &&selectedList[0].custormerIdx === dataGetAddressBooks2?.data.body.data[0].custormerIdx ? true : false}
     
      onClick={()=>{
        setAllclcik(!allClick)
        // console.log(allClick)
        if(allClick == true) {
          // store.onChangeSelectedAddressBooks(
            // dataGetAddressBooks2?.data.body.data as CustormerReadResponseData[]
            // )
            setSelectedList(dataGetAddressBooks2?.data.body.data as CustormerReadResponseData[])
            // rowSelection.onChange([],selectedList)
          }
          else if(allClick == false)
          {
            setSelectedList([] as CustormerReadResponseData[])
      }
      }}
      />
    );
  
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CustormerReadResponseData[],
      ) => {
      
        // setSelectedList(selectedList.filter((e)=> e.custormerIdx !== name ))
        // filtered = selectedList
        store.onChangeSelectedAddressBooks(selectedList);
      // console.log(selectedList);
      // setSelectedList(selectedRows);
      // store2.onChangeReceivedNumbers(selectedRows);
      // setArray(selectedRows);
      // console.log(name)
      // console.log(filtered)
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "", // Column configuration not to be checked
      name: record.name,
    }),
  };

  /**
   * [엑셀로 추가] 클릭시
   */
  const onClickUploadExcel = () => {
    inputFileRef.current?.click();
  };

  /**
   * [엑셀파일 추가] 클릭 이후 업로드시
   */
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = e;
    if (files && files?.length > 0) {
      const file = files[0];
      mutateUploadExcelAddressBooks({
        files: file,
      });
    }
    (e.target as any).value = null;
  };

  /**
   * [개인 주소록] + 버튼 클릭시
   */
  const onClickAddPrivateAddress = () => {
    store.formModalTag.onChangeForm({ isPublic: false });
    store.modalAddTag.onOpen();
  };

  /**
   * [공유 주소록] + 버튼 클릭시
   */
  const onClickAddShareAddress = () => {
    store.formModalTag.onChangeForm({ isPublic: true });
    store.modalAddTag.onOpen();
  };

  /**
   * [선택 번호 삭제] 버튼 클릭시
   */
  const onDeleteSelectedRows = () => {
    // message.info("준비중입니다");
    if (store.selectedAddresBooks.length > 0) {
      mutateDeleteAddressBooks({
        custormerIdxList: store.selectedAddresBooks.map((v) => ({
          custormerIdx: v.custormerIdx,
        })),
      });
    }
  };

  /**
   * [주소록 삭제 아이콘] 클릭시
   */
  const onDeleteAddressBook = useCallback((custormerIdx: number) => {
    mutateDeleteAddressBooks({
      custormerIdxList: [{ custormerIdx }],
    });
  }, []);

  /**
   * 모달창 [태그 추가] 확인 버튼 클릭시
   */
  const onSubmitAddTagModal = () => {
    const { form } = store.formModalTag;
    if (!form.tagName) {
      return message.warn("태그명을 입력해주세요");
    }
    if (form.tagName[0] !== "#") {
      return message.warn("태그명은 '#태그명' 형식으로 입력해주세요");
    }
    mutateAddTag({
      isPublic: form.isPublic ? 1 : 0,
      tagName: form.tagName,
    });
  };

  /**
   * 모달창 [태그 수정] 확인 버튼 클릭시
   */
  const onSubmitUpdateTagModal = () => {
    const { form } = store.formModalTag;
    if (!form.tagName) {
      return message.warn("태그명을 입력해주세요");
    }
    if (form.tagName[0] !== "#") {
      return message.warn("태그명은 '#태그명' 형식으로 입력해주세요");
    }
    mutateUpdateTag({
      id: form.tagIdx + "",
      isPublic: form.isPublic ? 1 : 0,
      tagName: form.tagName,
    });
  };

  /**
   * 모달창 [주소록 추가] 확인 버튼 클릭시
   */
  const onSubmitAddAddressBookModal = () => {
    const { form } = store.formModalAddressBook;
    if (form.name.length < 1) {
      return message.warn("이름을 입력해주세요");
    }
    if (form.phone.length < 1) {
      return message.warn("전화번호를 입력해주세요");
    }
    if (
      form.phone.length < 10 ||
      !Pattern.checkOptional(Pattern.숫자).test(form.phone)
    ) {
      return message.warn("전화번호는 10~11자리의 숫자로 입력해주세요");
    }
    // if (form.group.length < 1) {
    //   return message.warn("그룹을 입력해주세요");
    // }
    if (form.email && !Pattern.checkEmail.test(form.email)) {
      return message.warn("올바른 형식의 이메일을 입력해주세요");
    }
    // const currentsavingNumbers = dataGetAddressBooks?.data.body.data.map(
    //   (v) => v.custormerPhone1+v.custormerName
    // );

    // if (currentsavingNumbers?.includes(form.phone+form.name)) {
     

    //   return message.warn("이미 등록된 주소입니다.");
    // }
    mutateAddAddressBooks({
      custormerList: [
        {
          custormerName: form.name,
          custormerPhone1: form.phone,
          isPublic: form.isPublic ? 1 : 0,
          custormerGroup: form.group || undefined,
          phone1AdFlag: form.agreeAds ? 1 : 0,
          custormerEmail: form.email,
          tagName: form.tag || undefined,
        },
      ],
    });
  };

  /**
   * 모달창 [주소록 수정] 확인 버튼 클릭시
   */
  const onSubmitUpdateAddressBookModal = () => {
    const { form } = store.formModalAddressBook;
    if (form.name.length < 1) {
      return message.warn("이름을 입력해주세요");
    }
    if (form.phone.length < 1) {
      return message.warn("전화번호를 입력해주세요");
    }
    if (
      form.phone.length < 10 ||
      !Pattern.checkOptional(Pattern.숫자).test(form.phone)
    ) {
      return message.warn("전화번호는 10~11자리의 숫자로 입력해주세요");
    }
    if (form.email && !Pattern.checkEmail.test(form.email)) {
      return message.warn("올바른 형식의 이메일을 입력해주세요");
    }
    // if (form.group.length < 1) {
    //   return message.warn("그룹을 입력해주세요");
    // }
    mutateUpdateAddressBook({
      id: form.custormerIdx + "",
      isPublic: form.isPublic ? 1 : 0,
      custormerGroup: form.group,
      custormerName: form.name,
      custormerPhone1: form.phone,
      custormerEmail: form.email,
      phone1AdFlag: form.agreeAds ? 1 : 0,
      tagName: form.tag.length === 0 ? "#" : form.tag,
    });
  };

  /**
   * 모달창 [주소록 목록 추가] 확인 버튼 클릭시
   */
  const onSubmitAddedAddressBooksModal = () => {
    if (
      store.uploadedExcelAddressBooks.some(
        (v) => !v.custormerName || !v.custormerPhone1
      )
    ) {
      return message.warn("이름과 전화번호는 필수값입니다.");
    }
    if (
      store.uploadedExcelAddressBooks.some(
        (v) =>
          v.custormerPhone1.length < 10 ||
          !Pattern.checkOptional(Pattern.숫자).test(v.custormerPhone1)
      )
    ) {
      return message.warn("전화번호는 10~11자리의 숫자로만 입력해주세요");
    }
    if (
      store.uploadedExcelAddressBooks.some(
        (v) =>
          v.custormerEmail &&
          v.custormerEmail.length > 0 &&
          !Pattern.checkEmail.test(v.custormerEmail)
      )
    ) {
      return message.warn("올바른 형식의 이메일을 입력해주세요");
    }
    // store.uploadedExcelAddressBooks.map((v)=>{
    //   console.log(v.custormerTag)
    // })
    mutateAddAddressBooks({
      custormerList: store.uploadedExcelAddressBooks.map((v) => ({
        custormerName: v.custormerName,
        custormerPhone1: v.custormerPhone1,
        isPublic: v.isPublic as 1,
        custormerEmail : v.custormerEmail,
        custormerGroup: v.custormerGroup,
        phone1AdFlag: v.phone1AdFlag,
        tagName: (v as any).custormerTag,
      })),
    });
  };

  /**
   * [태그 렌더]
   */
  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if(props.value && props.value[0] === '#') {
      const isPublic = publicTags.some(
        (v) => v.tagIdx === +props.value.slice(1)
      );
      return (
        <Tag
          color={isPublic ? "green" : "blue"}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {store.formFilter.form.clicked === 1 ? "#최근사용" :  label}
        </Tag>
      );
    }
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  /**
   * [주소록 태그] 업데이트 아이콘 클릭시
   */
  const onClickTagUpdate = () => {
    message.info("준비중입니다.");
  };

  /**
   * [주소록 태그] 삭제 아이콘 클릭시
   */
  const onClickTagDelete = () => {
    message.info("준비중입니다.");
  };

  /**
   * [페이징] 변경시
   */
  const onChangePagination = (page: number, pageSize?: number) => {
    store.formFilter.onChangeForm({
      page,
    });
  };

  /**
   * 검색필드 [이름,회사] 입력값 수정시
   */
  // const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const {
  //     target: { value },
  //   } = e;
  //   clearTimeout(ref.current);
  //   ref.current = setTimeout(() => {
  //     store.formFilter.onChangeForm({
  //       page: 1,
  //       custormerName: value,
  //     });
  //     store.onChangeSelectedAddressBooks([]);
  //   }, 300);
  // };

  // const onSearch = (e: string) => {
  //   console.log("onSearch: ", e);
  //   const isTagName = e.length > 0 && e[0] === "#";
  //   clearTimeout(ref.current);
  //   ref.current = setTimeout(() => {
  //     store.formFilter.onChangeForm({
  //       page: 1,
  //       // tagName: isTagName ? e : undefined,
  //       custormerName: e
  //     });
  //     store.onChangeSelectedAddressBooks([]);
  //   }, 300);
  // };

  /**
   * 검색필드 [이름,회사, 태그명] 입력값 수정시
   */
  const onChangeInput = (value: string[]) => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      const filteredValue = value
        .map((val) => {
          if (val[0] === "#") {
            const tag = [...publicTags, ...privateTags].find(
              (v) => v.tagIdx === +val.slice(1) || v.tagName === val.slice(1)
            );
            if (!tag) {
              return undefined;
            }
            return "#" + tag.tagIdx;
          }
          return val;
        })
        .filter((v) => v) as string[];
      store.formFilter.onChangeForm({
        page: 1,
        // tagName: isTagName ? e : undefined,
        searchField: filteredValue,
      });
      selectRef.current.blur();
      store.onChangeSelectedAddressBooks([]);
    }, 300);
  };

  /**
   * [최근사용 주소록] 클릭시
   */
  const onClickRecentAddressBook = () => {
    setSelectedList([]);
    setAllclcik(true);
    const filteredValue = ["#최근사용"]
    .map((val) => {
      if (val[0] === "#") {
        const tag = [...privateTags2].find(
          (v) => v.tagIdx === +val.slice(1) || v.tagName === val.slice(1)
          
        );
        if (!tag) {
          return  ["#최근사용"];
        }
        return ["#" + tag.tagIdx];
      }
      return val;
    })
    // .filter((v) => v) as string[];
    // const filteredValue2 = ["#최근사용"]
    // .map((val) => {
    //   if (val[0] === "#") {
    //     const tag = [...publicTags].find(
    //       (v) => v.tagIdx === +val.slice(1) || v.tagName === val.slice(1)
    //     );
    //     if (!tag) {
    //       return undefined;
    //     }
    //     return "#" + tag.tagIdx;
    //   }
    //   return val;
    // })
    // .filter((v) => v) as string[];
    store.formFilter.onChangeForm({
      page: 1,
      clicked : 1,
      searchField: filteredValue[0] as string[]
    });

    // store.formFilter.onChangeForm({
    //   page: 1,
    //   searchField: ["#최근사용"],
    // });
   
    // form.resetFields(["name"]);
  };

  /**
   * 주소록 목록의 [이름,전화번호,그룹] 칼럼 클릭시
   */
  const onRenderColumnClick = (e: string, v: CustormerReadResponseData) => {
    const onClick = () => {
      let tagNames = [];
      if (v.isPublic) {
        tagNames = v.publicTaggedInformation.map((v) => "#" + v.tagIdx.tagName);
      } else {
        tagNames = v.privateTaggedInformation.map(
          (v) => "#" + v.tagIdx.tagName
        );
      }
      const tag = tagNames.join("");
      store.formModalAddressBook.onChangeForm({
        custormerIdx: v.custormerIdx,
        agreeAds: v.phone1AdFlag,
        group: v.custormerGroup,
        isPublic: v.isPublic,
        name: v.custormerName,
        phone: v.custormerPhone1,
        email: v.custormerEmail || undefined,
        tag,
      });
      store.modalUpdateAddressBook.onOpen();
    };
    // return <Link onClick={onClick}>{e}</Link>;
    return <>{e}</>;
  };
  /**
   * [전체] 클릭시, 검색필드 초기화
   */
  const onResetInput = useCallback(() => {
    setSelectedList([]);
    setAllclcik(true);
    store.formFilter.onChangeForm({
      page: 1,
      clicked : 0,
      searchField: [],
    });
  }, []);

  /** 공유주소록 */
  const publicTags = useMemo(
    () => dataGetTags?.data.body.data[0].publicTags || [],
    [dataGetTags?.data.body.data]
  );

  /** 개인주소록 */
  const privateTags = useMemo(
    // () => dataGetTags?.data.body.data[1].privateTags || [],
    // [dataGetTags?.data.body.data]
    () =>
      dataGetTags?.data.body.data[1].privateTags.filter(
        (v) => v.tagName !== "최근사용"
      ) || [],
    [dataGetTags?.data.body.data]
  );
  const privateTags2 = useMemo(
    () => dataGetTags?.data.body.data[1].privateTags || [],
    [dataGetTags?.data.body.data]
    // () =>
    //   dataGetTags?.data.body.data[1].privateTags.filter(
    //     (v) => v.tagName !== "최근사용"
    //   ) || [],
    // [dataGetTags?.data.body.data]
  );

  useEffect(() => {
    return () => {
      store.onReset();
    };
  }, []);

  if (loadingGetTags) {
    return <>loading...</>;
  }

  return (
    <Container>
      <ExtendedLayoutRow>
        <input
          style={{
            display: "none",
          }}
          name=""
          id=""
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={inputFileRef}
          onChangeCapture={onFileChangeCapture}
        />
        <LayoutContent>
          <Content>
            <Space>
              <ExtendedLayoutCell className="top">
                <LayoutTitle style={{ marginBottom: 20 }}>주소록</LayoutTitle>
                <ButtonGroup>
                  <AntdButton
                    isPrimary
                    style={{ color: "white", width: "100%" }}
                    onClick={store.modalAddAddressBook.onOpen}
                    className="btnBlue"
                  >
                    <FiUserPlus size={16} style={{ marginBottom: "-3px", marginRight: "5" }}/>
                    주소 추가
                  </AntdButton>
                  <ExcelSpace>
                    <AntdButton
                      isPrimary
                      style={{ color: "white", width: "100%" }}
                      className='btnExcelAdd'
                      loading={loadingUploadExcelAddressBooks}
                      onClick={onClickUploadExcel}
                    >
                      <RiFileExcel2Line size={16} style={{marginRight : 5, color : "white"}}/>
                      엑셀로 추가
                    </AntdButton>
                    <Text>
                      <div
                        style={{
                          textAlign: "center",
                          margin: "0 auto",
                          cursor: "pointer",
                        }}
                        onClick={
                          loadingExcelDownload
                            ? undefined
                            : () => mutateExcelDownload()
                        }
                      >
                        {/* <AiOutlineQuestionCircle
                          size={16}
                          color="#7a7a7a"
                          style={{ marginBottom: -3, marginRight: 2 }}
                        /> */}
                        <span>
                          엑셀양식 다운로드
                        </span>

                        <CgSoftwareDownload
                            size={16}
                            style={{ marginBottom: -2, marginLeft: 2 }}
                          />
                      </div>
                    </Text>
                  </ExcelSpace>
                </ButtonGroup>
                <AddressWrapper>
                  
                  <AdressCell>
                    <AddressTitle
                      style={{ cursor: "pointer", justifyContent: "flex-start" }}
                      onClick={onResetInput}
                    >
                      {/* <FaRegAddressBook size={20} style={{ marginRight: 0 }} /> */}
                      전체 주소록
                    </AddressTitle>
                  </AdressCell>

                  <AdressCell>
                    <AddressTitle
                      style={{ cursor: "pointer", justifyContent: "flex-start" }}
                      onClick={onClickRecentAddressBook}
                    >
                      {/* <FaRegAddressBook size={20} style={{ marginRight: 0 }} /> */}
                      최근 사용 주소록
                    </AddressTitle>
                  </AdressCell>
                  <AdressCell>
                    <AddressTitle>
                      <TitleSpace>
                        {/* <FaRegAddressBook size={20} /> */}
                        개인 주소록
                        <MdOutlineKeyboardArrowDown
                          className={`visible-icon ${
                            visiblePrivateAddressBook ? "" : "close"
                          }`}
                          onClick={() =>
                            setVisiblePrviateAddressBook((v) => !v)
                          }
                          size={20}
                        />
                      </TitleSpace>
                      <AiOutlinePlusCircle
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={onClickAddPrivateAddress}
                      />
                    </AddressTitle>
                    <AddressGroup
                      className={visiblePrivateAddressBook ? "" : "hide"}
                    >
                      {privateTags.map((v) => (
                        <GroupItem key={v.tagIdx}>
                          <div className="tagNameCont" style={{ display: "flex", alignItems: "center" }}>
                            <HiHashtag size={16} color='#8e9bb0' />
                            <TagName
                              onClick={() => {
                                // form.resetFields(["name"]);
                                setSelectedList([]);
                                setAllclcik(true);
                                store.formFilter.onChangeForm({
                                  page: 1,
                                  clicked : 0,
                                  searchField: ["#" + v.tagIdx],
                                });
                              }}
                            >
                              {v.tagName}
                            </TagName>
                          </div>
                          <IconGroup>
                            <BiEditAlt 
                              className="icon set"
                              size={18}
                              onClick={() => {
                                store.formModalTag.onChangeForm({
                                  tagIdx: v.tagIdx,
                                  tagName: "#" + v.tagName,
                                  isPublic: false,
                                });
                                store.modalUpdateTag.onOpen();
                              }}
                            />
                            <RiDeleteBin6Line 
                              className="icon delete"
                              size={18}
                              onClick={() => {
                                if (!loadingDeleteTag) {
                                  mutateDeleteTag({
                                    id: v.tagIdx + "",
                                    isPublic: 0,
                                  });
                                }
                              }}
                            />
                          </IconGroup>
                        </GroupItem>
                      ))}
                    </AddressGroup>
                  </AdressCell>
                  
                  <AdressCell>
                    <AddressTitle>
                      <TitleSpace>
                        {/* <FaRegAddressBook size={20} /> */}
                        공유 주소록
                        <MdOutlineKeyboardArrowDown
                          className={`visible-icon ${
                            visiblePublicAddressBook ? "" : "close"
                          }`}
                          onClick={() => setVisiblePublicAddressBook((v) => !v)}
                          size={20}
                        />
                      </TitleSpace>
                      <AiOutlinePlusCircle
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={onClickAddShareAddress}
                      />
                    </AddressTitle>
                    <AddressGroup
                      className={visiblePublicAddressBook ? "" : "hide"}
                    >
                      {publicTags.map((v) => (
                        <GroupItem key={v.tagIdx}>
                          <div className="tagNameCont" style={{ display: "flex", alignItems: "center" }}>
                            <HiHashtag size={16} color='#8e9bb0'/>
                            <TagName
                              onClick={() => {
                                // form.resetFields(["name"]);
                                setSelectedList([]);
                                setAllclcik(true);
                                store.formFilter.onChangeForm({
                                  page: 1,
                                  clicked : 0,
                                  searchField: ["#" + v.tagIdx],
                                });
                              }}
                            >
                              {v.tagName}
                            </TagName>
                          </div>
                          <IconGroup>
                            <BiEditAlt
                              className="icon set"
                              size={18}
                              onClick={() => {
                                store.formModalTag.onChangeForm({
                                  tagIdx: v.tagIdx,
                                  tagName: "#" + v.tagName,
                                  isPublic: true,
                                });
                                store.modalUpdateTag.onOpen();
                              }}
                            />
                            <RiDeleteBin6Line 
                              className="icon delete"
                              size={18}
                              onClick={() => {
                                if (!loadingDeleteTag) {
                                  mutateDeleteTag({
                                    id: v.tagIdx + "",
                                    isPublic: 1,
                                  });
                                }
                              }}
                            />
                          </IconGroup>
                        </GroupItem>
                      ))}
                    </AddressGroup>
                  </AdressCell>
                </AddressWrapper>
              </ExtendedLayoutCell>
              <ExtendedLayoutCell>
                <Form form={form}>
                  <Form.Item name="name">
                    <FormSpace>
                      <Select
                        ref={selectRef}
                        mode="tags"
                        tagRender={tagRender}
                        placeholder="이름, 회사명, 태그명 검색"
                        onChange={onChangeInput}
                        style={{ width: "100%" }}
                        value={store.formFilter.form.searchField}
                        filterOption={(input, option) => {
                          if (option?.children && input && input[0] === "#") {
                            const children = option?.children.join("");
                            return children
                              ?.toLowerCase()
                              .includes(input.slice(1).toLowerCase());
                          }
                          return false;
                        }}
                      >
                        <Select.OptGroup label="개인주소록">
                          {privateTags.map((v) => (
                            <Select.Option value={"#" + v.tagIdx}>
                              #{v.tagName}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label="공유주소록">
                          {publicTags.map((v) => (
                            <Select.Option value={"#" + v.tagIdx}>
                              #{v.tagName}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        {/* {[
                          ...new Set(
                            [...privateTags, ...publicTags].map((v) => v)
                          ),
                        ].map((v, i) => (
                          <Select.Option value={"#" + v.tagIdx} key={i}>
                            #{v.tagName}
                          </Select.Option>
                        ))} */}
                      </Select>
                      <Tooltip
                      
                          title={
                            <TagTitleSpace>
                              <p>검색하지 않았을 시 주소록 전체 다운로드,</p>
                              <p>검색 시 검색된 주소록만 다운로드 합니다.</p>
                            </TagTitleSpace>
                          }
                        >
                          <TagSpace className='overflow-hide'  style={{width : 180 ,height : 32}}>
                            <AntdButton 
                              style={{height: "32px", display: "flex", alignItems: "center" }} 
                              onClick={()=>{
                                mutateExcelDownload2({
                                  searchingTags: store.formFilter.form.searchField
                                  .filter((v) => v[0] === "#")
                                  .map((v) => +v.slice(1)),
                                  searchingValues: store.formFilter.form.searchField.filter((v) => v[0] !== "#")
                                  })
                              }}
                              className="btnLineblue"
                          
                            >
                              주소록 다운로드
                                <CgSoftwareDownload size={16} style={{ marginLeft: 4, marginTop:-2 }} />  
                            </AntdButton>
                          </TagSpace>
                        </Tooltip>
                      
                      {/* <AntdButton isPrimary style={{fontSize : 7, height: "32px",color: "white"}} onClick={()=>{
                        mutateExcelDownload2({
                          searchingTags: store.formFilter.form.searchField
                          .filter((v) => v[0] === "#")
                          .map((v) => +v.slice(1)),
                        searchingValues: store.formFilter.form.searchField.filter(
                          (v) => v[0] !== "#"
                        )
                        })
                      }}>검색한 주소록 다운</AntdButton> */}
                    </FormSpace>
                  </Form.Item>
                </Form>
                <ExtendedAntdTable
                  className="ant-table"
                  style={{
                    // minHeight: 440,
                    height: "calc(100% - 82px)",
                    borderTop: "1px solid #dfdfdf",
                    borderBottom: "1px solid #dfdfdf",
                  }}
                  rowSelection={{
                     
                  columnTitle:headerCheckbox,
                    selectedRowKeys: store.selectedAddresBooks.map(
                      (v) => v.custormerIdx
                    ),
                    onSelect: (v: CustormerReadResponseData) => {
                      name = v.custormerIdx
                      let result = selectedList.find((e)=> e.custormerIdx === name )
                      if(result == undefined){
                        setSelectedList(selectedList.concat(v))
                      }
                      else{

                        setSelectedList(selectedList.filter((e)=> e.custormerIdx !== name ))
                      }
                      console.log(result)
                    },
                    
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  
                  rowKey="custormerIdx"
                  tableLayout="fixed"
                  scroll={windowsize? {y: "100%" } :{ x: 520, y: "100%" }}
                  dataSource={dataGetAddressBooks?.data.body.data || []}
                  pagination={false}
                  onRow={(v: CustormerReadResponseData, rowIndex) => {
                    return {
                      onClick: (event) => {
                        console.log({ event });
                        let tagNames = [];
                        if (v.isPublic) {
                          tagNames = v.publicTaggedInformation.map(
                            (v) => "#" + v.tagIdx.tagName
                          );
                        } else {
                          tagNames = v.privateTaggedInformation.map(
                            (v) => "#" + v.tagIdx.tagName
                          );
                        }
                        const tag = tagNames.join("");
                        store.formModalAddressBook.onChangeForm({
                          custormerIdx: v.custormerIdx,
                          agreeAds: v.phone1AdFlag,
                          group: v.custormerGroup,
                          isPublic: v.isPublic,
                          name: v.custormerName,
                          phone: v.custormerPhone1,
                          email: v.custormerEmail,
                          tag,
                        });
                        store.modalUpdateAddressBook.onOpen();
                      }, // click row
                      onDoubleClick: (event) => {}, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                  
                  columns={[
                    {
                      title: "이름",
                      dataIndex: "custormerName",
                      key: "custormerName",
                      // fixed: true,
                      align:"center",
                      width: windowsize? 50 :80,
                      render: onRenderColumnClick,
                    },
                    {
                      title: "이메일",
                      dataIndex: windowsize? "none": "custormerEmail" ,
                      key: "custormerEmail",
                      align:"center",
                      width: windowsize? 0 :150,
                      render: onRenderColumnClick,
                    },
                    {
                      title: "전화번호",
                      dataIndex: "custormerPhone1",
                      key: "custormerPhone1",
                      align:"center",
                      width: windowsize? 90 :120,

                      // render: onRenderColumnClick,
                      render: (e, v: CustormerReadResponseData) => {
                        return (
                          <Typography>
                            {getPhoneNumber(v.custormerPhone1)}
                          </Typography>
                        );
                      },
               
                    },
                    {
                      title: "그룹",
                      dataIndex: windowsize? "none": "custormerGroup" ,
                      key: "custormerGroup",
                      align:"center",
                      width: windowsize? 0 :150,
                      render: onRenderColumnClick,
                    },
                    {
                      title: "태그",
                      dataIndex:  windowsize? "none": "isPublic",
                      key: "isPublic",
                      align: "center",
                      width: windowsize? 0 :150,
                      render: (e, v: CustormerReadResponseData) => {
                      
                        if (e) {
                          const tagNames = v.publicTaggedInformation.map(
                            (v) => v.tagIdx.tagName
                          );
                          return (
                            <Tooltip
                              title={
                                <TagSpace >
                                  {tagNames.map((v, i) => (
                                    <Tag key={i} color="green">
                                      {v}
                                    </Tag>
                                  ))}
                                </TagSpace>
                              }
                            >
                              <TagSpace className='overflow-hide'  
                               onClick={(e) => {
                               e.stopPropagation();}}>
                                {tagNames.map((v, i) => (
                                  <Tag key={i} color="green">
                                    {v}
                                  </Tag>
                                ))}
                              </TagSpace>
                            </Tooltip>
                          );
                        }
                        const tagNames = v.privateTaggedInformation.map(
                          (v) => v.tagIdx.tagName
                        );
                        return (
                          <Tooltip
                            title={
                              <TagSpace>
                                {tagNames.map((v, i) => (
                                  <Tag key={i} color="blue">
                                    {v}
                                  </Tag>
                                ))}
                              </TagSpace>
                            }
                          >
                            <TagSpace className="overflow-hide">
                              {tagNames.map((v, i) => (
                                <Tag key={i} color="blue">
                                  {v}
                                </Tag>
                              ))}
                            </TagSpace>
                          </Tooltip>
                        );
                      },
                    },
                    {
                      title: windowsize? "추가 정보" : "",
                      dataIndex: windowsize? "isPublic": "none" ,
                      key: "",
                      align:"center",
                      width: windowsize? 70 : 0,
                      render: (e, v: CustormerReadResponseData) => {
                      
                        if (e) {
                          const tagNames = v.publicTaggedInformation.map(
                            (v) => v.tagIdx.tagName
                          );
                          const email = v.custormerEmail
                          const group = v.custormerGroup
                        
                          return (
                            <Tooltip
                       
                            
                            title={
                            <>
                              <div style={{display: "flex"}}>
                              <p>이메일 :</p><p style={{marginLeft: 10 }}>{email}</p>
                              </div>
                              <div style={{display: "flex"}}>
                              <p>그룹 : </p><p style={{marginLeft: 10}}>{group}</p>
                              </div>
                              <div style={{display: "flex"}}>
                              <p>태그 : </p><p style={{marginLeft: 10}}>{tagNames.map((v, i) => (
                                    <Tag key={i} color="green">
                                   {v}
                                      </Tag>
                                  ))}</p>
                              </div>
                             </>  
                            }
                          >
                            <TagSpace className="overflow-hide"
                            onClick={(e)=>{
                              e.stopPropagation();
                            }}
                            style={{display:"flex", justifyContent:"center"}}
                            >
                               <GrMore />
                            </TagSpace>
                          </Tooltip>
                          );
                        }

                        const tagNames = v.privateTaggedInformation.map(
                          (v) => v.tagIdx.tagName
                        );
                        const email = v.custormerEmail
                        const group = v.custormerGroup
                        return (
                          <Tooltip
                            title={
                            <>
                              <div style={{display: "flex"}}>
                              <p>이메일 :</p><p style={{marginLeft: 10 }}>{email}</p>
                              </div>
                              <div style={{display: "flex"}}>
                              <p>그룹 : </p><p style={{marginLeft: 10 }}>{group}</p>
                              </div>
                              <div style={{display: "flex"}}>
                              <p>태그 : </p><p style={{marginLeft: 10 }}> {tagNames.map((v, i) => (
                                    <Tag key={i} color="blue">
                                      {v}
                                    </Tag>
                                  ))}</p>
                              </div>
                             </>  
                            }
                          >
                            <TagSpace className="overflow-hide"
                            onClick={(e)=>{
                              e.stopPropagation();
                            }}
                            style={{display:"flex", justifyContent:"center"}}
                            >
                               <GrMore />
                            </TagSpace>
                          </Tooltip>
                        );
                      },
                    },
                    {
                      title: "삭제",
                      dataIndex: "custormerIdx",
                      key: "custormerIdx",
                      align: "center",
                      width: 60,
                      render: (id) => {
                        return (
                          <AiFillCloseCircle
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!loadingDeleteAddressBooks) {
                                onDeleteAddressBook(id);
                              }
                            }}
                            size={16}
                            style={{ marginBottom: -3 }}
                            cursor="pointer"
                          />
                        );
                      },
                    },
                  ]}
                />
                <ButtonSpace>
                  <ButtonSpace2>
                  <Button
                    disabled={store.selectedAddresBooks.length < 1}
                    loading={loadingDeleteAddressBooks}
                    onClick={onDeleteSelectedRows}
                    type="dashed" danger
                  >
                    선택 번호 삭제
                  </Button>
                  <Button 
                    style={{marginLeft:10}}
                    disabled={store.selectedAddresBooks.length < 1}
                    loading={loadingDeleteAddressBooks}
                    
                    onClick={()=>{
                      store2.onChangeReceivedNumbers(selectedList);
                      localStorage.setItem('number',JSON.stringify(selectedList))
                      navigate(`/message/send`);
                      }
                    }
                    className="btnLineblue"
                  >
                    메세지 발송
                  </Button>
                    </ButtonSpace2>
                  {dataGetAddressBooks?.data &&
                    dataGetAddressBooks?.data.body.total > 10 && (
                      <AntdPagination
                        className="test"
                        style={{ marginTop: 0 }}
                        disabled={loadingGetAddressBooks}
                        loading={loadingGetAddressBooks}
                        pageSize={20}
                        position="right"
                        size= {windowsize? "small" : "default" } 
                        current={store.formFilter.form.page}
                        total={dataGetAddressBooks.data.body.total}
                        onChange={onChangePagination}
                      />
                    )}
                </ButtonSpace>   
              </ExtendedLayoutCell>
            </Space>
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      <AntdModal
        draggable
        title="태그 추가"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalAddTag.visible}
        onOk={onSubmitAddTagModal}
        onCancel={store.modalAddTag.onClose}
        okButtonProps={{
          loading: loadingAddTag,
          disabled: store.formModalTag.form.tagName.length < 1,
        }}
        okText="확인"
        cancelText="취소"
      >
        <TagFormModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="태그 수정"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalUpdateTag.visible}
        onOk={onSubmitUpdateTagModal}
        onCancel={store.modalUpdateTag.onClose}
        okButtonProps={{
          loading: loadingUpdateTag,
          disabled: store.formModalTag.form.tagName.length < 1,
        }}
        okText="확인"
        cancelText="취소"
      >
        <TagFormModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="주소 추가"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalAddAddressBook.visible}
        onOk={onSubmitAddAddressBookModal}
        onCancel={store.modalAddAddressBook.onClose}
        keyboard
        okButtonProps={{
          loading: loadingAddAddressBooks,
          // disabled: !availableAddChildAccount || loadingAddChildAccount,
        }}
        okText="확인"
        cancelText="취소"
      >
        <AddressBookFormModalContent onOk={onSubmitAddAddressBookModal} />
      </AntdModal>
      <AntdModal
        draggable
        title="주소 수정"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalUpdateAddressBook.visible}
        onOk={onSubmitUpdateAddressBookModal}
        onCancel={store.modalUpdateAddressBook.onClose}
        okButtonProps={{
          loading: loadingUpdateAddAddressBook,
          // disabled: !availableAddChildAccount || loadingAddChildAccount,
        }}
        okText="확인"
        cancelText="취소"
      >
        <AddressBookFormModalContent onOk={onSubmitUpdateAddressBookModal} />
      </AntdModal>
      <AntdModal
        draggable
        title="주소 추가"
        style={{
          maxWidth: "1200px",
          width: "100%",
        }}
        visible={store.modalAddAddressBookByExcel.visible}
        onOk={onSubmitAddedAddressBooksModal}
        onCancel={store.modalAddAddressBookByExcel.onClose}
        okButtonProps={{
          loading: loadingAddAddressBooks,
          // disabled: !availableAddChildAccount || loadingAddChildAccount,
        }}
        okText="확인"
        cancelText="취소"
      >
        <AddedAddressBooksModalContent/>
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    min-height: 100%;
  }
`;

const Content = styled.div`
  width: 100%;

`;

const ExtendedLayoutCell = styled(LayoutCell)`
  &.top {
    height: 100%;
  }
  
  height: 100%;
  .ant-table {
    .ant-table-container {
      .ant-table-body {
        height: 71.9vh;
        max-height: 831px !important;
        // max-height: 836px !important;

        /* height: calc(100vh - 412px); */
        /* height: calc(100vh - 533px); */
        /* max-height: initial !important; */
      }
    }
  }
`;

const Space = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  & > div {
    width: calc(100% - 300px - 30px);
    /* width: 100%; */
    &:first-child {
      max-width: 20%;
      min-width: 300px;
    }

  }
  @media ${device.laptop.min} {
    & > div {
      /* height: calc(100vh - 81px - 20px - 12px); */
    }
  }
  @media ${device.laptop.max} {
    flex-flow: column;
    & > div {
      width: 100%;
      &:first-child {
        max-width: 100%;
        min-width: auto;
      }
    }
  }
`;

const FormSpace = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  button {
    height: 30px;
  }
`;
const AddressWrapper = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  /* max-height: 60%; */
  height: 72.3vh;
  max-height: 836px;
  overflow-y: auto;
  border-top: 1px solid #eee;
  @media ${device.laptop.max} {
    max-height: 400px;
    height: auto;
  }
`;

const AdressCell = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
const AddressGroup = styled.div`
  padding: 6px;
  background: #fafbfe;
  overflow-y: auto; */
  opacity: 1;
  height: auto;
  transition: 0.2s;
  display: block;
  &.hide {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }
`;
const AddressTitle = styled.h4`
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius:6px;
  padding-left:10px;
  gap: 4px;
  font-size: 13px;
  font-weight: bold;
  color: black;
  svg {
    margin-top: -2px;
  }
  & > svg {
    margin-top: 0;
    margin-right: 10px;
  }
  &:hover {
    background: #f0f4ff;
  }
`;

const TitleSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  .visible-icon {
    transition: 0.2s;
    cursor: pointer;
    margin-top: 0;
    &.close {
      transform: rotateX(180deg);
    }
  }
`;

const GroupTitle = styled.p`
  /* font-size: 12px; */
  margin-bottom: 14px;
`;

const GroupItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  font-size: 13px;
  padding: 8px 12px;
  border-radius: 4px;
  .tagNameCont {
    span {
      margin-left: 4px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1px;
  }
  &:hover {
    background: #f0f4ff;
  }
  overflow: hidden;
  @media ${device.laptop.min} {
    &:hover {
      & > div {
        margin-right: 0;
        opacity: 1;
      }
    }
  }
`;

const ButtonSpace = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  @media ${device.laptop.max} {
    flex-flow: column;
    & > button {
      &:first-child {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
`;
const ButtonSpace2 = styled.div`
@media ${device.laptop.max} {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width : 100%;
  margin-bottom : 5px;

}


`;

const IconGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 10px;
  .icon {
    cursor: pointer;
    fill: gray;
  }
  transition: 0.2s;
  opacity: 0;
  @media ${device.laptop.max} {
    opacity: 1;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  & > button,
  & > div {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .ant-btn {
    font-size:14px;
  }
`;

const ExcelSpace = styled.div`
  .btnExcelAdd {
    background-color: #1f6e43!important;
      border-color: #1f6e43;
      display: flex;
      align-items: center;
      justify-content: center;
      color: 
      &:focus,
      &:active {
        border-color: #1f6e43;
        background-color: #1f6e43;
        filter: brightness(0.85);
      }
      &:hover {
        border-color: #1f6e43;
        background-color: #1f6e43;
        opacity: 0.9;
      }
  }
`;

const Text = styled.div`
  margin-top: 6px;
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 11px;
  color: #888;
  &:hover {
    color: var(--primary-color);
  }
`;

const PopupContent = styled.div`
  font-size: 12px;
  color: var(--primary-color);
`;

const TagSpace = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 4px;
  &.overflow-hide {
    flex-flow: row;
    overflow: hidden;
  }
`;
const TagTitleSpace = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  font-size : 11.5px;
  gap: 4px;
  &.overflow-hide {
    flex-flow: row;
    overflow: hidden;
  }
`;

const Link = styled.span`
  cursor: pointer;
`;

const TagName = styled.span`
  cursor: pointer;
`;
const ExtendedAntdTable = styled(AntdTable)`
@media ${device.laptop.max} {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
  font-size: 9px;
`;