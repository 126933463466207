// @ts-ignore
import Fade from "react-reveal/Fade";
import { Checkbox, Form, Input, Typography } from "antd";
import { AiOutlineLock, AiOutlineSend, AiOutlineUser } from "react-icons/ai";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import { setAccessToken } from "../auth";
import { AntdButton, AntdModal, LayouRow } from "../components";
import { MemberLogInDTO } from "../generated";
import { publicApi } from "../react-query";
import { useAppStore } from "../stores";
import { isSuccess } from "../utils";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import base64 from "base-64";
import { device } from "../device";
import { IoMdClose } from "react-icons/io";

const fetcherLogin = (params: MemberLogInDTO) =>
  publicApi.member.memberControllerLogIn(params);

export const Login = () => {
  const navigate = useNavigate();
  const store = useAppStore((s) => s);
  const [open, setopen] = useState(false);
  const [show, setshow] = useState(true);

  useEffect(() => {
    if (
      sessionStorage.getItem("show") === "true" ||
      sessionStorage.getItem("show") === null
    ) {
      setopen(true);
    } else {
      setopen(false);
    }
  }, []);

  const { isLoading: loadingLogin, mutate: mutateLogin } = useMutation(
    (params: MemberLogInDTO) => fetcherLogin(params),
    {
      onSuccess: (e) => {
        console.log("login onSuccess : ", e);
        if (isSuccess(e.data.header)) {
          // 토큰값 저장.
          const token = e.data.body.data.token;
          const [h, p] = token.split(".");

          store.onLogin(token);
          setAccessToken(token);
        }
      },
      onError: (e) => {
        console.log("login onError : ", e);
      },
    }
  );
  const onFinish = (values: { userId: string; password: string }) => {
    console.log("Received values of form: ", values);
    mutateLogin({
      userId: values.userId,
      password: values.password,
    });
  };

  /**
   * [비밀번호 찾기] 클릭시
   */
  const onClickFoundPassword = useCallback(() => {
    navigate("/found/password");
  }, []);

  /**
   * [회원가입] 클릭시
   */
  const onClickSignup = useCallback(() => {
    navigate("/signup");
  }, []);

  return (
    <Container>
      <div className="signInBgTxtCont">
        <div className="signinImgCont">
          <img src="/images/sendit_signin.png" alt="" className="signinImg" />
        </div>
      </div>
      <Fade>
        <LayouRow>
          <Wrapper>
            <Logo>
              <img src="/images/img-login.png" alt="" />
              {/* <AiOutlineSend size={16} /> */}
              로그인
            </Logo>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="userId"
                rules={[{ required: true, message: "이메일을 입력해주세요" }]}
              >
                <Input
                  autoFocus
                  prefix={<AiOutlineUser className="site-form-item-icon" />}
                  placeholder="이메일"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "패스워드를 입력해주세요" }]}
              >
                <Input
                  prefix={<AiOutlineLock className="site-form-item-icon" />}
                  type="password"
                  placeholder="패스워드"
                />
              </Form.Item>

              <Form.Item>
                <AntdButton
                  isPrimary
                  loading={loadingLogin}
                  htmlType="submit"
                  className="signin-form-button"
                >
                  로그인
                </AntdButton>
              </Form.Item>
              <Bottom>
                <FoundPassword onClick={onClickFoundPassword}>
                  비밀번호를 잊으셨나요?
                </FoundPassword>
                <SignupText>
                  계정이 없으신가요?{" "}
                  <span onClick={onClickSignup}>회원가입</span>
                </SignupText>
              </Bottom>
            </Form>
          </Wrapper>
        </LayouRow>
      </Fade>
      <AntdModal
        visible={open}
        onCancel={() => setopen(false)}
        draggable
        centered
        footer={
          <div style={{ display: "flex", gap: 10 }}>
          <Checkbox
            value={show}
            onChange={(e) => {
              if (e.target.checked) {
                sessionStorage.setItem(
                  "show",
                  e.target.checked ? "false" : "true"
                );
              }
              setshow(e.target.checked ? false : true);
            }}
          ></Checkbox>
          <Typography>오늘 하루 보지 않기</Typography>
        </div>
        }
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <Typography style={{ fontWeight: 700 ,fontSize:18 }}>
공지사항</Typography>
            <IoMdClose
              size={20}
              onClick={() => {
                setopen(false);
              }}
            />
          </div>
        }
        style={{
          maxWidth: "600px",
          width: "100%",
        }}
        cancelButtonProps={{
          hidden: true,
        }}
        okButtonProps={{
          hidden: true,
        }}

        // onOk={() => setopen(false)}
      >
        <Typography style={{ fontWeight: 700 }}>
        최근 문자/알림톡 발신정책 강화에 따라, 2024년 5월 24일부로 다음과 같이 제한됩니다.
        </Typography>
        <br />
        <Typography>[가입 후, 1개월 미만]</Typography>
        {/* <Typography>가입한지 1개월 이내 사용자</Typography> */}
        <Typography>- 1회 발송건수를 50건으로 제한.</Typography>
        <Typography>- 1일 발송건수를 100건으로 제한.</Typography>
        <Typography>- 특수기호 제한.</Typography>
        <Typography>- 알림톡에 변수에 입력가능한 글자수 14자로 제한.</Typography>
        <Typography></Typography>
        <br />
        <Typography>
        [가입 후, 1개월 초과]
        </Typography>
        <Typography>- 사용량에 따라 100건단위로 추가. </Typography>
        <Typography>
        - 이전 1개월간의 1일 발송량 확인.
                </Typography>
        <Typography>
        - 신고여부 확인.
          </Typography>
        <Typography>
        * 단, 미사용계정의 경우, 요청이 거부될 수 있습니다.
          </Typography>
        <br />
        <Typography>감사합니다.</Typography>
        <Typography>센딧 </Typography>

       
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  background: var(--primary-color);
  .signInBgTxtCont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    .signinImgCont {
      width: 100%;
      .signinImg {
        height: 490px;
        padding-left: 80px;
      }
      &::after {
        content: " ";
        width: 100%;
        border-bottom: 2px dashed #577b89;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px;
        font-size: 20px;
        color: #ff9b52;
        font-weight: 500;
      }
    }
  }

  @media ${device.tablet.max} {
    .signInBgTxtCont {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  height: 100vh;
  min-height: 460px;
  flex-flow: column;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 32px 0px;
  transition: 0.2s;

  form {
    max-width: 400px;
    width: 100%;
    .ant-input {
      padding: 6px;
    }
    .signin-form-button {
      color: white;
      width: 100%;
      margin-top: 10px;
      height: 48px;
      fontweight: 500;
      span {
        font-size: 16px !important;
      }
    }
  }

  @media ${device.tabletL.max} {
    max-width: 100%;
  }

  @media ${device.tablet.max} {
    form {
      padding: 0 36px;
      .ant-input {
        padding: 3px 6px;
      }
      .signin-form-button {
        height: 42px;
        span {
          font-size: 14px !important;
        }
      }
    }
  }
`;

const Logo = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 600;
  border-bottom-right-radius: 38%;
  border-top-left-radius: 20%; */
  width: 184px;
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;

  svg {
    fill: var(--primary-color);
  }
  img {
    max-width: 194px;
    display: block;
    margin: 0 auto;
    margin-bottom: 13px;
  }

  @media ${device.tablet.max} {
    font-size: 23px;
    margin-bottom: 20px;
    img {
      max-width: 150px;
    }
  }
  
`;
const Bottom = styled.div`
  margin-top: 29px;
  text-align: center;

  @media ${device.tablet.max} {
    margin-top: 22px;
  }
`;

const FoundPassword = styled.span`
  color: var(--primary-color);
  cursor: pointer;
  font-weight: bold;
`;

const SignupText = styled.p`
  color: gray;
  margin-top: 6px;
  span {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
  }

  @media ${device.tablet.max} {
    margin-top: 4px;
  }
`;
