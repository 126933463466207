// @ts-ignore
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import { AntdButton, LayouRow } from "../components";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import { Helmet } from "react-helmet";
import { device } from "../device";
import { useCallback } from "react";
import { MdOutlineArrowBack } from "react-icons/md";

export const FoundPassword = () => {
  const navigate = useNavigate();
  
  /**
   * [로그인] 클릭시
   */
  const onClickLogin = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <Container>
      <div className="foundPwTxtCont">
        <div className="foundPwImgCont">
          <img src="/images/sendit_signin.png" alt="" className="signinImg"/>
        </div>
      </div>
      <Fade>
        <LayouRow className="foundPwBox">
          <Wrapper>
            <span onClick={onClickLogin}><MdOutlineArrowBack className="icon"/></span>  
            <Logo>
              {/* <AiOutlineSend size={16} /> */}
              비밀번호 재설정
            </Logo>
            <Text>
              가입하신 전화번호로 비밀번호를 보내드리겠습니다.
            </Text>
            <Cell>
              <Form>
                <h4>전화번호 <span>*</span></h4>
                <Form.Item>
                  <Input placeholder="전화번호를 입력해주세요" />
                </Form.Item>
                <AntdButton
                  isPrimary
                >
                  확인
                </AntdButton>
              </Form>
            </Cell>
          </Wrapper>
        </LayouRow>
      </Fade>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--primary-color);
  .foundPwTxtCont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    .foundPwImgCont {
      width: 100%;
      .signinImg {
        height: 490px;
        padding-left:80px;
      }
      &::after {
        content: " ";
        width: 100%;
        border-bottom: 2px dashed #577b89;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px;
        font-size: 20px;
        color: #ff9b52;
        font-weight: 500;
      }
    }
  }
  .foundPwBox {
    height: 100vh;
    
  }

  @media ${device.tabletL.max} {
    .foundPwCont {
      .foundPwCont {
        &::after {
          display: none;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  height: 400px;
  min-height: 360px;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 32px 0px;
  padding: 40px;
  transition: .2s;

  .icon {
    font-size: 24px;
    cursor: pointer;
  }
  form {
    width: 100%;
    h4 {
      font-weight: 600;
      span {
        color: red;
      }
      margin-bottom: 8px;
    }
    .ant-input {
      padding: 10px;
    }
  }

  @media ${device.tabletL.max} {
    max-width: 100%;
    border-radius: 0;
    box-shadow: none;
    height: 100vh;
  }
`;

const Logo = styled.div`
  font-size: 29px;
  text-align: left;
  font-weight: 600;
  margin-top: 18px;
  color: #292929;
`;

const Text = styled.p`
  color: var(--primary-color);
  text-align: left;
  margin: 10px 0;
  font-size: 13px;
`;

const Cell = styled.div`
  margin-top: 30px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  button {
    color: white;
      width: 100%;
      margin-top: 10px;
      height: 48px;
      fontWeight: 500;
      span {
        font-size: 16px!important;
      }
  }

  @media ${device.tablet.max} {
    button {
      height: 42px;
      span {
        font-size: 14px!important;
      }
    }
  }
`;
