import { createGlobalStyle, css } from "styled-components";
import { device } from "../device";

const variables = css`
  :root {
    --primary-color: #0A1527;
    --primary-light-color: #959ca9;
    --primary-dark-color: #070e1b;
    --success-color: #28c76f;
    --success-label-lightcolor: #e3fbf7;
    --danger-color: #ea5455;
    --danger-label-lightcolor: #f9e5e5;
    --danger-bg-color: #fff9f9;
    --warning-color: #ff8d1f;
    --warning-label-lightcolor: #ffe2c5;

    --gray-dark-color: #4b4b4b;
    --blue-color: #317bd3;
    --blue-select-color: #1b59b3;
    --blue-price-color: #3a84f1;
    --blue-bg-color: #f3f5fb;
    --blue-line-color: #8fa9d0;
    --green-color: #36a873;
    --purple-color: #6f42c1;
    --purple-bg-color: #fdfbfe;
    --secondary-color: #6c757d;

    --sms-color: #3498db;
    --sms-label-lightcolor: #ecf8ff;
    --lms-color: #1abc9c;
    --lms-label-lightcolor: #e7fff4;
    --mms-color: #9b59b6;
    --mms-label-lightcolor: #f7ebff;
    --talk-color: #ffdb32;
    --talk-dark-color: #f5b002;
    --talk-label-lightcolor: #fffae2;
    --sendit-color: #f05e29;
    --sendit-line-color: #f05e29;
    --sendit-bg-color: #f05e29;
  }
`;

const antdResetCss = css`

// 기본 버튼
  .btnPrimary {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .btnBlue {
    color: #fff;
    background-color: var(--blue-color);
    border-color: var(--blue-color);
    &:focus,
    &:active {
      border-color: var(--blue-color);
      background-color: var(--blue-color);
      filter: brightness(0.85);
    }
    &:hover {
      border-color: var(--blue-color);
      background-color: var(--blue-color);
      opacity: 0.9;
    }
  }

  .btnSendit {
    color: #fff;
    background-color: var(--sendit-color);
    border-color: var(--sendit-color);
    &:focus,
    &:active {
      color: #fff;
      border-color: var(--sendit-color);
      background-color: var(--sendit-color);
      filter: brightness(0.85);
    }
    &:hover {
      color: #fff;
      border-color: var(--sendit-color);
      background-color: var(--sendit-color);
      opacity: 0.9;
    }
  }
  .btnGreen {
    color: #fff;
    background-color: var(--green-color);
    border-color: var(--green-color);
    &:focus,
    &:active {
      border-color: var(--green-color);
      background-color: var(--green-color);
      filter: brightness(0.85);
    }
    &:hover {
      border-color: var(--green-color);
      background-color: var(--green-color);
      opacity: 0.9;
    }
  }
  .btnYellow {
    color: #fff;
    background-color: var(--talk-dark-color);
    border-color: var(--talk-dark-color);
    &:focus,
    &:active {
      border-color: var(--talk-dark-color);
      background-color: var(--talk-dark-color);
      filter: brightness(0.85);
    }
    &:hover {
      border-color: var(--talk-dark-color);
      background-color: var(--talk-dark-color);
      opacity: 0.9;
    }
  }
  .btnSecondary {
    color: #fff;
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    &:focus,
    &:active {
      background-color: var(--secondary-color);
    border-color: var(--secondary-color);
      filter: brightness(0.85);
    }
    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
      opacity: 0.9;
    }
  }
  .btnDashedPurple {
    color: var(--purple-color);
    border-color: var(--purple-color);
    &:focus,
    &:active {
      border-color: var(--purple-color);
      color: var(--purple-color);
      background-color: var(--purple-light-color);
    }
    &:hover {
      border-color: var(--purple-color);
      color: var(--purple-color);
      background-color: var(--purple-bg-color);
    }
  } 
  .btnLineblue {
    color: var(--blue-color);
    border-color: var(--blue-line-color);
    &:focus,
    &:active {
      border-color: var(--blue-line-color);
      color: var(--blue-color);
      background-color: var(--blue-bg-color);
    }
    &:hover {
      border-color: var(--blue-line-color);
      color: var(--blue-color);
      background-color: var(--blue-bg-color);
    }
  } 
  .btnLineSendit {
    color: var(--sendit-color);
    border-color: var(--sendit-line-color);
    &:focus,
    &:active {
      border-color: var(--sendit-line-color);
      color: var(--sendit-color);
      background-color: var(--sendit-bg-color);
    }
    &:hover {
      border-color: var(--sendit-line=color);
      color: var(--sendit-color);
      background-color: var(--sendit-bg-color);
    }
  } 
  .ant-btn-dangerous {
    &:focus,
    &:active {
      background-color: var( --danger-bg-color);
    }
    &:hover {
      background-color: var( --danger-bg-color);
    }
  }


  

// 아웃라인 버튼
  .btnLabelSms {
    background: var(--sms-label-lightcolor)!important;
    color: var(--sms-color)!important;
    border: 1px solid var(--sms-color)!important;
  }
  .btnLabelLms {
    background: var(--lms-label-lightcolor)!important;
    color: var(--lms-color)!important;
    border: 1px solid var(--lms-color)!important;
  }
  .btnLabelMms {
    background: var(--mms-label-lightcolor)!important;
    color: var(--mms-color)!important;
    border: 1px solid var(--mms-color)!important;
  }
  .btnLabelTalk {
    background: var(--talk-label-lightcolor)!important;
    color: var(--talk-dark-color)!important;
    border: 1px solid var(--talk-color)!important;
  }

  .btnLabelSuccess {
    background:  var(--success-label-lightcolor)!important;
    color: var(--success-color)!important;
    border: 1px solid var(--success-color)!important;
  }

  .ant-table-cell-row-hover {
    background: #ecf3fe!important;
  }
  .ant-table-thead > tr > th {
    font-size: 11px;
    font-weight: 600;
    color: #6c8198;
    background: #f6f9fc;
    padding: 4px 2px !important;
    line-height: 1.2;
    height: 34px;
  }
  .ant-modal-header {
    text-align: center;
  }
  .ant-tabs {
    color: rgb(98 121 164 / 85%);
  }
  .ant-tabs-tab {
    padding: 12px 20px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #19366a;
  }
  .ant-tabs-tab-btn {
    font-weight: bold
  }
  .ant-tabs-ink-bar {
    background: #19366a!important;
  }
  .ant-tabs-tab {
    &:hover {
      color: #19366a;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 16px;
  }

  span.ant-radio + * {
    font-weight: bold;
    font-size: 14px;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
  }
  .ant-radio-inner::after {
    background-color: var(--primary-color);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-color);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-color);
  }
  .ant-select-open,
  .ant-picker:hover,
  .ant-picker-focused,
  .ant-input:focus,
  .ant-input-focused,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-input:hover {
    border-color: var(--primary-color) !important;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0;
    padding-bottom: 4px;
    font-size: 13px;
  }
  .ant-checkbox-wrapper > span,
  .ant-select-selection-item,
  .ant-input {
    font-size: 13px;
  }
  .ant-form label {
    font-size: 13px;
  }
  .ant-input:placeholder-shown {
    font-size: 13px;
  }
  .ant-form-vertical .ant-form-item-label > label {
    color: var(--gray-dark-color);
    font-size: 13px;
    letter-spacing: -.5px;
    font-weight: 600;
  }

  .ant-modal-content {
    border-radius: 10px
  }

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .ant-modal {
    .ant-modal-footer {
      button {
        padding: 6px 10px;
        font-size: 12px;
        min-width: 60px;
        &.ant-btn {
          &.ant-btn-primary {
            color: white;
            background-color: var(--blue-color);
            border-color: var(--blue-color);
            &:focus,
            &:active {
              border-color: var(--blue-select-color);
              background-color: var(--blue-select-color);
            }
            &:hover {
              border-color: var(--blue-select-color);
              background-color: var(--blue-select-color);         
            }
          }
        }
      }
    }
  }

  @media ${device.laptop.max} {
    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
      font-size: 12px;
    }

    .ant-checkbox-wrapper > span,
    .ant-select-selection-item,
    .ant-input {
      font-size: 12px;
    }
    .ant-input:placeholder-shown {
      font-size: 12px;
    }

    .ant-modal {
      max-width: calc(100% - 20px) !important;
      .ant-modal-footer {
        padding: 6px 10px;
        button {
          padding: 6px 10px;
          font-size: 12px;
        }
      }
      .ant-modal-header {
        padding: 12px 24px;
        .ant-modal-title {
          font-size: 13px;
        }
      }
      .ant-modal-body {
        padding: 14px;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
      }
    }
  }
`;

export const GlobalSyles = createGlobalStyle`
    ${variables}
    ${antdResetCss}

    * {
      /* font-family: 'Noto Sans KR', sans-serif !important; */
      font-family: 'Mulish', sans-serif !important;
    box-sizing: border-box;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      width: 5px;
      height: 7px;
    }

    /* Track - ScrollBar-Track */
    ::-webkit-scrollbar-track {
      background: #e6e6e6;
      border-radius: 10px;
    }
    /* Handle - ScrollBar-Circle */
    ::-webkit-scrollbar-thumb {
      background: #9ea7af;
      border-radius: 10px;
      transition: 0.3s;
      cursor: pointer !important;
      &:hover {
        background: #868b90;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:active {
       background: #65717b;
      cursor: pointer;
    }
  }

  .ant-rate {
    color: #ffb700;
  }
  html {
    /* overflow: auto; */
  }
  body {
    /* overflow: inherit; */
    background-color: #f2f4f8;
  }
  body,
  html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* font-family: 'Nanum Gothic', sans-serif !important; */
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  #root {
    height: 100%;
    @media ${device.laptop.min} {
      min-height: 600px;
    }
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  h1,h2,h3,h4,h5,
  ul,
  li,
  a,
  p {
    padding: 0;
    margin: 0;
    list-style: inherit;
    color: inherit;
    text-decoration: inherit;
  }

  h5 {
    color: var(--gray-dark-color);
    letter-spacing: -.5px;  
  }
  .color-red {
    color: red;
  }
  .color-blue {
    color: blue;
  }
  .ant-tag {
    margin-right: 0;
    border-radius: 18px;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    /* padding-top: 2.5px;
    margin-bottom: -2.5px; */
  }

  textarea {
    resize: none;
  }

  text {
    -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non */
  }

  // **** custom ****  
  .content-header {
    background: #fff!important;
    border-bottom: 1px solid #eee;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }

  button {
    border-radius: 4px!important;
    letter-spacing: -1px
  }

  & .titLine p {
    display: flex;
    align-items: center;
    color: var(--gray-dark-color);
    font-size: 13px;
    letter-spacing: -.5px;
}
  }

.ant-tooltip {
  font-size: 12px!important;
}

.ant-switch {
  border-radius : 100px!important
}

  

`;


