import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Input, message, Select, Tag ,Tooltip, Typography} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import {
  CustormerControllerReadAllParams,
  CustormerReadResponseData,
} from "../generated";
import { GrMore } from "react-icons/gr";

import { privateApi } from "../react-query";
import { useMessageSendStore } from "../stores";
import { IComponentProps } from "../types";
import { isSuccess } from "../utils";
import { AntdButton } from "./AntdButton";
import { AntdTable } from "./AntdTable";
import getPhoneNumber from "../utils/getPhoneNumber";


interface IProps extends IComponentProps {}

interface IAddressBookContextParams {
  custormerName?: string;
  custormerGroup?: string;
  custormerPhone1?: string;
  phone1AdFlag?: 0 | 1;
  page?: number;
  pageSize?: number;
}

const fetcherGetAddressBooks = (params: CustormerControllerReadAllParams) =>
  privateApi.custormer.custormerControllerReadAll(params);

const fetcherAddressBookDelete = (id: number) =>
  privateApi.custormer.custormerControllerDelete({
    custormerIdxList: [{ custormerIdx: id }],
  });

const fetcherAddressBookTags = () =>
  privateApi.custormer.custormerControllerReadTag();

export const AddressBookModalContent: React.FC<IProps> = (props) => {
  const store = useMessageSendStore((s) => s);
  const ref = useRef<any>();
  const selectRef = useRef<any>(null);
  const [array,setArray] = useState<CustormerReadResponseData[]>([]);
  const [array2,setArray2] = useState<React.Key[]>([]);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  const {
    isLoading: loadingGetTags,
    data: dataGetTags,
    refetch: refetchTags,
  } = useQuery(["custormerControllerReadTag"], fetcherAddressBookTags, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("custormerControllerReadTag onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("custormerControllerReadTag onError: ", e);
    },
  });

  const {
    data,
    isLoading: loadingGetAddressBooks,
    mutate: mutateGetAddresBooks,
  } = useMutation(
    (params: CustormerControllerReadAllParams) =>
      fetcherGetAddressBooks(params),
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerReadAll onSuccess: ", e);
          // TODO : 이미 선택되어있는 수신번호가 보여지도록 수정하기.
          store.formReqMessage.onChangeForm({
            selectedAddressBooks: [],
          });
        }
        const receivedNumbers = store.receivedNumbers.map(
          (v) => v.custormerPhone1
        );
        e.data.body.data = e.data.body.data.filter(
          (v) => !receivedNumbers.includes(v.custormerPhone1)
        );
      },
      onError: (e) => {
        console.log("custormerControllerReadAll onError: ", e);
      },
    }
  );

  const {
    isLoading: loadingDeleteAddressBook,
    mutate: mutateDeleteAddressBook,
  } = useMutation((id: number) => fetcherAddressBookDelete(id), {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("custormerControllerDelete onSuccess: ", e);
        message.info("제거되었습니다");
        onClickSearch();
      }
    },
    onError: (e) => {
      console.log("custormerControllerDelete onError: ", e);
    },
  });

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CustormerReadResponseData[]
    ) => {
      store.formReqMessage.onChangeForm({
        selectedAddressBooks: selectedRows,
      });
      setArray2(selectedRowKeys)
      setArray(selectedRows)
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onChangeSearchType = (
    value: "custormerName" | "custormerGroup" | "custormerPhone1" | "all"
  ) => {
    store.formReqMessage.onChangeForm({
      addressBookSearchType: value,
    });
  };

  // const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const {
  //     target: { value },
  //   } = e;
  //   clearTimeout(ref.current);
  //   ref.current = setTimeout(() => {
  //     store.formReqMessage.onChangeForm({
  //       addressBookSearchInput: value,
  //     });
  //     store.formReqMessage
  //   }, 50);
  // };

  /**
   * 검색필드 [이름,회사, 태그명] 입력값 수정시
   */
  const onChangeInput = (value: string[]) => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      const filteredValue = value
        .map((val) => {
          if (val[0] === "#") {
            const tag = [...publicTags, ...privateTags].find(
              (v) => v.tagIdx === +val.slice(1) || v.tagName === val.slice(1)
            );
            if (!tag) {
              return undefined;
            }
            return "#" + tag.tagIdx;
          }
          return val;
        })
        .filter((v) => v) as string[];

      store.formReqMessage.onChangeForm({
        // tagName: isTagName ? e : undefined,
        addressBookSearchInput: filteredValue,
      });
      onClickSearch(value);
      selectRef.current.blur();
    }, 300);
  };

  const onClickSearch = (addressBookSearchInput?: string[]) => {
    let query = {
      page: 1,
      pageSize: 1000,
    } as IAddressBookContextParams;
    if (
      store.formReqMessage.form.addressBookSearchType !== "all" &&
      store.formReqMessage.form.addressBookSearchInput.length > 0
    ) {
      query = Object.assign(query, {
        [store.formReqMessage.form.addressBookSearchType]:
          store.formReqMessage.form.addressBookSearchInput,
      });
    }

    const searchField =
      addressBookSearchInput ||
      store.formReqMessage.form.addressBookSearchInput;

    mutateGetAddresBooks({
      ...query,
      searchingTags: searchField
        .filter((v) => v[0] === "#")
        .map((v) => +v.slice(1)),
      searchingValues: searchField.filter((v) => v[0] !== "#"),
    });
  };

  /**
   * [태그 렌더]
   */
  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    if (props.value && props.value[0] === "#") {
      const isPublic = publicTags.some(
        (v) => v.tagIdx === +props.value.slice(1)
      );
      return (
        <Tag
          color={isPublic ? "green" : "blue"}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
        >
          {label}
        </Tag>
      );
    }
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    mutateGetAddresBooks({
      page: 1,
      pageSize: 1000,
      custormerGroup: undefined,
      custormerName: undefined,
      custormerPhone1: undefined,
      phone1AdFlag: undefined,
    } as IAddressBookContextParams);
  }, []);

  useEffect(() => {

    rowSelection.onChange(array2,array)
    // clicked ? setClicked(false) : setClicked(true); 
  }, [array]);

  /** 공유주소록 */
  const publicTags = useMemo(
    () => dataGetTags?.data.body.data[0].publicTags || [],
    [dataGetTags?.data.body.data]
  );

  /** 개인주소록 */
  const privateTags = useMemo(
    () => dataGetTags?.data.body.data[1].privateTags || [],
    [dataGetTags?.data.body.data]
  );

  return (
    <Container {...props}>
      <AddressSearchBar>
        {/* <Select
          style={{ width: 100 }}
          value={store.formReqMessage.form.addressBookSearchType}
          onChange={onChangeSearchType}
        >
          <Select.Option value="all">전체</Select.Option>
          <Select.Option value="custormerName">고객 이름</Select.Option>
          <Select.Option value="custormerGroup">고객 그룹</Select.Option>
          <Select.Option value="custormerPhone1">전화번호</Select.Option>
        </Select> */}
        <Select
          autoFocus
          ref={selectRef}
          loading={loadingGetTags}
          mode="tags"
          tagRender={tagRender}
          placeholder="이름, 회사명, 태그명 검색"
          onChange={onChangeInput}
          style={{ width: "100%" }}
          value={store.formReqMessage.form.addressBookSearchInput}
          filterOption={(input, option) => {
            if (option?.children && input && input[0] === "#") {
              const children = option?.children.join("");
              return children
                ?.toLowerCase()
                .includes(input.slice(1).toLowerCase());
            }
            return false;
          }}
        >
          <Select.OptGroup label="개인주소록">
            {privateTags.map((v) => (
              <Select.Option value={"#" + v.tagIdx}>#{v.tagName}</Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup label="공유주소록">
            {publicTags.map((v) => (
              <Select.Option value={"#" + v.tagIdx}>#{v.tagName}</Select.Option>
            ))}
          </Select.OptGroup>
          {/* {[
            ...new Set([...privateTags, ...publicTags].map((v) => v.tagName)),
          ].map((v, i) => (
            <Select.Option value={"#" + v} key={i}>
              #{v}
            </Select.Option>
          ))} */}
        </Select>
        {/* <Input
          placeholder="입력해주세요"
          // defaultValue={store.formReqMessage.form.addressBookSearchInput}
          onChange={onChangeSearchInput}
          onPressEnter={onClickSearch}
        /> */}
        {/* <AntdButton
          isPrimary
          style={{ color: "white" }}
          onClick={onClickSearch}
        >
          검색
        </AntdButton> */}
      </AddressSearchBar>
      <AntdTable
        loading={loadingGetAddressBooks || loadingDeleteAddressBook}
        style={{
          minHeight: 300,
          borderTop: "1px solid #dfdfdf",
          borderBottom: "1px solid #dfdfdf",
        }}
        tableLayout="fixed"
        scroll={windowsize ? {y: 310 }:{ x: 520, y: 310 }}
        rowSelection={{
          selectedRowKeys: store.formReqMessage.form.selectedAddressBooks.map(
            (v) => v.custormerIdx
          ),
          type: "checkbox",
          ...rowSelection,
        }}
        rowKey="custormerIdx"
        dataSource={data?.data.body.data || []}
        pagination={false}
      
        onRow={
          (v:CustormerReadResponseData)=>{
            return{
              onClick: () =>{

                  array2.some((array2)=>array2==v.custormerIdx) ?
                  setArray2(array2.filter((array2)=>array2!==v.custormerIdx))
                  :setArray2(array2.concat(v.custormerIdx))

                  array.some((array)=>array.custormerIdx==v.custormerIdx) ? 
                  setArray(array.filter((array)=>array.custormerIdx!==v.custormerIdx))
                  :setArray(array.concat(v))
              }
            }
            
          }
        }
        columns={[
          {
            title: "이름",
            dataIndex: "custormerName",
            key: "custormerName",
            width: windowsize? 50 :80,

          },
          {
            title: "이메일",
            dataIndex: windowsize? "none": "custormerEmail" ,
            key: "custormerEmail",
            width: windowsize? 0 :150,

          },
          {
            title: "전화번호",
            dataIndex: "custormerPhone1",
            key: "custormerPhone1",
            width: windowsize? 90 :120,
            render: (e, v: CustormerReadResponseData) => {
              return (
                <Typography>
                  {getPhoneNumber(v.custormerPhone1)}
                </Typography>
              );
            },
          },
          {
            title: "그룹",
            dataIndex: windowsize? "none": "custormerGroup" ,
            key: "custormerGroup",
            width: windowsize? 0 :150,

          },
          {
            title: "태그",
            dataIndex:  windowsize? "none": "isPublic",
            key: "isPublic",
            align: "center",
            width: windowsize? 0 :150,

            render: (e, v: CustormerReadResponseData) => {
              if (e) {
                const tagNames = v.publicTaggedInformation.map(
                  (v) => v.tagIdx.tagName
                );
                return (
                  <Tooltip
                  title={
                    <TagSpace >
                      {tagNames.map((v, i) => (
                        <Tag key={i} color="green">
                          {v}
                        </Tag>
                      ))}
                    </TagSpace>
                  }
                >
                  <TagSpace className='overflow-hide'  
                   onClick={(e) => {
                   e.stopPropagation();}}>
                    {tagNames.map((v, i) => (
                      <Tag key={i} color="green">
                        {v}
                      </Tag>
                    ))}
                  </TagSpace>
                </Tooltip>
              );
              }
              const tagNames = v.privateTaggedInformation.map(
                (v) => v.tagIdx.tagName
              );
              return (
                <Tooltip
                title={
                  <TagSpace>
                    {tagNames.map((v, i) => (
                      <Tag key={i} color="blue">
                        {v}
                      </Tag>
                    ))}
                  </TagSpace>
                }
              >
                <TagSpace className="overflow-hide">
                  {tagNames.map((v, i) => (
                    <Tag key={i} color="blue">
                      {v}
                    </Tag>
                  ))}
                </TagSpace>
              </Tooltip>
            );
            },
          },
          {
            title: windowsize? "추가 정보" : "",
            dataIndex: windowsize? "isPublic": "none" ,
            key: "",
            align:"center",
            width: windowsize? 70 : 0,
            render: (e, v: CustormerReadResponseData) => {
            
              if (e) {
                const tagNames = v.publicTaggedInformation.map(
                  (v) => v.tagIdx.tagName
                );
                const email = v.custormerEmail
                const group = v.custormerGroup
              
                return (
                  <Tooltip
             
                  
                  title={
                  <>
                    <div style={{display: "flex"}}>
                    <p>이메일 :</p><p style={{marginLeft: 10 }}>{email}</p>
                    </div>
                    <div style={{display: "flex"}}>
                    <p>그룹 : </p><p style={{marginLeft: 10}}>{group}</p>
                    </div>
                    <div style={{display: "flex"}}>
                    <p>태그 : </p><p style={{marginLeft: 10}}>{tagNames.map((v, i) => (
                          <Tag key={i} color="green">
                         {v}
                            </Tag>
                        ))}</p>
                    </div>
                   </>  
                  }
                >
                  <TagSpace className="overflow-hide"
                  onClick={(e)=>{
                    e.stopPropagation();
                  }}
                  style={{display:"flex", justifyContent:"center"}}
                  >
                     <GrMore />
                  </TagSpace>
                </Tooltip>
                );
              }

              const tagNames = v.privateTaggedInformation.map(
                (v) => v.tagIdx.tagName
              );
              const email = v.custormerEmail
              const group = v.custormerGroup
              return (
                <Tooltip
                  title={
                  <>
                    <div style={{display: "flex"}}>
                    <p>이메일 :</p><p style={{marginLeft: 10 }}>{email}</p>
                    </div>
                    <div style={{display: "flex"}}>
                    <p>그룹 : </p><p style={{marginLeft: 10 }}>{group}</p>
                    </div>
                    <div style={{display: "flex"}}>
                    <p>태그 : </p><p style={{marginLeft: 10 }}> {tagNames.map((v, i) => (
                          <Tag key={i} color="blue">
                            {v}
                          </Tag>
                        ))}</p>
                    </div>
                   </>  
                  }
                >
                  <TagSpace className="overflow-hide"
                  onClick={(e)=>{
                    e.stopPropagation();
                  }}
                  style={{display:"flex", justifyContent:"center"}}
                  >
                     <GrMore />
                  </TagSpace>
                </Tooltip>
              );
            },
          },
          // {
          //   title: "광고수신",
          //   dataIndex: "isAlphone1AdFlagow",
          //   key: "isAlphone1AdFlagow",
          //   align: "center",
          //   render: (v) => {
          //     return v ? (
          //       <Tag color="green">수신</Tag>
          //     ) : (
          //       <Tag color="red">거부</Tag>
          //     );
          //   },
          // },
          {
            title: "삭제",
            dataIndex: "custormerIdx",
            key: "custormerIdx",
            align: "center",
            width: 60,
            render: (v) => {
              const onClick = () => {
                mutateDeleteAddressBook(v);
              };
              return (
                <AiFillCloseCircle
                  size={16}
                  onClick={onClick}
                  style={{
                    cursor: "pointer",
                  }}
                />
              );
            },
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div``;

const AddressSearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 6px;
  padding-bottom: 8px;
  /* padding: 10px 20px; */
  /* margin: 10px 0; */
  /* background-color: #f5f5f5; */
  input {
    max-width: 300px;
  }
`;

const AddressButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 8px;
  button {
    /* flex: 1; */
  }
`;

const TagSpace = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 4px;
  &.overflow-hide {
    flex-flow: row;
    overflow: hidden;
  }
`;
