import { Pagination, PaginationProps } from "antd";
import styled from "styled-components";
import { SpinLoading } from ".";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  loading?: boolean;
  position?: "left" | "center" | "right";
}

export const AntdPagination: React.FC<IProps & PaginationProps> = ({
  className,
  loading = false,
  position = "left",
  ...props
}) => (
  <Container className={className} style={props.style}>
    {loading ? (
      <SpinLoading />
    ) : (
      <Pagination
        style={{
          ...props.style,
          justifyContent:
            position === "left"
              ? "flex-start"
              : position === "right"
              ? "flex-end"
              : "center",
        }}
        hideOnSinglePage
        {...props}
      />
    )}
  </Container>
);

const Container = styled.div`
  margin-top: 20px;
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    display: flex;
    /* justify-content: center; */
  }

  .ant-pagination-item {
    &:hover {
      border-color: var(--primary-color);
      a {
        color: var(--primary-color);
      }
    }
  }
  .ant-pagination-item-active {
    border-color: var(--primary-color);
    a {
      color: var(--primary-color);
    }
  }
`;
