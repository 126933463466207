import { Spin } from "antd";
import styled from "styled-components";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const SpinLoading: React.FC<IProps> = (props) => {
  return (
    <Container {...props}>
      <Spin />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  .ant-spin {
    width: 100%;
    padding: 50px;
  }
`;
