import dayjs from "dayjs";
import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import {
  MessageControllerReadAllParams,
  MessageReadResponseData,
} from "../generated";
import {
  IReducerForm,
  IReducerModal,
  reducerForm,
  reducerModal,
} from "../reducers";

interface IFormFilter extends MessageControllerReadAllParams {
  hideSort: boolean;
}

interface IStore extends State {
  /** 요청 쿼리 입력폼 */
  formFilter: IReducerForm<IFormFilter>;
  /** [전송결과] 클릭시, 선택된 발송내역 */
  selectedSentMessage?: MessageReadResponseData;
  /** 선택된 발송내역 업데이트 */
  onChangeSelectedSentMessage: (sentMessage?: MessageReadResponseData) => void;
  /** 모달 - 전송결과 */
  modalReqResult: IReducerModal;
  onReset: () => void;
}

const startRequestDate = dayjs().add(-1, "month").format("YYYY-MM-DD");
const endRequestDate = dayjs().format("YYYY-MM-DD");

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    formFilter: reducerForm<IStore, IFormFilter>(set, "formFilter", {
      messageType: "all",
      statisticsType: "daily",
      startRequestDate,
      endRequestDate,
      recipientNo : "",
      page: 1,
      hideSort: true,
      pageSize: 10,
      sortBy: "registerDate",
      sortOrder: "DESC",
    }),
    selectedSentMessage: undefined,
    modalReqResult: reducerModal<IStore>(set, "modalReqResult", false, () => {
      setTimeout(() => {
        set({
          selectedSentMessage: undefined,
        });
      }, 300);
    }),
    onChangeSelectedSentMessage: (selectedSentMessage) => {
      const { modalReqResult } = get();
      set({
        selectedSentMessage,
        modalReqResult: {
          ...modalReqResult,
          visible: true,
        },
      });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** [분석] > [발송통계] 네비메뉴 상태관리 */
export const useAnalysisSendStore = create<IStore>()(
  devtools(initialStateCreator)
);
