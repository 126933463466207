import { Button, ButtonProps } from "antd";
import styled, { css } from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  isPrimary?: boolean;
}

export const AntdButton: React.FC<IProps & ButtonProps> = ({
  className,
  style,
  children,
  isPrimary,
  color,
  ...props
}) => (
  <ExtendedButton
    className={className}
    style={style}
    customColor={isPrimary ? "var(--primary-color)" : color}
    {...props}
  >
    {children}
  </ExtendedButton>
);

const ExtendedButton = styled(Button)<{ customColor?: string }>`
  ${(props) => customColorCss(props.customColor)}
  font-size: 13px;

  @media ${device.laptop.max} {
    padding: 4px 10px;
    span {
      font-size: 13px;
    }
  }
`;

const customColorCss = (customColor?: string) => {
  if (customColor) {
    return css`
      background-color: ${customColor};
      border-color: ${customColor};
      &:focus,
      &:active {
        border-color: ${customColor};
        background-color: ${customColor};
        filter: brightness(0.85);
      }
      &:hover {
        border-color: ${customColor};
        background-color: ${customColor};
        opacity: 0.9;
      }

      &:disabled {
        opacity: 0.2;
        border-color: ${customColor};
        background: ${customColor};
        background-color: ${customColor};
        &:hover,
        &:focus,
        &:active {
          border-color: ${customColor};
          background: ${customColor};
          background-color: ${customColor};
        }
      }
    `;
  }
  return css``;
};
