import { Button, ButtonProps } from "antd";
import styled, { css } from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";
import { AiFillMessage } from "react-icons/ai";
import { useEffect } from "react";

interface IProps extends IComponentProps {
  isPrimary?: boolean;
}

export const ChatButton = () =>{
 
  
return <div
style={{
  bottom:"40px",
  position: 'fixed',
  right: 20 ,
  zIndex: 1200,
  border: "2px solid orange",
  background:"orange",
  borderRadius:40,
  width : 40,
  height : 40,
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  cursor:"pointer"
}}
onClick={()=>{
  
  const iframeDiv = document.getElementById('lydian-iframediv');
  const iframe = document.getElementById('lydian-iframe');
  // console.log('lydian-iframediv', iframeDiv?.style.display, aIframe);

     
      if (iframeDiv !== null && iframe !== null) {
          if (iframeDiv.style.display === 'none') {
              iframeDiv.style.display = 'block';
          } else {
              iframeDiv.style.display = 'none';
          }
      } 

  
}}
>
  <AiFillMessage size={25} color="white"/>

</div>
}
