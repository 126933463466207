export const getByte = (str: string) => {
  // 예전엔 80바이트 이하, 지금은 90바이트 이하 단문(SMS), 초과 장문(LMS)
  var len = 0;
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i);
    if (
      c > 128 ||
      c == 0x22 ||
      c == 0x27 ||
      c == 0x2f ||
      c == 0x5c ||
      c == 0x7c
    ) {
      // ", ', /, \, |
      len += 2;
    } else if (c == 0x0d) {
      // 0x0A 에서 1카운트 0x0D 는 무시
      len += 0;
    } else {
      len += 1;
    }
  }
  return len;
};
