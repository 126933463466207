import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Alert, Carousel } from "antd";
import styled from "styled-components";
import { privateApi } from "../react-query";
import { isSuccess } from "../utils";
import { AntdModal } from "./AntdModal";
import { NoticeResponseData } from "../generated";

interface IProps {
  visibleNotice: boolean;
  onCloseVisibleNotice: () => void;
}

const fetcherGetNotices = () => privateApi.notice.noticeControllerRead();

export const LayoutNotice: React.FC<IProps> = ({
  visibleNotice,
  onCloseVisibleNotice,
}) => {
  const [selectedNotice, setSelectedNotice] = useState<
    NoticeResponseData | undefined
  >(undefined);
  const { data: dataGetNotices } = useQuery(
    ["noticeControllerReadCurrentMember"],
    fetcherGetNotices,
    {
      enabled: visibleNotice,
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("noticeControllerReadCurrentMember onSuccess: ", e);
          if (e.data.body.total < 1) {
            onCloseVisibleNotice();
          }
        }
      },
      onError: (e) => {
        console.log("noticeControllerReadCurrentMember onError: ", e);
      },
    }
  );

  const onClickNoticeDetail = (idx: number) => {
    const notice = dataGetNotices?.data.body.data.find(
      (v) => v.noticeIdx === idx
    );
    if (notice) {
      setSelectedNotice(notice);
    }
  };

  const onChange = (currentSlide: number) => {
    // console.log(currentSlide);
  };

  return (
    <Container
      style={{
        display: visibleNotice ? "block" : "none",
      }}
    >
      {dataGetNotices?.data.body.data && (
        <Carousel
          autoplay
          autoplaySpeed={20000}
          vertical
          dots={false}
          afterChange={onChange}
          // swipeToSlide
          // draggable
          // infinite={false}
        >
          {dataGetNotices?.data.body.data.map((v) => (
            <Alert
              key={v.noticeIdx}
              message={
                <Space>
                  <Text onClick={() => onClickNoticeDetail(v.noticeIdx)}>
                    {v.title}
                  </Text>
                </Space>
              }
              type={
                v.level === "1"
                  ? "success"
                  : v.level === "2"
                  ? "info"
                  : v.level === "3"
                  ? "warning"
                  : "error"
              }
              showIcon={false}
              closable
              // onClick={() => {
              //   alert("hello world");
              // }}
              onClose={onCloseVisibleNotice}
            />
          ))}
        </Carousel>
      )}
      <AntdModal
        visible={Boolean(selectedNotice)}
        draggable
        centered
        title={selectedNotice?.title || ""}
        style={{
          maxWidth: "600px",
          width: "100%",
        }}
        cancelButtonProps={{
          hidden: true,
        }}
        okText="확인"
        onOk={() => setSelectedNotice(undefined)}
      >
        <div>{selectedNotice?.content}</div>
      </AntdModal>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  color: white;
  font-size: 14px;
  .ant-alert {
    display: flex !important;
    .ant-alert-content {
      flex: initial;
      width: fit-content;
    }
    .ant-alert-message {
      width: fit-content;
    }
  }
  .ant-carousel{
    .slick-list{
      height : 40px; 
      .slick-slide{
        
        div{
          justify-content:center;
          div{
            justify-content:center;
          }
        }
      }
    }
  }
`;
const Space = styled.div`
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
const Text = styled.div`
  cursor: pointer;
  max-width: 60vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
