import { Form, Input, message, Select } from "antd";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import { CompanyCertKakaoCreateDTO, CompanyCertKakaoUpdateDTO, CompanyControllerReadKakaoProfileCategoriesParams } from "../generated";
import { privateApi } from "../react-query";
import { useMessageSetStore } from "../stores";
import { IComponentProps } from "../types";
import { isSuccess, Pattern } from "../utils";
import { AntdButton } from "./AntdButton";
import getPhoneNumber from "../utils/getPhoneNumber";

interface IProps extends IComponentProps {}

const fetcherGetCategories = (
  params: CompanyControllerReadKakaoProfileCategoriesParams
) => privateApi.company.companyControllerReadKakaoProfileCategories(params);

// REVIEW : 해당 인증요청 API가있는지 확인이필요.
const fetcherCreateKakaoProfile = (params: CompanyCertKakaoCreateDTO) =>
  privateApi.company.companyControllerCreateKakaoProfile(params);

const fetcherVerifyCode = (
  params: CompanyCertKakaoUpdateDTO & { id: string }
) =>
  privateApi.company.companyControllerCertKakaoProfile(params.id, {
    plusFriendId: params.plusFriendId,
    token: params.token,
  });

export const AddedReceivedProfileModalContent: React.FC<IProps> = () => {
  const store = useMessageSetStore(s => s);
  const {
    isLoading: loadingGetCategory1,
    data: dataGetCategories1,
    mutate: mutateGetCategories1,
  } = useMutation(fetcherGetCategories, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log(
          "companyControllerReadKakaoProfileCategories onSuccess: ",
          e
        );
      }
    },
    onError: (e) => {
      console.log("companyControllerReadKakaoProfileCategories onError: ", e);
    },
  });
  const {
    isLoading: loadingGetCategory2,
    data: dataGetCategories2,
    mutate: mutateGetCategories2,
  } = useMutation(fetcherGetCategories, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log(
          "companyControllerReadKakaoProfileCategories onSuccess: ",
          e
        );
      }
    },
    onError: (e) => {
      console.log("companyControllerReadKakaoProfileCategories onError: ", e);
    },
  });
  const {
    isLoading: loadingGetCategory3,
    data: dataGetCategories3,
    mutate: mutateGetCategories3,
  } = useMutation(fetcherGetCategories, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log(
          "companyControllerReadKakaoProfileCategories onSuccess: ",
          e
        );
      }
    },
    onError: (e) => {
      console.log("companyControllerReadKakaoProfileCategories onError: ", e);
    },
  });

  const {
    isLoading: loadingCreateKakaoProfile,
    mutate: mutateCreateKakaoProfile,
  } = useMutation(fetcherCreateKakaoProfile, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        message.info("인증 요청되었습니다");
        store.formSms.onChangeForm({
          addedIsValidating: true,
          addedProfileIdx: e.data.body.data[0].profileIdx,
        });
        console.log("companyControllerCreateKakaoProfile onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("companyControllerCreateKakaoProfile onError: ", e);
    },
  });

  const { isLoading: loadingVerifyCode, mutate: mutateVerifyCode } =
    useMutation(fetcherVerifyCode, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("companyControllerCertKakaoProfile onSuccess: ", e);
          message.info("인증 완료되었습니다");
          store.formSms.onChangeForm({
            addedIsValiated: true,
            addedIsValidating: false,
          });
        }
      },
      onError: (e) => {
        console.log("companyControllerCertKakaoProfile onError: ", e);
      },
    });
  

  /** [발신프로필명] 수정 */
  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { name, value },
    } = e;
    store.formSms.onChangeForm({
      [name]: value,
    });
  };

  
  /** 카테고리 "대분류" 업데이트 */
  const onSelectCategory1 = (code: string) => {
    if (dataGetCategories1?.data) {
      const selectCategory = dataGetCategories1.data.body.data.find(
        (v) => v.code === code
      );
      console.log({ selectCategory });
      if (selectCategory) {
        mutateGetCategories2({ parentCode: selectCategory.code });
        store.formSms.onChangeForm({
          addedTemplateCode1: code,
          addedTemplateCode2: "all",
        });
      } else {
        // '대분류' 선택한경우
        store.formSms.onChangeForm({
          addedTemplateCode1: "all",
          addedTemplateCode2: "all",
          addedTemplateCode3: "all",
        });
      }
    }
  };

  /** 카테고리 "중분류" 업데이트 */
  const onSelectCategory2 = (code: string) => {
    if (dataGetCategories2?.data) {
      const selectCategory = dataGetCategories2.data.body.data.find(
        (v) => v.code === code
      );
      if (selectCategory) {
        mutateGetCategories3({ parentCode: selectCategory.code });
        store.formSms.onChangeForm({
          addedTemplateCode2: code,
          addedTemplateCode3: "all",
        });
      } else {
        // '중분류' 선택한경우
        store.formSms.onChangeForm({
          addedTemplateCode2: "all",
          addedTemplateCode3: "all",
        });
      }
    }
  };

  /** 카테고리 "소분류" 업데이트 */
  const onSelectCategory3 = (code: string) => {
    if (dataGetCategories3?.data) {
      const selectCategory = dataGetCategories3.data.body.data.find(
        (v) => v.code === code
      );
      if (selectCategory) {
        store.formSms.onChangeForm({
          addedTemplateCode3: code,
        });
      } else {
        // '중분류' 선택한경우
        store.formSms.onChangeForm({
          addedTemplateCode3: "all",
        });
      }
    }
  };

  /** [인증요청] 버튼 클릭시 */
  const onClickCreateKakao = () => {
    const { addedProfileName, addedPhone, addedTemplateCode3 } =
      store.formSms.form;
    if (addedProfileName.replace(/ /g, "").length < 1) {
      return message.warn("발신 프로필을 입력해주세요");
    }
    if (addedTemplateCode3 === "all") {
      return message.warn("사업자 카테고리를 선택해주세요");
    }
    if (addedPhone.length >= 10) {
      if (Pattern.checkOptional(Pattern.숫자).test(addedPhone)) {
        return mutateCreateKakaoProfile({
          categoryCode: addedTemplateCode3,
          phoneNo: addedPhone,
          plusFriendId: addedProfileName,
        });
      }
      return message.warn("휴대폰 번호는 숫자로만 입력해주세요");
    }
    return message.warn("휴대폰 번호는 10~11자리의 숫자를 입력해주세요");
  };

  /** [인증번호] 확인 클릭시 */
  const onClickCertificationNumber = () => {
    const {
      addedProfileIdx,
      addedProfileName,
      addedCertificationNumber,
      addedIsValidating,
    } = store.formSms.form;
    if (addedCertificationNumber.length > 0) {
      if (Pattern.checkOptional(Pattern.숫자).test(addedCertificationNumber)) {
        if (addedIsValidating) {
          return mutateVerifyCode({
            id: addedProfileIdx + "",
            plusFriendId: addedProfileName,
            token: +addedCertificationNumber,
          });
        }
        return message.warn("먼저 휴대폰 인증을 진행해주세요");
      }
      return message.warn("인증번호는 숫자로 입력해주세요");
    }
    return message.warn("인증번호를 입력해주세요");
  }


  /**
   * 인증요청중 상태에서 → 취소
   */
  const onCancelCreateKako = () => {
    store.formSms.onChangeForm({
      addedIsValidating: false,
      addedProfileIdx: undefined,
      addedCertificationNumber: '',
    })
  };

  /**
   * 인증완료된 상태에서 → 취소
   */
  const onCancelVerifyCode = () => {
    store.formSms.onChangeForm({
      addedIsValiated: false,
      addedProfileIdx: undefined,
      addedCertificationNumber: '',
    })
  }

  useEffect(() => {
    mutateGetCategories1({
      parentCode: "0",
    });
  }, []);

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="발신프로필">
          <Input
            placeholder="발신프로필을 입력해주세요"
            maxLength={50}
            name="addedProfileName"
            value={store.formSms.form.addedProfileName}
            onChange={onChangeInput}
            disabled={
              store.formSms.form.addedIsValiated ||
              store.formSms.form.addedIsValidating
            }
          />
          <Hint>
            <span>*</span> 비지니스 인증된, 카카오톡채널 아이디를 입력해 주세요.
          </Hint>
        </Form.Item>
        <Form.Item required label="사업자 카테고리">
          <SelectSpace>
            <Select
              style={{ width: "100%" }}
              loading={loadingGetCategory1}
              onChange={onSelectCategory1}
              value={store.formSms.form.addedTemplateCode1}
              disabled={
                store.formSms.form.addedIsValiated ||
                store.formSms.form.addedIsValidating
              }
              options={
                dataGetCategories1?.data &&
                dataGetCategories1?.data.body.data.length > 0
                  ? [
                      {
                        label: "대분류",
                        value: "all",
                      },
                    ].concat(
                      dataGetCategories1.data.body.data.map((v) => ({
                        label: v.name,
                        value: v.code,
                      }))
                    )
                  : [
                      {
                        label: "대분류",
                        value: "all",
                      },
                    ]
              }
            />
            <Select
              loading={loadingGetCategory2}
              style={{ width: "100%" }}
              onChange={onSelectCategory2}
              value={store.formSms.form.addedTemplateCode2}
              disabled={
                store.formSms.form.addedTemplateCode1 === "all" ||
                store.formSms.form.addedIsValiated ||
                store.formSms.form.addedIsValidating
              }
              options={
                dataGetCategories2?.data &&
                dataGetCategories2?.data.body.data.length > 0
                  ? [
                      {
                        label: "중분류",
                        value: "all",
                      },
                    ].concat(
                      dataGetCategories2.data.body.data.map((v) => ({
                        label: v.name,
                        value: v.code,
                      }))
                    )
                  : [
                      {
                        label: "중분류",
                        value: "all",
                      },
                    ]
              }
            />
          </SelectSpace>
          <Select
            loading={loadingGetCategory3}
            disabled={
              store.formSms.form.addedTemplateCode2 === "all" ||
              store.formSms.form.addedIsValiated ||
              store.formSms.form.addedIsValidating
            }
            style={{ width: "100%" }}
            value={store.formSms.form.addedTemplateCode3}
            onChange={onSelectCategory3}
            options={
              dataGetCategories3?.data &&
              dataGetCategories3?.data.body.data.length > 0
                ? [
                    {
                      label: "소분류",
                      value: "all",
                    },
                  ].concat(
                    dataGetCategories3.data.body.data.map((v) => ({
                      label: v.name,
                      value: v.code,
                    }))
                  )
                : [
                    {
                      label: "소분류",
                      value: "all",
                    },
                  ]
            }
          />
          <Hint>
            <span>*</span> 카카오톡채널의 가입시 등록한 카테고리를 입력해주세요.
          </Hint>
        </Form.Item>
        <Form.Item required label="카카오톡채널 관리자 휴대폰 번호">
          <SelectSpace>
            <Input
              placeholder="휴대폰번호를 입력해주세요"
              name="addedPhone"
              value={store.formSms.form.addedPhone}
              maxLength={11}
              onChange={onChangeInput}
              disabled={
                store.formSms.form.addedIsValidating ||
                store.formSms.form.addedIsValiated
              }
            />
            {store.formSms.form.addedIsValiated ? (
              <AntdButton disabled onClick={onClickCreateKakao}>
                인증완료
              </AntdButton>
            ) : store.formSms.form.addedIsValidating ? (
              <AntdButton
                disabled={!store.formSms.form.addedIsValidating}
                onClick={onCancelCreateKako}
              >
                취소
              </AntdButton>
            ) : (
              <AntdButton
                disabled={
                  loadingCreateKakaoProfile ||
                  store.formSms.form.addedIsValidating
                }
                onClick={onClickCreateKakao}
              >
                인증요청
              </AntdButton>
            )}
          </SelectSpace>
          <SelectSpace className="no-margin">
            <Input
              placeholder="인증번호를 입력해주세요"
              name="addedCertificationNumber"
              maxLength={20}
              disabled={!store.formSms.form.addedIsValidating}
              value={store.formSms.form.addedCertificationNumber}
              onChange={onChangeInput}
            />
            {store.formSms.form.addedIsValiated ? (
              <AntdButton
                disabled={store.formSms.form.addedIsValidating}
                onClick={onCancelVerifyCode}
              >
                취소
              </AntdButton>
            ) : (
              <AntdButton
                loading={loadingVerifyCode}
                disabled={!store.formSms.form.addedIsValidating}
                onClick={onClickCertificationNumber}
              >
                확인
              </AntdButton>
            )}
          </SelectSpace>
          <Hint>
            <span>*</span> 카카오톡채널의 가입시 등록한 휴대폰 번호를 입력해
            주세요.
          </Hint>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div`
`;

const SelectSpace = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
  button {
    min-width: 84px;
  }
  &.no-margin {
    margin-bottom: 0;
  }
`;

const Hint = styled.div`
  font-size: 12px;
  color: #f06363;
  display: flex;
  word-break: keep-all;
  span {
    margin-top: 3px;
    margin-right: 4px;
  }
`;
