import { parseMutationArgs, useMutation } from "@tanstack/react-query";
import { message } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { setAccessToken, setRefreshToken } from "../auth";
import { publicApi } from "../react-query";
import { useAppStore } from "../stores";
import { isSuccess } from "../utils";

export const withPreAuth = (Component: React.FC) => () => {
  console.log("PreAuthHoc");
  const store = useAppStore((s) => s);
  const navigate = useNavigate();
  const { accessKey } = useParams() as { accessKey: string };
  const { isLoading: loadingLogin, mutate: mutateLogin } = useMutation(
    publicApi.member.memberControllerLogInByAccessKey,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerLogInByAccessKey onSuccess: ", e);
          store.onChangeIsAccessKey(true);
          setAccessToken(e.data.body.data.token);
        } else {
          navigate("/");
        }
      },
      onError: (e) => {
        console.log("memberControllerLogInByAccessKey onError: ", e);
      },
    }
  );

  useEffect(() => {
    mutateLogin({ accesskey: accessKey });
  }, []);
  if (store.isAccessKey) {
    return (
      <Container>
        <Component />
      </Container>
    );
  }
  return <></>;
};

const Container = styled.div`
  padding-top: 30px;
`;
