import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes,HashRouter } from "react-router-dom";
import { getAccessToken } from "./auth";
import { Layout } from "./components/Layout";
import { withPreAuth } from "./hocs";
import {
  Login,
  MessageSend,
  MessageSend2,
  MessageSet,
  AnalysisSend,
  SummaryDashboard,
  Signup,
  FoundPassword,
  MyPageAccount,
  ChargePayment,
  ChargePayment2,
  AddressBookList,
  SignupInvitation,
  PaymentCompleteMobile,
} from "./pages";
import { useAppStore } from "./stores";

const MessageSendWithPreAuth = withPreAuth(MessageSend);
const MessageSendWithPreAuth2 = withPreAuth(MessageSend2);

export const App = () => {
  const store = useAppStore((s) => s);
  const [loading, setLoading] = useState<boolean>(true);
  const [Length, setLength] = useState(0);


  const iframeDiv = document.getElementById('lydian-iframediv');
  const iframe = document.getElementById('lydian-iframe');
  let aIframe: any;

  if (iframeDiv === null && iframe === null) {
      let iframeDiv = document.createElement("div");
      let closeDiv = document.createElement("div");
      let closebutton = document.createElement("button");

      iframeDiv.id = "lydian-iframediv";
      iframeDiv.className = "lydian-iframediv";
      document.body.appendChild(iframeDiv);

      aIframe = document.createElement("iframe");

      // aIframe.className = 'mobile-iframe';
      aIframe.id = "lydian-iframe";
      aIframe.src = "https://chat.learnex.kr/";
      iframeDiv.appendChild(closeDiv);
      closeDiv.appendChild(closebutton);

      iframeDiv.appendChild(aIframe);
      closeDiv.style.display = "flex";
      closeDiv.style.justifyContent = "flex-end";
      closeDiv.style.width = "100%";
      closeDiv.style.height = "30px";
      // closeDiv.style.paddingRight = "10px";
      closeDiv.style.background = "#3F51B5";
      closeDiv.style.borderRadius = "12px 12px 0 0";
      closebutton.style.cursor = "pointer";
      closebutton.textContent = "X"; // "X" 라벨 설정
      closebutton.style.border = "none";
      closebutton.style.color = "white";
      closebutton.style.width = "42px";
      closebutton.style.background = "transparent";
      closebutton.style.paddingRight = "18px";
      closebutton.onclick = function() {

        // 클릭 시 실행될 함수 정의
        iframeDiv.style.display = 'none';
        console.log("Button clicked!");
        // 또는 다른 동작 수행 가능
    };
      iframeDiv.style.display = "none";
      iframeDiv.style.position = "fixed";
      iframeDiv.style.bottom = "114px";
      iframeDiv.style.right = "22px";
      iframeDiv.style.width = "350px";
      iframeDiv.style.height = "580px";
      iframeDiv.style.zIndex = "5000";

      aIframe.style.height ="100%";
      aIframe.style.width = "100%";
      aIframe.style.border = "1px solid #989898";
      aIframe.style.borderRadius = "0 0 12px 12px";
      aIframe.style.boxShadow = "rgb(0 0 0 / 15%) 0px 4px 7px";
    
  }


  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      store.onLogin(accessToken);
    } else {
    }
    setLoading(false);
  }, []);





  function askNotificationPermission() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
        console.log('This browser does not support notifications.');
    } else {
        console.log('before request permission', Notification.permission);
        Notification.requestPermission().then((permission) => {
            console.log('permisssio', permission);
            //handlePermission(permission);
        });
    }
}



function notifyMe(title, body, roomId) {
  console.log('Notification.permission', Notification.permission);
  if (!('Notification' in window)) {
      // Check if the browser supports notifications
      alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
      const notification = new Notification(title, {
          body: body,
          icon: 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png',
          data: {
              roomId: roomId
          }
      });
      notification.onclick = (ev) => {
          console.log('click ev', ev);
          const lydianIframe: any = document.getElementById('lydian-iframe');
          const lydianIframeDiv: any = document.getElementById('lydian-iframediv');
          console.log('before postmessage', roomId);
          if (lydianIframeDiv.style.display === 'none') {
              // console.log('AAAAAAAAAAAAAAAAAAAAAAAAAA');
              lydianIframeDiv.style.display = 'block';
          }
          lydianIframe.contentWindow.postMessage(
              {
                  type: 'lydian_chat_enter_room',
                  roomId: roomId
              },
              '*'
          );
      };
      // …
  } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          console.log('then permission ', permission);
          if (permission === 'granted') {
              // const notification = new Notification(title, {
              //     body: body,
              //     icon: 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png'
              // });
              // …
          }
      });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}
class LydianEventResponse {
  customEvents: any;
  constructor() {
      // 미리 이벤트 정하고 정해진것만 추가
      this.customEvents = {};
      this.customEvents['lydian_chat_signup_res'] = new Set();
      this.customEvents['lydian_chat_signin_res'] = new Set();
      this.customEvents['lydian_chat_friends_res'] = new Set();
      this.customEvents['lydian_chat_invite_room_res'] = new Set();
      this.customEvents['lydian_chat_create_room_res'] = new Set();
      this.customEvents['lydian_chat_create_single_room_res'] = new Set();
      this.customEvents['lydian_chat_floating_res'] = new Set();
      this.customEvents['lydian_chat_update_myinfo_res'] = new Set();
      this.customEvents['lydian_chat_create_single_room_res'] = new Set();
  }

  // createCustomEvent(eventName) {
  //   if (!this.customEvents[eventName]) {
  //     this.customEvents[eventName] = new Set();
  //   }
  // }

  addLydianEventListener(eventName, listener) {
      if (!this.customEvents[eventName]) {
          //this.createCustomEvent(eventName);
      }
      this.customEvents[eventName].add(listener);
  }

  removeLydianEventListener(eventName, listener) {
      if (this.customEvents[eventName]) {
          this.customEvents[eventName].delete(listener);
      }
  }

  dispatchLydianEvent(eventName, data, error) {
      if (this.customEvents[eventName]) {
          this.customEvents[eventName].forEach((listener) => {
              listener(data, error);
          });
      }
  }
}
const lydianResponseEvent = new LydianEventResponse();


  function lydianInit() {
    const iframeDiv = document.getElementById('lydian-iframediv');
    const iframe = document.getElementById('lydian-iframe');
    let aIframe: any;
  
    if (iframeDiv === null && iframe === null) {
        let iframeDiv = document.createElement("div");
        let closeDiv = document.createElement("div");
        let closebutton = document.createElement("button");
  
        iframeDiv.id = "lydian-iframediv";
        iframeDiv.className = "lydian-iframediv";
        document.body.appendChild(iframeDiv);
  
        aIframe = document.createElement("iframe");
  
        // aIframe.className = 'mobile-iframe';
        aIframe.id = "lydian-iframe";
        aIframe.src = "https://chat.learnex.kr/";
        iframeDiv.appendChild(closeDiv);
        closeDiv.appendChild(closebutton);
  
        iframeDiv.appendChild(aIframe);
        closeDiv.style.display = "flex";
        closeDiv.style.justifyContent = "flex-end";
        closeDiv.style.width = "100%";
        closeDiv.style.height = "30px";
        // closeDiv.style.paddingRight = "10px";
        closeDiv.style.background = "#3F51B5";
        closeDiv.style.borderRadius = "12px 12px 0 0";
        closebutton.style.cursor = "pointer";
        closebutton.textContent = "X"; // "X" 라벨 설정
        closebutton.style.border = "none";
        closebutton.style.color = "white";
        closebutton.style.width = "100%";
        closebutton.style.paddingRight = "18px";
        closebutton.style.background = "transparent";
        closebutton.onclick = function() {
          // 클릭 시 실행될 함수 정의
          iframeDiv.style.display = 'none';
          console.log("Button clicked!");
          // 또는 다른 동작 수행 가능
      };
        iframeDiv.style.display = "none";
        iframeDiv.style.position = "fixed";
        iframeDiv.style.bottom = "150px";
        iframeDiv.style.right = "10px";
        iframeDiv.style.width = "350px";
        iframeDiv.style.height = "580px";
        iframeDiv.style.zIndex = "5000";
  
        aIframe.style.height ="100%";
        aIframe.style.width = "100%";
    }
    
  }
  useEffect(() => {
    askNotificationPermission();

    lydianInit()

    window.addEventListener('message', (e) => {
      try {
          //console.log("E = ", e)
          //const validTypeArray = ["lydian_chat_signup_res", "lydian_chat_signin_res", ]
          const parsedEvent = e.data;
          // console.log(e.data);
          const eventType = parsedEvent.type;
          setLength(Length + 1);
          if (eventType === 'lydian_notification') {
              const response = parsedEvent.response;
              if (response.ok) {
                  if (response.data) {
                      // console.log(response.data);
                      console.log(Length+1);
                      // console.log('noti data=!!!', response.data);
                      notifyMe(response.data.title, response.data.body, response.data.roomId);
                  }
              }
          } else if (eventType === 'lydian_chat_signin_res') {
              const response = parsedEvent.response;
              if (response.ok) {
                  if (response.data) {
                      lydianResponseEvent.dispatchLydianEvent('lydian_chat_signin_res', response.data, null);
                  } else {
                      // 응답 성공하면 데이터를 주게 되어있는데 백엔드 실수 있다면 안줄수도 있으니
                      lydianResponseEvent.dispatchLydianEvent('lydian_chat_signin_res', null, null);
                  }
              } else {
                  // 오류일때는 오류
                  lydianResponseEvent.dispatchLydianEvent('lydian_chat_signin_res', null, response.error);
              }
          }
      } catch (mesageParseError) {
          console.log('message parse error ', mesageParseError);
      }
  });

  }, [])

  if (loading) {
    return "";
  }
  return (
    <BrowserRouter>
      {store.isLoggedIn ? <LoggedIn /> : <LoggedOut />}
    </BrowserRouter>
  );
};

const LoggedOut = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signup/invitation/:code" element={<SignupInvitation />} />
      <Route path="/found/password" element={<FoundPassword />} />
      <Route
        path="/payment/complete/mobile"
        element={<PaymentCompleteMobile />}
      />
      <Route
        path="/message/send/:accessKey"
        element={<MessageSendWithPreAuth />}
      />
      <Route
        path="/message/send/v2/:accessKey"
        element={<MessageSendWithPreAuth2 />}
      />

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

const LoggedIn = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/summary/dashboard" element={<SummaryDashboard />} />

        <Route path="/message/send" element={<MessageSend />} />
        {/* <Route path="/message/send/v2" element={<MessageSend2 />} /> */}
        <Route path="/message/send/v2/:accessKey" element={<MessageSendWithPreAuth2 />} />
        {/* <Route path="/message/send/v3" element={<MessageSend2 />} /> */}
        <Route path="/message/set" element={<MessageSet />} />

        <Route path="/address-book/list" element={<AddressBookList />} />

        <Route path="/analysis/send" element={<AnalysisSend />} />

        {/* <Route path="/charge/payment" element={<ChargePayment />} /> */}
        <Route path="/charge/payment" element={<ChargePayment2 />} />

        <Route path="/mypage/account" element={<MyPageAccount />} />
        <Route
          path="/payment/complete/mobile"
          element={<PaymentCompleteMobile />}
        />

        <Route
          path="*"
          element={<Navigate replace to="/summary/dashboard" />}
        />
      </Routes>
    </Layout>
  );
};
