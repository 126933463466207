import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ConfigProvider, message } from "antd";
import "antd/dist/antd.css";
import koKR from "antd/lib/locale/ko_KR";
import dayjs from "dayjs";
import { createRoot } from "react-dom/client";
import { Hydrate, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import { App } from "./App";
import { queryClient } from "./react-query";
import { GlobalSyles } from "./styles";

import "dayjs/locale/ko";
dayjs.locale("ko");

message.config({
  duration: 5,
});


const container = document.getElementById("root");

const dehydratedState = (window as any).__REACT_QUERY_STATE__;

console.log("dehydratedState: ", dehydratedState);


if (container) {
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <QueryClientProvider client={queryClient} contextSharing>
      <Hydrate state={dehydratedState}>
        <ConfigProvider locale={koKR}>
          <ThemeProvider theme={{}}>
            <App />
            <GlobalSyles />
          </ThemeProvider>
        </ConfigProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}
