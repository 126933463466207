import { useMutation, useQuery } from "@tanstack/react-query";
import { Input, message, Popover, Tag } from "antd";
import dayjs from "dayjs";
import { ChangeEvent } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import styled from "styled-components";
import { PaymentHistoryResponseData } from "../generated";
import { privateApi } from "../react-query";
import { useChargePaymentStore } from "../stores";
import { IComponentProps } from "../types";
import { Formatter, isSuccess, Pattern } from "../utils";
import { AntdButton } from "./AntdButton";
import { AntdTable } from "./AntdTable";

interface IProps extends IComponentProps {
  /** 환불가능금액 */
  repaymentableAmount: number;
}

const text = `[환불유의사항]
* 구매시 결제한 수단으로만 환불 가능.
- 상단 리스트에 표시되지 않는 거래 건은 'PG사(토스페이먼츠) 환불기간 정책'에 따라 환불 불가합니다.
* 환불가능기간
- 카드:  결제일로 부터 3개월 이내
- 가상계좌: 결제일로 부터 12개월 이내
`;
const text2 = `* 구매시 결제한 수단으로만 환불 가능.\n- 상단 리스트에 표시되지 않는 거래 건은 'PG사(토스페이먼츠) 환불기간 정책'에 따라 환불 불가합니다.
 
`;
const text3 = ` - 상단 리스트에 표시되지 않는 거래 건은 'PG사(토스페이먼츠) 환불기간 정책'에 따라 환불 불가합니다.
`;
const text4 = `
* 환불가능기간
`;
const text5= `
- 카드:  결제일로 부터 3개월 이내
- 가상계좌: 결제일로 부터 12개월 이내
`;

export const RepaymentModalContent: React.FC<IProps> = ({
  repaymentableAmount,
}) => {
  const store = useChargePaymentStore();
  const {
    isLoading: loadingGetPayment,
    data: dataGetPayment,
    refetch: refetchGetPayment,
  } = useQuery(
    ["paymentControllerRead"],
    privateApi.payment.paymentControllerRead,
    {
      enabled: store.modalRepayment.visible,
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          store.formModalRepayment.onChangeForm({
            payments: e.data.body.data.map((v) => ({
              ...v,
              balance: v.refundableBalance,
            })),
          });
          console.log("paymentControllerRead onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("paymentControllerRead onError: ", e);
      },
    }
  );

  const { isLoading: loadingCancelPayment, mutate: mutateCancelPayment } =
    useMutation(privateApi.payment.paymentControllerCancel, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("paymentControllerCancel onSuccess: ", e);
          message.info("환불되었습니다");
          refetchGetPayment();
        }
      },
      onError: (e) => {
        console.log("paymentControllerCancel onError: ", e);
      },
    });

    /**
     * [환불신청 금액] 변경시
     */
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = e;
      const updatedPayments = store.formModalRepayment.form.payments.map(v => {
        if(v.paymentHistoryIdx === +name) {
          return {
            ...v,
            balance: Number(value),
          };
        }
        return v;
      });
      store.formModalRepayment.onChangeForm({
        payments: updatedPayments,
      });
    };

  /**
   * [환불] 버튼 클릭시
   */
  const onClickRepayment = (id: number) => {
    const {form} =store.formModalRepayment;
    const payment = form.payments.find((v) => v.paymentHistoryIdx === id);
    if(payment) {
      if (
        !Pattern.checkOptional(Pattern.숫자).test(payment.balance + "") ||
        +payment.balance < 0
      ) {
        return message.warn("환불신청 금액은 0보다 큰 숫자로 입력해주세요");
      }
        if (payment.balance > payment.refundableBalance) {
          return message.warn(
            "환불신청 금액은 환불가능 금액이하로 입력해주세요"
          );
        } 
      mutateCancelPayment({
        paymentHistoryIdx: id,
        cancelAmount: payment.balance,
      });
    }
  }

  return (
    <Container>
      <Header>
        이용 가능 금액 : <span>{Formatter.통화(repaymentableAmount)}</span>원 <br />{" "}
        {/* <Popover
          placement="top"
          content="이용금액 결제시 부가세가 포함된 금액이 결제되었으므로, 환불시
          잔액에 부가세가 가산되어 환불됩니다."
          >
          <AiOutlineQuestionCircle size={16} color="#ababab" />
        </Popover> */}
      </Header>
      <AntdTable
        loading={loadingGetPayment}
        style={{
          minHeight: 300,
          borderTop: "1px solid #dfdfdf",
          borderBottom: "1px solid #dfdfdf",
        }}
        tableLayout="fixed"
        scroll={{ x: 520, y: 310 }}
        rowKey="paymentHistoryIdx"
        dataSource={store.formModalRepayment.form.payments}
        pagination={false}
        columns={[
          {
            title: "결제일자",
            dataIndex: "impDate",
            key: "impDate",
            fixed: true,
            align: "center",
            render: (e) => {
              const date = dayjs(e);
              return (
                <>
                  <p>{date.format("YYYY-MM-DD")}</p>
                </>
              );
            },
          },
          {
            title: "결제수단",
            dataIndex: "payMethod",
            key: "payMethod",
            align: "center",
            render: (e) => <Tag>{e === "card" ? "카드" : "휴대폰"}</Tag>,
          },
          {
            title: "결제금액",
            dataIndex: "paidAmount",
            key: "paidAmount",
            align: "center",
            render: (e) => {
              return Formatter.통화(e) + "원";
            },
          },
          {
            title: "환불가능 금액",
            dataIndex: "refundableBalance",
            key: "refundableBalance",
            align: "center",
            render: (e) => {
              return Formatter.통화(e) + "원";
            },
          },
          {
            title: "환불신청 금액",
            dataIndex: "balance",
            key: "balance",
            align: "center",
            render: (e, val: PaymentHistoryResponseData) => {
              return (
                <p style={{ display: "flex", alignItems: "center", gap: 6 }}>
                  <Input
                    value={e}
                    name={val.paymentHistoryIdx + ""}
                    onChange={onChange}
                  />
                  원
                </p>
              );
            },
          },
          {
            title: "환불신청",
            dataIndex: "paymentHistoryIdx",
            key: "paymentHistoryIdx",
            align: "center",
            render: (e) => {
              return (
                <AntdButton
                  loading={loadingCancelPayment}
                  onClick={() => onClickRepayment(e)}
                >
                  환불
                </AntdButton>
              );
            },
          },
        ]}
      />

      <p style={{fontWeight:200, fontSize:12, color:"red", width:"100%", textAlign:"right", marginTop: "20px"}}>* 환불가능금액은 <b>이용가능금액에 부가세 10%가 가산</b>되어 계산됩니다.</p>
      <Detail>
        <div className="detailCont">
          <h4>[환불 유의사항]</h4>
          <p>* 카드사 정책에 따라 아래와 같이 구매 금액이 제한됩니다.</p>
          <div className="detailList">
            <span> - 상단 리스트에 표시되지 않는 거래 건은 'PG사(토스페이먼츠) 환불기간 정책'에 따라 환불 불가합니다.</span>
          </div>
          <p>* 환불가능기간</p>
          <div className="detailList">
            <span> - 카드: 3개월 이내</span>
            <br/>
            <span> - 가상계좌: 12개월 이내</span>
          </div>
          <p className="redTxt">*구매시 결제한 수단으로만 환불 가능.</p>
        </div>

   {/* {text} */}
     </Detail>
    </Container>
  );
};

const Container = styled.div``;
const Header = styled.div`
  text-align: center;
  gap: 10px;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  span {
    color: #36b936;
    padding: 0 2px 0 11px;
    font-size: 20px;
  }
`;
const Detail = styled.div`
  white-space: pre-wrap;
  font-size: 12px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px;
  background-color: #f7f7f7;
  margin-top: 9px;
  .detailCont {
    h4 {
      font-weight: 600;
      font-size: 11px;
      margin-bottom:4px;
    }
    p {
      font-size: 11px;
      color: #f18631;
    }
    .redTxt {
      color: red;
      font-weight:bold;
    }
    .detailList {
      font-size: 12px;
      padding: 0 5px;
      margin: 4px 0 8px;
    }
  }
`;
