import styled from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const LayoutCell: React.FC<IProps> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};
const Container = styled.div`
  flex: 1;
  background-color: white;
  padding: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
  border-radius: 6px;
  &.last-cell {
    max-width: 450px;
  }

  @media ${device.laptop.max} {
    &.last-cell {
      max-width: 100%;
    }
    border-radius: 6px;
  }
`;
