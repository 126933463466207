import { Form, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useMessageSendStore, useMessageSetStore } from "../stores";
import { IComponentProps } from "../types";
import { getByte } from "../utils";
import { ByteText } from "./ByteText";

interface IProps extends IComponentProps {
  /** [메시지 저장하기] 팝업의 경우 true반환  */
  isSavedMessage?: boolean;
}

export const AddedMessageModalContent: React.FC<IProps> = ({
  isSavedMessage = false,
}) => {
  const store = useMessageSetStore((s) => s);
  const sendedMessageStore = useMessageSendStore((s) => s);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      if (isSavedMessage) {
        sendedMessageStore.formReqMessage.onChangeForm({
          addSmsQuery: {
            ...sendedMessageStore.formReqMessage.form.addSmsQuery,
            [name]: value,
          },
        });
      } else {
        store.formSms.onChangeForm({
          addSmsQuery: {
            ...store.formSms.form.addSmsQuery,
            [name]: value,
          },
        });
      }
    },
    [
      store.formSms.form.addSmsQuery,
      sendedMessageStore.formReqMessage.form.addSmsQuery,
    ]
  );

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    const {
      target: { checked },
    } = e;
    if (isSavedMessage) {
      sendedMessageStore.formReqMessage.onChangeForm({
        addSmsQuery: {
          ...sendedMessageStore.formReqMessage.form.addSmsQuery,
          isAd: checked ? 1 : 0,
        },
      });
    } else {
      store.formSms.onChangeForm({
        addSmsQuery: {
          ...store.formSms.form.addSmsQuery,
          isAd: checked ? 1 : 0,
        },
      });
    }
  };

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="메시지명">
          <Input
            autoFocus
            placeholder="메시지명을 입력해주세요"
            name="templateName"
            value={
              isSavedMessage
                ? sendedMessageStore.formReqMessage.form.addSmsQuery
                    .templateName
                : store.formSms.form.addSmsQuery.templateName
            }
            onChange={onChange}
          />
        </Form.Item>
        <Form.Item required label="문자내용">
          <Input.TextArea
            placeholder="문자내용을 입력해주세요"
            rows={8}
            name="templateContent"
            value={
              isSavedMessage
                ? sendedMessageStore.formReqMessage.form.addSmsQuery
                    .templateContent
                : store.formSms.form.addSmsQuery.templateContent
            }
            onChange={onChange}
            maxLength={2000}
          />
          <ByteText
            checked={
              isSavedMessage
                ? Boolean(
                    sendedMessageStore.formReqMessage.form.addSmsQuery?.isAd
                  )
                : Boolean(store.formSms.form.addSmsQuery?.isAd)
            }
            onChangeCheckbox={onChangeCheckbox}
            length={
              isSavedMessage
                ? getByte(
                    sendedMessageStore.formReqMessage.form.addSmsQuery
                      .templateContent
                  )
                : getByte(store.formSms.form.addSmsQuery.templateContent)
            }
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div``;

const CheckboxSapce = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const ByteItem = styled.div`
  font-size: 12px;
`;
