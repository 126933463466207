import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../device";
import { useAppStore } from "../stores";
import { IComponentProps } from "../types";
import { LayoutNotice } from "./LayoutNotice";
import { LayouRow } from "./LayoutRow";
import { Navbar } from "./Navbar";
import { Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import { isSuccess } from "../utils";
import { privateApi } from "../react-query";
import { ChatButton } from "./ChatButton";

interface IProps extends IComponentProps {}

let currentVisibleMenu = window.innerWidth > 1200;

const visibleDate = window.localStorage.getItem("x-nt");

const defaultVisibleNotice =
  !visibleDate || !(Number(visibleDate) > Date.now());

export const Layout: React.FC<IProps> = ({ children }) => {
  const ref = useRef<any>();
  const [visibleMenu, setVisibleMenu] = useState<boolean>(
    window.innerWidth > 1200
  );
  const [visibleNotice, setVisibleNotice] =
    useState<boolean>(false);
  const store = useAppStore((s) => s);

  const location = useLocation();
  const onToggleVisibleMenu = useCallback(() => {
    setVisibleMenu((v) => {
      currentVisibleMenu = !v;
      return !v;
    });
  }, []);
  const fetcherGetMember = () =>
    privateApi.member.memberControllerReadCurrentMember();

  const { isLoading: loadingGetMember, data: dataGetMember } = useQuery(
    ["memberControllerReadCurrentMember"],
    fetcherGetMember,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerReadCurrentMember onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("memberControllerReadCurrentMember onError: ", e);
      },
    }
  );


  const onCloseVisibleNotice = () => {
    setVisibleNotice(false);
    window.localStorage.setItem(
      "x-nt",
      dayjs().add(1, "day").toDate().getTime() + ""
    );
  };

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setVisibleMenu(false);
      currentVisibleMenu = false;
    }
  }, [location.pathname]);
  useEffect(() => {

    console.log(store.isAccessKey)
    console.log(store.isPopup)
  }, [store.isPopup]);


  function lydian_chat_signin(systemKey : any, email: any, password: any) {
    const aIframe: any = document.getElementById('lydian-iframe');
    console.log(aIframe)
    if (systemKey && email && password) {
        if (aIframe !== null) {
            aIframe.contentWindow.postMessage(
                {
                    type: 'lydian_chat_signin',
                    id: email,
                    password: password,
                    systemKey: systemKey
                },
                '*'
            );
            // sessionStorage.removeItem('ID');
            // sessionStorage.removeItem('Pass');
        }
    }
}




  useEffect(() => {
  
    
    console.log(dataGetMember)
    sessionStorage.setItem("CIdx", dataGetMember?.data.body.data.companyIdx)
    lydian_chat_signin(`sendit_${dataGetMember?.data.body.data.companyIdx}`, dataGetMember?.data.body.data.userId, "1234");

   //계정 권한, 100:마스터(개인), 101:일반(개인) 900:마스터(기업), 901:일반(기업)


  }, [dataGetMember]);

  useEffect(() => {
    function resize(e: any) {
      clearTimeout(ref.current);
      ref.current = setTimeout(() => {
        if (currentVisibleMenu && window.innerWidth < 1200) {
          currentVisibleMenu = false;
          setVisibleMenu(false);
        } else if (!currentVisibleMenu && window.innerWidth >= 1200) {
          currentVisibleMenu = true;
          setVisibleMenu(true);
        }
        console.log("resize: ", window.innerWidth);
      }, 300);
    }
    window.addEventListener("resize", resize);
  }, []);


  const roleClass = dataGetMember?.data.body.data.userRole === '900' || dataGetMember?.data.body.data.userRole === '100' ? 'master' : 'normal';

  return (
    <Container>
      <LayoutNotice
        visibleNotice={visibleNotice}
        onCloseVisibleNotice={onCloseVisibleNotice}
      />
      <Wrapper className={`${visibleNotice ? "" : "hide-notice"}`}>
        <Navbar visibleMenu={visibleMenu} />
        <Content
          className={`${visibleMenu ? "" : "hide-active"} ${
            store.isAccessKey ? "hide-active" : store.isPopup ? "hide-active" : ""
          }`}
        >
          <ExtenedLayoutRow className="content-header">
            <ContentMenu className={`${
            store.isAccessKey ? "hide-active" : store.isPopup ? "hide-active" : ""
          }`}>
            <div style={{display:"flex" ,justifyContent:"space-between" ,width: visibleMenu ? "calc(100% - 250px)":"100%" }}>
              <IconBox
                className={!visibleMenu ? "active" : ""}
                onClick={onToggleVisibleMenu}
              >
                <HiOutlineMenuAlt2 size={25}></HiOutlineMenuAlt2>
              </IconBox>

              <div className="userNameCont">
                <img src="/images/icon-user.svg" alt="" className="userIcon"/>
                <Typography>
                  {dataGetMember?.data.body.data.userName}
                  <span className={roleClass}>
                    {dataGetMember?.data.body.data.userRole === "900"  || dataGetMember?.data.body.data.userRole === "100" ? "마스터" : "일반"}
                  </span>
                </Typography>
              </div>
            
            </div>
              
            </ContentMenu>
            
          </ExtenedLayoutRow>
        
          <ContentCell>{children}</ContentCell>
          {/* {children} */}
        </Content>
        <MobileBg
          className={`${visibleMenu ? "" : "hide-active"}  ${
            store.isAccessKey ? "hide-active" : store.isPopup ? "hide-active" : ""
          }`}
          onClick={onToggleVisibleMenu}
        />
        <ChatButton/>

      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  /* min-height: 100vh; */
  height: 100%;
`;
const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  /* min-height: 100vh; */
  height: calc(100% - 42.42px);
  overflow-y: hidden;
  &.hide-notice {
    height: 100%;
  }
  /*
  @media ${device.laptop.max} {
    height: 100%;
  } */
`;
const ContentCell = styled.div`
  /* max-height: calc(100% - 55px); */
  /* overflow-y: auto; */
  /* min-height: 100%; */
  margin-top: 55px;
  padding-top: 20px;

  @media ${device.laptop.max} {
    & > div {
      /* padding-bottom: 20px; */
    }
    margin-top: 0px;
    padding-top: 20px;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  background-color: #f2f4f8;
  transition: 0.2s;
  max-height: 100%;
  /* max-height: 100vh; */
  overflow-y: auto;
  margin-left: 0;
  min-height: 100%;
  padding-bottom: 30px;
  @media ${device.laptop.min} {
    &.hide-active {
      margin-left: -250px;
    }
  }

  @media ${device.laptop.max} {
    padding-bottom: 20px;
  }
`;

const ExtenedLayoutRow = styled(LayouRow)`
  height: auto;
  max-width: initial;
  @media ${device.laptop.max} {
    position: sticky;
    z-index: 4;
    top: 0;
    /* padding: 0 10px; */
    background-color: inherit;
  }
`;

const ContentMenu = styled.div`
  display: flex;
  padding: 10px 0;
  &.hide-active {
    display : none;
  }
  @media ${device.laptop.max} {
  }
  .userNameCont {
    display: flex;
    align-items: center;
    img { 
      width: 26px;
      margin-right: 6px;
    } 
    .master {
      color: #c41d7f;
      background: #fff0f6;
      border: 1px solid #ffadd2;
      box-sizing: border-box;
      margin: 0 0 0 4px;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      display: inline-block;
      height: auto;
      padding: 0 5px;
      font-size: 11px;
      line-height: 21px;
      white-space: nowrap;
      border-radius: 18px;
      opacity: 1;
      transition: all 0.3s;
    }
    .normal {
      color: #1d39c4;
      background: #f0f5ff;
      border: 1px solid #adc6ff;
      box-sizing: border-box;
      margin: 0 0 0 4px;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      display: inline-block;
      height: auto;
      padding: 0 5px;
      font-size: 11px;
      line-height: 21px;
      white-space: nowrap;
      border-radius: 18px;
      opacity: 1;
      transition: all 0.3s;
    }
  }
  
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  width: min-content;
  border-radius: 50%;
  transition: 0.2s;
  svg {
    color: #484848;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &.active {
    svg {
      color: var(--primary-color);
    }
  }
`;

const MobileBg = styled.div`
  display: none;
  @media ${device.laptop.max} {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100vw;
    bottom: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.14);
    &.hide-active {
      display: none;
    }
  }
`;
