import styled from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const LayoutContent: React.FC<IProps> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

const Container = styled.div`
  /* border: 1px solid #f0f0f0; */
  border-top: 0;
  /* background-color: white; */
  /* border-radius: 7px; */
  /* background-color: rgb(255, 255, 255); */
  /* box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px; */
  padding: 0;

  @media ${device.laptop.max} {
    /* padding: 0; */
    box-shadow: none;
    /* padding: 0; */
  }
`;
