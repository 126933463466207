
import { useEffect, useMemo,useState } from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import styled from "styled-components";
import { useSummaryDashboardStore } from "../stores";
import { IComponentProps } from "../types";
import { linearGradientDef } from "@nivo/core";
import { device } from "../device";
import { Formatter } from "../utils";
import dayjs from "dayjs";

interface IProps extends IComponentProps {}

export const DashboardServiceStats: React.FC<IProps> = () => {
  const store = useSummaryDashboardStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  useEffect(() => {
    const text = document.getElementsByTagName("text");
    if (text) {
      // console.log("text.length: ", text.length);
      for (var i = 0; i < text.length; i++) {
        var status = text[i].getAttribute("dominant-baseline");
        if (status == "text-before-edge") {
          // const onClicked = () => {
          //   console.log("onCLocked");
          //   text.item(i)?.classList.add("text-line-active");
          // };
          text.item(i)?.addEventListener("click", (e: any) => {
            document
              .querySelector(".text-line-active")
              ?.classList.remove("text-line-active");
            console.log("e.target: ", e.target);
            e.target.classList.add("text-line-active");
            const requestDate = e.target.innerHTML;
            const selectedMessageStats = store.messageStats.find(
              (v) => windowsize ? dayjs(v.requestDate).format("MM-DD") === requestDate  : v.requestDate === requestDate
            );
            store.formMessageStats.onChangeForm({
              selectedMessageStats,
            });
          });
        }
      }
    }

    return () => {
      // store.onReset();
    };
  }, [store.messageStats]);

  const serie = useMemo(() => {
    const sms: Serie = {
      id: "SMS",
      color: "var(--sms-color)",
      data: [],
    };
    const lms: Serie = {
      id: "LMS",
      color: 'var(--lms-color)',
      data: [],
    };
    const mms: Serie = {
      id: "MMS",
      color: "var(--mms-color)",
      data: [],
    };
    const alimtalk: Serie = {
      id: "알림톡",
      color: "var(--talk-color)",
      data: [],
    };

    store.messageStats.reverse().forEach((v) => {
      sms.data.push({
        x:  windowsize? dayjs(v.requestDate).format("MM-DD") : v.requestDate,
        y: +v.smsSent,
      });
      lms.data.push({
        x:  windowsize? dayjs(v.requestDate).format("MM-DD") : v.requestDate,
        y: +v.lmsSent,
      });
      mms.data.push({
        x: windowsize? dayjs(v.requestDate).format("MM-DD") : v.requestDate,
        y: +v.mmsSent,
      });
      alimtalk.data.push({
        x: windowsize? dayjs(v.requestDate).format("MM-DD") : v.requestDate,
        y: +v.alimtalkSent,
      });
    });
    return [alimtalk, mms, lms, sms];
  }, [store.messageStats]);

  // console.log("store.messageStats: ", store.messageStats);
  // console.log("serie: ", serie);

  return (
    <Container className="nivo-wrapper">
      <ResponsiveLine
        isInteractive
        data={serie}
        theme={ windowsize? {fontSize : 9} : {fontSize : 11 }}
        margin={windowsize? { top: 60, right:60, bottom: 100, left: 60 } : { top: 50, right:110, bottom: 50, left: 60 } }
        xScale={{ type: "point" }}
        colors={{ datum: "color" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          // stacked: true,
          reverse: false,
        }}
        // yFormat=" >-.2f"
        // yFormat=" = .0"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "transportation",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          // orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "count",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={10}
        // pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        animate
        useMesh
        // enableGridX
        enableCrosshair
        enablePointLabel
        // enableArea
        // crosshairType="x"
        enablePoints={false}
        enableGridX={false}
        crosshairType="x"
        enableSlices="x"
        enableArea={false}
        // tooltip={(props: any) => {
        //   return (
        //     <div
        //       style={{
        //         padding: 12,
        //         textAlign: "center",
        //         color: "white",
        //         background: "#343745",
        //         borderRadius: 3,
        //         // background: props.point.serieColor,
        //       }}
        //     >
        //       <h4 style={{ marginBottom: -3, fontSize: 14 }}>
        //         {props.point.data.x}
        //       </h4>
        //       <p style={{ fontSize: 13 }}>
        //         <strong>{Formatter.통화(props.point.data.y)}</strong>
        //       </p>
        //     </div>
        //   );
        // }}

        // pointLabel={(v) => v.y + "K"}
        // pointLabel={(v) => v.y ? v.y + "K" : ""}
        // pointLabel={(v) => v.y}
        markers={
          store.formMessageStats.form.selectedMessageStats
            ? [
                {
                  legendPosition: "top",
                  axis: "x",
                  value:
                  windowsize?  dayjs(store.formMessageStats.form.selectedMessageStats
                    .requestDate).format("MM-DD")
                  :store.formMessageStats.form.selectedMessageStats
                  .requestDate,
                   
                  lineStyle: { stroke: "#ff8d00", strokeWidth: 2 },
                  legend:
                    store.formMessageStats.form.selectedMessageStats
                      .requestDate + "",
                  textStyle: {
                    fontSize: 12,
                    fill: "#ff8d00",
                    transform: "translateY(-30px)",
                  },
                },
                // {
                //   legendPosition: "right",
                //   axis: "y",
                //   value:
                //     +store.formMessageStats.form.selectedMessageStats.smsSent,
                //   lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
                //   legend: "SMS",
                //   // legendOrientation: "vertical",
                // },
                // {
                //   legendPosition: "right",
                //   axis: "y",
                //   value:
                //     +store.formMessageStats.form.selectedMessageStats.lmsSent,
                //   lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
                //   legend: "LMS",
                //   // legendOrientation: "vertical",
                // },
                // {
                //   legendPosition: "right",
                //   axis: "y",
                //   value:
                //    +store.formMessageStats.form.selectedMessageStats
                //       .alimtalkSent,
                //   lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
                //   legend: "알림톡",
                //   // legendOrientation: "vertical",
                // },
                // {
                //   legendPosition: "right",
                //   axis: "y",
                //   value:
                //     +store.formMessageStats.form.selectedMessageStats.mmsSent,
                //   lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
                //   legend: "MMS",
                //   // legendOrientation: "vertical",
                // },
              ]
            : undefined
        }
        legends={[
          {
            anchor: windowsize? "bottom" : "top-right",
            direction: windowsize? "row" : "column",
            justify: false,
            translateX: windowsize? 20 : 100,
            translateY: windowsize? 65 : 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            // symbolShape: "circle",
            // symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  max-height: 350px;
  // TODO : 수정하기.
  @media ${device.laptop.max} {
    & > div {
      height: 300px !important;
      & > div {
        & > svg {
          height: 300px;
        }
      }
    }
  }
  .text-line-active {
    fill: #0039a9 !important;
  }
  text[dominant-baseline="text-before-edge"] {
    cursor: pointer;
    &.text-line-active {
      fill: #0039a9 !important;
      font-weight: bold;
      position: relative;
      text-decoration: underline;
    }
  }
`;