import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Form,
  Input,
  message,
  Popover,
  Radio,
  RadioChangeEvent,
  Select,
  Tag,
  Upload,
  AutoComplete
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RangePickerProps } from "antd/lib/date-picker";
import { RcFile } from "antd/lib/upload";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useRef,useState } from "react";
import { Helmet } from "react-helmet";
import {
  AiOutlineCloseCircle,
  AiOutlineQuestionCircle,
  AiOutlineReload,
} from "react-icons/ai";
import {
  BiMessageDetail,
  BiPhoneCall
} from "react-icons/bi";
import { BsExclamationCircle } from "react-icons/bs";
import { MdOutlinePriorityHigh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAccessToken } from "../auth";
import {
  AddedMessageModalContent,
  AddressBookModalContent,
  AntdButton,
  AntdDatePicker,
  AntdModal,
  AntdTable,
  ByteText,
  ByteTextformessage2,
  LayouRow,
  LayoutCell,
  LayoutContent,
  MessageOrTemplateModalContent,
} from "../components";
import { device } from "../device";
import {
  AttachedResponseDTO,
  CompanyCertKakaoCreateDTO,
  ContentType,
  CustormerControllerAddToListParams,
  CustormerReadResponseData,
  MessageSenderAlimtalkDTO,
  MessageSenderMmsDTO,
  MessageSenderSmsDTO,
  RecipientList,
  SmsTemplateCreateDTO,
  SmsControllerReadAllTemplateParams
} from "../generated";
import { privateApi } from "../react-query";
import {
  useAppStore,
  useMessageSendStore,
  useMessageSetStore,
  useAddressBookListStore
} from "../stores";
import { getByte, isSuccess, Pattern } from "../utils";
interface IProps {}

/**
 *
 *  SMS : 90byte 미만
 *  MMS : 90byte 이상 or 첨부파일 업로드
 */

// const autoResizeTextarea = () => {
//   let textarea = document.querySelector('.autoTextarea') as any;

//   if (textarea) {
//     textarea.style.height = 'auto';
//     let height = textarea.scrollHeight; // 높이
//     textarea.style.height = `${height + 6}px`;
//   }
// };

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};



const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherGetSMSs = async (params: SmsControllerReadAllTemplateParams) =>
privateApi.sms.smsControllerReadAllTemplate(params);
const fetcherGetMessage = () =>
  privateApi.message.messageControllerReadAll({
    sortBy: "requestDate",
    sortOrder: "DESC",
    messageType: "all",
    statisticsType: "daily",
    page: 1,
    pageSize: 1,
    startRequestDate: dayjs().add(-1, "day").format("YYYY-MM-DD"),
    endRequestDate: dayjs()
      .add(+1, "day")
      .format("YYYY-MM-DD"),
  });

const fetcherSendSMS = (params: MessageSenderSmsDTO) =>
  privateApi.message.messageControllerSmsSender(params);

const fetcherSendMMS = (params: MessageSenderMmsDTO) =>
  privateApi.message.messageControllerMmsSender(params);

const fetcherSendAlimtalk = (params: MessageSenderAlimtalkDTO) =>
  privateApi.message.messageControllerAlimtalkSender(params);

const fetcherFileUpload = (params: any) => {
  return privateApi.message.messageControllerMmsAttached(params);
};

// REVIEW : 발신프로필 추가 API 작성 필요.
const fetcherCreateKakaoProfile = (params: CompanyCertKakaoCreateDTO) =>
  privateApi.company.companyControllerCreateKakaoProfile(params);

const fetcherUploadExcel = (params: { files: File }) =>
  privateApi.custormer.custormerControllerExcelAttached(params);

const fetcherAddSms = (params: SmsTemplateCreateDTO) =>
  privateApi.sms.smsControllerCreateTemplate(params);

const fetcherDownloadExcel = () =>
  privateApi.custormer.custormerControllerDownload({ format: "blob" });

const fetcherAddTodoList = (params: CustormerControllerAddToListParams) =>
  privateApi.custormer.custormerControllerAddToList({
    custormerPhone1: params.custormerPhone1,
  });
  interface List {
  
    custormerIdx: number;
 
    custormerName: string;
  
    custormerPhone1: string;
  
  }

export const MessageSend2: React.FC<IProps> = () => {
  var urlStr = window.location.href;
  var url = new URL(urlStr);
  var urlPrm = url.searchParams;
  var PhNum : any = urlPrm.get(`phone`) as String; // teacher id

  const appStore = useAppStore((s) => s);
  const store = useMessageSendStore((s) => s);
  const messageSetStore = useMessageSetStore((s) => s);
  // const preventClose = (e: BeforeUnloadEvent) => {
  //   e.preventDefault();
  //   var dialogText = 'asdasd';
  //   e.returnValue = dialogText;
  //   // e.returnValue = ""; //Chrome에서 동작하도록; deprecated
  //   return ()=>{
  //     console.log("새로고침")
  //   };
  // };
   

  const navigate = useNavigate();
  const [array,setArray] = useState<CustormerReadResponseData[]>([]);
  const [array2,setArray2] = useState<React.Key[]>([]);
  const [clicked,setClicked] = useState(false);
  const [sendclicked,setsendClicked] = useState(false);

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    appStore.onChangeIsPopup(true);
    mutateAddTodoList({ custormerPhone1: PhNum })
    mutateGetMessages({
      page : 1,
      pageSize : 25
    });
  }, []);
  // useEffect(() => {
  //   console.log(appStore.isPopup)
  // }, [appStore.isPopup]);

  // const { isLoading: loadingGetSMSs, data: dataGetSMSs } = useQuery(
  //   ["smsControllerReadAllTemplate"],
  //   () =>
  //     fetcherGetSMSs({
  //       page: 1,
  //       pageSize: 1000,
  //     }),
  //   {
  //     enabled: store.modalLoadMessage.visible,
  //     onSuccess: (e) => {
  //       if (isSuccess(e.data.header)) {
  //         console.log("smsControllerReadAllTemplate onSuccess: ", e);
  //       }
  //     },
  //     onError: (e) => {
  //       console.log("smsControllerReadAllTemplate onError: ", e);
  //     },
  //   }
  // );
  const { isLoading: loadingAddTodoList, mutate: mutateAddTodoList } =
    useMutation(fetcherAddTodoList, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("custormerControllerAddToList onSuccess: ", e);
          store.onAddReceivedNumber({
            custormerIdx: e.data.body.data.custormerIdx,
            custormerName: e.data.body.data.custormerName,
            custormerPhone1: e.data.body.data.custormerPhone1,
          });
      //      store.onAddReceivedList({
      //       custormerIdx: e.data.body.data.custormerIdx,
      //       custormerName: e.data.body.data.custormerName,
      //       custormerPhone1: e.data.body.data.custormerPhone1,
      // })
        }
      },
      onError: (e) => {
        console.log("custormerControllerAddToList onError: ", e);
      },
    });
  const {
    isLoading: loadingGetMember,
    data: dataGetMember,
    refetch,
  } = useQuery(["memberControllerReadCurrentMember"], fetcherGetMember, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("memberControllerReadCurrentMember onSuccess: ", e);
        const { certKakaoInformation, certPhoneInformation } =
          e.data.body.data.companyInformation;
        certPhoneInformation[0]?.phoneCertFlag === 1?
        store.formReqMessage.onChangeForm({
          selectedPhone : certPhoneInformation[0],
        })
        :
        store.formReqMessage.onChangeForm({
         
        });
        certKakaoInformation[0]?.status === "YSC03"?
        store.formReqMessage.onChangeForm({
          selectedProfile : certKakaoInformation[0],
        })
        :
        store.formReqMessage.onChangeForm({
         
        });
        return;
      }
      return message.error(e.data.header.resultMessage);
    },
    onError: (e) => {
      console.log("memberControllerReadCurrentMember onError: ", e);
    },
  });

  const {
    isLoading: loadingGetMessage,
    data: dataGetMessage,
    mutate: mutateGetMessage,
  } = useMutation(fetcherGetMessage, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("messageControllerReadAll onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("messageControllerReadAll onError: ", e);
    },
  });

  const { data : dataGetSMSs, mutate: mutateGetMessages } = useMutation(
    fetcherGetSMSs,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerSmsSender onSuccess: ", e);
        
        }
      },
      onError: (e) => {
        console.log("messageControllerSmsSender onError: ", e);
      },
    }
  );
  const { isLoading: loadingSendSms, mutate: mutateSendSms } = useMutation(
    fetcherSendSMS,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerSmsSender onSuccess: ", e);
          message.info("전송되었습니다");
          mutateGetMessage();
        }
      },
      onError: (e) => {
        console.log("messageControllerSmsSender onError: ", e);
      },
    }
  );

  const { isLoading: loadingSendMms, mutate: mutateSendMms } = useMutation(
    fetcherSendMMS,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerMmsSender onSuccess: ", e);
          message.info("전송되었습니다");
          mutateGetMessage();
        }
      },
      onError: (e) => {
        console.log("messageControllerMmsSender onError: ", e);
      },
    }
  );

  const { isLoading: loadingSendAlimtalk, mutate: mutateSendAlimtalk } =
    useMutation(fetcherSendAlimtalk, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerAlimtalkSender onSuccess: ", e);
          message.info("전송되었습니다");
          mutateGetMessage();
        }
      },
      onError: (e) => {
        console.log("messageControllerAlimtalkSender onError: ", e);
      },
    });

  const { isLoading: loadingUpload, mutateAsync: mutateUpload } = useMutation(
    fetcherFileUpload,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("messageControllerMmsAttached onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("messageControllerMmsAttached onError: ", e);
      },
    }
  );



  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CustormerReadResponseData[]
    ) => {
      store.onChangeSelectedReceivedNumbers(selectedRows);
      setArray2(selectedRowKeys)
      setArray(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "", // Column configuration not to be checked
      name: record.name,
    }),
  };


  const onChangeFormMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = e;
      store.formReqMessage.onChangeForm({ message: value });
    },
    []
  );
  const onChangeFormMessage2 = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = e;
      store.formReqMessage.onChangeForm({ message: value });
    },
    []
  );

  const handleChange = (value: string) => {
    
    store.formReqMessage.onChangeForm({ message: value });
  };

  /**
   * [수신번호 추가] 클릭시
   */
  
  /**
   * [최근목록] 클릭시
   */
 
  /**
   * [선택번호 삭제] 클릭시
   */

  /**
   * [발신번호 선택] 변경시
   */
  const onChangeSelectedPhone = (idx: number) => {
    if (dataGetMember?.data) {
      const selectedPhone =
        dataGetMember.data.body.data.companyInformation.certPhoneInformation.find(
          (v) => v.idx === idx
        );
      store.formReqMessage.onChangeForm({
        selectedPhone,
      });
    }
  };

  /**
   * [발신프로필 선택] 변경시
   */

  /**
   * [광고성 문자] 변경시
   */
  

  /** [첨부파일] 이미지 삭제시 */
  const onRemoveFile = useCallback(
    (index: number) => {
      store.formReqMessage.onChangeForm({
        files: store.formReqMessage.form.files.filter((v, i) => i !== index),
        previews: store.formReqMessage.form.previews.filter(
          (v, i) => i !== index
        ),
      });
    },
    [store.formReqMessage.form.files, store.formReqMessage.form.previews]
  );

  /**
   * [예약일시] 변경시
   */

  /**
   * [발송현황] refresh icon 클릭시
   */

  /**
   * [엑셀파일 추가] 클릭시
   */

  /**
   * [엑셀파일 추가] 클릭 이후 업로드시
   */
    const onCall = ()=>{
      alert(PhNum)
    }
  /**
   * 발송 클릭시
   */
  const onSend = async () => {
    const { form } = store.formReqMessage;
    if (form.message.replace(/ /g, "").length < 1) {
      return message.warn("메시지를 입력해주세요");
    }
    if (PhNum === null) {
      return message.warn("수신번호 목록을 등록해주세요");
    }
    let requestDate: string | undefined = undefined;
    if (store.sendType === "RESERVATION") {
      if (!form.reservationDate) {
        return message.warn("예약일시를 선택해주세요");
      }
      if (form.reservationDate <= dayjs()) {
        return message.warn("예약일시는 현재시각 이후로 선택해주세요");
      }
      requestDate = form.reservationDate.format("YYYY-MM-DD HH:mm");
    }

    if (store.sendMethod === "SMS") {
      if (!form?.selectedPhone) {
        return message.warn("발신번호를 선택해주세요");
      }

      // SMS or MMS
      if (form.files.length > 0 || form.message.length > 90) {
        // REVIEW : 제목 비필수로 변경됨.
        // if (form.title.replace(/ /g, "").length < 1) {
        //   return message.warn("(MMS의 경우) 문자 제목을 입력해주세요");
        // }

        let attachFileIds: number[] | undefined;
        if (form.files.length > 0) {
          console.log("form.files: ", form.files);

          const headers = new Headers();
          headers.append("Authorization", `Bearer ${getAccessToken()}`);

          let formData = new FormData();
          form.files.forEach((v) => {
            formData.append("files", v);
          });
          // MMS
          // const {
          //   data: {
          //     body: { data },
          //     header,
          //   },
          // } = await mutateUpload({
          //   files: form.files,
          // });

          const res = await fetch(
            privateApi.baseUrl + "/message/sender/upload",
            {
              method: "POST",
              body: formData,
              headers,
            }
          );
          const {
            body: { data },
            header,
          } = (await res.json()) as AttachedResponseDTO;

          if (!header.isSuccess) {
            return;
          }
          // REVIEW : 변경된사항
          // TODO : 수정하기.
          attachFileIds = data.map((v) => v.fileId);
        }
        mutateSendMms({
          attachFileId: attachFileIds,
          requestDate,
          body: form.message,
          title: form.title,
          isAd: form.checkedAds ? 1 : 0,
          sendNo: form.selectedPhone.phone,
          recipientList: store.receivedNumbers.map(
            (v) =>
              ({
                recipientNo: v.custormerPhone1,
                custormerIdx: v.registerDate === "" ? "" : v.custormerIdx || "",
                custormerName: v.custormerName || "",
              } as RecipientList)
          ),
        });
      } else {
        // SMS
        mutateSendSms({
          body: form.message,
          requestDate,
          isAd: form.checkedAds ? 1 : 0,
          sendNo: form.selectedPhone.phone,
          recipientList: store.receivedNumbers.map(
            (v) =>
              ({
                recipientNo: v.custormerPhone1,
                // custormerIdx: v.registerDate === "" ? "" : v.custormerIdx || "",
                custormerIdx: v.custormerIdx === null ? null : v.custormerIdx || "",
                custormerName: v.custormerName || "",
              } as RecipientList)
              ),
            });
      }
    } else {
      // Alimtalk
      if (!store.selectedAlimtalkTemplate) {
        return message.warn("발신 템플릿을 선택해주세요");
      }
      mutateSendAlimtalk({
        plusFriendId: store.selectedAlimtalkTemplate.plusFriendId,
        templateCode: store.selectedAlimtalkTemplate.templateCode,
        requestDate,
        templateParameter: store.formReqMessage.form.updatedTemplateParameter
          ? JSON.parse(store.formReqMessage.form.updatedTemplateParameter)
          : undefined,
        recipientList: store.receivedNumbers.map(
          (v) =>
            ({
              recipientNo: v.custormerPhone1,
              custormerIdx: v.registerDate === "" ? "" : v.custormerIdx || "",
              custormerName: v.custormerName || "",
            } as RecipientList)
        ),
        body: form.message,
      });
    }

    setsendClicked(true)
    // TODO : 전송완료시, 3개의 mutation내에 필드값이 초기화 되도록 수정하기.
  };

  /**
   * [발신번호 등록] 클릭시
   */
  /**
   * [발신프로필 등록] 클릭시
   */

  /**
   * [문자 저장하기] 모달 팝업 확인 클릭시
   */



  

  useEffect(() => {

  }, [clicked]);
  

  const renderTitle = (title: string) => (
    <div  style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
    <span>
      {title}
    </span>
    <span onClick={()=>{
      setClicked(true);
      
    }}>지우기</span>
    </div>
  );
  const renderItem = (title: string,name: string ,key : number) => ({
    value: title,
    label: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {name}
        <span></span> {title}
        {/* <span></span> {key} */}
       
      </div>
    ),
    key : key
  });
 
  useEffect(() => {

    rowSelection.onChange(array2,array)
    // clicked ? setClicked(false) : setClicked(true); 
  }, [array]);
  // useEffect(() => {
  //   onAddListedNumber(e)
  // }, [usedNumber]);
  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>
            <ExtendedLayoutCell>
              <Form layout="vertical">
              

                  <h3 style={{fontWeight: 700,marginBottom: "5px"}}>Call to Student</h3>
                  <Select
                      id="phone"
                      loading={loadingGetMember}
                      value={
                        store.formReqMessage.form?.selectedPhone?.idx as any
                      }
                      style={{width : "100%" , display: "none"}}
                      onChange={onChangeSelectedPhone}
                      options={
                        !dataGetMember?.data
                          ? []
                          : 
                          // dataGetMember.data.body.data.companyInformation.certPhoneInformation.map(
                          //     (v) => ({
                          //       label: v.phone,
                          //       value: v.idx,
                          //     })
                          //   )
                          dataGetMember.data.body.data.companyInformation.certPhoneInformation.filter(
                            (v)=>v.phoneCertFlag == 1
                          ).map(
                                (v) => ({
                                  label: v.phone,
                                  value: v.idx,
                                })
                              )
                      }
                    />
                  <div style={{display: "flex" ,flexDirection: "column" ,alignItems: "center",width: "100%"}}>
                <CallButton onClick={onCall}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                  <div style={{marginTop: "5px",marginRight:"4px"}}><  BiPhoneCall style={{color : "white" ,fontSize: 17}}/></div><span style={{color: "white",marginTop:"2px"}}> Call</span>
                  </div>
                    
                   </CallButton>
          </div>
          <hr style={{width: "99%", marginTop: "17px"}}></hr>
                  <h3 style={{fontWeight: 700,marginBottom: "5px"}}>Message to Student</h3>
                  <Form.Item required label="Message">
                    <Select 
                    style={{marginBottom: 5}} 
                    onChange={handleChange}
                    placeholder="Select Message"
                         options={
                          
                          !dataGetSMSs?.data
                            ? []
                            : 
                            // dataGetMember.data.body.data.companyInformation.certPhoneInformation.map(
                            //     (v) => ({
                            //       label: v.phone,
                            //       value: v.idx,
                            //     })
                            //   )
                            dataGetSMSs.data.body.data
                            .map(
                                  (v) => ({
                                    key: v.templateIdx,
                                    label: v.templateName,
                                    value:  v.templateContent,
                                  }
                                  
                                  )
                                )
                        }
                    ></Select>

                    
                    <Input.TextArea
                      // rows={4}
                      // className="autoTextarea"
                      className={
                        store.formReqMessage.form?.files.length > 0
                          ? "uploaded"
                          : ""
                      }
                      style={{height:150}}
                      maxLength={2000}
                      value={store.formReqMessage.form.message}
                      onChange={onChangeFormMessage}
                      // onKeyDown={autoResizeTextarea}
                      // onKeyUp={autoResizeTextarea}
                    />
                    <ByteTextformessage2
                      // checked={store.formReqMessage.form.checkedAds}
                      // onChangeCheckbox={onCheckAds}
                      length={getByte(store.formReqMessage.form.message)}
                    />
                  </Form.Item>

               
                  <>
                    <SendMethodButtonGroup>
                      <Upload
                        name="file"
                        // accept=".ipynb"
                        accept="image/jpg, image/jpeg"
                        // beforeUpload={(e) => {
                        // setUploading(true);
                        // }}
                        fileList={[]}
                        onChange={(info) => {
                          if (store.formReqMessage.form.files.length === 2) {
                            return message.warn(
                              "최대 첨부파일 갯수는 2건입니다"
                            );
                          }
                          if (info.file.status !== "uploading") {
                            console.log(info.file, info.fileList);
                          }
                          if (info.file.status === "uploading") {
                            // setFile(info.fileList);
                          }
                          if (info.file.status === "done") {
                            // setFile(info.fileList);
                            message.success(
                              `${info.file.name} file uploaded successfully`
                            );
                          } else if (info.file.status === "error") {
                            message.error(
                              `${info.file.name} file upload failed.`
                            );
                          } else {
                            const originFileObjs = info.fileList.map(
                              (v) => v.originFileObj as RcFile
                            );
                            const file = originFileObjs[0];
                            console.log({ file });
                            if (
                              !["image/jpg", "image/jpeg"].includes(file.type)
                            ) {
                              return message.warn(
                                "올바른 이미지 형식이 아닙니다"
                              );
                            }

                            // REVIEW : 파일크기 검증 제거.
                            // 300KB 이하로만 가능.
                            // if (file.size > 300 * 1024) {
                            //   return message.warn(
                            //     "첨부파일 크기는 300KB이하로 첨부해주세요"
                            //   );
                            // }

                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                              const base64data = reader.result;
                              store.formReqMessage.onChangeForm({
                                files: [
                                  ...store.formReqMessage.form.files,
                                  file,
                                ],
                                previews: [
                                  ...store.formReqMessage.form.previews,
                                  base64data as string,
                                ],
                              });
                            };
                            // console.log({ file });
                            // console.log("info.file.status: ", info.file.status);
                            return;
                          }
                        }}
                      >
                      <AntdButton>Attach File</AntdButton>
                      </Upload>
                    </SendMethodButtonGroup>
                    {store.formReqMessage.form?.previews.length > 0 && (
                      <PreviewSpace>
                        {[
                          store.formReqMessage.form.previews.map((v, i) => (
                            <div key={i}>
                              <Preview src={v}>
                                <AiOutlineCloseCircle
                                  size={20}
                                  onClick={() => onRemoveFile(i)}
                                />
                              </Preview>
                              {store.formReqMessage.form?.files[i] && (
                                <FileName >
                                  {store.formReqMessage.form?.files[i].name}
                                </FileName>
                              )}
                            </div>
                          )),
                        ]}
                      </PreviewSpace>
                    )}
                  </>
               
              </Form>
                <div style={{display: "flex" ,flexDirection: "column" ,alignItems: "center",width: "100%"}}>
                <SendButton
                 onClick={onSend}
                 >
                  <div style={{display: "flex", justifyContent: "center"}}>
                  <div style={{marginTop: "5px",marginRight:"3px"}}><BiMessageDetail style={{color : "white" ,fontSize: 17}}/></div><span style={{color: "white",marginTop: "2px"}}> (Send)</span>
                  </div>
                    
                   </SendButton>
          </div>
            </ExtendedLayoutCell>
            
            <Divide />
           
         
          </Content>
          <AntdModal
            draggable
            title="메시지 불러오기"
            style={{
              maxWidth: "1200px",
              width: "100%",
            }}
            visible={store.modalLoadMessage.visible}
            onOk={store.onConfirmModalLoadMessage}
            onCancel={store.modalLoadMessage.onClose}
            okText="확인"
            cancelText="취소"
            okButtonProps={{
              disabled: !store.formReqMessage.form?.selectedTemplateOnModal,
            }}
          >
            <MessageOrTemplateModalContent mode="message" />
          </AntdModal>
          <AntdModal
            draggable
            title="템플릿 선택"
            style={{
              maxWidth: "1200px",
              width: "100%",
            }}
            visible={store.modalSelectTemplate.visible}
            onOk={store.onConfirmModalSelectTemplate}
            onCancel={store.modalSelectTemplate.onClose}
            okText="확인"
            cancelText="취소"
          >
            <MessageOrTemplateModalContent mode="template" />
          </AntdModal>
          <AntdModal
            draggable
            title="주소록"
            style={{
              maxWidth: "1200px",
              width: "100%",
            }}
            visible={store.modalAddressBook.visible}
            onOk={store.onConfirmModalAddressBook}
            onCancel={store.modalAddressBook.onClose}
            okButtonProps={{
              disabled:
                store.formReqMessage.form.selectedAddressBooks.length < 1,
            }}
            okText="확인"
            cancelText="취소"
          >
            <AddressBookModalContent />
          </AntdModal>
         
        </LayoutContent>
      </ExtendedLayoutRow>
    </Container>
  );
};

const Container = styled.div`
  display : flex;
  justify-content : center;
`;

const Content = styled.div`
  display: flex;
  justify-content : center;
  align-items : center;
  width: 350px;
  gap: 30px;

  @media ${device.laptop.max} {
    flex-flow: column;
  }
  textarea.ant-input {
    height: 100px;
  }
  @media ${device.laptop.min} {
    & > div {
      width: 33.3333%;
      /* height: 700px; */
      /* height: 719px; */
      /* height: calc(100vh - 81px - 20px - 31px); */
      /* max-height: 720px; */
    }
    textarea.ant-input {
      /* height: calc(100vh - 460px); */
      /* height: calc(100vh - 580px); */
      height: 293px;
      /* height: 280px; */
      &.uploaded {
        height: 180px;
        /* height: calc(100vh - 325px - 125px); */
      }
    }
  }
`;

const Divide = styled.div`
  /* display: none; */
  /* width: 1px; */
  /* margin: 0 20px; */
  margin: 0;
  margin: 0 -2px;
  /* background-color: #dfdfdf; */
  display: none;
  @media ${device.laptop.max} {
    width: 100%;
    /* height: 1px;
    margin: 20px 0; */
  }
`;

const SendMethodButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-top: 12px;
  & > span,
  & > button {
    flex: 1;
  }
  button {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  & > span {
    div,
    span,
    button {
      width: 100%;
    }
  }
  @media ${device.laptop.max} {
    margin-top: 10px;
    button {
      padding: 6px;
    }
  }
  @media (max-width: 1300px) {
    gap: 3px;
    button {
    }
  }
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    display: flex;
    flex-flow: column-reverse;
    justify-content: space-between;
    /* min-height: 100vh; */
    min-height: 100%;
  }
`;

const Preview = styled.div<{ src?: string }>`
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #dfdfdf;
  margin-top: 10px;
  border-radius: 4px;
  background-size: contain;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  & svg {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
    fill: #9f9f9f;
    &:hover {
      cursor: pointer;
    }
  }
`;

const FileName = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: #747474;
  max-width: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ExtendedLayoutCell = styled(LayoutCell)`
  /* height: calc(100vh - 81px - 20px - 12px); */
  @media ${device.laptop.max} {
    height: auto;
  }
  .ant-form-vertical .ant-form-item-label > label {
    width: 100%;
    .space {
      display: flex;
      cursor: pointer;
      margin-left: auto;
      align-items: center;
      gap: 3px;
      justify-content: space-between;
      color: #7a7a7a;
    }
  }
`;

const Link = styled.p`
  cursor: pointer;

  display: flex;
  gap: 3px;
  align-items: center;
  white-space: nowrap;
`;

const ExtendedAntdTable = styled(AntdTable)`
  width: 100%;
  @media ${device.laptop.min} {
    &.center-table {
      .ant-table-container {
        .ant-table-body {
          height: 340px;
          max-height: 340px !important;
          /* height: calc(100vh - 412px); */
          /* height: calc(100vh - 533px); */
          /* max-height: initial !important; */
        }
      }
    }
    &.last-table {
      .ant-table-container {
        .ant-table-body {
          /* height: calc(100vh - 430px); */
          /* max-height: initial !important; */
        }
      }
    }
  }
  @media ${device.laptop.max} {
    &.center-table {
      .ant-table-container {
        .ant-table-body {
          max-height: 310px !important;
        }
      }
    }
  }
`;

const PreviewSpace = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const Text = styled.div`
  margin-top: 6px;
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
`;
const SendButton = styled.button`
background-color : rgb(052,164, 252);
border : 0.1px solid white;
border-radius : 3px;
margin-top : 5px;
width : 100%;
padding : 3px;
&:hover {
  cursor: pointer;
  border : 1px solid black;
  // background-color : blue;
}
`;
const CallButton = styled.button`
background-color : rgb(052,198, 089);
border : 0.1px solid white;
border-radius : 3px;
margin-top : 5px;
width : 100%;
padding : 3px;
&:hover {
  cursor: pointer;
  border : 1px solid black;
  // background-color : blue;
}
`;
