import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, message, notification, Popconfirm, Select, Tag } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo,useState } from "react";
import { Helmet } from "react-helmet";
import { BsArrowRight } from "react-icons/bs";
import { BiWon } from "react-icons/bi";
import { RiArrowRightSLine, RiErrorWarningFill } from "react-icons/ri";
import styled from "styled-components";
import {
  AntdButton,
  AntdDatePicker,
  AntdModal,
  AntdPagination,
  AntdTable,
  AutoPaymentModalContent,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTitle,
  PaymentModalContent,
  RepaymentModalContent,
} from "../components";
import { device } from "../device";
import {
  ChargeControllerReadHistoryParams,
  ChargeHistoryReadResponseData,
} from "../generated";
import { privateApi } from "../react-query";
import { useChargePaymentStore } from "../stores";
import { IQueryFunctionContext, IRequestPayResponse } from "../types";
import { Formatter, isSuccess, Pattern } from "../utils";

const MOBILE_REDIRECT_URL = window.location.origin + "/payment/complete/mobile";

function addIamport() {
  const jquery = document.createElement("script");
  jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
  const iamport = document.createElement("script");
  iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js";
  document.head.appendChild(jquery);
  document.head.appendChild(iamport);
  return () => {
    document.head.removeChild(jquery);
    document.head.removeChild(iamport);
  };
}

const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherGetCharge = (
  params: IQueryFunctionContext<ChargeControllerReadHistoryParams>
) => {
  const query = params.queryKey[1];
  return privateApi.charge.chargeControllerReadHistory({
    page: query.page,
    pageSize: query.pageSize,
    startRequestDate: query.startRequestDate,
    endRequestDate: query.endRequestDate,
    chargedType: query.chargedType,
  });
};

export const ChargePayment2 = () => {
  const store = useChargePaymentStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const [UUID, setUUID] = useState("")
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  const {
    isLoading: loadingGetMember,
    data: dataGetMember,
    refetch: refetchGetMember,
  } = useQuery(["memberControllerReadCurrentMember"], fetcherGetMember, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("memberControllerReadCurrentMember onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("memberControllerReadCurrentMember onError: ", e);
    },
  });

  const {
    isLoading: loadingGetCharges,
    data: dataGetCharges,
    refetch: refetchGetChargers,
  } = useQuery(
    [
      "chargeControllerReadHistory",
      {
        page: store.formFilter.form.page,
        pageSize: store.formFilter.form.pageSize,
        endRequestDate: store.formFilter.form.endRequestDate,
        startRequestDate: store.formFilter.form.startRequestDate,
        chargedType: store.formFilter.form.chargedType,
      } as ChargeControllerReadHistoryParams,
    ],
    fetcherGetCharge,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("chargeControllerReadHistory onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("chargeControllerReadHistory onError: ", e);
      },
    }
  );

  const { isLoading: loadingPayment, mutate: mutatePayment } = useMutation(
    privateApi.payment.paymentControllerCreate,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("paymentControllerCreate onSuccess: ", e);
          const { form } = store.formModalPayment;
          if (form.uuid) {
            // IMP 요청
            console.log(form.uuid)
            handleRequestPaymentToIMP(
              +form.expectedPayment,
              form.method ===  "카드" ? "card" : form.method === "가상계좌" ? "vbank" : "phone",
              form.uuid
            );
          }
        }
      },
      onError: (e) => {
        console.log("paymentControllerCreate onError: ", e);
      },
    }
  );

  const { isLoading: loadingPaymentComplete, mutate: mutatePaymentComplete } =
    useMutation(privateApi.payment.paymentControllerUpdateImp, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("paymentControllerUpdateImp onSuccess: ", e);
          notification.success({
            message: "결제 완료",
            description: "결제가 완료되었습니다.",
            duration: 0,
          });
          refetchGetMember();
          refetchGetChargers();
          refetchGetAutoPayment();
          store.modalPayment.onClose();
          store.modalAutoPayment.onClose();
          store.onReset()
        }
      },
      onError: (e) => {
        console.log("paymentControllerUpdateImp onError: ", e);
      },
    });

  const { isLoading: loadingAutoPayment, mutate: mutateAutoPayment } =
    useMutation(privateApi.payment.paymentControllerCreateSchedule, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          const { form } = store.formModalAutoPayment;
          console.log("paymentControllerCreateSchedule onSuccess: ", e);
          if (form.uuid && form.custormerUid) {
            // IMP 요청
            handleRequestAutoPaymentToIMP(form.uuid, form.custormerUid);
          }
        }
      },
      onError: (e) => {
        console.log("paymentControllerCreateSchedule onError: ", e);
      },
    });

  const {
    isLoading: loadingGetAutoPayment,
    data: dataGetAutoPayment,
    refetch: refetchGetAutoPayment,
  } = useQuery(
    ["paymentControllerReadSchedule"],
    privateApi.payment.paymentControllerReadSchedule,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("paymentControllerReadSchedule onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("paymentControllerReadSchedule onError: ", e);
      },
    }
  );

  const { isLoading: loadingCancelPayment, mutate: mutateCancelAutoPayment } =
    useMutation(privateApi.payment.paymentControllerDeleteTag, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("paymentControllerDeleteTag onSuccess: ", e);
          refetchGetMember();
          refetchGetChargers();
          refetchGetAutoPayment();
          message.info("자동충전이 해지되었습니다");
        }
      },
      onError: (e) => {
        console.log("paymentControllerDeleteTag onError: ", e);
      },
    });

  /**
   * 일반결제 요청시
   */
  function handleRequestPaymentToIMP(
    amount: number,
    pay_method: "card" | "phone" |"vbank",
    merchant_uid: string
  ) {
    const { IMP } = window as any;
    // console.log(IMP)
    IMP.init("imp18827175");
    const data = {
      pg: "tosspayments.im_ridiarvh83",
      pay_method,
      merchant_uid,
      name: "리디안랩스-서비스이용료",
      amount,
      popup : false,
      // buyer_name : "",
      m_redirect_url: MOBILE_REDIRECT_URL,
      /*
       * 모바일 결제완료후 이동되는 페이지 주소
       * 해당페이지에서 query param으로 [GET] payment/complete/mobile 보낸다음 success일경우 충전페이지로 이동
       */
    };
    IMP.request_pay(data, function (rsp: IRequestPayResponse) {
      console.log("rsp: ", rsp);
      if (rsp.error_code) {
        var msg = "결제에 실패하였습니다.";
        msg += "에러내용 : " + rsp.error_msg;
        notification.error({
          message: "결제 실패",
          description: rsp.error_msg,
          duration: 0,
        });
        store.modalPayment.onClose();

        console.log("msg: ", msg);
        if (rsp.imp_uid) {
          // mutatePayFailed({
          //   reservationId: e.data.body?.id!,
          //   impUid: rsp.imp_uid as string,
          // });
          store.onReset()

        }
       
      } else {
        if (rsp.imp_uid){

          var msg = "결제가 완료되었습니다.";
          msg += "고유ID : " + rsp.imp_uid; // 결제번호
          msg += "상점 거래ID : " + rsp.merchant_uid; // 주문번호
          msg += "결제 금액 : " + amount;
          // msg += "카드 승인번호 : " + rsp.apply_num;
          console.log("msg: ", msg);
          store.modalPayment.onClose();
          store.modalAutoPayment.onClose();
          mutatePaymentComplete({
            impUid: rsp.imp_uid,
            merchantUid: rsp.merchant_uid as string,
          });
        }
     
      }

     
    });
  }

  /**
   * 정기결제 요청시
   */
  function handleRequestAutoPaymentToIMP(
    merchant_uid: string,
    customer_uid: string
  ) {
    const { IMP } = window as any;
    IMP.init("imp18827175");
    const data = {
      pg: "kcp",
      pay_method: "card",
      name: "리디안랩스-최초인증결제",
      amount: 1,
      customer_uid,
      merchant_uid,
      m_redirect_url: MOBILE_REDIRECT_URL,
      /*
       * 모바일 결제완료후 이동되는 페이지 주소
       * 해당페이지에서 query param으로 [GET] payment/complete/mobile 보낸다음 success일경우 충전페이지로 이동
       */
    };
    IMP.request_pay(data, function (rsp: IRequestPayResponse) {
      console.log("rsp: ", rsp);
      if (rsp.success) {
        var msg = "결제가 완료되었습니다.";
        msg += "고유ID : " + rsp.imp_uid; // 결제번호
        msg += "상점 거래ID : " + rsp.merchant_uid; // 주문번호
        msg += "결제 금액 : " + rsp.paid_amount;
        msg += "카드 승인번호 : " + rsp.apply_num;
        console.log("msg: ", msg);
        if (rsp.imp_uid) {
          mutatePaymentComplete({
            impUid: rsp.imp_uid,
            merchantUid: rsp.merchant_uid as string,
          });
        }
      } else {
        var msg = "결제에 실패하였습니다.";
        msg += "에러내용 : " + rsp.error_msg;
        notification.error({
          message: "결제 실패",
          description: rsp.error_msg,
          duration: 0,
        });
        store.modalAutoPayment.onClose();
        console.log("msg: ", msg);
        if (rsp.imp_uid) {
          // mutatePayFailed({
          //   reservationId: e.data.body?.id!,
          //   impUid: rsp.imp_uid as string,
          // });
        }
      }
    });
  }

  /**
   * [검색기간] 변경시
   */
  const onChangeDate = useCallback((e: [Dayjs, Dayjs]) => {
    if (e[0] && e[1]) {
      const startRequestDate = e[0].format("YYYY-MM-DD");
      const endRequestDate = e[1].format("YYYY-MM-DD");
      store.formFilter.onChangeForm({
        startRequestDate,
        endRequestDate,
      });
    }
  }, []);

  /**
   * 모달창 충전 [충전 신청] 버튼 클릭시
   */
  const onSubmitPayment = () => {
    const { form } = store.formModalPayment;
    if (
      !Pattern.checkOptional(Pattern.숫자).test(form.payment) ||
      Number(form.payment) < 1
    ) {
      return message.warn("충전금액은 0보다 큰 숫자로 입력해주세요");
    }
    if (Number(form.payment) < 5000) {
      return message.warn("충전금액은 5천원 이상으로 입력해주세요");
    }
    if (
      
      Number(form.expectedPayment) < 1
    ) {
      return message.warn("결제예정금액은 0보다 큰 숫자로 입력해주세요");
    }

    if (form.uuid) {
      setUUID(form.uuid)
      console.log(form.uuid)
      console.log(form.method)
      if(form.uuid !== UUID){
        
        console.log(UUID)
        mutatePayment({
          pg: "tosspayments",
          merchantUid: form.uuid,
          amount: +form.expectedPayment,
          // payMethod: form.method ===  "카드" ? "vbank" : form.method === "가상계좌" ? "vbank" : "vbank",
          payMethod: form.method ===  "카드" ? "card" : form.method === "가상계좌" ? "vbank" : "phone",
          paidAmount: +form.expectedPayment,
        });
      }
      else {
        message.info("진행중입니다.기다려주세요")
      }
    
    }
  };

  /**
   * 모달창 자동충전 [자동충전 신청] 버튼 클릭시
   */
  const onSubmitAutoPayment = () => {
    const { form } = store.formModalAutoPayment;
    if (
      !Pattern.checkOptional(Pattern.숫자).test(form.payment) ||
      Number(form.payment) < 1
    ) {
      return message.warn("충전금액은 0보다 큰 숫자로 입력해주세요");
    }
    if (Number(form.payment) < 5000) {
      return message.warn("충전금액은 5천원 이상으로 입력해주세요");
    }
    if (
      !Pattern.checkOptional(Pattern.숫자).test(form.expectedPayment) ||
      Number(form.expectedPayment) < 1
    ) {
      return message.warn("결제예정금액은 0보다 큰 숫자로 입력해주세요");
    }
    if (
      !Pattern.checkOptional(Pattern.숫자).test(form.day) ||
      Number(form.day) < 1
    ) {
      return message.warn("기준금액은 0보다 큰 숫자로 입력해주세요");
    }
    if (form.custormerUid) {
      mutateAutoPayment({
        recurringAmount: +form.expectedPayment,
        recurringType: form.type,
        recurringValue: +form.day,
        customerUid: form.custormerUid,
      });
    }
  };

  /**
   * [자동충전 해지하기] 클릭시
   */
  const onClickCancelAutoPayment = () => {
    if(dataGetAutoPayment?.data.body.data && dataGetAutoPayment?.data.body.data.length > 0) {
      const {paymentScheduleIdx} = dataGetAutoPayment?.data.body.data[0];
      mutateCancelAutoPayment(paymentScheduleIdx + "");
    }
  };

  /**
   * [페이징] 변경시
   */
  const onChangePagination = (page: number, pageSize?: number) => {
    store.formFilter.onChangeForm({
      page,
    });
  };

  /**
   * [충전/차감 구분] 변경시
   */
  const onChangeSelect = (chargedType: "sub" | "all" | "add") => {
    store.formFilter.onChangeForm({
      page: 1,
      chargedType,
    });
  };

  useEffect(() => {
    return () => {
      setTimeout(() => {
        store.onReset();
      }, 300);
    };
  }, []);

  /** 할인적용여부 */
  const isApplyDiscount = useMemo(() => {
    if (!dataGetMember?.data) {
      return false;
    }
    const { basicChargePlanInformation, chargePlanInformation } =
      dataGetMember?.data.body.data.companyInformation;
    return (
      basicChargePlanInformation.chargePlanCode !==
      chargePlanInformation.chargePlanCode
    );
  }, [dataGetMember?.data]);

  useEffect(addIamport, []);

  if (loadingGetMember) {
    return <>loading...</>;
  }
  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>
            <Space>
              <ExtendedLayoutCell>
                <LayoutTitle>상품</LayoutTitle>
                <BalanceCell>
                  <div>
                    <Balance>
                      <ImgBox>
                        {/* <MdAttachMoney size={30} /> */}
                        <BiWon size={20} />
                      </ImgBox>
                      <span className="text">
                        <span className="strong">
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .balance
                          }
                        </span>
                        원
                      </span>
                    </Balance>
                  </div>
                  <div className="priceBtnCont">
                    <AntdButton
                      isPrimary
                      style={{ color: "white" }}
                      onClick={store.modalPayment.onOpen}
                    >
                      상품구매    
                    </AntdButton>
                    <LinkGroup>
                      {/* <Link onClick={store.modalAutoPayment.onOpen}>
                        자동충전
                        <RiArrowRightSLine size={16} />
                      </Link> */}
                      <Link onClick={store.modalRepayment.onOpen}>
                        환불하기
                        <RiArrowRightSLine size={16} />
                      </Link>
                    </LinkGroup>
                  </div>
                  {dataGetAutoPayment?.data.body.data &&
                    dataGetAutoPayment?.data.body.data.length > 0 && (
                      <>
                        {dataGetAutoPayment?.data.body.data[0].recurringType ===
                        "date" ? (
                          <AutoChargerSpace>
                            <span>자동충전 이용중</span>
                            <Text>
                              매월{" "}
                              <strong>
                                {
                                  dataGetAutoPayment?.data.body.data[0]
                                    .recurringValue
                                }
                                일
                              </strong>{" "}
                              마다{" "}
                              <strong>
                                {Formatter.통화(
                                  dataGetAutoPayment?.data.body.data[0]
                                    .recurringAmount
                                )}
                                원(VAT 포함)
                              </strong>{" "}
                              충전
                            </Text>
                            <Popconfirm
                              disabled={loadingCancelPayment}
                              title="자동충전을 해지하시겠습니까?"
                              onConfirm={onClickCancelAutoPayment}
                              okText="네"
                              cancelText="아니오"
                            >
                              <AntdButton
                                loading={loadingCancelPayment}
                                style={{ color: "white" }}
                                color="gray"
                              >
                                해지하기
                              </AntdButton>
                            </Popconfirm>
                          </AutoChargerSpace>
                        ) : (
                          <AutoChargerSpace>
                            <span>자동충전 이용중</span>
                            <Text>
                              잔액이{" "}
                              <strong>
                                {Formatter.통화(
                                  dataGetAutoPayment?.data.body.data[0]
                                    .recurringValue
                                )}
                                원
                              </strong>{" "}
                              미만시,{" "}
                              <strong>
                                {Formatter.통화(
                                  dataGetAutoPayment?.data.body.data[0]
                                    .recurringAmount
                                )}
                                원(VAT 포함)
                              </strong>{" "}
                              충전
                            </Text>
                            <Popconfirm
                              disabled={loadingCancelPayment}
                              title="자동충전을 해지하시겠습니까?"
                              onConfirm={onClickCancelAutoPayment}
                              okText="네"
                              cancelText="아니오"
                            >
                              <AntdButton
                                loading={loadingCancelPayment}
                                style={{ color: "white" }}
                                color="gray"
                              >
                                해지하기
                              </AntdButton>
                            </Popconfirm>
                          </AutoChargerSpace>
                        )}
                      </>
                    )}
                </BalanceCell>
              </ExtendedLayoutCell>

              <ExtendedLayoutCell>
                <LayoutTitle>플랜</LayoutTitle>

                <PlanCell>
                  <PlanTitle>
                    <div className="planTitCont">
                      <span className="label">플랜명: </span>
                      <span className="value">
                        {isApplyDiscount
                          ? dataGetMember?.data.body.data.companyInformation
                              .chargePlanInformation.chargePlanName
                          : dataGetMember?.data.body.data.companyInformation
                              .basicChargePlanInformation.chargePlanName}
                      </span>
                    </div>
                      {isApplyDiscount && (
                        <span className="gray">
                          (할인적용기간 만료 후{" "}
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .basicChargePlanInformation.chargePlanName
                          }
                          로 변경됩니다)
                        </span>
                      )}
                  </PlanTitle>
                  <PlanGroup>
                    <PlanItem style={isApplyDiscount ? {justifyContent:"center"} : {}} className="smsCont">
                      <PlainTag className="smsTit">
                          SMS
                      </PlainTag>
                      <span className="planLine"></span>
                      {isApplyDiscount && (
                        <PlainSlashText>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .basicChargePlanInformation.chargeSms
                          }
                          <span className="priceWon">원</span>
                        </PlainSlashText>
                      )}
                      <PlainSpace  style={isApplyDiscount ? {} : {marginLeft : "0"}}>
                        {isApplyDiscount && <BsArrowRight size={20} />}
                        <PlainValue>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .chargePlanInformation.chargeSms
                          }
                          <span className="priceWon">원</span>
                        </PlainValue>
                      </PlainSpace>
                    </PlanItem>
                    <PlanItem style={isApplyDiscount ? {justifyContent:"center"} : {}} className="lmsCont">
                      <PlainTag className="lmsTit">LMS</PlainTag>
                      <span className="planLine"></span>
                      {isApplyDiscount && (
                        <PlainSlashText>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .basicChargePlanInformation.chargeLms
                          }
                          <span className="priceWon">원</span>
                        </PlainSlashText>
                      )}
                      <PlainSpace style={isApplyDiscount ? {} : {marginLeft : "0"}}>
                        {isApplyDiscount && <BsArrowRight size={20} />}
                        <PlainValue>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .chargePlanInformation.chargeLms
                          }
                          <span className="priceWon">원</span>
                        </PlainValue>
                      </PlainSpace>
                    </PlanItem>
                    <PlanItem style={isApplyDiscount ? {justifyContent:"center"} : {}} className="mmsCont">
                      <PlainTag className="mmsTit">MMS</PlainTag>
                      <span className="planLine"></span>
                      {isApplyDiscount && (
                        <PlainSlashText>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .basicChargePlanInformation.chargeMms
                          }
                          <span className="priceWon">원</span>
                        </PlainSlashText>
                      )}

                      <PlainSpace style={isApplyDiscount ? {} : {marginLeft : "0"}}>
                        {isApplyDiscount && <BsArrowRight size={20} />}
                        <PlainValue>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .chargePlanInformation.chargeMms
                          }
                          <span className="priceWon">원</span>
                        </PlainValue>
                      </PlainSpace>
                    </PlanItem>
                    <PlanItem style={isApplyDiscount ? {justifyContent:"center"} : {}} className="talkCont">
                      <PlainTag className="talkTit">알림톡</PlainTag>
                      <span className="planLine"></span>
                      {isApplyDiscount && (
                        <PlainSlashText>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .basicChargePlanInformation.chargeAlimtalk
                          }
                          <span className="priceWon">원</span>
                        </PlainSlashText>
                      )}
                      <PlainSpace style={isApplyDiscount ? {} : {marginLeft : "0"}}>
                        {isApplyDiscount && <BsArrowRight size={20} />}
                        <PlainValue>
                          {
                            dataGetMember?.data.body.data.companyInformation
                              .chargePlanInformation.chargeAlimtalk
                          }
                          <span className="priceWon">원</span>
                        </PlainValue>
                      </PlainSpace>
                    </PlanItem>
                  </PlanGroup>
                  {isApplyDiscount && (
                    <PlanText>
                      <RiErrorWarningFill
                        size={18}
                        fill="#6a6a6a"
                        style={{ marginRight: 3 }}
                      />
                      할인적용기간:{" "}
                      {dayjs(
                        dataGetMember?.data.body.data.companyInformation
                          .promotionExpiryDate
                      ).format("YYYY-MM-DD")}
                    </PlanText>
                  )}
                </PlanCell>

              </ExtendedLayoutCell>
            </Space>

            <ExtendedLayoutCell className="bottom">
              <Form
                layout="vertical"
                style={{ marginBottom: 20, display: "flex" }}
              >
                <Form.Item label="검색기간">
                  <FormSpace>
                    <FormSubSpace>
                      <AntdDatePicker.RangePicker
                        allowClear={false}
                        onChange={onChangeDate as any}
                        value={[
                          dayjs(store.formFilter.form.startRequestDate),
                          dayjs(store.formFilter.form.endRequestDate),
                        ]}
                      />
                    </FormSubSpace>
                  </FormSpace>
                </Form.Item>
                <Form.Item label="이용구분">
                  <FormSpace>
                    <FormSubSpace>
                      <Select
                        style={{ width: 130 }}
                        onChange={onChangeSelect}
                        value={store.formFilter.form.chargedType}
                      >
                        <Select.Option value="all">전체</Select.Option>
                        <Select.Option value="add">충전</Select.Option>
                        <Select.Option value="sub">차감</Select.Option>
                      </Select>
                      {/* <AntdButton isPrimary style={{ color: "white" }}>
                        검색
                      </AntdButton> */}
                    </FormSubSpace>
                  </FormSpace>
                </Form.Item>
              </Form>

              <LayoutTitle>이용내역</LayoutTitle>
              <ExtendedAntdTable
                className="antd-table-bottom"
                style={{
                  // minHeight: 300,
                  borderTop: "1px solid #dfdfdf",
                  borderBottom: "1px solid #dfdfdf",
                }}
                tableLayout="fixed"
                scroll={ windowsize ? { y: "calc(36vh - 62px)" } : { x: 520, y: "calc(36vh - 62px)" }}
                rowKey="chargeHistoryIdx"
                dataSource={dataGetCharges?.data.body.data || []}
                pagination={false}
                rowClassName={(e: ChargeHistoryReadResponseData) => {
                  if (e.chargedType === "sub") {
                    return "bg-blue";
                  } else {
                    return "bg-orange";
                  }
                }}
                // onRow={(e) => {
                //   console.log("onRow: ", e);
                //   return e;
                // }}
                columns={[
                  {
                    title: "이용일자",
                    dataIndex: "requestDate",
                    key: "requestDate",
                    align: "center",
                    width : windowsize ? 75 : "auto",
                    render: (e) => {
                      const date = dayjs(e);
                      return (
                        <>
                         {/* <p>{dayjs(e).subtract(9,"hour").format("YYYY-MM-DD")}</p>
                          <p>{dayjs(e).subtract(9,"hour").format("HH:mm:ss")}</p> */}
                          {/* <p>{date.subtract(9,"hour").format("YYYY-MM-DD")}</p>
                          <p>{date.subtract(9,"hour").format("HH:mm:ss")}</p> */}
                          <p>{date.format("YYYY-MM-DD")}</p>
                          <p>{date.format("HH:mm:ss")}</p>
                        </>
                      );
                    },
                  },
                  {
                    title: "서비스명",
                    dataIndex: "serviceType",
                    key: "serviceType",
                    align: "center",
                    width : windowsize ? 55 : "auto",

                    render: (e) => {
                    //   if(e==="sms")
                    //   return (
                        
                    //     <Tag style={{ textTransform: "uppercase", background:"blue",color:"white"}}>
                    //      sms
                    //  </Tag>
                    //   );
                    //   else if(e==="mms")
                    //   return (
                        
                    //     <Tag style={{ textTransform: "uppercase", background:"orange",color:"white"}}>
                    //     mms
                    //     </Tag>
                    //   );
                    //   else if(e==="lms")
                    //   return (
                        
                    //     <Tag style={{ textTransform: "uppercase", background:"red",color:"white"}}>
                    //      lms
                    //     </Tag>
                    //   );
                    //   else
                    //   return (
                        
                    //     <Tag style={{ textTransform: "uppercase", background:"green",color:"white"}}>
                    //       알림톡
                    //     </Tag>
                    //   );
                    if(e==="alimtalk")
                    return (
                      
                      <Tag style={{color:"--talk-dark-color" , width : "50px"}} >
                         알림톡
                       </Tag>
                    );
                    else
                  return (
                    <Tag style={{ textTransform: "uppercase",color:"black", width : "50px"}}>
                           {e}
                         </Tag>
                     );
                    },
                  },
                  {
                    title: "이용구분",
                    dataIndex: "chargedType",
                    key: "chargedType",
                    align: "center",
                    width : windowsize ? 55 : "auto",

                    render: (e) => {
                      if (e === "sub") {
                        return <>차감</>;
                      }
                      return <>충전</>;
                    },
                  },
                  {
                    title: "이용금액",
                    dataIndex: "chargedBalance",
                    key: "chargedBalance",
                    align: "center",
                    width : windowsize ? 55 : "auto",

                  },
                  {
                    title: "이용 후 잔액",
                    dataIndex: "afterBalance",
                    key: "afterBalance",
                    align: "center",
                    width : windowsize ? 50 : "auto",

                  },
                ]}
              />
              {dataGetCharges?.data && dataGetCharges?.data.body.total > 10 && (
                <AntdPagination
                  className="test"
                  disabled={loadingGetCharges}
                  loading={loadingGetCharges}
                  pageSize={store.formFilter.form.pageSize}
                  position="right"
                  size= {windowsize? "small" : "default" } 
                  current={store.formFilter.form.page}
                  total={dataGetCharges.data.body.total}
                  onChange={onChangePagination}
                />
              )}
            </ExtendedLayoutCell>
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      <AntdModal
        draggable
        title="상품구매"
        style={{
          maxWidth: windowsize? "500px" : "850px",
          width: "100%",
        }}
        visible={
          store.modalPayment.visible &&
          Boolean(store.formModalPayment.form.uuid)
        }
        onOk={onSubmitPayment}
        onCancel={store.modalPayment.onClose}
        okButtonProps={{
          loading: loadingPayment,
          disabled: 
            store.formModalPayment.form.payment.length < 0 ||
            store.formModalPayment.form.expectedPayment.length < 0,
            
            
          // disabled: !availableAddChildAccount || loadingAddChildAccount,
        }}
        
        okText="구매하기"
        cancelText="취소"
      >
        <PaymentModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="자동충전"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={
          store.modalAutoPayment.visible &&
          Boolean(store.formModalAutoPayment.form.uuid)
        }
        onOk={onSubmitAutoPayment}
        onCancel={store.modalAutoPayment.onClose}
        okButtonProps={{
          loading: loadingAutoPayment,
          disabled:
            store.formModalAutoPayment.form.payment.length < 0 ||
            store.formModalAutoPayment.form.expectedPayment.length < 0 ||
            store.formModalAutoPayment.form.day.length < 0,
        }}
        okText="자동충전 신청"
        cancelText="취소"
      >
        <AutoPaymentModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="환불"
        style={{
          maxWidth: "1200px",
          width: "100%",
        }}
        visible={store.modalRepayment.visible}
        // onOk={onSubmitAutoPayment}
        onCancel={store.modalRepayment.onClose}
        okButtonProps={{
          hidden: true,
        }}
        // okText=""
        cancelText="닫기"
      >
        <RepaymentModalContent
          repaymentableAmount={
            dataGetMember?.data
              ? +dataGetMember?.data.body.data.companyInformation.balance
              : 0
            // dataGetMember?.data
            //   ? +dataGetMember?.data.body.data.companyInformation.balance * 1.1
            //   : 0
          }
        />
      </AntdModal>
    </Container>
  );
};

const Container = styled.div``;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    min-height: 100vh;
  }
`;

const Content = styled.div`
  width: 100%;
  .bg-orange {
    color: #d98c00;
  }
  .bg-blue {
    color: #082358;
  }

  @media ${device.laptop.min} {
    /* height: calc(100vh - 81px - 20px - 12px); */
    /* height: 100%; */
    height: 100%;
  }
`;

const ExtendedLayoutCell = styled(LayoutCell)`
  &.bottom {
    /* height: 40vh; */
    @media ${device.laptop.min} {
      .antd-table-bottom {
        height: calc(41.6vh - 52px);
        /* height: 60%; */
      }
    }
  }
  @media ${device.laptop.max} {
    .ant-form {
      display: block !important;
    }
  }

`;

const Space = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  margin-bottom: 30px;
  & > div {
    width: 100%;
  }

  @media ${device.laptop.max} {
    flex-flow: column;
  }
`;
const BalanceCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  flex-flow: column;
  .priceBtnCont {
    width: 80%;
  }
  .ant-btn {
    width: 100%;
    height: 46px;
    font-size: 15px!important;
    letter-spacing: 0;
    font-weight: bold;
    background: #f05e29;
    border:none;
    margin: 10px 0 16px;
    border-radius: 7px !important;      
  }
`;

const ImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 6px;
  background: #fef5ea;
  svg {
    color: var(--primary-color);
    display: block;
  }
`;

const Balance = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  font-size: 20px;
  .text {
    display: flex;
    align-items: baseline;
    color: var(--primary-color);
    font-weight: bold;
  }
  .strong {
    font-size: 35px;
    font-weight: 700;
  }
`;

const FormSpace = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 30px;
  }
`;

const FormSubSpace = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LinkGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const Link = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: bold;
  svg {
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    color: var(--primary-color);
  }
`;

const PlanCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-flow: column;
  padding: 0;
  .planTitCont {
    padding: 2px 36px;
    border-radius: 15px;
    background: var(--primary-color);
  }
  @media ${device.laptop.max} {
    padding: 0;
  }
`;

const PlanTitle = styled.p`
  color: white;
  margin: 10px auto 3px;
  .label {
    font-weight: bold;
  }
  .value {
    font-weight: bold;
  }
  .gray {
    white-space: nowrap;
    color: #a7a7a7;
    font-size: 13px;
  }
`;

const PlanGroup = styled.div`
  width: 100%;
  border-radius: 6px;
  margin-bottom: 3px;
  padding-top: 12px;
  display: flex;
  gap: 12px;
  .smsCont {
    border: 1px solid var(--sms-color);
    .smsTit {
      color:var(--sms-color);
      background: var(--sms-label-lightcolor);
    }
  }
  .lmsCont {
    border: 1px solid var(--lms-color);
    .lmsTit {
      color:var(--lms-color);
      background: var(--lms-label-lightcolor);
    }
  }
  .mmsCont {
    border: 1px solid var(--mms-color);
    .mmsTit {
      color:var(--mms-color);
      background: var(--mms-label-lightcolor);
    }
  }
  .talkCont {
    border: 1px solid var(--talk-color);
    .talkTit {
      color:var(--talk-dark-color);
      background: var(--talk-label-lightcolor);
    }
  }
  @media ${device.tablet.max} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const PlanItem = styled.div`
  width: 47%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  min-height: 130px;
  border-radius:4px;
  @media ${device.tablet.max} {
    min-height: 82px;
  }
`;

const PlainTag = styled.div`
  text-align: center;
  padding: 2px 12px;
  white-space: nowrap;
  font-size: 16px;
  font-weight:bold;
  width:100%;
  height:70%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px 4px 0 0;
  @media ${device.tablet.max} {
    font-size: 14px;
  }
`;

const PlainSlashText = styled.p`
  text-decoration: line-through;
  color: black;
  text-align: right;
`;
const PlainValue = styled.p`
  font-weight: bold;
  font-size: 22px;
  .priceWon {
    font-size:15px;
  }
  @media ${device.tablet.max} {
    font-size: 19px;
  }
`;
const PlainSpace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
  width: 100%;
  img {
  }
`;

const PlanText = styled.p`
  font-size: 12px;
  display: flex;
  margin-right: auto;
  align-items: center;
  color: #838383;
  svg {
    margin-top: -2px;
  }
`;

const AutoChargerSpace = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  gap: 3px;
  padding: 10px 6px;
  word-break: keep-all;
  margin-bottom: -20px;
  margin-top: 20px;
  white-space: nowrap;
  flex-direction: row;
  justify-content: space-between;
  & > span {
    font-size: 13px;
    color: var(--primary-color);
    font-weight: bold;
  }
  @media ${device.laptop.max} {
    flex-flow: column;
    gap: 12px;
  }
`;

const Text = styled.p`
  font-size: 12px;
`;
const ExtendedAntdTable = styled(AntdTable)`
.ant-table-tbody > tr > td {
  font-size: 13px;
  padding-left:3px!important;
  padding-right:3px!important;
}
.ant-table-tbody > tr > td:first-child {
  font-size: 12px;
  line-height:1.3;
}
.ant-table-tbody > tr > td:nth-child(4) {
  font-size: 15px;
  color: var(--danger-color);
  font-weight: bold;
}
.ant-table-tbody > tr > td:nth-child(5) {
  font-size: 15px;
  color: var(--blue-price-color);
  font-weight: bold;
}
@media ${device.tablet.max} {
  .ant-table-tbody > tr > td:first-child {
    font-size: 10px;
    line-height:1.3;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    font-size: 12px;
    color: var(--danger-color);
    font-weight: bold;
  }
  .ant-table-tbody > tr > td:nth-child(5) {
    font-size: 12px;
    color: var(--blue-price-color);
    font-weight: bold;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
  font-size: 9px;
`;
