 const getPhoneNumber = (str: string) => {
   let formattedPhoneNumber

  if (/^02\d{8}$/.test(str)) {
    formattedPhoneNumber = str.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    formattedPhoneNumber = str.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  }
  

  return formattedPhoneNumber

};
export default getPhoneNumber;

