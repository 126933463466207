import { Form, Input, message, Radio, RadioChangeEvent,Tooltip } from "antd";
import { Stream } from "stream";
import styled from "styled-components";
import { useMessageSetStore,useSignupStore,useAddSignupStore } from "../stores";
import { IComponentProps } from "../types";
import { AntdButton } from "./AntdButton";
import { useCallback,useRef} from "react";
import { useMutation } from "@tanstack/react-query";
import { isSuccess } from "../utils";
import { publicApi } from "../react-query";
import { BsQuestionCircle } from "react-icons/bs";
interface IProps extends IComponentProps {}
export interface IRequestCertiResponse {
  error_code?: string;
  error_msg?: string;
  imp_uid : string
  merchant_uid: string;
  pg_provider : string;
  pg_type : string;
  success: boolean;
}

export const AddPhoneModalContent: React.FC<IProps> = () => {
  const store = useMessageSetStore((s) => s);
  const store2 = useAddSignupStore((s) => s);


  function handleRequestCertificationToIMP() {
    const {IMP} = window as any;
    //리디안
    IMP.init("imp18827175"); 
    // IMP.init("imp10391932");
    const data ={
      merchant_uid :`mid_${new Date().getTime()}`,
      pg_provider : "danal",
      pg_type : "certification",
      popup : true
    }
  
    IMP.certification(data, function (rsp: IRequestCertiResponse) {
      console.log("rsp: ", rsp);
      if (rsp.success) {
       
        if (rsp.imp_uid) {
          console.log(rsp.imp_uid);
          message.info("본인인증이 완료되었습니다.")
          store2.form.onChangeForm({
            imp_uid:rsp.imp_uid
          })
        }
      } else {
        message.error(rsp.error_msg);
        if (rsp.imp_uid) {
          // mutatePayFailed({
          //   reservationId: e.data.body?.id!,
          //   impUid: rsp.imp_uid as string,
          // });
        }
      }
    });
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    store2.form.onChangeForm({
      carrierCertFilePhone: value,
    });
  };
  
  const onClickVerify = () => {};
  // const inputFileBusinessRegisteredRef = useRef<HTMLInputElement | null>(null);
  const inputFilePhoneCertRef = useRef<HTMLInputElement | null>(null);
  const inputFileemploymentRef = useRef<HTMLInputElement | null>(null);

  const fetcherUploadPhoneCert = (params: { files: File }) =>
  publicApi.member.memberControllerPhoneCertAttached({ files: params.files });

  const fetcherUploadUploadEmployment = (params: { files: File }) =>
  publicApi.company.companyControllerEmploymentCertAttached({ files: params.files });

let fileBusinessRegistered = "";
let filePhoneCert = "";
let fileEmploymentCert = "";

  const { isLoading: loadingUploadPhoneCert, mutate: mutateUploadPhoneCert } =
    useMutation(fetcherUploadPhoneCert, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerPhoneCertAttached onSuccess: ", e);
          store2.form.onChangeForm({
            filePhoneCert: {
              currentFileName: filePhoneCert,
              fileName: e.data.body.data.fileName,
            },
          });
          message.info("첨부되었습니다");
        }
      },
      onError: (e) => {
        console.log("memberControllerPhoneCertAttached onError: ", e);
      },
    });

  const { isLoading: loadingUploadEmployment, mutate: mutateUploadEmployment } =
    useMutation(fetcherUploadUploadEmployment, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerPhoneCertAttached onSuccess: ", e);
          store2.form.onChangeForm({
            currentEmployFileName: fileEmploymentCert,
            employmentCertFileName : e.data.body.data.fileName
          });
          message.info("첨부되었습니다");
        }
      },
      onError: (e) => {
        console.log("memberControllerPhoneCertAttached onError: ", e);
      },
    });


  /** [인증유형] 변경시 */
  const onChangeRadio = (e: RadioChangeEvent) => {
    const {
      target: { value },
    } = e;
    console.log(value)
    store.formAddPhoneModal.onChangeForm({
      type: value,
    });
  };

  const onFilePhoneCertChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { files },
    } = e;
    if (files && files?.length > 0) {
      const file = files[0];
      filePhoneCert = file.name;
      mutateUploadPhoneCert({
        files: file,
      });
    }
    (e.target as any).value = null;
  };

  const onFileemploymentChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { files },
    } = e;
    if (files && files?.length > 0) {
      const file = files[0];
      fileEmploymentCert = file.name;
      mutateUploadEmployment({
        files: file,
      });
    }
    (e.target as any).value = null;
  };

  /**
   * [서류인증 첨부하기] 클릭시
   */
  const onClickPhoneCert = useCallback(() => {
    inputFilePhoneCertRef.current?.click();
  }, [inputFilePhoneCertRef.current]);
  
  const onClickFileemployment = useCallback(() => {
    inputFileemploymentRef.current?.click();
  }, [inputFileemploymentRef.current]);

  return (
    <Container>
      <Form.Item>
        <input
          style={{
            display: "none",
          }}
          name=""
          id=""
          type="file"
          accept="image/jpg, image/jpeg ,image/png ,image/pdf"
          ref={inputFilePhoneCertRef}
          onChangeCapture={onFilePhoneCertChangeCapture}
        />
        <input
          style={{
            display: "none",
          }}
          name=""
          id=""
          type="file"
          accept="image/jpg, image/jpeg ,image/png ,image/pdf"
          ref={inputFileemploymentRef}
          onChangeCapture={onFileemploymentChangeCapture}
        />
        <RadioSpace>
          <Radio.Group
            value={store.formAddPhoneModal.form.type}
            onChange={onChangeRadio}
          >
            <Radio value="전화번호">전화번호인증</Radio>
            <Radio value="서류인증">서류인증</Radio>
          </Radio.Group>
          <Tooltip
            overlayStyle={{ maxWidth: "550px" }}
            title={
              <div>
                <p>
                  직원의 번호를 인증하고 싶은 경우 재직증명서를 첨부해주시기
                  바랍니다.
                </p>
                <p>
                  번호의 명의가 대표자명과 다른 경우 재직증명서 없이 추가 등록
                  불가능합니다.
                </p>
              </div>
            }
          >
            <BsQuestionCircle />
          </Tooltip>
        </RadioSpace>
      </Form.Item>
      <Form.Item>
    
        {store.formAddPhoneModal.form.type === "전화번호" && (
          <Space>
              <Form layout="vertical" style={{width: "100%"}}>
      <Form.Item required label="발신번호 인증">
       
            <div
              style={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {/* <span style={{color : "red", width : "3%"}}>* </span> */}
  
              <AntdButton
                isPrimary
                style={{ width: "100%", color: "white" }}
                onClick={handleRequestCertificationToIMP}
              >
                전화번호 인증하기
              </AntdButton>
              {store2.form.form.imp_uid != "" ? (
                <p style={{ margin: "auto" }}>인증이 완료되었습니다.</p>
              ) : (
                <p style={{ margin: "auto" }}></p>
              )}
            </div>
            </Form.Item>
            </Form>
          </Space>
        )}
        {store.formAddPhoneModal.form.type === "서류인증" && (
          <Form layout="vertical" style={{width: "100%"}}>
          <Form.Item required label="발신번호 인증">
          <div>
            <Input
              placeholder="통신가입증명원: 인증받을(기입된) 전화번호"
              name="currentFilePhone"
              maxLength={11}
              value={store2.form.form.carrierCertFilePhone}
              onChange={onChangeInput}
              style={{ marginBottom: "3px" }}
            />
            <Space>
      
              <Input
                disabled
                placeholder="통신가입증명원 첨부"
                value={store2.form.form.filePhoneCert.currentFileName}
              />
              <AntdButton
                isPrimary
                style={{
                  color: "white",
                }}
                loading={loadingUploadPhoneCert}
                onClick={()=>{
                  store2.form.form.carrierCertFilePhone != "" ?
                  onClickPhoneCert()
                  : message.info("인증 받을 전화번호를 입력하세요")
                }
              }
              >
                첨부하기
              </AntdButton>
            </Space>
          </div>
          </Form.Item>
          </Form>
        )}
         
         <Form layout="vertical" style={{width: "100%"}}>
      <Form.Item label="재직증명서 첨부">
        <Space>
          <Input
            required
            disabled
            placeholder="재직증명서 첨부"
             value={store2.form.form.currentEmployFileName}
          />
          <AntdButton
            isPrimary
            style={{
              color: "white",
            }}
             loading={loadingUploadEmployment}
             onClick={onClickFileemployment}
          >
            첨부하기
          </AntdButton>

        </Space>
          </Form.Item>
          </Form>
        
      </Form.Item>
    </Container>
  );
};

const Container = styled.div``;
const Space = styled.div`
  display: flex;
  width: 100%;
  margin-bottom : 3px;
  align-items: center;
  gap: 8px;
  button {
    height: 31px;
  }
`;
const RadioSpace = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;
