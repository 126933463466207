import { Checkbox, Form, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback } from "react";
import styled from "styled-components";
import { useAddressBookListStore } from "../stores";
import { IComponentProps } from "../types";
import getPhoneNumber from "../utils/getPhoneNumber";

interface IProps extends IComponentProps {
  onOk: () => void;
}

export const AddressBookFormModalContent: React.FC<IProps> = ({ onOk }) => {
  const store = useAddressBookListStore((s) => s);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      if(name === "phone"){
         store.formModalAddressBook.onChangeForm({
        [name]: value.replace(/[^0-9]/g, ''),
  
      });
      }
      else{

        store.formModalAddressBook.onChangeForm({
          [name]: value,
    
        });
      }
      
    },
    []
  );

  /**
   * [광고수신동의 or 공유주소록] 변경시
   */
  const onChangeCheckbox = useCallback((e: CheckboxChangeEvent) => {
    const {
      target: { name, checked },
    } = e;
    store.formModalAddressBook.onChangeForm({
      [name as string]: checked ? 1 : 0,
    });
  }, []);

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="이름">
          <Input
            autoFocus
            placeholder="이름을 입력해주세요"
            name="name"
            maxLength={200}
            value={store.formModalAddressBook.form.name}
            onChange={onChange}
            onPressEnter={onOk}
          />
        </Form.Item>
        <Form.Item label="이메일">
          <Input
            placeholder="이메일을 입력해주세요"
            name="email"
            maxLength={200}
            value={store.formModalAddressBook.form.email}
            onChange={onChange}
            onPressEnter={onOk}
          />
        </Form.Item>
        <Form.Item required label="전화번호">
          <Input
            placeholder="전화번호를 입력해주세요"
            name="phone"
            // maxLength={11}
            value={getPhoneNumber(store.formModalAddressBook.form.phone)}
            onChange={onChange}
            onPressEnter={onOk}
          />
        </Form.Item>
        <Form.Item label="그룹">
          <Input
            placeholder="그룹을 입력해주세요"
            name="group"
            maxLength={100}
            value={store.formModalAddressBook.form.group}
            onChange={onChange}
            onPressEnter={onOk}
          />
        </Form.Item>
        <Form.Item label="태그">
          <Input
            placeholder="#태그명"
            name="tag"
            maxLength={100}
            value={store.formModalAddressBook.form.tag}
            onChange={onChange}
            onPressEnter={onOk}
          />
        </Form.Item>
        <Form.Item style={{ marginTop: 12 }}>
          <Checkbox
            name="agreeAds"
            checked={Boolean(store.formModalAddressBook.form.agreeAds)}
            onChange={onChangeCheckbox}
          >
            광고수신동의
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            name="isPublic"
            checked={Boolean(store.formModalAddressBook.form.isPublic)}
            onChange={onChangeCheckbox}
          >
            공유주소록
          </Checkbox>
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div``;
