import { message } from "antd";
import { Dayjs } from "dayjs";
import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import {
  AlimtalkTemplatePramReadResponseData,
  AlimtalkTemplateReadDetailResponseData,
  CompanyCertKakaoEntity,
  CompanyCertPhoneEntity,
  CustormerAddToListResponseData,
  CustormerExcelResponseData,
  CustormerReadResponseData,
  SmsTemplateCreateDTO,
  SmsTemplateReadDetailResponseData,
} from "../generated";
import {
  IReducerForm,
  IReducerModal,
  reducerForm,
  reducerModal,
} from "../reducers";
import { getByte, getTemplateFromParams } from "../utils";

type TSendMethod = "SMS" | "ALARAM_TALK";
type TSendType = "IMMEDIATELY" | "RESERVATION";

/** 메시지발송 폼 */
interface IFormReqMessage {
  /** (문자) 발신번호 선택 */
  selectedPhone?: CompanyCertPhoneEntity;
  /** (문자) 발신프로필 선택 */
  selectedProfile?: CompanyCertKakaoEntity;
  /** (문자) 광고성 문자 체크 여부 */
  checkedAds: boolean;
  /** (문자) 첨부파일 */
  files: File[];
  /** (문자) 첨부파일 이미지 */
  previews: string[];
  /** 문자 or 템플릿 내용 */
  message: string;
  /** 문자 제목 (MMS의 경우에 필수) */
  title: string;
  /** (예약발송시) 예약 발송일자 */
  reservationDate?: Dayjs;
  /** (문자) 선택된 템플릿 */
  selectedTemplateOnModal?: SmsTemplateReadDetailResponseData;
  /** (문자) 선택된 템플릿 */
  selectedAlimtalkTemplateOnModal?: AlimtalkTemplateReadDetailResponseData;
  /** 수신번호 추가 입력폼 */
  addedReceivedNumberInput: string;
  /** 선택된 주소록 목록 (모달창 - 주소록) */
  selectedAddressBooks: CustormerReadResponseData[];
  /** 주소록 검색 유형 선택 (모달창 - 주소록) */
  addressBookSearchType:
    | "all"
    | "custormerName"
    | "custormerGroup"
    | "custormerPhone1";
  /** 주소록 검색 입력폼 (모달창 - 주소록) */
  addressBookSearchInput: string[];
  /** 치환문자 목록 (모달창 - 템플릿 선택) */
  templateParams: Array<
    AlimtalkTemplatePramReadResponseData & { value: string }
  >;
  /** 변경된 치환문자 (모달창 - 템플릿 선택/메시지불러오기) */
  updatedTemplateParameter?: string;
  /** 메시지 저장하기 쿼리 */
  addSmsQuery: SmsTemplateCreateDTO;
}

interface IStore extends State {
  /** 발송방법 (문자=SMS, 알림톡=ALARM_TALK)  */
  sendMethod: TSendMethod;
  /** 발송유형 (즉시발송=IMMEIDATELY, 예약발송=RESERVATION) */
  sendType: TSendType;
  /** 수신번호 목록 */
  receivedNumbers: CustormerReadResponseData[];
  // receivedList: CustormerReadResponseData[];
  /** 선택된 수신번호 목록 */
  selectedReceivedNumbers: CustormerReadResponseData[];
  /** (알림톡) 발신 템플릿 선택 */
  selectedAlimtalkTemplate?: AlimtalkTemplateReadDetailResponseData;
  /** 모달 - 주소록 */
  modalAddressBook: IReducerModal;
  /** 모달 - 메시지불러오기 */
  modalLoadMessage: IReducerModal;
  /** 모달 - 템플릿 선택 */
  modalSelectTemplate: IReducerModal;
  /** 모달 - 메시지 저장하기 */
  modalAddMessage: IReducerModal;
  /** 메시지발송 폼 */
  formReqMessage: IReducerForm<IFormReqMessage>;
  /** (입력폼으로) 수신번호 추가 이벤트 */
  // onAddReceivedList: (data: CustormerAddToListResponseData) => void;
  // onResetList: () => void;
  onAddReceivedNumber: (data: CustormerAddToListResponseData) => void;
  /** 선택번호 제외 이벤트 */
  onRemoveSelectedReceivedNumbers: (custormerIdx: number[]) => void;
  /** 모달 - 주소록 확인 클릭. */
  onConfirmModalAddressBook: () => void;
  /** [메시지 불러오기] 모달 확인 클릭 */
  onConfirmModalLoadMessage: () => void;
  /** [템플릿 선택] 모달 확인 클릭 */
  onConfirmModalSelectTemplate: () => void;
  /** 발송방법 변경 이벤트 */
  onChangeSendMethod: (
    sendMethod: TSendMethod,
    phone?: CompanyCertPhoneEntity,
    profile?: CompanyCertKakaoEntity
  ) => void;
  /** 엑셀파일 추가시 */
  onChangeReceivedNumbersFromExcel: (
    data: CustormerExcelResponseData[]
  ) => void;
  /** 발송유형 변경 이벤트 */
  onChangeSendType: (sendType: TSendType) => void;
  /** 선택된 수신번호 목록 업데이트 이벤트 */
  onChangeReceivedNumbers: (numbers: CustormerReadResponseData[]) => void;
  /** 선택된 수신번호 목록 업데이트 이벤트 */
  onChangeSelectedReceivedNumbers: (
    numbers: CustormerReadResponseData[]
  ) => void;
  onReset: () => void;
}

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    selectedPhone: undefined,
    selectedAlimtalkTemplateOnModal: undefined,
    sendMethod: "SMS",
    sendType: "IMMEDIATELY",
    // receivedList: [],
    receivedNumbers: [],
    selectedAddressBooks: [],
    selectedReceivedNumbers: [],
    selectedAlimtalkTemplate: undefined,
    visibleMessageLoadModal: false,
    formReqMessage: reducerForm<IStore, IFormReqMessage>(
      set,
      "formReqMessage",
      {
        message: "",
        title: "",
        addedReceivedNumberInput: "",
        checkedAds: false,
        files: [],
        previews: [],
        selectedAddressBooks: [],
        addressBookSearchType: "all",
        addressBookSearchInput: [],
        templateParams: [],
        updatedTemplateParameter: undefined,
        addSmsQuery: {
          isAd: undefined,
          templateName: "",
          templateContent: "",
        },
      }
    ),
    modalAddressBook: reducerModal<IStore>(
      set,
      "modalAddressBook",
      false,
      () => {
        get().formReqMessage.onChangeForm({
          selectedAddressBooks: [],
          addressBookSearchType: "all",
          addressBookSearchInput: [],
        });
      }
    ),
    modalLoadMessage: reducerModal<IStore>(
      set,
      "modalLoadMessage",
      false,
      () => {
        get().formReqMessage.onChangeForm({
          selectedTemplateOnModal: undefined,
          templateParams: [],
        });
      }
    ),
    modalSelectTemplate: reducerModal<IStore>(
      set,
      "modalSelectTemplate",
      false,
      () => {
        get().formReqMessage.onChangeForm({
          selectedAlimtalkTemplateOnModal: undefined,
          templateParams: [],
        });
      }
    ),
    modalAddMessage: reducerModal<IStore>(
      set,
      "modalAddMessage",
      false,
      () => {
        get().formReqMessage.onChangeForm({
          addSmsQuery: {
            isAd: undefined,
            templateName: "",
            templateContent: "",
          },
        });
      },
      () => {
        const { form, onChangeForm } = get().formReqMessage;
        const templateName =
          !form!.files.length && getByte(form.message) <= 90 ? form.title : "";
        const templateContent = form.message;
        onChangeForm({
          addSmsQuery: {
            isAd: form.checkedAds ? 1 : 0,
            templateName,
            templateContent,
          },
        });
      }
    ),
    onRemoveSelectedReceivedNumbers: (custormerIdxs: number[]) => {
      const updatedReceivedNumbers = get().receivedNumbers.filter(
        (v) => !custormerIdxs.includes(v.custormerIdx)
      );
      set({
        receivedNumbers: updatedReceivedNumbers,
        selectedReceivedNumbers: [],
      });
    },
    onConfirmModalAddressBook: () => {
      const { receivedNumbers, formReqMessage } = get();
      const { selectedAddressBooks } = formReqMessage.form;
      if (selectedAddressBooks.length > 0) {
        const currentReceivedNumbers = receivedNumbers.map(
          (v) => v.custormerPhone1
        );
        const filteredNumbers = selectedAddressBooks.filter(
          (v) => !currentReceivedNumbers.includes(v.custormerPhone1)
        );
        set({
          receivedNumbers: [...receivedNumbers, ...filteredNumbers],
          // receivedList: [...receivedNumbers, ...filteredNumbers]
        });
        get().modalAddressBook.onClose();
      }
    },
    onChangeSendType: (sendType) => {
      const { formReqMessage } = get();
      set({
        sendType,
        formReqMessage: {
          ...formReqMessage,
          form: {
            ...formReqMessage.form,
            reservationDate: undefined,
          },
        },
      });
    },
    onConfirmModalLoadMessage: () => {
      const { templateParams } = get().formReqMessage.form;
      let updatedTemplateParameter: string | undefined = undefined;
      if (templateParams?.length > 0) {
        const templateValues = templateParams.map((v) => v.value);
        if (
          templateValues.some(
            (v) => v.replace(/ /g, "").length < 1 || v.length < 1
          )
        ) {
          return message.warn("치환문자를 입력해주세요");
        }
        if (templateValues.length !== [...new Set(templateValues)].length) {
          return message.warn("치환문자가 중복되었습니다");
        }
        let obj = {};
        templateParams.map((v) => {
          Object.assign(obj, {
            [v.key]: v.value,
          });
        });
        updatedTemplateParameter = JSON.stringify(obj);
      }
      const formReqMessage = get().formReqMessage;
      if (formReqMessage.form?.selectedTemplateOnModal) {
        formReqMessage.onChangeForm({
          updatedTemplateParameter,
          message: getTemplateFromParams(
            formReqMessage.form?.selectedTemplateOnModal?.templateContent,
            templateParams
          ),
          selectedTemplateOnModal: undefined,
        });
        get().modalLoadMessage.onClose();
      }
    },
    onConfirmModalSelectTemplate: () => {
      const { templateParams } = get().formReqMessage.form;
      let updatedTemplateParameter: string | undefined = undefined;
      if (templateParams?.length > 0) {
        const templateValues = templateParams.map((v) => v.value);
        if (
          templateValues.some(
            (v) => v.replace(/ /g, "").length < 1 || v.length < 1
          )
        ) {
          return message.warn("치환문자를 입력해주세요");
        }
        if (templateValues.length !== [...new Set(templateValues)].length) {
          return message.warn("치환문자가 중복되었습니다");
        }
        let obj = {};
        templateParams.map((v) => {
          Object.assign(obj, {
            [v.key]: v.value,
          });
        });
        updatedTemplateParameter = JSON.stringify(obj);
      }
      const formReqMessage = get().formReqMessage;
      if (formReqMessage.form?.selectedAlimtalkTemplateOnModal) {
        set({
          selectedAlimtalkTemplate:
            formReqMessage.form?.selectedAlimtalkTemplateOnModal,
          formReqMessage: {
            ...formReqMessage,
            form: {
              ...formReqMessage.form,
              updatedTemplateParameter,
              message: getTemplateFromParams(
                formReqMessage.form?.selectedAlimtalkTemplateOnModal
                  ?.templateContent,
                templateParams
              ),
            },
          },
        });
        get().modalSelectTemplate.onClose();
      }
    },
    onChangeSendMethod: (sendMethod, phone, profile) => {
      // TODO : 문자정보 or 템플릿정보 초기화.
      const { formReqMessage } = get();
      set({
        sendMethod,
        selectedAlimtalkTemplate: undefined,
        formReqMessage: {
          ...formReqMessage,
          form: {
            ...formReqMessage.form,
            title: "",
            message: "",
            selectedPhone: phone || undefined,
            selectedProfile: profile || undefined,
            checkedAds: false,
            files: [],
            previews: [],
            templateParams: [],
            updatedTemplateParameter: undefined,
          },
        },
      });
    },
    onChangeReceivedNumbersFromExcel: (data) => {
      if (data.length > 0) {
        const currentPhoneNumbers = get().receivedNumbers.map(
          (v) => v.custormerPhone1
        );
        const addedReceivedNumbers = data
          .filter(
            // TODO : 스웨거 변경시 수정하기
            (v) => !currentPhoneNumbers.includes(v.custormerPhone1)
          )
          .map(
            (v, i) =>
              ({
                phone1AdFlag: 0,
                registerDate: "",
                custormerGroup: "",
                custormerIdx: Date.now() + i,
                custormerName: v.custormerName,
                custormerEmail : v.custormerEmail,

                custormerPhone1: v.custormerPhone1,
              } as CustormerReadResponseData)
          );
        set({
          receivedNumbers: [...get().receivedNumbers, ...addedReceivedNumbers],
          // selectedReceivedNumbers: [],
        });
      }
    },
    onChangeReceivedNumbers: (numbers) => {
      set({
        receivedNumbers: numbers,
      });
    },
    onAddReceivedNumber: (data: CustormerAddToListResponseData) => {
      set({
        receivedNumbers: [
          ...get().receivedNumbers,
          {
            custormerPhone1: data.custormerPhone1,
            custormerEmail: "",
            custormerGroup: "",
            custormerIdx: data.custormerIdx || Date.now(),
            custormerName: data.custormerName,
            phone1AdFlag: 0,
            registerDate: "",
            isPublic: 0,
            privateTaggedInformation: [],
            publicTaggedInformation: [],
          },
        ],
      });
    },
    // onAddReceivedList: (data: CustormerAddToListResponseData) => {
    //   set({
    //     receivedList: [
    //       ...get().receivedList,
    //       {
    //         custormerPhone1: data.custormerPhone1,
    //         custormerEmail: "",
    //         custormerGroup: "",
    //         custormerIdx: data.custormerIdx || Date.now(),
    //         custormerName: data.custormerName,
    //         phone1AdFlag: 0,
    //         registerDate: "",
    //         isPublic: 0,
    //         privateTaggedInformation: [],
    //         publicTaggedInformation: [],
    //       },
    //     ],
    //   });
    // },
    // onResetList: () => {
  
    //     set({
    //       receivedList: []
    //     });
       
    // },
    onChangeSelectedReceivedNumbers: (numbers) => {
      set({
        selectedReceivedNumbers: numbers,
      });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** [메시지] > [메시지 발송] 네비메뉴 상태관리 */
export const useMessageSendStore = create<IStore>()(
  devtools(initialStateCreator)
);
