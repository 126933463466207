import { Form, Input } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useMyPageAccountStore } from "../stores";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const UpdatePasswordModalContent: React.FC<IProps> = ({}) => {
  const store = useMyPageAccountStore((s) => s);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      store.formUpdatePassword.onChangeForm({
        [name]: value,
      });
    },
    []
  );

  if(store.formUpdatePassword.form.password ===store.formUpdatePassword.form.passwordConfirm &&
     store.formUpdatePassword.form.password.length !=0 &&
      store.formUpdatePassword.form.passwordConfirm.length !=0 
      ){
    return (
      <Container>
        <Form layout="vertical">
          <Form.Item required label="패스워드">
            <Input
              autoFocus
              type="password"
              placeholder="패스워드를 입력해주세요"
              name="password"
              value={store.formUpdatePassword.form.password}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item required label="패스워드 확인">
            <Input
              type="password"
              placeholder="패스워드 확인을 입력해주세요"
              name="passwordConfirm"
              value={store.formUpdatePassword.form.passwordConfirm}
              onChange={onChange}
            />
          </Form.Item>
          <div style={{color :"blue"}}>*비밀번호 일치합니다!</div>
        </Form>
      </Container>
    );
  }
  else if(store.formUpdatePassword.form.password !==store.formUpdatePassword.form.passwordConfirm && 
    store.formUpdatePassword.form.password.length !=0 && store.formUpdatePassword.form.passwordConfirm.length !=0 ){
    return (
      <Container>
        <Form layout="vertical">
          <Form.Item required label="패스워드">
            <Input
              autoFocus
              type="password"
              placeholder="패스워드를 입력해주세요"
              name="password"
              value={store.formUpdatePassword.form.password}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item required label="패스워드 확인">
            <Input
              type="password"
              placeholder="패스워드 확인을 입력해주세요"
              name="passwordConfirm"
              value={store.formUpdatePassword.form.passwordConfirm}
              onChange={onChange}
            />
          </Form.Item>
          <div style={{color :"red"}}>*비밀번호가 불일치합니다!</div>
        </Form>
      </Container>
    );
  }
  else if(store.formUpdatePassword.form.password.length ==0 && store.formUpdatePassword.form.passwordConfirm.length ==0){
    return (
      <Container>
        <Form layout="vertical">
          <Form.Item required label="패스워드">
            <Input
              autoFocus
              type="password"
              placeholder="패스워드를 입력해주세요"
              name="password"
              value={store.formUpdatePassword.form.password}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item required label="패스워드 확인">
            <Input
              type="password"
              placeholder="패스워드 확인을 입력해주세요"
              name="passwordConfirm"
              value={store.formUpdatePassword.form.passwordConfirm}
              onChange={onChange}
            />
          </Form.Item>
         
        </Form>
      </Container>
    );
  }
  else 
  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="패스워드">
          <Input
            autoFocus
            type="password"
            placeholder="패스워드를 입력해주세요"
            name="password"
            value={store.formUpdatePassword.form.password}
            onChange={onChange}
          />
        </Form.Item>
        <Form.Item required label="패스워드 확인">
          <Input
            type="password"
            placeholder="패스워드 확인을 입력해주세요"
            name="passwordConfirm"
            value={store.formUpdatePassword.form.passwordConfirm}
            onChange={onChange}
          />
        </Form.Item>
        {/* <div style={{color :"red"}}>비밀번호 불일치</div> */}
      </Form>
    </Container>
  );
};

const Container = styled.div``;

const WarningText = styled.p`
  display: flex;
  font-size: 12px;
  gap: 6px;
  color: #686868;
`;
