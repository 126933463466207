import { setAccessToken, setRefreshToken } from "../auth";
import { useAppStore } from "../stores";
import base64 from "base-64";

/**
 * JWT Payload.
 * - exp : 토큰 만료시간 (unix time 포맷)
 * - sub : 순번 (index)
 */
export interface JwtPayload {
  exp: string;
  sub: string;
}
export interface JwtHeader {
  alg: string;
  typ: string;
}

export class JwtObject {
  constructor(
    private readonly header: JwtHeader,
    private readonly payload: JwtPayload
  ) {}
  public getJwtHeader(): JwtHeader {
    return this.header;
  }

  public getPayload(): JwtPayload {
    return this.payload;
  }

  public get isExpired() {
    return +this.payload.exp * 1000 - 60 * 1000 * 5 <= Date.now();

    /** 토큰만료시간 - 59분전에 파기되었다고 반환 */
    // return +this.payload.exp - 60 * 1000 * 59 <= Date.now();
  }

  /**
   * 로그인 유저 순번을 반환한다. (tmcode)
   * @returns 로그인 유저 순번 (숫자)
   */
  get getIndex(): number {
    return +this.payload.sub;
  }

  // REVIEW : 토큰내에 사용자 타입이 없음.
  /**
   * 사용자 타입을 반환한다.
   * @returns 권한 (운영자:"mr", 사용자:"u",)
   */
  // getUserType():
}

export class JwtHelper {
  public static getJwtObject(token: string) {
    try {
      const [h, p] = token.split(".");
      const header: JwtHeader = JSON.parse(this.base64Decode(h));
      const payload: JwtPayload = JSON.parse(this.base64Decode(p));
      // const header: JwtHeader = JSON.parse(this.base64Decode(this.base64UrlToBase64(h)));
      // const payload: JwtPayload = JSON.parse(this.base64Decode(this.base64UrlToBase64(p)));
      return new JwtObject(header, payload);
    } catch (e) {
      console.error("잘못된 토큰정보입니다: ", e);
      alert("잘못된 토큰정보입니다");
      setAccessToken("");
      useAppStore.getState().onLogout();
      // window.location.pathname = "/";
      // throw new Error('잘못된 토큰정보입니다');
    }
  }
  private static base64Encode(str: string) {
    return base64.encode(str);
  }

  private static base64Decode(encode: string) {
    return base64.decode(encode);
  }
}
