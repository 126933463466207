import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { device } from "../device";
import { AlimtalkControllerReadCategoriesParams } from "../generated";
import { privateApi } from "../react-query";
import { useMessageSetStore } from "../stores";
import { IComponentProps } from "../types";
import { isSuccess } from "../utils";

interface IProps extends IComponentProps {}

const fetcherGetProfiles = () =>
  privateApi.company.companyControllerReadKakaoProfile();

const fetcherGetCategories = (params: AlimtalkControllerReadCategoriesParams) =>
  privateApi.alimtalk.alimtalkControllerReadCategories(params);

export const AddedTemplateModalContent: React.FC<IProps> = () => {
  const store = useMessageSetStore((s) => s);
  const { isLoading: loadingGetProfiles, data: dataGetProfiles } = useQuery(
    ["companyControllerReadKakaoProfile"],
    fetcherGetProfiles,
    {
      enabled: store.modalAddTemplate.visible,
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("companyControllerReadKakaoProfile onSuccess: ", e);
          // e.data.body.data[0].plusFriendId
          if (e.data.body.data.length > 0) {
            store.formSms.onChangeForm({
              selectedTemplateOnModal: e.data.body.data[0],
            });
          }
        }
      },
      onError: (e) => {
        console.log("companyControllerReadKakaoProfile onError: ", e);
      },
    }
  );

  const {
    isLoading: loadingGetCategoriesOnCode1,
    data: dataGetCategoriesOnCode1,
    mutate: mutateGetCategoriesOnCode1,
  } = useMutation(fetcherGetCategories, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("alimtalkControllerReadCategories onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("alimtalkControllerReadCategories onError: ", e);
    },
  });

  const {
    isLoading: loadingGetCategoriesOnCode2,
    data: dataGetCategoriesOnCode2,
    mutate: mutateGetCategoriesOnCode2,
  } = useMutation(fetcherGetCategories, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("alimtalkControllerReadCategories onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("alimtalkControllerReadCategories onError: ", e);
    },
  });

  /**
   * [발신프로필] 선택시
   */
  const onSelectProfile = (idx: number) => {
    if (dataGetProfiles?.data) {
      const selectedProfile = dataGetProfiles.data.body.data.find(
        (v) => v.idx === idx
      );
      if (selectedProfile) {
        store.formSms.onChangeForm({
          selectedTemplateOnModal: selectedProfile,
        });
      }
    }
  };

  /** [템플릿명] or [넴플릿 내용] 수정 */
  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { name, value },
    } = e;
    // console.log(e.target.value)
    store.formSms.onChangeForm({
      [name]: value,
    });
  };

  /** 카테고리 "대분류" 업데이트 */
  const onSelectCategoryCode1 = (code: string) => {
    if (dataGetCategoriesOnCode1?.data) {
      const selectCategory = dataGetCategoriesOnCode1.data.body.data.find(
        (v) => v.code === code
      );
      console.log({ selectCategory });
      if (selectCategory) {
        mutateGetCategoriesOnCode2({ parentCode: selectCategory.code });
        store.formSms.onChangeForm({
          templateCode1: code,
          templateCode2: "all",
        });
      } else {
        // '대분류' 선택한경우
        store.formSms.onChangeForm({
          templateCode1: "all",
          templateCode2: "all",
        });
      }
    }
  };

  /** 카테고리 "중분류" 업데이트 */
  const onSelectCategoryCode2 = (code: string) => {
    if (dataGetCategoriesOnCode2?.data) {
      const selectCategory = dataGetCategoriesOnCode2.data.body.data.find(
        (v) => v.code === code
      );
      if (selectCategory) {
        store.formSms.onChangeForm({
          templateCode2: code,
        });
      } else {
        // '중분류' 선택한경우
        store.formSms.onChangeForm({
          templateCode2: "all",
        });
      }
    }
  };

  useEffect(() => {
    mutateGetCategoriesOnCode1({
      parentCode: "0",
    });
  }, []);

  return (
    <Container>
      <Form layout="vertical">
        <Group>
          <Cell>
            <Form.Item required label="발신프로필">
              <Select
                loading={loadingGetProfiles}
                style={{ width: "100%" }}
                onChange={onSelectProfile}
                value={store.formSms.form?.selectedTemplateOnModal?.idx}
                options={
                  dataGetProfiles?.data &&
                  dataGetProfiles.data.body.data.length > 0
                    ? dataGetProfiles.data.body.data.map((v) => ({
                        label: v.plusFriendId,
                        value: v.idx,
                      }))
                    : []
                }
              />
            </Form.Item>
            <Form.Item required label="템플릿명">
              <Input
                name="templateNameOnModal"
                placeholder="템플릿명을 입력해주세요"
                maxLength={50}
                onChange={onChangeInput}
              />
            </Form.Item>
            <Form.Item required label="카테고리">
              <SelectSpace>
                <Select
                  style={{ width: "100%" }}
                  value={store.formSms.form.templateCode1}
                  options={
                    dataGetCategoriesOnCode1?.data &&
                    dataGetCategoriesOnCode1.data.body.data.length > 0
                      ? ([
                          {
                            label: "대분류",
                            value: "all",
                          },
                        ].concat(
                          dataGetCategoriesOnCode1?.data.body.data.map((v) => ({
                            label: v.name,
                            value: v.code,
                          }))
                        ) as any)
                      : [
                          {
                            label: "대분류",
                            value: "all",
                          },
                        ]
                  }
                  onChange={onSelectCategoryCode1}
                />
                <Select
                  style={{ width: "100%" }}
                  disabled={store.formSms.form.templateCode1 === "all"}
                  value={store.formSms.form.templateCode2}
                  options={
                    dataGetCategoriesOnCode2?.data &&
                    dataGetCategoriesOnCode2.data.body.data.length > 0
                      ? ([
                          {
                            label: "중분류",
                            value: "all",
                          },
                        ].concat(
                          dataGetCategoriesOnCode2?.data.body.data.map((v) => ({
                            label: v.name,
                            value: v.code,
                          }))
                        ) as any)
                      : [
                          {
                            label: "중분류",
                            value: "all",
                          },
                        ]
                  }
                  onChange={onSelectCategoryCode2}
                />
              </SelectSpace>
            </Form.Item>
          </Cell>
          <Divide />
          <Cell>
            <Form.Item required label="템플릿 내용">
              <Input.TextArea
                placeholder="템플릿 내용을 입력해주세요"
                rows={12}
                maxLength={2000}
                name="templateContentOnModal"
                onChange={onChangeInput}
              />
            </Form.Item>
          </Cell>
        </Group>
      </Form>
    </Container>
  );
};

const Container = styled.div``;

const Group = styled.div`
  display: flex;
  width: 100%;

  @media ${device.laptop.max} {
    flex-flow: column;
  }
`;

const Cell = styled.div`
  flex: 1;
  @media ${device.laptop.max} {
    textarea {
      max-height: 130px;
      overflow-y: auto;
    }
  }
`;

const SelectSpace = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Divide = styled.div`
  flex: 1;
  max-width: 3px;
  background-color: #dfdfdf;
  margin: 0 10px;

  @media ${device.laptop.max} {
    display: none;
  }
`;
