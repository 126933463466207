/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BaseResponseHeader {
  isSuccess: true | false;
  resultCode: string;
  resultMessage: string;
  timestamp: string;
}

export interface BaseResponseDto {
  header: BaseResponseHeader;
}

export interface InvitationResponseData {
  /** 계정 INDEX */
  userIdx: number;

  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;
}

export interface InvitationResponseBody {
  data: InvitationResponseData;
}

export interface InvitationResponseDTO {
  header: BaseResponseHeader;
  body: InvitationResponseBody;
}

export interface MemberUpdateByCodeDTO {
  /** 계정 INDEX */
  userIdx: number;

  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 키값
   * @example 1234
   */
  code: string;

  /**
   * 패스워드
   * @example 1234
   */
  password?: string;
}

export interface MemberCreateDTO {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 패스워드
   * @example 1234
   */
  password: string;

  /**
   * iamport 본인인증 response
   * @example 1234
   */
  imp_uid: string;
}

export interface AttachedCompanyCertResponseData {
  /**
   * 파일명
   * @example 1654664169682.jpg
   */
  fileName: string;
}

export interface AttachedCompanyCertResponseBody {
  data: AttachedCompanyCertResponseData;
}

export interface AttachedCompanyCertResponseDTO {
  header: BaseResponseHeader;
  body: AttachedCompanyCertResponseBody;
}

export interface MemberCreateAsCompanyDTO {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 패스워드
   * @example 1234
   */
  password: string;

  /**
   * 회사 이름
   * @example 리디안랩스
   */
  companyName: string;

  /**
   * 회사 대표번호
   * @example 리디안랩스
   */
  companyPhone: string;

  /**
   * 법인: 법인등록번호, 개인: 생년월일
   * @example 1234
   */
  residentNumber: string;

  /**
   * 사업자등록번호
   * @example 1234
   */
  registrationNumber: string;

  /**
   * 사업자등록증 파일명
   * @example 1234.nnn
   */
  companyCertFileName: string;

  /**
   * 대표자명
   * @example 홍길동
   */
  presidentName: string;

  /**
   * iamport 본인인증 response
   * @example 1234
   */
  imp_uid?: string;

  /**
   * 통신가입증명원으로 인증받을 전화번호
   * @example 1234
   */
  carrierCertFilePhone?: string;

  /**
   * 통신가입증명원 파일명
   * @example 1234.nnn
   */
  carrierCertFileName?: string;
}

export interface MemberCreateFromCompanyDTO {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 멤버 이름
   * @example 테스트
   */
  userName: string;
}

export interface MemberLogInDTO {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 패스워드
   * @example 1234
   */
  password: string;
}

export interface MemberLogInResponseData {
  /**
   * Access Token
   * @example eyJhbGciOiJIUzI1NiIsInR5cCI6
   */
  token: string;
}

export interface MemberLogInResponseBody {
  data: MemberLogInResponseData;
}

export interface MemberLogInResponseDTO {
  header: BaseResponseHeader;
  body: MemberLogInResponseBody;
}

export interface MemberLogInByAccesskeyDTO {
  /**
   * accesskey
   * @example 1234
   */
  accesskey: string;
}

export interface CompanyCertPhoneEntity {
  /** 전화번호 INDEX */
  idx: number;

  /**
   * 신청번호
   * @example 01012345678
   */
  phone: string;

  /**
   * 전화번호 심사상태(0:대기, 1:승인, 2:거부, 9:차단)
   * @example 1
   */
  phoneCertFlag: 0 | 1 | 2 | 9;

  /**
   * 전화번호 인증방법(DANAL:휴대폰인증, DOC:서류인증)
   * @example DANAL
   */
  phoneCertType: "DANAL" | "DOC";

  /**
   * 전화번호 인증서류
   * @example 리디안랩스
   */
  phoneCertUrl: string;

  /** 재직증명서 */
  employmentCertUrl: string;

  /**
   * 등록일
   * @format date-time
   */
  createAt: string;
}

export interface CompanyCertKakaoEntity {
  /** 발신프로필 INDEX */
  idx: number;

  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 신청번호
   * @example 01012345678
   */
  phoneNo: string;

  /**
   * 발신 프로필 심사상태(YSC02: 대기, YSC03: 승인)
   * @example YSC03
   */
  status: "YSC02" | "YSC03";

  /**
   * 등록일
   * @format date-time
   */
  createAt: string;
}

export interface ChargePlanEntity {
  /** 요금제 INDEX */
  chargePlanIdx: number;

  /**
   * 요금제코드
   * @example 0000
   */
  chargePlanCode: string;

  /**
   * 요금제명
   * @example 기본요금제
   */
  chargePlanName: string;

  /**
   * sms 차감금액
   * @example 1.10
   */
  chargeSms: number;

  /**
   * lms 차감금액
   * @example 1.10
   */
  chargeLms: number;

  /**
   * mms 차감금액
   * @example 1.10
   */
  chargeMms: number;

  /**
   * 알림톡 차감금액
   * @example 1.10
   */
  chargeAlimtalk: number;

  /**
   * 요금제 그룹
   * @example 0
   */
  chargeGroup: number;
}

export interface CompanyEntity {
  /**
   * 회사이름
   * @example 리디안랩스
   */
  companyName: string;

  /**
   * 회사 대표번호
   * @example 0212345678
   */
  companyPhone: string;

  /**
   * 법인등록번호
   * @example 1234567890123
   */
  presidentName: string;

  /**
   * 법인등록번호
   * @example 1234567890123
   */
  residentNumber: string;

  /**
   * 사업자등록번호
   * @example 1234567890123
   */
  registrationNumber: string;

  /**
   * 잔액
   * @example 0.00
   */
  balance: number;

  /**
   * 사업자 인증여부(대기, 승인, 반려)
   * @example 1
   */
  companyCertFlag: 0 | 1 | 2;

  /**
   * 080 수신거부 번호
   * @example 08012345678
   */
  companyUnsubscribeNo: string;
  certPhoneInformation: CompanyCertPhoneEntity[];
  certKakaoInformation: CompanyCertKakaoEntity[];
  basicChargePlanInformation: ChargePlanEntity;
  chargePlanInformation: ChargePlanEntity;

  /**
   * 프로모션 만료일
   * @example 2022-06-16T10:36:03.000Z
   */
  promotionExpiryDate: string;

  /**
   * 등록일
   * @format date-time
   */
  createAt: string;

  /** @example true */
  isAllowed: 0 | 1;
}

export interface MemberReadResponseData {
  /** 계정 INDEX */
  userIdx: number;

  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 멤버 이름
   * @example 테스트
   */
  userName: string;

  /**
   * 계정 권한, 100:마스터(개인), 101:일반(개인) 900:마스터(기업), 901:일반(기업)
   * @example 100
   */
  userRole: string;
  companyInformation: CompanyEntity;

  /**
   * 마스터아이디 관리하는 허용여부(true, false)
   * @example true
   */
  companyAccessFlag: 0 | 1;

  /**
   * 등록 시간
   * @format date-time
   * @example 2022-06-16T10:36:03.000Z
   */
  registerDate: string;

  /**
   * accesskey
   * @example 1234
   */
  accesskey: string;
}

export interface MemberReadResponseBody {
  data: MemberReadResponseData;
}

export interface MemberReadResponseDTO {
  header: BaseResponseHeader;
  body: MemberReadResponseBody;
}

export interface MemberAllReadResponseData {
  /** 계정 INDEX */
  userIdx: number;

  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 멤버 이름
   * @example 테스트
   */
  userName: string;

  /**
   * 계정 권한, 100:마스터(개인), 101:일반(개인) 900:마스터(기업), 901:일반(기업)
   * @example 100
   */
  userRole: string;

  /**
   * 마스터아이디 관리하는 허용여부(true, false)
   * @example true
   */
  companyAccessFlag: 0 | 1;

  /**
   * 등록 시간
   * @format date-time
   * @example 2022-06-16T10:36:03.000Z
   */
  registerDate: string;
}

export interface MemberAllReadResponseBody {
  total: number;
  data: MemberAllReadResponseData[];
}

export interface MemberAllReadResponseDTO {
  header: BaseResponseHeader;
  body: MemberAllReadResponseBody;
}

export interface MemberUpdateDTO {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;

  /**
   * 패스워드
   * @example 1234
   */
  password?: string;

  /**
   * 아이디 허용 여부(true, false)
   * @example true
   */
  companyAccessFlag?: 0 | 1;
}

export interface MemberInvitationDTO {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;
}

export type MessageHookDTO = object;

export type AlimtalkHookDTO = object;

export interface AttachedResponseData {
  /**
   * 파일 아이디
   * @example 86978851
   */
  fileId: number;

  /**
   * 파일명
   * @example 1654664169682.jpg
   */
  fileName: string;

  /**
   * 첨부파일 기본경로
   * @example /temporary/4982/toast-mt-2022-06-08/1356/86978851
   */
  filePath: string;
}

export interface AttachedResponseBody {
  data: AttachedResponseData[];
}

export interface AttachedResponseDTO {
  header: BaseResponseHeader;
  body: AttachedResponseBody;
}

export interface RecipientList {
  /** 수신 번호 */
  recipientNo: string;

  /** 주수록 반환 IDX */
  custormerIdx: string;

  /** 주소록 반환 이름 */
  custormerName: string;
}

export interface MessageSenderSmsDTO {
  /**
   * 광고 여부(0: 광고아님, 1: 광고)
   * @example 0
   */
  isAd?: 0 | 1;

  /**
   * 메시지 내용
   * @example test
   */
  body: string;

  /**
   * 발신 번호
   * @example 01012345678
   */
  sendNo: string;

  /**
   * 예약 발송
   * @example 2018-08-10 10:00
   */
  requestDate?: string;
  recipientList: RecipientList[];
}

export interface MessageSendResponseData {
  /**
   * 메시지 uuid
   * @example abc
   */
  requestId: string;
}

export interface MessageSendResponseBody {
  total: number;
  data: MessageSendResponseData;
}

export interface MessageSendResponseDTO {
  header: BaseResponseHeader;
  body: MessageSendResponseBody;
}

export interface MessageSenderMmsDTO {
  /**
   * 광고 여부(0: 광고아님, 1: 광고)
   * @example 0
   */
  isAd?: 0 | 1;

  /**
   * 메시지 제목
   * @example test
   */
  title: string;

  /**
   * 메시지 내용
   * @example test
   */
  body: string;

  /**
   * 발신 번호
   * @example 01012345678
   */
  sendNo: string;

  /**
   * 예약 발송
   * @example 2018-08-10 10:00
   */
  requestDate?: string;
  recipientList: RecipientList[];

  /**
   * 첨부파일
   * @example 첨부파일 업로드후 얻은 id값
   */
  attachFileId?: number[];
}

export interface MessageSenderAlimtalkDTO {
  /**
   * 플러스친구 아이디
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 템플릿 코드
   * @example test0001
   */
  templateCode: string;

  /**
   * 예약 발송
   * @example 2018-08-10 10:00
   */
  requestDate?: string;
  recipientList: RecipientList[];

  /**
   * 메시지 내용
   * @example test
   */
  body: string;

  /**
   * 치환 key:value
   * @example {"의미없는문자": "테스트"},{"의미없는숫자": "123"}
   */
  templateParameter?: object;
}

export interface MessageReadResponseData {
  /**
   * 메시지 uuid
   * @example abc
   */
  requestId: string;

  /**
   * 발송자
   * @example 테스트
   */
  userName: string;

  /**
   * 메시지 발송일
   * @example 2022-06-16T10:36:03.000Z
   */
  registerDate: string;

  /**
   * 메시지 발송일
   * @example 2022-06-16T10:36:03.000Z
   */
  requestDate: string;

  /**
   * 발송타입
   * @example alimtalk
   */
  messageType: string;

  /**
   * 총 발송 건수
   * @example 1
   */
  requested: string;

  /**
   * 성공 건수
   * @example 1
   */
  sentSuccess: string;

  /**
   * 실패 건수
   * @example 0
   */
  sentFailed: string;

  /**
   * 전송중
   * @example 0
   */
  pending: string;

  /**
   * 메세지 내용
   * @example abc
   */
  body: string;
}

export interface MessageReadResponseBody {
  total: number;
  data: MessageReadResponseData[];
}

export interface MessageReadResponseDTO {
  header: BaseResponseHeader;
  body: MessageReadResponseBody;
}

export interface MessageReadStatsResponseData {
  /**
   * 메시지 발송일
   * @example 2022-06-16
   */
  requestDate: string;

  /**
   * 총 발송 건수
   * @example 1
   */
  requested: string;

  /**
   * sms 건수
   * @example 0
   */
  smsSent: string;

  /**
   * sms 성공 건수
   * @example 0
   */
  smsSentSuccess: string;

  /**
   * sms 실패 건수
   * @example 0
   */
  smsSentFailed: string;

  /**
   * sms 전송중
   * @example 0
   */
  smsPending: string;

  /**
   * lms 건수
   * @example 0
   */
  lmsSent: string;

  /**
   * lms 성공 건수
   * @example 0
   */
  lmsSentSuccess: string;

  /**
   * lms 실패 건수
   * @example 0
   */
  lmsSentFailed: string;

  /**
   * lms 전송중
   * @example 0
   */
  lmsPending: string;

  /**
   * mms 건수
   * @example 0
   */
  mmsSent: string;

  /**
   * mms 성공 건수
   * @example 0
   */
  mmsSentSuccess: string;

  /**
   * mms 실패 건수
   * @example 0
   */
  mmsSentFailed: string;

  /**
   * mms 전송중
   * @example 0
   */
  mmsPending: string;

  /**
   * alimtalk 건수
   * @example 1
   */
  alimtalkSent: string;

  /**
   * alimtalk 성공 건수
   * @example 1
   */
  alimtalkSentSuccess: string;

  /**
   * alimtalk 실패 건수
   * @example 0
   */
  alimtalkSentFailed: string;

  /**
   * alimtalk 전송중
   * @example 0
   */
  alimtalkPending: string;
}

export interface MessageReadStatsResponseBody {
  total: number;
  data: MessageReadStatsResponseData[];
}

export interface MessageReadStatsResponseDTO {
  header: BaseResponseHeader;
  body: MessageReadStatsResponseBody;
}

export interface MessageReadOneResponseData {
  /**
   * 수신자 번호
   * @example 01012345678
   */
  recipientNo: string;

  /**
   * 메세지 내용
   * @example abc
   */
  body: string;

  /** 발송 결과 */
  resultMessage: string;
}

export interface MessageReadOneResponseBody {
  total: number;
  data: MessageReadOneResponseData[];
}

export interface MessageReadOneResponseDTO {
  header: BaseResponseHeader;
  body: MessageReadOneResponseBody;
}

export interface CustormerExcelResponseData {
  /**
   * 고객 이름
   * @example test
   */
  custormerName: string;

  /**
   * 전화번호1
   * @example 01012345678
   */
  custormerPhone1: string;

  /**
   * 이메일
   * @example test@test.com
   */
  custormerEmail: string;

  /**
   * 그룹
   * @example 리디안랩스
   */
  custormerGroup: string;

  /**
   * 태그
   * @example #리디안랩스
   */
  custormerTag: string;
}

export interface CustormerExcelResponseBody {
  total: number;
  data: CustormerExcelResponseData[];
}

export interface CustormerExcelResponseDTO {
  header: BaseResponseHeader;
  body: CustormerExcelResponseBody;
}

export interface CustormerTagCreateDTO {
  /**
   * 0: 개인주소록, 1:공용주소록
   * @example true
   */
  isPublic: 0 | 1;

  /**
   * 해시태그
   * @example #123#456
   */
  tagName: string;
}

export interface CustormerTag {
  /** Tag INDEX */
  tagIdx: number;

  /**
   * 태그 이름
   * @example test
   */
  tagName: string;

  /**
   * public:공유, private:개인
   * @example 1
   */
  isPublic: "public" | "private";
}

export interface CustormerTagData {
  publicTags: CustormerTag[];
  privateTags: CustormerTag[];
}

export interface CustormerTagReadResponseBody {
  data: CustormerTagData[];
}

export interface CustormerTagReadResponseDTO {
  header: BaseResponseHeader;
  body: CustormerTagReadResponseBody;
}

export interface CustormerTagDeleteDTO {
  /**
   * 0: 개인주소록, 1:공용주소록
   * @example true
   */
  isPublic: 0 | 1;
}

export interface CustormerCreateList {
  /**
   * 고객 이름
   * @example test
   */
  custormerName: string;

  /**
   * 고객 그룹
   * @example 1234
   */
  custormerGroup?: string;

  /**
   * 전화번호1
   * @example 01012345678
   */
  custormerPhone1: string;

  /**
   * 전화번호1 광고 수신여부
   * @example true
   */
  phone1AdFlag?: 0 | 1;

  /**
   * 이메일
   * @example test@test.com
   */
  custormerEmail?: string;

  /**
   * 0: 개인주소록, 1:공용주소록
   * @example true
   */
  isPublic: 0 | 1;

  /**
   * 해시태그
   * @example #123#456
   */
  tagName?: string;
}

export interface CustormerCreateDTO {
  custormerList: CustormerCreateList[];
}

export interface TagIdx {
  /** 태그 INDEX */
  tagIdx: number;

  /** 태그명 */
  tagName: string;
}

export interface TaggedInformation {
  /** 태깅된 INDEX */
  taggedIdx: number;
  tagIdx: TagIdx;
}

export interface CustormerReadResponseData {
  /** 고객 INDEX */
  custormerIdx: number;

  /**
   * 고객 이름
   * @example test
   */
  custormerName: string;

  /**
   * 고객 그룹
   * @example 1234
   */
  custormerGroup: string;

  /**
   * 전화번호1
   * @example 01012345678
   */
  custormerPhone1: string;

  /**
   * 이메일
   * @example test@test.com
   */
  custormerEmail: string;

  /**
   * 전화번호1 광고 수신여부(true, false)
   * @example 1
   */
  phone1AdFlag: 0 | 1;

  /**
   * 고객 이름
   * @example 2022-06-28T13:05:39.000Z
   */
  registerDate: string;

  /**
   * 0: 개인주소록, 1:공용주소록
   * @example 1
   */
  isPublic: number;
  publicTaggedInformation: TaggedInformation[];
  privateTaggedInformation: TaggedInformation[];
}

export interface CustormerReadResponseBody {
  total: number;
  data: CustormerReadResponseData[];
}

export interface CustormerReadResponseDTO {
  header: BaseResponseHeader;
  body: CustormerReadResponseBody;
}

export interface CustormerAddToListResponseData {
  /** 고객 INDEX */
  custormerIdx: number;

  /**
   * 고객 이름
   * @example test
   */
  custormerName: string;

  /**
   * 전화번호1
   * @example 01012345678
   */
  custormerPhone1: string;
}

export interface CustormerAddToListResponseBody {
  data: CustormerAddToListResponseData;
}

export interface CustormerAddToListResponseDTO {
  header: BaseResponseHeader;
  body: CustormerAddToListResponseBody;
}

export interface CustormerUpdateDTO {
  /**
   * 고객 이름
   * @example test
   */
  custormerName?: string;

  /**
   * 고객 그룹
   * @example 1234
   */
  custormerGroup?: string;

  /**
   * 전화번호1
   * @example 01012345678
   */
  custormerPhone1?: string;

  /**
   * 전화번호1 광고 수신여부
   * @example true
   */
  phone1AdFlag?: 0 | 1;

  /**
   * 이메일
   * @example test@test.com
   */
  custormerEmail?: string;

  /**
   * 0: 개인주소록, 1:공용주소록
   * @example true
   */
  isPublic: 0 | 1;

  /**
   * 해시태그
   * @example #123#456
   */
  tagName?: string;
}

export interface CustormerIdxList {
  /**
   * Custormer 인덱스
   * @example [{"custormerIdx": 1},{"custormerIdx": 2}]
   */
  custormerIdx: number;
}

export interface CustormerDeleteDTO {
  custormerIdxList: CustormerIdxList[];
}

export interface ProfileCategoryReadResponseData {
  /**
   * 카테고리 코드
   * @example 00100010001
   */
  code: string;

  /**
   * 카테고리 이름
   * @example 종합병원
   */
  name: string;
}

export interface ProfileCategoryReadResponseBody {
  data: ProfileCategoryReadResponseData[];
}

export interface ProfileCategoryReadResponseDTO {
  header: BaseResponseHeader;
  body: ProfileCategoryReadResponseBody;
}

export interface CompanyCertKakaoCreateDTO {
  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 관리자 휴대폰 번호
   * @example 01012345678
   */
  phoneNo: string;

  /**
   * 카테고리 코드
   * @example 00100010001
   */
  categoryCode: string;
}

export interface CreateProfileResponseData {
  profileIdx: number;
}

export interface CreateProfileResponseBody {
  data: CreateProfileResponseData[];
}

export interface CreateProfileResponseDTO {
  header: BaseResponseHeader;
  body: CreateProfileResponseBody;
}

export interface CompanyCertKakaoUpdateDTO {
  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 프로필 등록후 관리자 휴대폰번호로 전송되는 인증코드
   * @example 227691
   */
  token: number;
}

export interface ReadProfileResponseData {
  /** 발신프로필 INDEX */
  idx: number;

  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 관리자 휴대폰 번호
   * @example 01012345678
   */
  phoneNo: string;

  /**
   * 카테고리 코드
   * @example 00100010001
   */
  categoryCode: string;

  /**
   * 발신 프로필 상태코드
   * @example YSC03
   */
  status: "YSC02" | "YSC03";
}

export interface ReadProfileResponseBody {
  data: ReadProfileResponseData[];
}

export interface ReadProfileResponseDTO {
  header: BaseResponseHeader;
  body: ReadProfileResponseBody;
}

export interface AttachedPhoneCertResponseData {
  /**
   * 파일명
   * @example 1654664169682.jpg
   */
  fileName: string;
}

export interface AttachedPhoneCertResponseBody {
  data: AttachedPhoneCertResponseData;
}

export interface AttachedPhoneCertResponseDTO {
  header: BaseResponseHeader;
  body: AttachedPhoneCertResponseBody;
}

export interface CompanyCertPhoneCreateDTO {
  /**
   * iamport 본인인증 response
   * @example 1234
   */
  imp_uid?: string;

  /**
   * 통신가입증명원으로 인증받을 전화번호
   * @example 1234
   */
  carrierCertFilePhone?: string;

  /**
   * 통신가입증명원 파일명
   * @example 1234.nnn
   */
  carrierCertFileName?: string;

  /**
   * 재직증명서 파일명
   * @example 1234.nnn
   */
  employmentCertFileName?: string;
}

export type AlimtalkTemplateHookDTO = object;

export interface AlimtalkTemplateCategoryReadResponseData {
  /**
   * 카테고리 코드 (템플릿 등록/수정 시, 사용)
   * @example 001001
   */
  code: string;

  /**
   * 카테고리 이름
   * @example 회원가입
   */
  name: string;

  /**
   * 카테고리 적용 대상 템플릿 설명
   * @example 회원가입 완료 내용의 템플릿이 대상입니다. 가입에 따른 축하적립금/쿠폰을 포함합니다.
   */
  inclusion: string;

  /**
   * 카테고리 제외 대상 템플릿 설명
   * @example 상품/서비스가입은 구매 > 상품가입 (002002)로 분류합니다.
   */
  exclusion: string;
}

export interface AlimtalkTemplateCategoryReadResponseBody {
  data: AlimtalkTemplateCategoryReadResponseData[];
}

export interface AlimtalkTemplateCategoryReadResponseDTO {
  header: BaseResponseHeader;
  body: AlimtalkTemplateCategoryReadResponseBody;
}

export interface AlimtalkTemplateCreateDTO {
  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 템플릿 이름
   * @example 테스트 템플릿 이름
   */
  templateName: string;

  /**
   * 템플릿 본문
   * @example 테스트 템플릿 본문 #{의미없는숫자}
   */
  templateContent: string;

  /**
   * 카테고리 코드
   * @example 999999
   */
  categoryCode: string;
}

export interface AlimtalkTemplateReadResponseData {
  /** 템플릿 INDEX */
  templateIdx: number;

  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 템플릿 코드 (알림톡 발신시 필요)
   * @example test0001
   */
  templateCode: string;

  /**
   * 템플릿 이름
   * @example 테스트템플릿코드
   */
  templateName: string;

  /**
   * 템플릿 본문
   * @example 테스트템플릿 시작
   *
   * #{의미없는문자}:#{의미없는숫자}
   * 테스트템플릿 종료
   */
  templateContent: string;

  /**
   * 카테고리 코드
   * @example 999999
   */
  categoryCode: string;

  /**
   * nhn response code
   * @example 0
   */
  nhnResultCode: string;

  /**
   * TSC01: 요청, TSC02: 검수, TSC03: 등록, TSC04: 반려
   * @example TSC03
   */
  nhnHookTemplateStatus: "TSC01" | "TSC02" | "TSC03" | "TSC04";

  /**
   * 반려사유(TSC04 일때 노출)
   * @example 안녕하세요. 카카오톡 알림톡 검수 담당자입니다.
   *
   * 테스트 템플릿으로 확인되어 승인합니다.
   * 감사합니다.
   */
  nhnHookTemplateComment: string;

  /**
   * 생성 일시
   * @example 2022-07-19 15:37:47
   */
  createAt: string;

  /**
   * 수정 일시
   * @example 2022-07-19 15:37:47
   */
  updateAt: string;

  /**
   * 생성/수정자명
   * @example 테스트
   */
  updateUserName: string;
}

export interface AlimtalkTemplateReadResponseBody {
  total: number;
  data: AlimtalkTemplateReadResponseData[];
}

export interface AlimtalkTemplateReadResponseDTO {
  header: BaseResponseHeader;
  body: AlimtalkTemplateReadResponseBody;
}

export interface AlimtalkTemplatePramReadResponseData {
  idx: number;
  key: string;
}

export interface AlimtalkTemplateReadDetailResponseData {
  /** 템플릿 INDEX */
  templateIdx: number;

  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId: string;

  /**
   * 템플릿 코드 (알림톡 발신시 필요)
   * @example test0001
   */
  templateCode: string;

  /**
   * 템플릿 이름
   * @example 테스트템플릿코드
   */
  templateName: string;

  /**
   * 템플릿 본문
   * @example 테스트템플릿 시작
   *
   * #{의미없는문자}:#{의미없는숫자}
   * 테스트템플릿 종료
   */
  templateContent: string;

  /**
   * 카테고리 코드
   * @example 999999
   */
  categoryCode: string;

  /**
   * nhn response code
   * @example 0
   */
  nhnResultCode: string;

  /**
   * TSC01: 요청, TSC02: 검수, TSC03: 등록, TSC04: 반려
   * @example TSC03
   */
  nhnHookTemplateStatus: "TSC01" | "TSC02" | "TSC03" | "TSC04";

  /**
   * 반려사유(TSC04 일때 노출)
   * @example 안녕하세요. 카카오톡 알림톡 검수 담당자입니다.
   *
   * 테스트 템플릿으로 확인되어 승인합니다.
   * 감사합니다.
   */
  nhnHookTemplateComment: string;

  /**
   * 생성 일시
   * @example 2022-07-19 15:37:47
   */
  createAt: string;

  /**
   * 수정 일시
   * @example 2022-07-19 15:37:47
   */
  updateAt: string;

  /**
   * 생성/수정자명
   * @example 테스트
   */
  updateUserName: string;
  AlimtalkTemplateParamInformation: AlimtalkTemplatePramReadResponseData[];
}

export interface AlimtalkTemplateReadDetailResponseBody {
  total: number;
  data: AlimtalkTemplateReadDetailResponseData[];
}

export interface AlimtalkTemplateReadDetailResponseDTO {
  header: BaseResponseHeader;
  body: AlimtalkTemplateReadDetailResponseBody;
}

export interface AlimtalkTemplateUpdateDTO {
  /**
   * 템플릿 본문
   * @example 테스트 템플릿 본문 #{의미없는숫자}
   */
  templateContent: string;

  /**
   * 카테고리 코드
   * @example 999999
   */
  categoryCode?: string;
}

export interface SmsTemplateCreateDTO {
  /**
   * 광고 여부
   * @example 0
   */
  isAd?: 0 | 1;

  /**
   * 템플릿명
   * @example test
   */
  templateName: string;

  /**
   * 템플릿 본문
   * @example test
   */
  templateContent: string;
}

export interface SmsTemplateReadResponseData {
  /** 템플릿 INDEX */
  templateIdx: number;

  /**
   * 광고 여부
   * @example 0
   */
  isAd: 0 | 1;

  /**
   * 템플릿 이름
   * @example 테스트템플릿코드4
   */
  templateName: string;

  /**
   * 템플릿 내용(본문)
   * @example 테스트템플릿 #{테스트}
   */
  templateContent: string;

  /**
   * 생성 일시
   * @example 2022-07-19 15:37:47
   */
  createAt: string;

  /**
   * 수정 일시
   * @example 2022-07-19 15:37:47
   */
  updateAt: string;

  /**
   * 생성/수정자명
   * @example 테스트
   */
  updateUserName: string;
}

export interface SmsTemplateReadResponseBody {
  total: number;
  data: SmsTemplateReadResponseData[];
}

export interface SmsTemplateReadResponseDTO {
  header: BaseResponseHeader;
  body: SmsTemplateReadResponseBody;
}

export interface SmsTemplatePramReadResponseData {
  idx: number;
  key: string;
}

export interface SmsTemplateReadDetailResponseData {
  /** 템플릿 INDEX */
  templateIdx: number;

  /**
   * 광고 여부
   * @example 0
   */
  isAd: 0 | 1;

  /**
   * 템플릿 이름
   * @example 테스트템플릿코드4
   */
  templateName: string;

  /**
   * 템플릿 내용(본문)
   * @example 테스트템플릿 #{테스트}
   */
  templateContent: string;

  /**
   * 생성 일시
   * @example 2022-07-19 15:37:47
   */
  createAt: string;

  /**
   * 수정 일시
   * @example 2022-07-19 15:37:47
   */
  updateAt: string;

  /**
   * 생성/수정자명
   * @example 테스트
   */
  updateUserName: string;
  SmsTemplateParamInformation: SmsTemplatePramReadResponseData[];
}

export interface SmsTemplateReadDetailResponseBody {
  total: number;
  data: SmsTemplateReadDetailResponseData[];
}

export interface SmsTemplateReadDetailResponseDTO {
  header: BaseResponseHeader;
  body: SmsTemplateReadDetailResponseBody;
}

export interface SmsTemplateUpdateDTO {
  /**
   * 광고 여부
   * @example 0
   */
  isAd?: 0 | 1;

  /**
   * 템플릿 본문
   * @example test
   */
  templateContent: string;
}

export interface ChargePlanReadResponseData {
  /** 요금제 INDEX */
  chargePlanIdx?: number;

  /**
   * 요금제코드
   * @example 0000
   */
  chargePlanCode?: string;

  /**
   * 요금제명
   * @example 기본요금제
   */
  chargePlanName?: string;

  /**
   * sms 차감금액
   * @example 1.10
   */
  chargeSms?: number;

  /**
   * lms 차감금액
   * @example 1.10
   */
  chargeLms?: number;

  /**
   * mms 차감금액
   * @example 1.10
   */
  chargeMms?: number;

  /**
   * 알림톡 차감금액
   * @example 1.10
   */
  chargeAlimtalk?: number;

  /**
   * 요금제 그룹
   * @example 0
   */
  chargeGroup?: number;
}

export interface ChargePlanReadResponseBody {
  total: number;
  data: ChargePlanReadResponseData[];
}

export interface ChargePlanReadResponseDTO {
  header: BaseResponseHeader;
  body: ChargePlanReadResponseBody;
}

export interface ChargeHistoryReadResponseData {
  /** 요금제 INDEX */
  chargeHistoryIdx: number;

  /**
   * 서비스 이용시간
   * @example 2022-06-16T10:36:03.000Z
   */
  requestDate: string;

  /**
   * request uuid
   * @example abc
   */
  requestId: string;

  /**
   * 이용한 서비스 타입
   * @example sms
   */
  serviceType: string;

  /**
   * 차감(sub), 충전(add) 구분
   * @example sub
   */
  chargedType: "add" | "sub";

  /**
   * 차감(sub), 충전(add) 사유
   * @example 메세지발송 차감
   */
  chargedDesc: string;

  /**
   * 요금제코드
   * @example 0000
   */
  chargePlanCode: string;

  /**
   * 차감전 잔액
   * @example 0
   */
  beforeBalance: number;

  /**
   * 차감 금액
   * @example 0
   */
  chargedBalance: number;

  /**
   * 차감후 잔액
   * @example 0
   */
  afterBalance: number;
}

export interface ChargeHistoryReadResponseBody {
  total: number;
  data: ChargeHistoryReadResponseData[];
}

export interface ChargeHistoryReadResponseDTO {
  header: BaseResponseHeader;
  body: ChargeHistoryReadResponseBody;
}

export interface ChargeHistoryStatsResponseData {
  /**
   * 서비스 이용시간
   * @example 2022-06-16T10:36:03.000Z
   */
  requestDate: string;

  /**
   * 차감 금액
   * @example 0
   */
  chargedBalance: number;
}

export interface ChargeHistoryStatsResponseBody {
  total: number;
  data: ChargeHistoryStatsResponseData[];
}

export interface ChargeHistoryStatsResponseDTO {
  header: BaseResponseHeader;
  body: ChargeHistoryStatsResponseBody;
}

export interface PaymentImpHookDTO {
  /** imp_uid */
  imp_uid: string;

  /** merchant_uid */
  merchant_uid: string;
}

export interface PaymentDTO {
  /** 구매고유값 */
  merchantUid: string;

  /**
   * PG
   * @example tosspayments
   */
  pg: "tosspayments";

  /**
   * 지불수단
   * @example card
   */
  payMethod: "card" | "phone" | "vbank";

  /**
   * 충전금액
   * @example 1000
   */
  amount: number;

  /**
   * 실결제금액(부가세 포함)
   * @example 1100
   */
  paidAmount: number;
}

export interface PaymentImpDTO {
  /** 구매고유값 */
  merchantUid: string;

  /** IMP 응답: 고유 결제번호 */
  impUid: string;
}

export interface PaymentScheduleDTO {
  /**
   * 정기결제타입(date: 지정일, amount: 기준금액)
   * @example date
   */
  recurringType: "date" | "amount";

  /**
   * 지정일: 1,5,10,15,20,25 OR 기준금액
   * @example 1000
   */
  recurringValue: number;

  /**
   * 정기결제금액
   * @example 5000
   */
  recurringAmount: number;

  /** 카드(빌링키)와 1:1로 대응하는 값 */
  customerUid: string;
}

export interface PaymentScheduleResponseData {
  /** 정기결제IDX */
  paymentScheduleIdx: number;

  /**
   * 정기결제타입(date: 지정일, amount: 기준금액)
   * @example date
   */
  recurringType: "date" | "amount";

  /**
   * 지정일: 1,5,10,15,20,25 OR 기준금액
   * @example 1000
   */
  recurringValue: number;

  /**
   * 정기결제금액
   * @example 5000
   */
  recurringAmount: number;
}

export interface PaymentScheduleResponseBody {
  data: PaymentScheduleResponseData[];
}

export interface PaymentScheduleResponseDTO {
  header: BaseResponseHeader;
  body: PaymentScheduleResponseBody;
}

export interface PaymentHistoryResponseData {
  /** 결제IDX */
  paymentHistoryIdx: number;

  /**
   * 지불수단
   * @example card
   */
  payMethod: "card" | "phone" | "vbank";

  /**
   * 실결제금액(부가세 포함)
   * @example 1100
   */
  paidAmount: number;

  /** 결제시간 */
  impDate: string;

  /** 환불가능금액 */
  refundableBalance: number;
}

export interface PaymentHistoryResponseBody {
  data: PaymentHistoryResponseData[];
}

export interface PaymentHistoryResponseDTO {
  header: BaseResponseHeader;
  body: PaymentHistoryResponseBody;
}

export interface PaymentCancelDTO {
  /** 결제 IDX */
  paymentHistoryIdx: number;

  /** 취소금액 */
  cancelAmount: number;
}

export interface NoticeResponseData {
  /** 공지INDEX */
  noticeIdx: number;

  /**
   * 등록일
   * @format date-time
   */
  createAt: string;

  /**
   * 공지제목
   * @example [공지] 나라말씀이
   */
  title: string;

  /**
   * 공지내용
   * @example 나라말씀이
   */
  content: string;

  /**
   * 공지레벨(1~4)
   * @example 1
   */
  level: "1" | "2" | "3" | "4";
}

export interface NoticeResponseBody {
  total: number;
  data: NoticeResponseData[];
}

export interface NoticeResponseDTO {
  header: BaseResponseHeader;
  body: NoticeResponseBody;
}

export interface NoticeReadOneResponseBody {
  data: NoticeResponseData;
}

export interface NoticeReadOneResponseDTO {
  header: BaseResponseHeader;
  body: NoticeReadOneResponseBody;
}

export interface MemberControllerCheckIdParams {
  /**
   * 아이디(email)
   * @example test@test.com
   */
  userId: string;
}

export interface MemberControllerCheckCodeParams {
  /**
   * 키값
   * @example 1234
   */
  code: string;
}

export interface MessageControllerReadAllParams {
  /**
   * 메시지 타입
   * @example all
   */
  messageType: "all" | "sms" | "lms" | "mms" | "alimtalk";

  /**
   * 통계 구분
   * @example daily
   */
  statisticsType: "daily";

  /**
   * 발송 날짜 시작값
   * @example yyyy-MM-dd
   */
  startRequestDate: string;

  /**
   * 발송 날짜 종료값
   * @example yyyy-MM-dd
   */
  endRequestDate: string;

  /** 수신번호 */
  recipientNo?: string;

  /**
   * sorting 컬럼명
   * @example requestDate
   */
  sortBy: "registerDate" | "requestDate" | "messageType";

  /**
   * sorting
   * @example DESC
   */
  sortOrder: "DESC" | "ASC";

  /**
   * page
   * @example 1
   */
  page?: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface MessageControllerReadStatsParams {
  /**
   * 통계 구분
   * @example daily
   */
  statisticsType: "daily";

  /**
   * 발송 날짜 시작값
   * @example yyyy-MM-dd
   */
  startRequestDate: string;

  /**
   * 발송 날짜 종료값
   * @example yyyy-MM-dd
   */
  endRequestDate: string;

  /**
   * page
   * @example 1
   */
  page?: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface MessageControllerReadPhoneParams {
  /** 수신번호 */
  recipientNo: string;

  /**
   * page
   * @example 1
   */
  page: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface CustormerControllerReadAllParams {
  /** 해시태그를 제외한 검색값 */
  searchingValues?: string[];

  /** 해시태그 */
  searchingTags?: number[];

  /**
   * page
   * @example 1
   */
  page?: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface CustormerControllerDownloadXlsxParams {
  /** 해시태그를 제외한 검색값 */
  searchingValues?: string[];

  /** 해시태그 */
  searchingTags?: number[];
}

export interface CustormerControllerAddToListParams {
  /**
   * 전화번호1
   * @example 01012345678
   */
  custormerPhone1: string;
}

export interface CompanyControllerReadKakaoProfileCategoriesParams {
  /** 트리구조, 1depth: 빈값/ 2depth: 1depth code/ 3depth: 1depth code + 2depth code */
  parentCode: string;
}

export interface AlimtalkControllerReadCategoriesParams {
  /** 트리구조, 1depth: 0/ 2depth: 1depth code */
  parentCode: string;
}

export interface AlimtalkControllerReadAllTemplateParams {
  /**
   * 발신 프로필
   * @example 리디안랩스
   */
  plusFriendId?: string;

  /**
   * page
   * @example 1
   */
  page?: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface SmsControllerReadAllTemplateParams {
  /**
   * page
   * @example 1
   */
  page?: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface ChargeControllerReadHistoryParams {
  /**
   * 발송 날짜 시작값
   * @example yyyy-MM-dd
   */
  startRequestDate: string;

  /**
   * 발송 날짜 종료값
   * @example yyyy-MM-dd
   */
  endRequestDate: string;

  /**
   * 충전, 차감, 구분
   * @example all
   */
  chargedType?: "all" | "add" | "sub";

  /**
   * page
   * @example 1
   */
  page: number;

  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface ChargeControllerReadHistoryStatsParams {
  /**
   * 통계 구분
   * @example none
   */
  statisticsType: "daily" | "monthly";

  /**
   * 발송 날짜 시작값
   * @example yyyy-MM-dd
   */
  startRequestDate: string;

  /**
   * 발송 날짜 종료값
   * @example yyyy-MM-dd
   */
  endRequestDate: string;
}

export interface PaymentControllerUpdateImpMobileParams {
  /** 구매고유값 */
  merchantUid: string;

  /** IMP 응답: 고유 결제번호 */
  impUid: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Messaging - API
 * @version 0.0.1
 * @baseUrl /api
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  member = {
    /**
     * No description
     *
     * @name MemberControllerCheckId
     * @summary 아이디 중복 확인
     * @request GET:/member/check/id
     * @response `200` `BaseResponseDto` 성공
     * @response `409` `BaseResponseDto` 이미 가입된 계정
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerCheckId: (query: MemberControllerCheckIdParams, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/check/id`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerCheckCode
     * @summary invitation 코드 확인
     * @request GET:/member/check/code
     * @response `200` `InvitationResponseDTO` 성공
     * @response `400` `BaseResponseDto` 유효하지 않은 링크
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerCheckCode: (query: MemberControllerCheckCodeParams, params: RequestParams = {}) =>
      this.request<InvitationResponseDTO, BaseResponseDto>({
        path: `/member/check/code`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerUpdatePasswordByCode
     * @summary invitation 코드로 패스워드 변경
     * @request POST:/member/check/code/password
     * @response `200` `BaseResponseDto` 성공
     * @response `400` `BaseResponseDto` 유효하지 않은 링크
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerUpdatePasswordByCode: (data: MemberUpdateByCodeDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/check/code/password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerSignup
     * @summary 개인회원가입
     * @request POST:/member/signup
     * @response `201` `BaseResponseDto` 성공
     * @response `409` `BaseResponseDto` 이미 가입된 계정
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerSignup: (data: MemberCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerCompanyCertAttached
     * @summary 사업자등록증 업로드
     * @request POST:/member/signup/company/upload/cert
     * @response `201` `AttachedCompanyCertResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerCompanyCertAttached: (data: { files?: File }, params: RequestParams = {}) =>
      this.request<AttachedCompanyCertResponseDTO, BaseResponseDto>({
        path: `/member/signup/company/upload/cert`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerPhoneCertAttached
     * @summary 통신가입증명원 업로드
     * @request POST:/member/signup/company/upload/phone
     * @response `201` `AttachedCompanyCertResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerPhoneCertAttached: (data: { files?: File }, params: RequestParams = {}) =>
      this.request<AttachedCompanyCertResponseDTO, BaseResponseDto>({
        path: `/member/signup/company/upload/phone`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerSignupAsCompany
     * @summary 기업회원가입
     * @request POST:/member/signup/company
     * @response `201` `BaseResponseDto` 성공
     * @response `409` `BaseResponseDto` 이미 가입된 계정
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerSignupAsCompany: (data: MemberCreateAsCompanyDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/signup/company`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerSignupFromCompany
     * @summary 하위계정생성
     * @request POST:/member/signup/company/invitation
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `409` `BaseResponseDto` 이미 가입된 계정
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerSignupFromCompany: (data: MemberCreateFromCompanyDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/signup/company/invitation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerLogIn
     * @summary 로그인
     * @request POST:/member/login
     * @response `201` `MemberLogInResponseDTO` 성공
     * @response `401` `void` 승인대기
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    memberControllerLogIn: (data: MemberLogInDTO, params: RequestParams = {}) =>
      this.request<MemberLogInResponseDTO, void>({
        path: `/member/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerLogInByAccessKey
     * @summary accesskey 로그인
     * @request POST:/member/login/accesskey
     * @response `201` `MemberLogInResponseDTO` 성공
     * @response `403` `void` accesskey 에러
     * @response `500` `void` 서버에러
     */
    memberControllerLogInByAccessKey: (data: MemberLogInByAccesskeyDTO, params: RequestParams = {}) =>
      this.request<MemberLogInResponseDTO, void>({
        path: `/member/login/accesskey`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerReadCurrentMember
     * @summary 내 정보
     * @request GET:/member
     * @secure
     * @response `200` `MemberReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerReadCurrentMember: (params: RequestParams = {}) =>
      this.request<MemberReadResponseDTO, BaseResponseDto>({
        path: `/member`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerUpdateMember
     * @summary 회원 정보 업데이트
     * @request PUT:/member
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerUpdateMember: (data: MemberUpdateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerUpdateAccesskey
     * @summary accesskey 발급
     * @request PUT:/member/accesskey
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerUpdateAccesskey: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/accesskey`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerReadAllMember
     * @summary 소속 회원 정보
     * @request GET:/member/all
     * @secure
     * @response `200` `MemberAllReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerReadAllMember: (params: RequestParams = {}) =>
      this.request<MemberAllReadResponseDTO, BaseResponseDto>({
        path: `/member/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MemberControllerSendInvitaitonLink
     * @summary 비밀번호 재전송
     * @request PUT:/member/invitation
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    memberControllerSendInvitaitonLink: (data: MemberInvitationDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/member/invitation`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  message = {
    /**
     * No description
     *
     * @name MessageControllerMessageHooks
     * @summary 백엔드용: 메시지상태 업데이트훅
     * @request POST:/message/message-hook
     * @response `201` `void`
     */
    messageControllerMessageHooks: (data: MessageHookDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/message/message-hook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerAlimtalkHooks
     * @summary 백엔드용: 알림톡상태 업데이트훅
     * @request POST:/message/alimtalk-hook
     * @response `201` `void`
     */
    messageControllerAlimtalkHooks: (data: AlimtalkHookDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/message/alimtalk-hook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerMmsAttached
     * @summary mms 파일업로드
     * @request POST:/message/sender/upload
     * @secure
     * @response `201` `AttachedResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerMmsAttached: (data: { files?: File[] }, params: RequestParams = {}) =>
      this.request<AttachedResponseDTO, BaseResponseDto>({
        path: `/message/sender/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerSmsSender
     * @summary SMS 발신
     * @request POST:/message/sender/sms
     * @secure
     * @response `201` `MessageSendResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSmsSender: (data: MessageSenderSmsDTO, params: RequestParams = {}) =>
      this.request<MessageSendResponseDTO, BaseResponseDto>({
        path: `/message/sender/sms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerMmsSender
     * @summary MMS 발신
     * @request POST:/message/sender/mms
     * @secure
     * @response `201` `MessageSendResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerMmsSender: (data: MessageSenderMmsDTO, params: RequestParams = {}) =>
      this.request<MessageSendResponseDTO, BaseResponseDto>({
        path: `/message/sender/mms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerAlimtalkSender
     * @summary 알림톡 발신
     * @request POST:/message/sender/alimtalk
     * @secure
     * @response `201` `MessageSendResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerAlimtalkSender: (data: MessageSenderAlimtalkDTO, params: RequestParams = {}) =>
      this.request<MessageSendResponseDTO, BaseResponseDto>({
        path: `/message/sender/alimtalk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerReadAll
     * @summary 발신 결과 조회
     * @request GET:/message
     * @secure
     * @response `200` `MessageReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadAll: (query: MessageControllerReadAllParams, params: RequestParams = {}) =>
      this.request<MessageReadResponseDTO, BaseResponseDto>({
        path: `/message`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerSendingResult
     * @summary requestId로 조회
     * @request GET:/message/sending/{id}
     * @secure
     * @response `200` `MessageReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSendingResult: (id: string, params: RequestParams = {}) =>
      this.request<MessageReadResponseDTO, BaseResponseDto>({
        path: `/message/sending/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerReadStats
     * @summary 발신 결과 통계
     * @request GET:/message/stats
     * @secure
     * @response `200` `MessageReadStatsResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadStats: (query: MessageControllerReadStatsParams, params: RequestParams = {}) =>
      this.request<MessageReadStatsResponseDTO, BaseResponseDto>({
        path: `/message/stats`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerReadPhone
     * @summary 메시지 상대 번호로 조회
     * @request GET:/message/phone
     * @secure
     * @response `200` `MessageReadOneResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadPhone: (query: MessageControllerReadPhoneParams, params: RequestParams = {}) =>
      this.request<MessageReadOneResponseDTO, BaseResponseDto>({
        path: `/message/phone`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerReadOne
     * @summary 발신 결과 조회
     * @request GET:/message/{id}
     * @secure
     * @response `200` `MessageReadOneResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadOne: (id: string, params: RequestParams = {}) =>
      this.request<MessageReadOneResponseDTO, BaseResponseDto>({
        path: `/message/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name MessageControllerResvCancel
     * @summary 예약메시지 취소
     * @request PUT:/message/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerResvCancel: (id: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/message/${id}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  custormer = {
    /**
     * No description
     *
     * @name CustormerControllerDownload
     * @summary 주소록 샘플 다운로드
     * @request GET:/custormer/sample
     * @secure
     * @response `200` `void`
     */
    custormerControllerDownload: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/custormer/sample`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerExcelAttached
     * @summary 주소록 파일업로드
     * @request POST:/custormer/upload
     * @secure
     * @response `201` `CustormerExcelResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerExcelAttached: (data: { files?: File }, params: RequestParams = {}) =>
      this.request<CustormerExcelResponseDTO, BaseResponseDto>({
        path: `/custormer/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerCreateTag
     * @summary 주소록 태그: 생성
     * @request POST:/custormer/tag
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerCreateTag: (data: CustormerTagCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/custormer/tag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerReadTag
     * @summary 주소록 태그: 조회
     * @request GET:/custormer/tag
     * @secure
     * @response `200` `CustormerTagReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerReadTag: (params: RequestParams = {}) =>
      this.request<CustormerTagReadResponseDTO, BaseResponseDto>({
        path: `/custormer/tag`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerUpdateTag
     * @summary 주소록 태그: 수정
     * @request PUT:/custormer/tag/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerUpdateTag: (id: string, data: CustormerTagCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/custormer/tag/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerDeleteTag
     * @summary 주소록 태그: 삭제
     * @request DELETE:/custormer/tag/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerDeleteTag: (id: string, data: CustormerTagDeleteDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/custormer/tag/${id}`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerCreate
     * @summary 주소록: 생성
     * @request POST:/custormer
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerCreate: (data: CustormerCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/custormer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerReadAll
     * @summary 주소록: 조회(전체)
     * @request GET:/custormer
     * @secure
     * @response `200` `CustormerReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerReadAll: (query: CustormerControllerReadAllParams, params: RequestParams = {}) =>
      this.request<CustormerReadResponseDTO, BaseResponseDto>({
        path: `/custormer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerDelete
     * @summary 주소록: 삭제
     * @request DELETE:/custormer
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerDelete: (data: CustormerDeleteDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/custormer`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerDownloadXlsx
     * @summary 주소록: 엑셀 다운로드
     * @request GET:/custormer/download
     * @secure
     * @response `200` `void`
     */
    custormerControllerDownloadXlsx: (query: CustormerControllerDownloadXlsxParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/custormer/download`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerAddToList
     * @summary 주소록: 메시지발송 메뉴 - 수신목록 추가
     * @request GET:/custormer/addToList
     * @secure
     * @response `200` `CustormerAddToListResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerAddToList: (query: CustormerControllerAddToListParams, params: RequestParams = {}) =>
      this.request<CustormerAddToListResponseDTO, BaseResponseDto>({
        path: `/custormer/addToList`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerReadOne
     * @summary 주소록: 조회(상세)
     * @request GET:/custormer/{id}
     * @secure
     * @response `200` `CustormerReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerReadOne: (id: string, params: RequestParams = {}) =>
      this.request<CustormerReadResponseDTO, BaseResponseDto>({
        path: `/custormer/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CustormerControllerUpdate
     * @summary 주소록: 수정
     * @request PUT:/custormer/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    custormerControllerUpdate: (id: string, data: CustormerUpdateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/custormer/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  company = {
    /**
     * No description
     *
     * @name CompanyControllerReadKakaoProfileCategories
     * @summary 프로필 카테고리 코드 조회
     * @request GET:/company/kakao/profile/categories
     * @secure
     * @response `200` `ProfileCategoryReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerReadKakaoProfileCategories: (
      query: CompanyControllerReadKakaoProfileCategoriesParams,
      params: RequestParams = {},
    ) =>
      this.request<ProfileCategoryReadResponseDTO, BaseResponseDto>({
        path: `/company/kakao/profile/categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerCreateKakaoProfile
     * @summary 프로필 등록 -> 카카오로 인증코드 전송
     * @request POST:/company/kakao/profile
     * @secure
     * @response `201` `CreateProfileResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerCreateKakaoProfile: (data: CompanyCertKakaoCreateDTO, params: RequestParams = {}) =>
      this.request<CreateProfileResponseDTO, BaseResponseDto>({
        path: `/company/kakao/profile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerReadKakaoProfile
     * @summary 프로필 조회
     * @request GET:/company/kakao/profile
     * @secure
     * @response `200` `ReadProfileResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerReadKakaoProfile: (params: RequestParams = {}) =>
      this.request<ReadProfileResponseDTO, BaseResponseDto>({
        path: `/company/kakao/profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerCertKakaoProfile
     * @summary 프로필 인증 -> 전송된 인증코드 입력
     * @request PUT:/company/kakao/profile/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerCertKakaoProfile: (id: string, data: CompanyCertKakaoUpdateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/company/kakao/profile/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerDeleteKakaoProfile
     * @summary 프로필 삭제
     * @request DELETE:/company/kakao/profile/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerDeleteKakaoProfile: (id: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/company/kakao/profile/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerPhoneCertAttached
     * @summary 통신가입증명원 업로드
     * @request POST:/company/phone/cert
     * @response `201` `AttachedPhoneCertResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerPhoneCertAttached: (data: { files?: File }, params: RequestParams = {}) =>
      this.request<AttachedPhoneCertResponseDTO, BaseResponseDto>({
        path: `/company/phone/cert`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerEmploymentCertAttached
     * @summary 재직증명서 업로드
     * @request POST:/company/employment/cert
     * @response `201` `AttachedPhoneCertResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerEmploymentCertAttached: (data: { files?: File }, params: RequestParams = {}) =>
      this.request<AttachedPhoneCertResponseDTO, BaseResponseDto>({
        path: `/company/employment/cert`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerCreatePhoneProfile
     * @summary 발신번호 등록
     * @request POST:/company/phone/profile
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerCreatePhoneProfile: (data: CompanyCertPhoneCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/company/phone/profile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name CompanyControllerDeletePhoneProfile
     * @summary 프로필 삭제
     * @request DELETE:/company/phone/profile/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    companyControllerDeletePhoneProfile: (id: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/company/phone/profile/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  alimtalk = {
    /**
     * No description
     *
     * @name AlimtalkControllerTemplateHook
     * @summary 백엔드용: 템플릿 상태 업데이트를 위한 훅
     * @request POST:/alimtalk/template-hook
     * @response `201` `void`
     */
    alimtalkControllerTemplateHook: (data: AlimtalkTemplateHookDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/alimtalk/template-hook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AlimtalkControllerReadCategories
     * @summary 템플릿 카테고리 코드 조회
     * @request GET:/alimtalk/template/categories
     * @secure
     * @response `200` `AlimtalkTemplateCategoryReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    alimtalkControllerReadCategories: (query: AlimtalkControllerReadCategoriesParams, params: RequestParams = {}) =>
      this.request<AlimtalkTemplateCategoryReadResponseDTO, BaseResponseDto>({
        path: `/alimtalk/template/categories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AlimtalkControllerCreateTemplate
     * @summary 알림톡: 템플릿 등록
     * @request POST:/alimtalk/template
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    alimtalkControllerCreateTemplate: (data: AlimtalkTemplateCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/alimtalk/template`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AlimtalkControllerReadAllTemplate
     * @summary 알림톡: 템플릿 조회(전체)
     * @request GET:/alimtalk/template
     * @secure
     * @response `200` `AlimtalkTemplateReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    alimtalkControllerReadAllTemplate: (query: AlimtalkControllerReadAllTemplateParams, params: RequestParams = {}) =>
      this.request<AlimtalkTemplateReadResponseDTO, BaseResponseDto>({
        path: `/alimtalk/template`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AlimtalkControllerReadOneTemplate
     * @summary 알림톡: 템플릿 조회(상세)
     * @request GET:/alimtalk/template/{id}
     * @secure
     * @response `200` `AlimtalkTemplateReadDetailResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    alimtalkControllerReadOneTemplate: (id: string, params: RequestParams = {}) =>
      this.request<AlimtalkTemplateReadDetailResponseDTO, BaseResponseDto>({
        path: `/alimtalk/template/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AlimtalkControllerUpdateTemplate
     * @summary 알림톡: 템플릿 수정
     * @request PUT:/alimtalk/template/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    alimtalkControllerUpdateTemplate: (id: string, data: AlimtalkTemplateUpdateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/alimtalk/template/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  sms = {
    /**
     * No description
     *
     * @name SmsControllerCreateTemplate
     * @summary 단문/장문: 템플릿 등록
     * @request POST:/sms/template
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    smsControllerCreateTemplate: (data: SmsTemplateCreateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/sms/template`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SmsControllerReadAllTemplate
     * @summary 단문/장문: 템플릿 조회(전체)
     * @request GET:/sms/template
     * @secure
     * @response `200` `SmsTemplateReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    smsControllerReadAllTemplate: (query: SmsControllerReadAllTemplateParams, params: RequestParams = {}) =>
      this.request<SmsTemplateReadResponseDTO, BaseResponseDto>({
        path: `/sms/template`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SmsControllerReadOneTemplate
     * @summary 단문/장문: 템플릿 조회(상세)
     * @request GET:/sms/template/{id}
     * @secure
     * @response `200` `SmsTemplateReadDetailResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    smsControllerReadOneTemplate: (id: string, params: RequestParams = {}) =>
      this.request<SmsTemplateReadDetailResponseDTO, BaseResponseDto>({
        path: `/sms/template/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SmsControllerUpdateTemplate
     * @summary 단문/장문: 템플릿 수정
     * @request PUT:/sms/template/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    smsControllerUpdateTemplate: (id: string, data: SmsTemplateUpdateDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/sms/template/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name SmsControllerDeleteTemplate
     * @summary 단문/장문: 템플릿 삭제
     * @request DELETE:/sms/template/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    smsControllerDeleteTemplate: (id: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/sms/template/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  charge = {
    /**
     * No description
     *
     * @name ChargeControllerReadPlan
     * @summary 요금제 조회
     * @request GET:/charge/plan
     * @secure
     * @response `200` `ChargePlanReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    chargeControllerReadPlan: (params: RequestParams = {}) =>
      this.request<ChargePlanReadResponseDTO, BaseResponseDto>({
        path: `/charge/plan`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChargeControllerReadHistory
     * @summary 요금 이력 조회
     * @request GET:/charge
     * @secure
     * @response `200` `ChargeHistoryReadResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    chargeControllerReadHistory: (query: ChargeControllerReadHistoryParams, params: RequestParams = {}) =>
      this.request<ChargeHistoryReadResponseDTO, BaseResponseDto>({
        path: `/charge`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ChargeControllerReadHistoryStats
     * @summary 요금 이력 통계
     * @request GET:/charge/stats
     * @secure
     * @response `200` `ChargeHistoryStatsResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    chargeControllerReadHistoryStats: (query: ChargeControllerReadHistoryStatsParams, params: RequestParams = {}) =>
      this.request<ChargeHistoryStatsResponseDTO, BaseResponseDto>({
        path: `/charge/stats`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  payment = {
    /**
     * No description
     *
     * @name PaymentControllerPaymentHook
     * @summary 백엔드용: 결제완료를 위한 훅
     * @request POST:/payment/payment-hook
     * @response `201` `void`
     */
    paymentControllerPaymentHook: (data: PaymentImpHookDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payment/payment-hook`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerCreate
     * @summary 결제요청시 입력된 데이터
     * @request POST:/payment
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerCreate: (data: PaymentDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/payment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerRead
     * @summary 결제내역
     * @request GET:/payment
     * @secure
     * @response `200` `PaymentHistoryResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerRead: (params: RequestParams = {}) =>
      this.request<PaymentHistoryResponseDTO, BaseResponseDto>({
        path: `/payment`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerUpdateImp
     * @summary 결제완료(PC)
     * @request POST:/payment/complete
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerUpdateImp: (data: PaymentImpDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/payment/complete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerUpdateImpMobile
     * @summary 결제완료(MOBILE)
     * @request GET:/payment/complete/mobile
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerUpdateImpMobile: (query: PaymentControllerUpdateImpMobileParams, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/payment/complete/mobile`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerCreateSchedule
     * @summary 정기결제 정보 저장
     * @request POST:/payment/schedule
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerCreateSchedule: (data: PaymentScheduleDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/payment/schedule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerReadSchedule
     * @summary 정기결제정보
     * @request GET:/payment/schedule
     * @secure
     * @response `201` `PaymentScheduleResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerReadSchedule: (params: RequestParams = {}) =>
      this.request<PaymentScheduleResponseDTO, BaseResponseDto>({
        path: `/payment/schedule`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerDeleteTag
     * @summary 정기결제정보 삭제
     * @request DELETE:/payment/schedule/{id}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerDeleteTag: (id: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/payment/schedule/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerCancel
     * @summary 결제취소
     * @request POST:/payment/cancel
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerCancel: (data: PaymentCancelDTO, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/payment/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notice = {
    /**
     * No description
     *
     * @name NoticeControllerNoAd
     * @summary 광고수신거부등록
     * @request GET:/notice/cron/noAd
     * @response `200` `void`
     */
    noticeControllerNoAd: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notice/cron/noAd`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name NoticeControllerRead
     * @summary 상단공지
     * @request GET:/notice
     * @response `200` `NoticeResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerRead: (params: RequestParams = {}) =>
      this.request<NoticeResponseDTO, BaseResponseDto>({
        path: `/notice`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name NoticeControllerReadOne
     * @summary 상단공지 상세
     * @request GET:/notice/{id}
     * @response `200` `NoticeReadOneResponseDTO` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerReadOne: (id: string, params: RequestParams = {}) =>
      this.request<NoticeReadOneResponseDTO, BaseResponseDto>({
        path: `/notice/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
