import { SetState } from "zustand";

export interface IReducerModal {
  visible: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const dispatch = (setter: SetState<{}>, name: string, v: boolean) => {
  setter((s) => ({
    [name]: {
      ...(s as any)[name],
      visible: v,
    },
  }));
};

export const reducerModal = <T extends {}>(
  setter: SetState<T>,
  name: keyof T,
  p: boolean = false,
  onCloseCallback?: () => void,
  onOpenCallback?: () => void
): IReducerModal => {
  return {
    visible: p,
    onOpen: () => {
      dispatch(setter, name as string, true);
      if (onOpenCallback) {
        onOpenCallback();
      }
    },
    onClose: () => {
      dispatch(setter, name as string, false);
      if (onCloseCallback) {
        onCloseCallback();
      }
    },
  };
};
