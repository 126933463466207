import { Modal, ModalProps } from "antd";
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import { IComponentProps } from "../types";

export const AntdModal: React.FC<
  ModalProps &
    IComponentProps & {
      draggable?: boolean;
    }
> = (props = { draggable: true }) => {
  const draggleRef = useRef<any>();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [bounds, setBounds] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });

  const onStart = (_e: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    if (!disabled) {
      setBounds({
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      });
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      closable={false}
      keyboard={false}
      maskClosable={false}
      width="100%"
      {...props}
      title={
        <div
          style={{
            width: "100%",
            cursor: props.draggable ? "move" : "default",
          }}
          onMouseOver={() => {
            if (props.draggable) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          // onFocus={() => {}}
          // onBlur={() => {}}
          // end
        >
          {props.title}
        </div>
      }
      modalRender={(modal: any) => (
        <Draggable
          disabled={!props.draggable || disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      {props.children}
    </Modal>
  );
};
