import { useEffect } from "react";
import { message, notification } from "antd";
import qs from "query-string";
import { IPaymentCompleteMobileResponse } from "../types";
import {
  useLocation,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { privateApi } from "../react-query";
import { isSuccessMobile } from "../utils";

export const PaymentCompleteMobile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const query: IPaymentCompleteMobileResponse = qs.parse(location.search);

  const { mutate: mutatePaymentCompleteMobile } = useMutation(
    privateApi.payment.paymentControllerUpdateImpMobile,
    {
      onSuccess: (e) => {
        if (isSuccessMobile(e.data.header)) {
          console.log("paymentControllerUpdateImpMobile onSuccess: ", e);
          message.success("결제가 완료되었습니다");
          navigate("/charge/payment");
        }
        else {
          message.error("사용자가 결제를 취소하였습니다.")
          navigate("/charge/payment");
        }
      },
      onError: (e) => {
        console.log("paymentControllerUpdateImpMobile onError: ", e)
      },
    }
  );

  useEffect(() => {
    if (
      // query.imp_success === undefined ||
      query.imp_uid === undefined ||
      query.merchant_uid === undefined
    ) {
      const text = JSON.stringify(query);
      return notification.error({
        message: "잘못된 접근입니다",
        description: "쿼리정보 : " + text,
        duration: 0,
      });
    }
    mutatePaymentCompleteMobile({
      impUid: query.imp_uid,
      merchantUid: query.merchant_uid,
    });
  }, []);
  return <></>;
};
