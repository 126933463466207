import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import {
  CustormerControllerReadAllParams,
  CustormerReadResponseData,
} from "../generated";
import {
  IReducerForm,
  IReducerModal,
  reducerForm,
  reducerModal,
} from "../reducers";

interface IFormFilter extends CustormerControllerReadAllParams {
  /** 검색항목 */
  searchField: string[];
  clicked : number;
}

interface IFormModalTag {
  /** 태그순번 */
  tagIdx: number;
  /** 태그명 */
  tagName: string;
  /** 공용주소록 태그 여부 */
  isPublic: boolean;
}

interface IFormModalAddressBook {
  /** 순번 */
  custormerIdx: number;
  /** 이름 */
  name: string;
  /** 전화번호 */
  phone: string;
  /** 그룹 */
  group: string;
  /** 태그 */
  tag: string;
  /** 광고수신동의 여부 */
  agreeAds: number;
  /** 공유주소록 여부 */
  isPublic: number;
  /** 이메일 */
  email: string;
}

interface IStore extends State {
  /** 검색 입력폼 */
  formFilter: IReducerForm<IFormFilter>;
  /** 모달창 (태그추가/수정) 입력값 */
  formModalTag: IReducerForm<IFormModalTag>;
  /** 모달창 (주소추가/수정) 입력값 */
  formModalAddressBook: IReducerForm<IFormModalAddressBook>;
  /** 선택된 주소록 목록 */
  selectedAddresBooks: CustormerReadResponseData[];
  /** 엑셀 업로드로 반환된 주소록 목록 */
  uploadedExcelAddressBooks: CustormerReadResponseData[];
  /** 모달창 (태그추가) */
  modalAddTag: IReducerModal;
  /** 모달창 (태그수정) */
  modalUpdateTag: IReducerModal;
  /** 모달창 (주소추가) */
  modalAddAddressBook: IReducerModal;
  /** 모달창 (주소수정) */
  modalUpdateAddressBook: IReducerModal;
  /** 모달창 (주소 엑셀로 추가) */
  modalAddAddressBookByExcel: IReducerModal;
  /** 선택된 주소록 목록 업데이트 */
  onChangeSelectedAddressBooks: (e: CustormerReadResponseData[]) => void;
  /** 엑셀 업로드로 반환된 주소록 목록 업데이트 */
  onChangeUploadedExcelAddressBooks: (e: CustormerReadResponseData[]) => void;
  onReset: () => void;
}

const initFormModalAddressBook: IFormModalAddressBook = {
  custormerIdx: Date.now(),
  name: "",
  phone: "",
  group: "",
  tag: "",
  agreeAds: 1,
  isPublic: 0,
  email: "",
};

const initFormModalTag: IFormModalTag = {
  tagIdx: 0,
  tagName: "",
  isPublic: false,
};

const initFormFilter: IFormFilter = {
  page: 1,
  pageSize: 20,
  searchField: [],
  clicked : 0
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    formFilter: reducerForm<IStore, IFormFilter>(
      set,
      "formFilter",
      initFormFilter
    ),
    formModalTag: reducerForm<IStore, IFormModalTag>(
      set,
      "formModalTag",
      initFormModalTag
    ),
    formModalAddressBook: reducerForm<IStore, IFormModalAddressBook>(
      set,
      "formModalAddressBook",
      initFormModalAddressBook
    ),
    selectedAddresBooks: [],
    uploadedExcelAddressBooks: [],
    modalAddTag: reducerModal<IStore>(set, "modalAddTag", false, () => {
      setTimeout(() => {
        get().formModalTag.onChangeForm(initFormModalTag);
      }, 300);
    }),
    modalUpdateTag: reducerModal<IStore>(set, "modalUpdateTag", false, () => {
      setTimeout(() => {
        get().formModalTag.onChangeForm(initFormModalTag);
      }, 300);
    }),
    modalAddAddressBook: reducerModal<IStore>(
      set,
      "modalAddAddressBook",
      false,
      () => {
        setTimeout(() => {
          get().formModalAddressBook.onChangeForm(initFormModalAddressBook);
        }, 300);
      }
    ),
    modalUpdateAddressBook: reducerModal<IStore>(
      set,
      "modalUpdateAddressBook",
      false,
      () => {
        setTimeout(() => {
          get().formModalAddressBook.onChangeForm(initFormModalAddressBook);
        }, 300);
      }
    ),
    modalAddAddressBookByExcel: reducerModal<IStore>(
      set,
      "modalAddAddressBookByExcel",
      false,
      () => {
        setTimeout(() => {
          get().onChangeUploadedExcelAddressBooks([]);
        }, 300);
      }
    ),
    onChangeSelectedAddressBooks: (selectedAddresBooks) => {
      set({ selectedAddresBooks });
    },
    onChangeUploadedExcelAddressBooks: (uploadedExcelAddressBooks) => {
      set({ uploadedExcelAddressBooks });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** 주소록 > [주소록] 페이지 상태관리 */
export const useAddressBookListStore = create<IStore>()(
  devtools(initialStateCreator)
);
