import { message } from "antd";
import { BaseResponseHeader } from "../generated";

export const isSuccessMobile = (header: BaseResponseHeader) => {
  const isSuccess = header.isSuccess;
  if (isSuccess) {
    return true;
  }
  const resultMessage = header.resultMessage || "";
  console.log({ resultMessage });
};