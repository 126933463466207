import { QueryClient } from "@tanstack/react-query";
import ky from "ky";
import { Api } from "./generated";
import { message } from "antd";
// import { useAppStore } from './stores';
import { getAccessToken, setAccessToken } from "./auth";
import { useAppStore } from "./stores";
import { JwtHelper } from "./utils";
// import { IExceptionResponse } from "./types";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// TODO 확인하기
export const privateClient = ky.extend({
  // throwHttpErrors: false,
  timeout: 20000,
  hooks: {
    beforeRequest: [
      async (req: Request) => {
        console.log("req: ", req);
        const { onLogout } = useAppStore.getState();
        const at = getAccessToken();
        if (at) {
          if (JwtHelper.getJwtObject(at)?.isExpired) {
            try {
              // 토큰 재발급 요청 후 토큰 저장.
              console.log("토큰만료...");
              message.error("토큰이 만료되었습니다");
              setAccessToken("");
              onLogout();
            } catch (e) {
              setAccessToken("");
              onLogout();
            }
          }
          return;
        }
        message.error("토큰이 존재하지 않거나 잘못된 토큰정보의 요청입니다.");
      },
      (req) => {
        // console.log("nextRequest: ", req);
        const accessToken = getAccessToken();
        if (accessToken) {
          req.headers.set("Authorization", `Bearer ${accessToken}`);
        }
      },
    ],
    afterResponse: [
      async (input, option, response) => {
        console.log("afterResponse: ", response);
        if (!response.ok) {
          if (response.status === 403) {
            message.error("접근권한이 올바르지 않습니다");
          }
        }
        if (!response.ok) {
          const error = new Error(`[${response.status}] ${response.url}`);
          const body = option.body;
          const headers = option.headers;
        }
      },
    ],
  },
});

export const baseUrl = "https://admin.sendit.kr/api";

/** 헤더의 토큰값 검증 (인증된 요청시) */
export const privateApi = new Api({
  baseUrl,
  customFetch: privateClient,
  baseApiParams: { format: "json" },
});

/** 헤더의 토큰값 검증 X (비인증 요청시) */
export const publicApi = new Api({
  baseUrl,
  customFetch: ky.extend({
    throwHttpErrors: false,
    hooks: {
      afterResponse: [
        async (_input, option, response) => {
          if (!response.ok) {
            const error = new Error(`[${response.status}] ${response.url}`);
            const body = option.body;
            const headers = option.headers;
          }
        },
      ],
    },
  }),
  baseApiParams: { format: "json" },
});
