import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { setAccessToken, setRefreshToken } from "../auth";
import { device } from "../device";
import { useAppStore } from "../stores";
import { IComponentProps } from "../types";
import { useEffect,useCallback,useState} from "react";
import { useHotkeys } from 'react-hotkeys-hook'
import { 
  MdSpaceDashboard,
  MdSend,
  MdAssignmentInd,
  MdPayment 
} from "react-icons/md";
import { 
  RiLogoutBoxRLine ,
  RiListSettingsFill  
} from "react-icons/ri";

import { FaUserCog } from "react-icons/fa";
import { BiTable } from "react-icons/bi";


interface IProps extends IComponentProps {
  visibleMenu: boolean;
}

export const Navbar: React.FC<IProps> = ({ visibleMenu }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const appStore = useAppStore((s) => s);
  console.log({ pathname });
  const [useFunction, setUseFunction] = useState(false)

 useHotkeys('ctrl', () => setUseFunction(!useFunction),[useFunction])
 useHotkeys('1', () => {
  navigate('/summary/dashboard')
  setUseFunction(false)
1})
 useHotkeys('2', () => {
  
  navigate('/message/send')
  setUseFunction(false)
})
 useHotkeys('3', () =>{
  
  navigate('/message/set')
  setUseFunction(false)
})
 useHotkeys('4', () => {
  navigate('/address-book/list')
  setUseFunction(false)


})
 useHotkeys('5', () => {
  navigate('/analysis/send')
  setUseFunction(false)


})
 useHotkeys('6', () => {
  
  navigate('/charge/payment')
  setUseFunction(false)
})

 useHotkeys('7', () => {
  navigate('/mypage/account')
  setUseFunction(false)

})

  
  const onClickMenu = (e: React.MouseEvent<HTMLParagraphElement>) => {
    navigate(e.currentTarget.id);
    setUseFunction(false)
  };

  /**
   * [로그아웃] 클릭시
   */
  function lydian_chat_signin(systemKey : any, email: any, password: any) {
    const aIframe: any = document.getElementById('lydian-iframe');
    const iframeDiv = document.getElementById('lydian-iframediv');

    console.log(aIframe)
    if (systemKey && email && password) {
        if (aIframe !== null) {
            aIframe.contentWindow.postMessage(
                {
                    type: 'lydian_chat_signin',
                    id: email,
                    password: password,
                    systemKey: systemKey
                },
                '*'
            );
            // sessionStorage.removeItem('ID');
            // sessionStorage.removeItem('Pass');
        }
    }
    iframeDiv.style.display = "none";

}

  const onClickLogout = () => {
    setAccessToken("");
    setRefreshToken("");
    appStore.onLogout();
    lydian_chat_signin(`sendit_${sessionStorage.getItem("CIdx")}`, "logout@sendit.kr", "1234");
 

  };

  return (
    <Container className={visibleMenu ? "" : "hide-active"}>
      <Wrapper>
        <Header>
          <Logo>
            {/* <RiMailSendFill color="white" size={20} /> */}
            {/* Messaging */}
            <img src="/images/img-main-logo.png" alt="" onClick={()=>{
              navigate('/summary/dashboard')
            }}/>
          </Logo>
        </Header>
        <Content>
          <MenuBox>
            <MenuCell>
              <MenuTitle>개요</MenuTitle>
              <MenuGroup>
                <MenuItem
                  id="/summary/dashboard"
                  className={pathname === "/summary/dashboard" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>대시보드</p><p>①</p></div> : <p style={{display:"flex"}}> <MdSpaceDashboard size={18} style={{ marginTop: '2px', marginRight: '12px' }} /> 대시보드</p>}
                </MenuItem>
              </MenuGroup>
            </MenuCell>
            <MenuCell>
              <MenuTitle>메시지</MenuTitle>
              <MenuGroup>
                <MenuItem
                  id="/message/send"
                  className={pathname === "/message/send" ? "active" : ""}
                  onClick={onClickMenu}
                >
                {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>메세지 발송</p><p>②</p></div> : <p style={{display:"flex"}}> <MdSend size={18} style={{ marginTop: '2px', marginRight: '12px' }}/> 메세지 발송</p>}
                </MenuItem>
                <MenuItem
                  id="/message/set"
                  className={pathname === "/message/set" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>메세지 설정</p><p>③</p></div> : <p style={{display:"flex"}}> <RiListSettingsFill size={18} style={{ marginTop: '2px', marginRight: '12px' }}/> 메세지 설정</p>}
                </MenuItem>
              </MenuGroup>
            </MenuCell>
            <MenuCell>
              <MenuTitle>주소록</MenuTitle>
              <MenuGroup>
                <MenuItem
                  id="/address-book/list"
                  className={pathname === "/address-book/list" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>주소록</p><p>④</p></div> : <p style={{display:"flex"}}> <MdAssignmentInd size={18} style={{ marginTop: '2px', marginRight: '12px' }}/> 주소록</p>}
                </MenuItem>
              </MenuGroup>
            </MenuCell>
            <MenuCell>
              <MenuTitle>분석</MenuTitle>
              <MenuGroup>
                <MenuItem
                  id="/analysis/send"
                  className={pathname === "/analysis/send" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>발송통계</p><p>⑤</p></div> : <p style={{display:"flex"}}> <BiTable size={18} style={{ marginTop: '2px', marginRight: '12px' }}/> 발송통계</p>}
                </MenuItem>
              </MenuGroup>
            </MenuCell>
            <MenuCell>
              <MenuTitle>요금</MenuTitle>
              {/* <MenuGroup>
                <MenuItem
                  id="/charge/payment"
                  className={pathname === "/charge/payment" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>결제관리</p><p>⑥</p></div> : <p>결제관리</p>}
                </MenuItem>
              </MenuGroup> */}
              <MenuGroup> 
                <MenuItem
                  id="/charge/payment"
                  className={pathname === "/charge/payment" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>결제관리</p><p>⑥</p></div> : <p style={{display:"flex"}}> <MdPayment size={18} style={{ marginTop: '2px', marginRight: '12px' }}/> 결제관리</p>}
                </MenuItem>
              </MenuGroup>
            </MenuCell>
            <MenuCell>
              <MenuTitle>내정보</MenuTitle>
              <MenuGroup>
                <MenuItem
                  id="/mypage/account"
                  className={pathname === "/mypage/account" ? "active" : ""}
                  onClick={onClickMenu}
                >
                  {useFunction ? <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between"}}><p>계정정보</p><p>⑦</p></div> : <p style={{display:"flex"}}> <FaUserCog size={18} style={{ marginTop: '2px', marginRight: '12px' }}/>계정정보</p>}
                </MenuItem>
              </MenuGroup>
            </MenuCell>
          </MenuBox>
        </Content>
        <Footer>
          <LogoutButton onClick={onClickLogout} style={{display:"flex", justifyContent: "center"}}><RiLogoutBoxRLine size={16} style={{ marginTop: '2px', marginRight: '8px' }} />로그아웃</LogoutButton>
        </Footer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: 100%;
  /* height: 100vh; */
  overflow-y: auto;
  min-width: 250px;
  max-width: 250px;
  background-color: var(--primary-color);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
  @media ${device.laptop.max} {
    transition: 0.2s;
    position: fixed;
    z-index: 1001;
    &.hide-active {
      left: -100%;
    }
  }
`;

const Wrapper = styled.div`
  background-color: inherit;
`;

const Header = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  background-color: inherit;
`;
const Logo = styled.p`
  text-align: center;
  font-size: 20px;
  color: white;
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    max-width: 100px;
  }
`;

const Content = styled.div`
  @media ${device.laptop.max} {
    padding-bottom: 25px;
  }
`;

const MenuBox = styled.div``;
const MenuCell = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const MenuTitle = styled.h4`
  color: #878c96;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 400;
`;
const MenuGroup = styled.div``;
const MenuItem = styled.p`
  padding: 10px 20px;
  font-size: 14px;
  color: rgb(255 255 255 / 70%);
  transition: 0.2s;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 3px;
    opacity: 0;
    background-color: #ffffff;
  }
  &:hover {
    background-color: #19263c;
    color: white;
  }
  &.active {
    position: relative;
    background-color: #19366a;
    color: white;
    &::before {
      opacity: 1;
    }
  }
  &:not(.active) {
    cursor: pointer;
  }
`;


const Footer = styled.div``;

const LogoutButton = styled.button`
  display: block;
  border: none;
  width: 100%;
  margin: 0 auto;
  background-color: #0a1527;
  color: var(--primary-light-color);
  font-weight : 300;
  font-size : 13px;
  padding: 10px;
  cursor: pointer;
  margin-top: 30px;
  &:hover {
    color: #b8c5df;
    font-weight : 500;
  }
`;
