import dayjs from "dayjs";
import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import { ChargeHistoryStatsResponseData, MessageReadStatsResponseData } from "../generated";
import { IReducerForm, reducerForm } from "../reducers";

const today = dayjs();
const startRequestDate = today.add(-6, "day").format("YYYY-MM-DD");
const endRequestDate = today.format("YYYY-MM-DD");

interface IFormFilter {
  /** 시작일자 */
  startRequestDate: string;
  /** 종료일자 */
  endRequestDate: string;
  /** 파이프 클릭? */
  clicked : number;
}

interface IFormMessageStats {
  /** (선택된) 서비스 이용통계 일자 */
  selectedMessageStats?: MessageReadStatsResponseData;
  /** (선택된) 원형 차트 유형 */
  type: "SMS" | "LMS" | "MMS" | "ALIMTALK";
}


interface IStore extends State {
  /** 검색 입력폼 */
  formFilter: IReducerForm<IFormFilter>;
  /** 서비스 이용 통계 폼 */
  formMessageStats: IReducerForm<IFormMessageStats>;
  /** 메시지 이용 통계 */
  messageStats: Array<MessageReadStatsResponseData>;
  /** 요금 내역 통계 */
  chargeStats: ChargeHistoryStatsResponseData[];
  /** 메시지 이용 통계 변경 이벤트 */
  onChangeMessageStats: (e: MessageReadStatsResponseData[]) => void;
  /** 요금 내역 통계 변경 이벤트 */
  onChangeChargeStats: (e: ChargeHistoryStatsResponseData[]) => void;
  onReset: () => void;
}
export const initMessageStats: MessageReadStatsResponseData = {
  requestDate: "",
  alimtalkSent: "0",
  lmsSent: "0",
  mmsSent: "0",
  requested: "0",
  smsSent: "0",

  alimtalkPending: "0",
  alimtalkSentFailed: "0",
  alimtalkSentSuccess: "0",
  lmsPending: "0",
  lmsSentFailed: "0",
  lmsSentSuccess: "0",
  mmsPending: "0",
  mmsSentFailed: "0",
  mmsSentSuccess: "0",
  smsPending: "0",
  smsSentFailed: "0",
  smsSentSuccess: "0",
};
const initFormFilter: IFormFilter = {
  startRequestDate,
  endRequestDate,
  clicked : 0,
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    formFilter: reducerForm<IStore, IFormFilter>(
      set,
      "formFilter",
      initFormFilter
    ),
    formMessageStats: reducerForm<IStore, IFormMessageStats>(
      set,
      "formMessageStats",
      {
        type: "SMS",
        selectedMessageStats: undefined,
      }
    ),
    messageStats: new Array(7).fill({ ...initMessageStats }).map(
      (v, i) =>
        ({
          ...v,
          requestDate: today.add(-i, "day").format("YYYY-MM-DD"),
        } as MessageReadStatsResponseData)
    ),
    chargeStats: [
      {
        requestDate: today.format("YYYY-MM"),
        chargedBalance: 0,
      },
      {
        requestDate: today.add(-1, "month").format("YYYY-MM"),
        chargedBalance: 0,
      },
      {
        requestDate: today.add(-2, "month").format("YYYY-MM"),
        chargedBalance: 0,
      },
      {
        requestDate: today.add(-3, "month").format("YYYY-MM"),
        chargedBalance: 0,
      },
      {
        requestDate: today.add(-4, "month").format("YYYY-MM"),
        chargedBalance: 0,
      },
      {
        requestDate: today.add(-5, "month").format("YYYY-MM"),
        chargedBalance: 0,
      },
    ],
    onChangeChargeStats: (chargeStats) => {
      set({ chargeStats });
    },
    onChangeMessageStats: (messageStats) => {
      set({ messageStats });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** 개요 > [대시보드] 페이지 상태관리 */
export const useSummaryDashboardStore = create<IStore>()(
  devtools(initialStateCreator)
);
