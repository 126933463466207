import { MdMail } from "react-icons/md";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, Input, message, Switch, Tag,Tooltip} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
  AddedChildAccountModalContent,
  AntdButton,
  AntdModal,
  AntdPagination,
  AntdTable,
  LayouRow,
  LayoutCell,
  LayoutContent,
  LayoutTitle,
  UpdatePasswordModalContent,
} from "../components";
import { device } from "../device";
import {
  MemberAllReadResponseData,
  MemberCreateFromCompanyDTO,
  MemberInvitationDTO,
  MemberUpdateDTO,
  
} from "../generated";
import { MdOutlinePassword, MdOutlineContentCopy } from "react-icons/md";

import { GrMore } from "react-icons/gr";
import { privateApi } from "../react-query";
import { useMyPageAccountStore } from "../stores";
import { isSuccess, Pattern } from "../utils";

const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

const fetcherGetMembers = () =>
  privateApi.member.memberControllerReadAllMember();

const fetcherAddChildAccount = (params: MemberCreateFromCompanyDTO) =>
  privateApi.member.memberControllerSignupFromCompany({
    userId: params.userId,
    userName: params.userName,
  });

const fetcherUpdateMember = (params: MemberUpdateDTO) =>
  privateApi.member.memberControllerUpdateMember({
    userId: params.userId,
    companyAccessFlag: params.companyAccessFlag,
    password: params.password,
  });

const fetcherReSendInvitation = (parmas: MemberInvitationDTO) =>
  privateApi.member.memberControllerSendInvitaitonLink({
    userId: parmas.userId,
  });

const fetcherUpdatePassword = (params: MemberUpdateDTO) =>
  privateApi.member.memberControllerUpdateMember({
    userId: params.userId,
    password: params.password,
  });

    const fetcherGetAccessKey = () =>
    privateApi.member.memberControllerUpdateAccesskey();
export const MyPageAccountusermaster = () => {
  const store = useMyPageAccountStore((s) => s);
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 
  console.log(windowsize2)
  },[windowsize2]);
  const { isLoading: loadingGetMember, data: dataGetMember } = useQuery(
    ["memberControllerReadCurrentMember"],
    fetcherGetMember,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerReadCurrentMember onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("memberControllerReadCurrentMember onError: ", e);
      },
    }
  );
  const { mutate: mutateGetAccesKey } =
    useMutation(["memberControllerSignupFromCompany"], fetcherGetAccessKey, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) { 
          refetchGetMembers();
          message.info("발급 후 접근키가 안나오시면 새로고침을 한번 부탁드립니다.")
        }
      },
      onError: (e) => {
        console.log("memberControllerSignupFromCompany onError: ", e);
      },
    });
  const {
    isLoading: loadingGetMembers,
    data: dataGetMembers,
    refetch: refetchGetMembers,
  } = useQuery(["memberControllerReadAllMember"], fetcherGetMembers, {
    onSuccess: (e) => {
      if (isSuccess(e.data.header)) {
        console.log("memberControllerReadAllMember onSuccess: ", e);
      }
    },
    onError: (e) => {
      console.log("memberControllerReadAllMember onError: ", e);
    },
  });

  const { isLoading: loadingAddChildAccount, mutate: mutateAddChildAccount } =
    useMutation(["memberControllerSignupFromCompany"], fetcherAddChildAccount, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerSignupFromCompany onSuccess: ", e);
          message.info("하위계정이 생성되었습니다");
          refetchGetMembers();
          store.modalAddChildAccount.onClose();
        }
      },
      onError: (e) => {
        console.log("memberControllerSignupFromCompany onError: ", e);
      },
    });

  const { isLoading: loadingUpdateUser, mutate: mutateUpdateUser } =
    useMutation(fetcherUpdateMember, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerUpdateMember onSuccess: ", e);
          message.info("업데이트 되었습니다");
          refetchGetMembers();
        }
      },
      onError: (e) => {
        console.log("memberControllerUpdateMember onError: ", e);
      },
    });

  const { isLoading: loadingReSendInvitation, mutate: mutateReSendInvitation } =
    useMutation(fetcherReSendInvitation, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerSendInvitaitonLink onSuccess: ", e);
          message.info("해당 메일로 전송되었습니다.");
        }
      },
      onError: (e) => {
        console.log("memberControllerSendInvitaitonLink onError: ", e);
      },
    });

  const { isLoading: loadingUpdatePassword, mutate: mutateUpdatePassword } =
    useMutation(fetcherUpdatePassword, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerUpdateMember onSuccess: ", e);
          message.info("패스워드가 변경되었습니다.");
          store.modalUpdatePassword.onClose();
        }
      },
      onError: (e) => {
        console.log("memberControllerUpdateMember onError: ", e);
      },
    });

  /**
   * [검색] 버튼 클릭시
   */
  const onClickSearch = () => {
    refetchGetMembers();
  };

  /**
   * [초대메일 재전송] 클릭시
   */
  const onClickReInvite = (userId: string) => {
    // TODO : 유저 ID값 추가하기
    mutateReSendInvitation({ userId });
  };

  /**
   * [하위계정생성] 확인 버튼 클릭시
   */
  const onModalClickAddChildAccount = () => {
    const { form } = store.formAddChildAccount;
    mutateAddChildAccount({ userId: form.email, userName: form.username });
  };

  /**
   * [패스워드 변경] 확인 버튼 클릭시
   */
  const onModalClickUpdatePassword = () => {
    const { form } = store.formUpdatePassword;
    if (dataGetMember?.data.body.data.userId) {
      mutateUpdatePassword({
        userId: dataGetMember?.data.body.data.userId,
        password: form.password,
      });
    }
  };

  /**
   * [페이징] 변경시
   */
  const onChangePagination = (page: number, pageSize?: number) => {
    store.formFilter.onChangeForm({
      page,
    });
  };

  /**
   * [계정정지] 업데이트
   */
  const onChangeCompanyAccessFlag = (
    userId: string,
    companyAccessFlag: boolean
  ) => {
    mutateUpdateUser({
      userId,
      companyAccessFlag: companyAccessFlag ? 1 : 0,
    });
  };

  /**
   * [사용자명 검색] 입력폼
   */
  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      store.formFilter.onChangeForm({
        useranme: value,
      });
    },
    []
  );

  /** 하위계정생성버튼 클릭 가능여부 */
  const availableAddChildAccount = useMemo(
    () =>
      store.formAddChildAccount.form.email.length > 0 &&
      store.formAddChildAccount.form.username.length > 0 &&
      Pattern.checkEmail.test(store.formAddChildAccount.form.email),
    [
      store.formAddChildAccount.form.email,
      store.formAddChildAccount.form.username,
    ]
  );


  /** 검색조건에 만족하는 계정정보들 */
  const members = useMemo(() => {
    if (!dataGetMembers?.data) {
      return [];
    }

    return dataGetMembers.data.body.data.filter(
      (v) =>
        v.userName.includes(store.formFilter.form.useranme) ||
        v.userId.includes(store.formFilter.form.useranme)
    );
  }, [dataGetMembers?.data, store.formFilter.form.useranme]);


  useEffect(() => {
    return () => {
      // store.onReset();
    };
  }, []);

  const handleCopyClipBoard = (text: string) => {
    try {
      navigator.clipboard.writeText(text);
      alert('클립보드에 복사되었습니다.');
    } catch (error) {
      alert('클립보드 복사에 실패하였습니다.');
    }
  };

  return (
    <Container>
      <ExtendedLayoutRow>
        <LayoutContent>
          <Content>
            <Space>
              <ExtendedLayoutCell>
                <LayoutTitle>계정 정보</LayoutTitle>
                <Table>
                  <tbody>
                    <tr>
                      <td>아이디</td>
                      <td>{dataGetMember?.data.body.data.userId}</td>
                      <td style={{ textAlign: "right" }}>
                      <AntdButton
                          isPrimary
                          style={windowsize? { color: "white" , marginLeft : 10 } : { color: "white",  display: "flex", alignItems: "center" }}
                          onClick={store.modalUpdatePassword.onOpen}
                          className="btnSecondary"
                        >
                          패스워드 변경
                          <MdOutlinePassword size={17}  style={{ marginLeft: "6px" }}/>
                        </AntdButton>
                      </td>
                    </tr>
                    <tr>
                      <td>접근키</td>
                      <td>{dataGetMember?.data.body.data.accesskey}</td>
                      <td style={{ textAlign: "right" }}>
                        <AntdButton
                          isPrimary
                          style={windowsize? { color: "white" , marginLeft : 10 } : { color: "white"}}
                          onClick={()=>{
                            if(dataGetMember?.data.body.data.accesskey === ""){
                              mutateGetAccesKey()
                            }
                            else{
                              handleCopyClipBoard(dataGetMember?.data.body.data.accesskey as string)
                            }
                          }}
                          className="btnBlue"

                        >
                          {dataGetMember?.data.body.data.accesskey === "" ? "발급" : "복사"}
                          <MdOutlineContentCopy size={14}  style={{ marginLeft: "6px"}}/>

                        </AntdButton>
                      </td>
                    </tr>
                    <tr>
                      <td>사용자명</td>
                      <td>{dataGetMember?.data.body.data.userName}</td>
                    </tr>
                    <tr>
                      <td>사용자권한</td>
                      <td>
                        {dataGetMember?.data.body.data.userRole === "100" && (
                          <Tag color="magenta">마스터</Tag>
                        )}
                        {dataGetMember?.data.body.data.userRole === "101" && (
                          <Tag color="geekblue">일반</Tag>
                        )}
                        {dataGetMember?.data.body.data.userRole === "900" && (
                          <Tag color="magenta">마스터</Tag>
                        )}
                        {dataGetMember?.data.body.data.userRole === "901" && (
                          <Tag color="geekblue">일반</Tag>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </ExtendedLayoutCell>
            </Space>
            {/* <ExtendedLayoutCell style={{marginBottom : "20px"}}> */}
                {/* <LayoutTitle>요금제 정보</LayoutTitle> */}
                {/* <Table3>
                  <tbody>
                    <tr>
                      <td>요금제</td>
                      <td>{dataGetMember?.data.body.data.companyInformation.basicChargePlanInformation.chargePlanName}</td>
                      <td style={{fontWeight: "bold"}}>프로모션</td>
                      <td>{dataGetMember?.data.body.data.companyInformation.chargePlanInformation.chargePlanName}</td>
                      <td style={{fontWeight: "bold"}}>만료기간</td>
                      <td>{dataGetMember?.data.body.data.companyInformation.promotionExpiryDate ? dataGetMember?.data.body.data.companyInformation.promotionExpiryDate : "해당사항 없음" }</td>
                    </tr>
                  </tbody>
                </Table3> */}
              {/* </ExtendedLayoutCell> */}
            <ExtendedLayoutCell className="bottom">
              <Form layout="vertical" style={{ marginBottom: 20 }}>
                <Form.Item label="사용자명 검색">
                  <FormSpace>
                    <FormSubSpace>
                      <Input
                        placeholder="이름, 아이디 검색"
                        value={store.formFilter.form.useranme}
                        onChange={onChangeInput}
                      />
                      {/* <AntdButton
                        isPrimary
                        style={{ color: "white" }}
                        onClick={onClickSearch}
                      >
                        검색
                      </AntdButton> */}
                    </FormSubSpace>
                    <AntdButton
                      isPrimary
                      loading={loadingAddChildAccount}
                      style={{ color: "white" }}
                      onClick={store.modalAddChildAccount.onOpen}
                      className="btnGreen"

                    >
                      초대하기
                    </AntdButton>
                  </FormSpace>
                </Form.Item>
              </Form>

              <LayoutTitle>하위계정정보</LayoutTitle>
              <ExtendedAntdTable
                className="antd-table-bottom"
                style={{
                 // minHeight: 300,
                 borderTop: "1px solid #dfdfdf",
                 borderBottom: "1px solid #dfdfdf",
                }}
                tableLayout="fixed"
                scroll={windowsize ? { y: 310 }: { x: 520, y: 310 }}
                rowKey="userIdx"
                loading={loadingGetMembers}
                dataSource={members}
                pagination={false}
                columns={[
                  {
                    title: "아이디",
                    dataIndex: "userId",
                    key: "userId",
                    // fixed: true,
                    align: "center",
                    width: windowsize? 140 : 350,
                  },
                  {
                    title: "사용자명",
                    dataIndex: windowsize ? "none" : "userName",
                    key: "userName",
                    align: "center",

                    width: windowsize? 0 : 120,
                  },
                  {
                    title:  windowsize?"추가 정보" : "",
                    dataIndex: windowsize ? "userRole" : "none",
                    key: "userName",
                    align: "center", 

                    width: windowsize? 35 : 0,
                    render : (e,v:MemberAllReadResponseData) =>{
                      if(e) {
                        const name = v.userName
                      
                        const registerDate = v.registerDate
                        const role =
                        v.userRole === "100"
                          ? "마스터"
                          : e === "101"
                          ? "일반"
                          : e === "900"
                          ? "마스터"
                          : "일반";
                        return windowsize? 
                        
                        (
                          <Tooltip
                          title={
                            <TagSpace style={{display:"flex", flexDirection : "column"}} >
                             <div><p>사용자 명: {name}</p></div>
                             <div><p>등록일 : {dayjs(registerDate).format("YYYY-MM-DD HH:mm")}</p></div>
                             <div><p>권한 : <Tag>{role}</Tag></p></div>
                            </TagSpace>
                          }
                        >
                        <GrMore/>
                        </Tooltip>
                        
                        )
                        : <div></div>
                      }
                    }
                  },
                  // {
                  //   title: "접근권한",
                  //   dataIndex: "companyAccessFlag",
                  //   key: "companyAccessFlag",
                  //   align: "center",
                  //   render: (e) => {
                  //     if (e === 1) {
                  //       return "허용";
                  //     } else {
                  //       return "정지";
                  //     }
                  //   },
                  // },
                  {
                    title: windowsize? "" :"사용자 권한",
                    dataIndex: windowsize ? "none" : "userRole",
                    key: "userRole",
                    align: "center",
                    width: windowsize? 0 : 160,
                    render: (e) => {
                      const role =
                        e === "100"
                          ? "마스터"
                          : e === "101"
                          ? "일반"
                          : e === "900"
                          ? "마스터"
                          : "일반";
                      return windowsize? <div style={{display:"none"}}></div> : <Tag>{role}</Tag>;
                    },
                  
                  },
                  {
                    title: windowsize ? "" : "등록일",
                    dataIndex: "registerDate",
                    key: "registerDate",
                    align: "center",
                    width:  windowsize ? 0 : 160,
                    render: (e) =>  windowsize ? <div></div> : <>{dayjs(e).format("YYYY-MM-DD HH:mm")}</>,
                  },
                  {
                    title: "초대메일 재전송",
                    dataIndex: "userId",
                    key: "userId",
                    align: "center",
                    width: windowsize ? 50 :160,
                    render: (e) => (
                      <Link>
                        <MdMail
                          size={20}
                          color="var(--primary-color)"
                          onClick={
                            !loadingReSendInvitation
                              ? () => onClickReInvite(e)
                              : undefined
                          }
                        />
                      </Link>
                    ),
                  },
                  {
                    title: "계정상태",
                    dataIndex: "companyAccessFlag",
                    key: "companyAccessFlag",
                    align: "center",
                    width: windowsize ? 60 :150,
                    render: (e, user: MemberAllReadResponseData) => {
                      return (
                        <Switch
                          onChange={(e) =>
                            onChangeCompanyAccessFlag(user.userId, e)
                          }
                          checked={Boolean(e)}
                        />
                      );
                    },
                  },
                ]}
              />
              {/* {dataGetMembers?.data && dataGetMembers?.data.body.total > 2 && (
                <AntdPagination
                  className="test"
                  disabled={loadingGetMembers}
                  loading={loadingGetMembers}
                  pageSize={2}
                  position="right"
                  current={store.formFilter.form.page}
                  total={dataGetMembers.data.body.total}
                  onChange={onChangePagination}
                />
              )} */}
            </ExtendedLayoutCell>
          </Content>
        </LayoutContent>
      </ExtendedLayoutRow>
      <AntdModal
        draggable
        title="하위계정생성"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalAddChildAccount.visible}
        onOk={onModalClickAddChildAccount}
        onCancel={store.modalAddChildAccount.onClose}
        okButtonProps={{
          disabled: !availableAddChildAccount || loadingAddChildAccount,
        }}
        okText="확인"
        cancelText="취소"
      >
        <AddedChildAccountModalContent />
      </AntdModal>
      <AntdModal
        draggable
        title="패스워드 변경"
        style={{
          maxWidth: "500px",
          width: "100%",
        }}
        visible={store.modalUpdatePassword.visible}
        onOk={onModalClickUpdatePassword}
        onCancel={store.modalUpdatePassword.onClose}
        okButtonProps={{
          disabled:
            loadingUpdatePassword ||
            store.formUpdatePassword.form.password.length < 1 ||
            store.formUpdatePassword.form.passwordConfirm.length < 1 ||
            store.formUpdatePassword.form.password !==
              store.formUpdatePassword.form.passwordConfirm,
        }}
        okText="확인"
        cancelText="취소"
      >
        <UpdatePasswordModalContent />
      </AntdModal>
    </Container>
  );
};
const Container = styled.div`
  height: 100%;

  @media ${device.laptop.max} {
    .ant-table-cell {
      &:first-child {
        width: 200px !important;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }
`;

const ExtendedLayoutRow = styled(LayouRow)`
  @media ${device.laptop.max} {
    min-height: 100vh;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  @media ${device.laptop.min} {
    /* height: calc(100vh - 81px - 20px - 12px); */
  }
`;

const ExtendedLayoutCell = styled(LayoutCell)`
  &.bottom {
    @media ${device.laptop.min} {
      .antd-table-bottom {
        height: calc(42.5vh);
        /* height: 60%; */
      }
      /* height: calc(100vh - 410px); */
    }
  }
`;

const Space = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  margin-bottom: 30px;
  & > div {
    width: 100%;
  }

  @media ${device.laptop.max} {
    flex-flow: column;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  /* border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color); */
  min-height: 200px;
  tr {
  }
  td {
    color: #585858;
    padding: 8px 12px;
    &:first-child {
      font-weight: bold;
      /* background-color: #26395d; */
      /* color: white; */
      width: 140px;
    }
 
  }
  @media ${device.laptop.max} {
    td {
      
        padding : 0px;
        /* background-color: #26395d; */
        /* color: white; */
        width: 140px;
        font-size : 12px;
        
      
    }
  }
`;

const FormSpace = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 30px;
  }

  @media ${device.laptop.max} {
    flex-flow: column;
    & > div {
      width: 100%;
      margin-bottom: 8px;
    }
    & > button {
      width: 100%;
    }
  }
`;
const FormSubSpace = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Link = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ExtendedAntdTable = styled(AntdTable)`
@media ${device.laptop.max} {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
  font-size: 9px;
`;
const TagSpace = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 4px;
  &.overflow-hide {
    flex-flow: row;
    overflow: hidden;
  }
`;