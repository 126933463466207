const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '992px',
  laptop: '1200px',
  // laptop: '500px',
  laptopL: '1440px',
  desktop: '2560px'
};

/**
 *  @example
 *
 *  @media ${device.laptop} {
      max-width: 800px;
    }
 */
export const device = {
  /** max-width: 320px */
  mobileS: {
    min: `(min-width: ${size.mobileS})`,
    max: `(max-width: ${size.mobileS})`
  },
  /** max-width: 375px */
  mobileM: {
    min: `(min-width: ${size.mobileM})`,
    max: `(max-width: ${size.mobileM})`
  },
  /** max-width: 425px */
  mobileL: {
    min: `(min-width: ${size.mobileL})`,
    max: `(max-width: ${size.mobileL})`
  },
  /** max-width: 768px */
  tablet: {
    min: `(min-width: ${size.tablet})`,
    max: `(max-width: ${size.tablet})`
  },
  /** max-width: 992 */
  tabletL: {
    min: `(min-width: ${size.tabletL})`,
    max: `(max-width: ${size.tabletL})`
  },
  /** max-width: 1200px */
  laptop: {
    min: `(min-width: ${size.laptop})`,
    max: `(max-width: ${size.laptop})`
  },
  /** max-width: 1440px */
  laptopL: {
    min: `(min-width: ${size.laptopL})`,
    max: `(max-width: ${size.laptopL})`
  },
  /** max-width: 2560px */
  desktop: {
    min: `(min-width: ${size.desktop})`,
    max: `(max-width: ${size.desktop})`
  }
};