import { SetState } from "zustand";

export interface IReducerForm<T extends {}> {
  form: T;
  onChangeForm: (params: Partial<T>) => void;
}

const dispatch = <T extends {}>(
  setter: SetState<{}>,
  name: string,
  updatedForm: Partial<T>
) => {
  setter((s) => ({
    [name]: {
      ...(s as any)[name],
      form: {
        ...(s as any)[name].form,
        ...updatedForm,
      },
    },
  }));
};

export const reducerForm = <T extends {}, K>(
  setter: SetState<T>,
  name: keyof T,
  form: K
): IReducerForm<K> => {
  return {
    form,
    onChangeForm: (updatedForm) => {
      dispatch(setter, name as string, updatedForm);
    },
  };
};
