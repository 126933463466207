export class Pattern {
  static 영소 = "a-z";
  static 영대 = "A-Z";
  static 한글 = "ㄱ-ㅎ|가-힣";
  static 숫자 = "0-9";
  static 특문 = "~!@#$%^&*()+=_-";

  /** 입력한 특정 형식들의 기본형식 반환 */
  static checkOptional(...args: Pattern[]): RegExp {
    const r = args.join("");
    const p = `^[${r}]*$`;
    return new RegExp(p);
  }

  /** 입력한 특정 입력형식들로 필수조합형식 반환 */
  static checkCombination(...args: Pattern[]): RegExp {
    const r = args.map((item) => `(?=.*[${item}])`).join("");
    const p = `^${r}.+$`;
    return new RegExp(p);
  }

  /** 이메일 형식 반환 */
  static get checkEmail() {
    const r =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return RegExp(r);
  }
}
