import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import { AiFillExclamationCircle } from "react-icons/ai";
import styled from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  emptyText?: string;
}

export const AntdTable2: React.FC<IProps & TableProps<any>> = ({
  className,
  style,
  children,
  emptyText = "검색결과가 없습니다.",
  ...props
}) => (
  <ExtendedTable
    locale={{
      emptyText: <EmptyText text={emptyText} />,
    }}
    showSorterTooltip
    className={className}
    style={style}
    size="small"
    scroll={{ y: 310 }}
    {...props}
  >
    {children}
  </ExtendedTable>
);

const ExtendedTable = styled(Table)`
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  .ant-table-thead > tr > th {
    /* background-color: #f5f5f7; */
    /* background-color: white; */
    /* border-bottom: 1px solid black; */
    &:before {
      display: none;
    }
  }
  tr {
    th,
    td {
      /* overflow-wrap: normal;
        word-break: keep-all;
        .ant-table-cell {
          overflow-wrap: normal;
          word-break: keep-all;
        } */
    }
  }
  .ant-table-empty {
    .ant-table-tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .ant-table-tbody > tr > td {
    font-size: 13px;
  }
  .ant-table-row-selected {
    td {
      background: #f2f6ff !important;
    }
  }

  /* @media ${device.laptop.max} {
    thead,
    tbody,
    tr {
      display: flex;
      flex-flow: column;
    }
    tbody {
      flex-flow: row;
      max-width: 100%;
      overflow: auto;
    }
    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }

    td, th {
      height: 40px;
      text-align: center !important;
    }
    .ant-table table {
      display: flex !important;
    }
    colgroup {
      display: none;
    }
  } */
`;

const EmptyText: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <EmptyContainer>
      <span>{text}</span>
    </EmptyContainer>
  );
};

const EmptyContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  color: #6a6a6a;
  span {
    margin-top: 4px;
  }
`;
