import {  Form, Input } from "antd";
import { useCallback } from "react";
import styled from "styled-components";
import { useAddressBookListStore } from "../stores";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const TagFormModalContent: React.FC<IProps> = () => {
  const store = useAddressBookListStore((s) => s);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      store.formModalTag.onChangeForm({
        [name]: value,
      });
    },
    []
  );

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="태그">
          <Input
            autoFocus
            placeholder="#태그명"
            name="tagName"
            maxLength={200}
            value={store.formModalTag.form.tagName}
            onChange={onChange}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const Container = styled.div``;
