import { Checkbox, Popover } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { BsQuestionCircleFill } from "react-icons/bs";
import styled from "styled-components";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  length: number;
  checked?: boolean;
  onChangeCheckbox?: (e: CheckboxChangeEvent) => void;
}

export const ByteText: React.FC<IProps> = ({
  length,
  checked = false,
  onChangeCheckbox,
  ...props
}) => {
  return (
    <Container {...props}>
      <Checkbox checked={checked} onChange={onChangeCheckbox}>
        <Text>
          광고성 문자
          <Popover
            placement="topLeft"
            content={
              <PopupContent>
                <p>※ 광고 및 스팸 메시지에 대한 책임은 발송 당사자에 있으며,</p>
                <p>이를 미준수 할 경우, 이용에 제한이 있을 수 있습니다.</p>
              </PopupContent>
            }
          >
            <BsQuestionCircleFill size={12} color="#bbb" />
          </Popover>
        </Text>
      </Checkbox>
      <ByteItem className={length === 2000 ? "active" : ""}>
        {length} / {length > 90 ? 2000 : 90} Byte
      </ByteItem>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  letter-spacing: -.5px;
  font-size: 12px;
  color: #888;
  &.hover {
    color: var(--primary-color);
  }
`;

const ByteItem = styled.div`
  font-size: 12px;
  &.active {
    color: #ff4444;
  }
`;

const PopupContent = styled.div`
  font-size: 12px;
  color: var(--primary-color);
`;
