import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import {
  AlimtalkTemplateReadDetailResponseData,
  ReadProfileResponseData,
  SmsControllerReadAllTemplateParams,
  SmsTemplateCreateDTO,
  SmsTemplateReadDetailResponseData,
} from "../generated";
import {
  IReducerForm,
  IReducerModal,
  reducerForm,
  reducerModal,
} from "../reducers";

type TTab = "SMS" | "ALARM";

interface IFormSms {
  /** 문자내용 or 템플릿내용 */
  message: string;
  /** (문자) 광고 여부 */
  isAd?: number;
  /** 저장된 문자 쿼리 */
  query: SmsControllerReadAllTemplateParams;
  /** 문자 추가시 쿼리 */
  addSmsQuery: SmsTemplateCreateDTO;
  /** 모달 - 템플릿추가 - 선택된 발신프로필 */
  selectedTemplateOnModal?: ReadProfileResponseData;
  /** 모달 - 템플릿추가 - 템플릿명 */
  templateNameOnModal: string;
  /** 모달 - 템플릿추가 - 템플릿내용 */
  templateContentOnModal: string;
  /** 모달 - 템플릿추가 - 대분류 */
  templateCode1: string;
  /** 모달 - 템플릿추가 - 중분류 */
  templateCode2: string;

  /** 모달 - 발신프로필추가 - 발신프로필 */
  addedProfileName: string;
  /** 모달 - 발신프로필추가 - 대분류 */
  addedTemplateCode1: string;
  /** 모달 - 발신프로필추가 - 중분류 */
  addedTemplateCode2: string;
  /** 모달 - 발신프로필추가 - 소분류 */
  addedTemplateCode3: string;
  /** 모달 - 발신프로필추가 - 휴대폰번호 */
  addedPhone: string;
  /** 모달 - 발신프로필추가 - 인증번호 */
  addedCertificationNumber: string;
  /** 모달 - 발신프로필추가 - 인증완료여부 */
  addedIsValiated: boolean;
  /** 모달 - 발신프로필추가 - 생성된 카카오 프로필 순번 */
  addedProfileIdx?: number;
  /** 모달 - 발신프로필추가 - 생성된 카카오 프로필 생성 인증요청 진행중 */
  addedIsValidating: boolean;
}

/** 발신번호 등록시 모달 폼 */
interface IFormAddPhoneModal {
  /** 인증완료여부 */
  verified: boolean;
  /** 인증유형 ("전화번호" or "서류인증") */
  type: "전화번호" | "서류인증";
  /** 발신번호 */
  phone: string;
}

interface IStore extends State {
  clicked : number;
  /** 문자설정(SMS) or 알림톡설정(ALARM) */
  tab: TTab;
  /** 상단 테이블 열기 여부 (열려있는경우=true) */
  isOpenTable: boolean;
  /** 문자설정 탭의 폼 */
  formSms: IReducerForm<IFormSms>;
  /** 발신번호 등록 모달 폼 */
  formAddPhoneModal: IReducerForm<IFormAddPhoneModal>;
  /** 문자설정 탭의 선택된 문자내역 */
  selectedSms?: SmsTemplateReadDetailResponseData;
  /** 알림톡설정 탭의 선택된 템플릿내역 */
  selectedTemplate?: AlimtalkTemplateReadDetailResponseData;
  /** 모달 - 메시지추가 */
  modalAddMessage: IReducerModal;
  /** 모달 - 템플릿추가 */
  modalAddTemplate: IReducerModal;
  /** 모달 - 발신번호 등록 */
  modalAddReceivedPhone: IReducerModal;
  /** 모달 - 발신프로필 등록 */
  modalAddReceivedProfile: IReducerModal;
  /** 문자설정 or 알림톡 탭 변경 이벤트 */
  onChangeTab: (tab: TTab) => void;
  /** 문자설정 탭의 선택된 문자내역 변경 이벤트 */
  onChangeSelectedSms: (sms?: SmsTemplateReadDetailResponseData) => void;
  /** 알림톡설정 탭의 선택된 템플릿 변경 이벤트 */
  onChangeSelectedTemplate: (
    template?: AlimtalkTemplateReadDetailResponseData
  ) => void;
  /** 상단 테이블 열기/닫기 이벤트 */
  onToggleIsOpenTable: () => void;
  onReset: () => void;
}

const initFormAddPhoneModal: IFormAddPhoneModal = {
  verified: false,
  type: "전화번호",
  phone: "",
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    clicked : 0,
    tab: "SMS",
    isOpenTable: false,
    formSms: reducerForm<IStore, IFormSms>(set, "formSms", {
      isAd: undefined,
      message: "",
      query: {
        page: 1,
        pageSize: 10,
      },
      addSmsQuery: {
        isAd: undefined,
        templateName: "",
        templateContent: "",
      },
      selectedTemplateOnModal: undefined,
      templateNameOnModal: "",
      templateContentOnModal: "",
      templateCode1: "all",
      templateCode2: "all",
      addedProfileName: "",
      addedTemplateCode1: "all",
      addedTemplateCode2: "all",
      addedTemplateCode3: "all",
      addedPhone: "",
      addedCertificationNumber: "",
      addedIsValiated: false,
      addedProfileIdx: undefined,
      addedIsValidating: false,
    }),
    formAddPhoneModal: reducerForm<IStore, IFormAddPhoneModal>(
      set,
      "formAddPhoneModal",
      initFormAddPhoneModal
    ),
    selectedSms: undefined,
    selectedTemplate: undefined,
    modalAddMessage: reducerModal<IStore>(set, "modalAddMessage", false, () => {
      get().formSms.onChangeForm({
        addSmsQuery: {
          isAd: undefined,
          templateName: "",
          templateContent: "",
        },
      });
    }),
    modalAddTemplate: reducerModal<IStore>(
      set,
      "modalAddTemplate",
      false,
      () => {
        get().formSms.onChangeForm({
          selectedTemplateOnModal: undefined,
          templateNameOnModal: "",
          templateContentOnModal: "",
          templateCode1: "all",
          templateCode2: "all",
        });
      }
    ),
    modalAddReceivedPhone: reducerModal<IStore>(
      set,
      "modalAddReceivedPhone",
      false,
      () => {
        get().formAddPhoneModal.onChangeForm(initFormAddPhoneModal);
      }
    ),
    modalAddReceivedProfile: reducerModal<IStore>(
      set,
      "modalAddReceivedProfile",
      false,
      () => {
        get().formSms.onChangeForm({
          addedProfileName: "",
          addedTemplateCode1: "all",
          addedTemplateCode2: "all",
          addedTemplateCode3: "all",
          addedPhone: "",
          addedCertificationNumber: "",
          addedIsValiated: false,
          addedProfileIdx: undefined,
          addedIsValidating: false,
        });
      }
    ),
    onToggleIsOpenTable: () => set({ isOpenTable: !get().isOpenTable ,clicked : 1}),
    onChangeTab: (tab) => {
      const { formSms } = get();
      set({
        tab,
        isOpenTable: false,
        selectedSms: undefined,
        formSms: {
          ...formSms,
          form: {
            ...formSms.form,
            isAd: undefined,
            message: "",
            query: {
              page: 1,
              pageSize: 10,
            },
          },
        },
      });
    },
    onChangeSelectedSms: (selectedSms) => {
      const { formSms } = get();
      set({
        selectedSms,
        formSms: {
          ...formSms,
          form: {
            ...formSms.form,
            isAd: selectedSms?.isAd ? 1 : 0,
            message: selectedSms?.templateContent || "",
          },
        },
      });
    },
    onChangeSelectedTemplate: (selectedTemplate) => {
      const { formSms } = get();
      set({
        selectedTemplate,
        formSms: {
          ...formSms,
          form: {
            ...formSms.form,
            message: selectedTemplate?.templateContent || "",
          },
        },
      });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** [메시지] > [메시지 설정] 네비메뉴 상태관리 */
export const useMessageSetStore = create<IStore>()(
  devtools(initialStateCreator)
);
