import { AlimtalkTemplatePramReadResponseData } from "../generated";

export const getTemplateFromParams = (
  template: string,
  params?: Array<
    AlimtalkTemplatePramReadResponseData & {
      value: string;
    }
  >
) => {
  if (params && params?.length > 0) {
    let updatedTemplate = template;

    for (var i = 0; i < params.length; i++) {
      const param = params[i];
      const key = `#{${param.key}}`;
      const value = `${param.value}`;
      if (param.key !== param.value && param.value.length > 0) {
        updatedTemplate = updatedTemplate.replace(key, value);
      }
    }

    return updatedTemplate;
  }
  return template;
};
