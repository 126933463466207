import { IoMdRefresh } from "react-icons/io";
import { useMemo, useCallback } from "react";
import { ResponsivePie } from "@nivo/pie";
import styled from "styled-components";
import { useSummaryDashboardStore } from "../stores";
import { IComponentProps } from "../types";
import { MdArrowBackIosNew } from "react-icons/md";
import dayjs from "dayjs";
import { queryClient } from "../react-query";
import { device } from "../device";
import { useNavigate } from 'react-router-dom';


interface IProps extends IComponentProps {}

export const DashboardMessageStats: React.FC<IProps> = () => {
  const store = useSummaryDashboardStore((s) => s);

  const navigate = useNavigate();

  const onClickButton = useCallback((e: string) => {
    store.formMessageStats.onChangeForm({
      type: e as "SMS",
    });
  }, []);

  /**
   * [좌측 화살표 아이콘] 클릭시
   */
  const onClickPrevArrow = useCallback(() => {
    let type;
    switch (store.formMessageStats.form.type) {
      case "SMS":
        type = "ALIMTALK";
        break;
      case "LMS":
        type = "SMS";
        break;
      case "MMS":
        type = "LMS";
        break;
      case "ALIMTALK":
        type = "MMS";
        break;
      default:
        break;
    }
    store.formMessageStats.onChangeForm({
      type: type as "SMS",
    });
  }, [store.formMessageStats]);

  /**
   * [우측 화살표 아이콘] 클릭시
   */
  const onClickNextArrow = useCallback(() => {
    let type;
    switch (store.formMessageStats.form.type) {
      case "SMS":
        type = "LMS";
        break;
      case "LMS":
        type = "MMS";
        break;
      case "MMS":
        type = "ALIMTALK";
        break;
      case "ALIMTALK":
        type = "SMS";
        break;
      default:
        break;
    }
    store.formMessageStats.onChangeForm({
      type: type as "SMS",
    });
  }, [store.formMessageStats]);

  /**
   * [새로고침 아이콘] 클릭시
   */
  const onClickRefresh = () => {
    const today = dayjs();
    const startRequestDate = today.add(-6, "day").format("YYYY-MM-DD");
    const endRequestDate = today.format("YYYY-MM-DD");

    if (store.formFilter.form.startRequestDate !== startRequestDate) {
      store.formFilter.onChangeForm({
        startRequestDate,
        endRequestDate,
      });
      store.formMessageStats.onChangeForm({
        type: "SMS",
      });
    } else {
      queryClient.refetchQueries(["messageControllerReadStats"]);
    }
  };

  const dataSource = useMemo(() => {
    let success = 0;
    let failed = 0;
    let pending = 0;
    const { selectedMessageStats } = store.formMessageStats.form;
    if (selectedMessageStats) {
      switch (store.formMessageStats.form.type) {
        case "SMS":
          success += +selectedMessageStats.smsSentSuccess;
          failed += +selectedMessageStats.smsSentFailed;
          pending += +selectedMessageStats.smsPending;
          break;
        case "LMS":
          success += +selectedMessageStats.lmsSentSuccess;
          failed += +selectedMessageStats.lmsSentFailed;
          pending += +selectedMessageStats.lmsPending;
          break;
        case "MMS":
          success += +selectedMessageStats.mmsSentSuccess;
          failed += +selectedMessageStats.mmsSentFailed;
          pending += +selectedMessageStats.mmsPending;
          break;
        case "ALIMTALK":
          success = +selectedMessageStats.alimtalkSentSuccess;
          failed = +selectedMessageStats.alimtalkSentFailed;
          pending = +selectedMessageStats.alimtalkPending;
          break;
      }
    } else {
      switch (store.formMessageStats.form.type) {
        case "SMS":
          store.messageStats.forEach((v) => {
            success += +v.smsSentSuccess;
            failed += +v.smsSentFailed;
            pending += +v.smsPending;
          });
          break;
        case "LMS":
          store.messageStats.forEach((v) => {
            success += +v.lmsSentSuccess;
            failed += +v.lmsSentFailed;
            pending += +v.lmsPending;
          });
          break;
        case "MMS":
          store.messageStats.forEach((v) => {
            success += +v.mmsSentSuccess;
            failed += +v.mmsSentFailed;
            pending += +v.mmsPending;
          });
          break;
        case "ALIMTALK":
          store.messageStats.forEach((v) => {
            success += +v.alimtalkSentSuccess;
            failed += +v.alimtalkSentFailed;
            pending += +v.alimtalkPending;
          });
          break;
      }
    }

    const total = success + failed + pending;
    const data = [
      {
        id: "성공",
        label: "성공",
        value:
          total === 0 && success === 0
            ? 0
            : Math.round((success / total) * 10000) / 100,
        color: "#28c76f",
      },
      {
        id: "실패",
        label: "실패",
        value:
          total === 0 && failed === 0
            ? 0
            : Math.round((failed / total) * 10000) / 100,
        color: "#ff9f43",
      },
      {
        id: "대기",
        label: "대기",
        value:
          total === 0 && pending === 0
            ? 0
            : Math.round((pending / total) * 10000) / 100,
        color: "#ea5455",
      },
    ];
    return data;
  }, [store.formMessageStats.form, store.messageStats]);

  console.log({
    dataSource,
  });
  return (
    <Container>
      {store.formMessageStats.form.selectedMessageStats ? (
        <Title className="active">
          {store.formMessageStats.form.selectedMessageStats.requestDate}
        </Title>
      ) : (
        <Title>
          {store.formFilter.form.startRequestDate}
          {" ~ "}
          {store.formFilter.form.endRequestDate}
        </Title>
      )}
      <div className="nivo-wrapper">
        <ResponsivePie
        onClick={()=>{
          store.formFilter.onChangeForm({
            clicked :1
          })
          // store.formFilter.form.clicked = 1;
          navigate(`/analysis/send`);
          // console.log(store.formMessageStats.form.type);
          // console.log(store.formFilter.form.startRequestDate);
          // console.log(store.formFilter.form.endRequestDate);
        }}
        
          isInteractive
          sortByValue={false}
          data={dataSource}
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          
          endAngle={-360}
          // enablePoints={false}
          // enableGridX={false}
          // crosshairType="x"
          // enableSlices="x"
          // enableArea={false}
          arcLinkLabelsStraightLength={8}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          // arcLinkLabel={(d) => `${d.id}: ${d.value}`}
          arcLabel={(e) => e.value + "%"}
          arcLinkLabel={(d) => `${d.id}`}
          colors={{ datum: "data.color" }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 1.2]],
          }}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["brighter", 6]],
          }}
          // defs={[
          //   {
          //     id: "dots",
          //     type: "patternDots",
          //     background: "inherit",
          //     color: "rgba(255, 255, 255, 0.3)",
          //     size: 4,
          //     padding: 1,
          //     stagger: true,
          //   },
          //   {
          //     id: "lines",
          //     type: "patternLines",
          //     background: "inherit",
          //     color: "rgba(255, 255, 255, 0.3)",
          //     rotation: -45,
          //     lineWidth: 6,
          //     spacing: 10,
          //   },
          // ]}
          fill={[
            {
              match: {
                id: "성공",
              },
              id: "lines",
              // id: "dots",
            },
            {
              match: {
                id: "실패",
              },
              id: "lines",
              // id: "dots",
            },
            {
              match: {
                id: "대기",
              },
              id: "dots",
            },
          ]}
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 90,
              // translateY: 56,
              itemsSpacing: 0,
              itemWidth: 80,
              itemHeight: 20,
              // itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 12,
              // symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
      <ButtonGroup>
        <Button
          className={store.formMessageStats.form.type === "SMS" ? "sms active" : ""}
          onClick={() => onClickButton("SMS")}
        >
          SMS
        </Button>
        <Button
          className={store.formMessageStats.form.type === "LMS" ? "lms active" : ""}
          onClick={() => onClickButton("LMS")}
        >
          LMS
        </Button>
        <Button
          className={store.formMessageStats.form.type === "MMS" ? "mms active" : ""}
          onClick={() => onClickButton("MMS")}
        >
          MMS
        </Button>
        <Button
          className={
            store.formMessageStats.form.type === "ALIMTALK" ? "talk active" : ""
          }
          onClick={() => onClickButton("ALIMTALK")}
        >
          알림톡
        </Button>
      </ButtonGroup>
      <ArrowIcon onClick={onClickPrevArrow}>
        <MdArrowBackIosNew size={30} />
      </ArrowIcon>
      <ArrowIcon className="right" onClick={onClickNextArrow}>
        <MdArrowBackIosNew size={30} />
      </ArrowIcon>
      <RefreshIcon>
        <IoMdRefresh size={20} cursor="pointer" onClick={onClickRefresh} />
      </RefreshIcon>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  .nivo-wrapper {
    height: 100%;
    max-height: 300px;
  }
  @media ${device.laptop.max} {
    .nivo-wrapper {
      max-height: inherit;
      & > div {
        &:last-child {
          height: 300px !important;
        }
        & > div {
          & > svg {
            height: 300px;
          }
        }
      }
    }
  }
`;
const Title = styled.h5`
  width: fit-content;
  margin: 0 auto -17px;
  padding: 0 28px;
  background: #f6f8fc;
  border-radius: 12px;
  color: #2d405f;
  font-weight: 300;
  text-align: center;
  font-size: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non */
  &.active {
    background-color: var(--primary-color);
    color: white;
    padding: 2px 8px;
    border-radius: 6px;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 12px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non */
`;

const Button = styled.button`
  width: 50px;  
  color: white;
  font-size: 13px;
  background-color: #bfbfbf;
  transition: 0.2s;
  padding: 1px 0;
  border-radius: 6px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  &.sms.active {
    background: #ecf8ff;
    color: var(--sms-color);
    border: 1px solid var(--sms-color);
  }
  &.lms.active {
    background: #e7fff4;
    color:var(--lms-color);
    border: 1px solid var(--lms-color);
  }
  &.mms.active {
    background: #f7ebff;
    color: var(--mms-color);
    border: 1px solid var(--mms-color);
  }
  &.talk.active {
    background: #fffae2;
    color: var(--talk-dark-color);
    border: 1px solid var(--talk-color);
  }
`;

const ArrowIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -6px;
  margin-top: 10px;
  svg {
    fill: #898989;
    cursor: pointer;
  }
  &.right {
    left: auto;
    right: -6px;
    svg {
      transform: rotateY(-180deg);
    }
  }
`;

const RefreshIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
