import styled from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {}

export const LayouRow: React.FC<IProps> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
  max-width: 100%;
  @media ${device.laptop.max} {
    padding: 0 10px;
  }
`;
