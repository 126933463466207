import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import { IReducerForm, reducerForm } from "../reducers";

interface IForm {
  /** 고객 유형 */
  type: "개인" | "기업";
  /** 사업자 유형 */
  bizType: "법인" | "개인";
  /** 인증 유형 */
  verifiedType: "전화번호" | "서류";
  /** 회사명 (기업 회원가입시 필수) */
  companyName: string;
  /** 아이디  */
  id: string;
  /** 패스워드 */
  password: string;
  /** 전화번호 */
  phone: string;
  /** (법인사업자: 법인등록번호) or (개인사업자: 생년월일) */
  regidentId: string;
  /** 사업자번호 */
  bizNo: string;
  
  imp_uid: string;

  /** 대표자명 */
  ceoName: string;
  /** 대표번호 */
  ceoPhone: string;
  /** 인증여부 */
  verified: boolean;
  currentFilePhone: string;
  /** 사업자등록증 첨부파일 */
  fileBusinessRegistered: {
    /** 실제 업로드된 파일명 */
    currentFileName: string;
    /** 서버로부터 응답받은 파일명 */
    fileName: string;
  };
  /** 통신가입증명원 첨부파일 */
  filePhoneCert: {
    /** 실제 업로드된 파일명 */
    currentFileName: string;
    /** 서버로부터 응답받은 파일명 */
    fileName: string;
    // currentFilePhone: string;
  };
}

interface IStore extends State {
  /** 회원가입 입력폼 */
  form: IReducerForm<IForm>;
  /** 회원가입 입력폼 초기화 (개인고객/기업고객 변경시) */
  onChangeType: (currentType: "개인" | "기업") => void;
  onReset: () => void;
}

const initForm: IForm = {
  type: "개인",
  bizType: "법인",
  companyName: "",
  verifiedType: "전화번호",
  bizNo: "",
  ceoName: "",
  ceoPhone: "",
  id: "",
  password: "",
  imp_uid:"",
  phone: "",
  regidentId: "",
  currentFilePhone: "",
  verified: false,
  fileBusinessRegistered: {
    currentFileName: "",
    fileName: "",
  },
  filePhoneCert: {
    currentFileName: "",
    fileName: "",

  },
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    form: reducerForm<IStore, IForm>(set, "form", initForm),
    onChangeType: (currentType) => {
      get().form.onChangeForm({
        ...initForm,
        type: currentType,
      });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** [회원가입] 상태관리 */
export const useSignupStore = create<IStore>()(devtools(initialStateCreator));
