import { Form, Input, Radio, RadioChangeEvent, Tooltip,Button } from "antd";
import React, { useCallback,useState,useEffect } from "react";
import styled from "styled-components";
import { useChargePaymentStore } from "../stores";
import { IComponentProps } from "../types";
import { Formatter, Pattern } from "../utils";
import { AntdButton } from "./AntdButton";
import {
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { privateApi } from "../react-query";
import { useQuery } from "@tanstack/react-query";
import { isSuccess } from "../utils";
interface IProps extends IComponentProps {}

// const text =`결제시 유의사항
// * 환불은 PG사 정책에 따라 카드 결제시 12개월, 휴대폰 결제시 결제 당월로 제한하고 있음을 숙지하여 주시기 바랍니다.


// 프로모션 안내
// * 준비중  `;
const text =`[유의사항]
* 구매하신 상품의 사용가능 기간은 1년입니다.
* 카드사 정책에 따라 아래와 같이 구매 금액이 제한됩니다.
  - 국민: 100만원/월
  - 농협: 50만원/월
  - 롯데: 50만원/일
  - 삼성: 50만원/월
  - 신한: 5만원/일(프리미엄 플러스 상품 구매 불가)
  - 현대: 55만원/월
  - 기타: 해당사항없음

[환불]
* 구매시 결제한 수단으로만 환불 가능.
* 환불가능기간
  - 카드: 3개월 이내
  - 가삭계좌: 12개월 이내
`
const text2 = `
- 국민: 100만원/월
- 농협: 50만원/월
- 롯데: 50만원/일
- 삼성: 50만원/월
- 신한: 5만원/일(프리미엄 플러스 상품 구매 불가)
- 현대: 55만원/월
- 기타: 해당사항없음
`

const fetcherGetPlan = () =>
  privateApi.charge.chargeControllerReadPlan();



export const PaymentModalContent: React.FC<IProps> = ({}) => {
  const store = useChargePaymentStore((s) => s);
  const [mainfirst, setMainfirst] = useState(false)
  const [first, setFirst] = useState(true)
  const [second, setSecond] = useState(false)
  const [third, setThird] = useState(false)
  const [fourth, setFourth] = useState(false)
  const [windowsize, setWindowsize] = useState(false)
  const [windowsize2, setWindowsize2] = useState(0)
  const handleResize =() =>{
    console.log(`x=${window.innerWidth},y=${window.innerHeight}`)
    
    setWindowsize2(window.innerWidth)
   
  }
  
  useEffect(()=>{
  window.addEventListener('resize',handleResize);
  if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
  return () =>{
  window.removeEventListener('resize',handleResize)
  }
 
  },[]);
  useEffect(()=>{
    if(window.innerWidth< 600)
  setWindowsize(true)
  else
  setWindowsize(false)
 

  },[windowsize2]);
  const { isLoading: loadingGetProfiles, data: dataGetCharges } = useQuery(
    ["companyControllerReadKakaoProfile"],
    fetcherGetPlan,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("chargeControllerReadPlan onSuccess: ", e);
        }
      },
      onError: (e) => {
        console.log("chargeControllerReadPlan onError: ", e);
      },
    }
  );
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;
      if(name === 'payment') {
        store.formModalPayment.onChangeForm({
          [name]: value,
          expectedPayment: Math.floor(+value * 1.1) + "",
        });
      } else {
        store.formModalPayment.onChangeForm({
          [name]: value,
        });
      }

    },
    []
  );

  const onChangeRadio = (e: RadioChangeEvent) => {
   
    const {
      target: { value },
    } = e;
    store.formModalPayment.onChangeForm({
      method: value,
    });
  };
  const firstButtonClick = () => {
    setMainfirst(true)
    setFirst(true)
    setSecond(false)
    setThird(false)
    setFourth(false)
    onClickPayButton("10000")
  };
  const secondButtonClick = () => {
    setMainfirst(true)
    setFirst(false)
    setSecond(true)
    setThird(false)
    setFourth(false)
    onClickPayButton("100000")

  };
  const thirdButtonClick = () => {
    setMainfirst(true)
    setFirst(false)
    setSecond(false)
    setThird(true)
    setFourth(false)
    onClickPayButton("500000")
  };
  const fourthButtonClick = () => {
    setMainfirst(true)
    setFirst(false)
    setSecond(false)
    setThird(false)
    setFourth(true)
    onClickPayButton("1000000")
  };

  /** 표기된 [금액] 선택시 */
  const onClickPayButton = (num: "10000" | "100000" | "500000" | "1000000") => {
    const { payment } = store.formModalPayment.form;
    // const currentPayment = Pattern.checkOptional(Pattern.숫자).test(payment)
    //   ? payment || 0
    //   : 0;
    store.formModalPayment.onChangeForm({
      payment: num + "",
      expectedPayment: Math.floor(+num * 1.1 )+ "",
    });
  };

  return (
    <Container>
      <Form layout="vertical">
        {/* <Form.Item required label="상품">
          <Input
            autoFocus
            placeholder="충전금액을 입력해주세요"
            name="payment"
            value={store.formModalPayment.form.payment}
            suffix = { <AiOutlineCloseCircle
              size={16}
              style={{
                cursor: "pointer",
              }}
              onClick={()=>{
                store.formModalPayment.onChangeForm({
                  payment : "5000",
                  expectedPayment: "5500",
                })
              
              }}
            />}
            onChange={onChange}
          >
            
          </Input>
        </Form.Item> */}
        <Form.Item required label="상품" style={{ marginTop: -6 }}>
          {/* <ButtonGroup>
            <AntdButton onClick={() => onClickPayButton("5000")}>
              5,000원
            </AntdButton>
            <AntdButton onClick={() => onClickPayButton("10000")}>
              10,000원
            </AntdButton>
            <AntdButton onClick={() => onClickPayButton("50000")}>
              50,000원
            </AntdButton>
            <AntdButton onClick={() => onClickPayButton("100000")}>
              100,000원
            </AntdButton>
          </ButtonGroup> */}
          <ButtonGroup style={windowsize?{gap : "0px"} : {gap : "15px"}}>
            <Items className={`${first ? "clicked" : "noclicked"}`}>
              <PlanName>
                <div className="userNameCont">
                  <img src="/images/sendit_pymn01.png" alt="" className="userIcon"/>
                </div>
                <p style={windowsize? {padding : "2px 2px 0" , fontWeight : 600 , fontSize: 12} : {padding : "4px 2px 0" ,fontWeight : 600 , fontSize: 14}}>
                  {dataGetCharges?.data.body.data[0].chargePlanName}
                </p>
              </PlanName>
              <div className="basicPlanGroup">
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>SMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[0].chargeSms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[0].chargeSms}<span>원</span></>
                      )}
                    </p>  
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>LMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[0].chargeLms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[0].chargeLms}<span>원</span></> 
                      )}
                    </p>
                    </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>MMS</PlainTag>
                    <p>
                      {windowsize ? ( 
                        <>{dataGetCharges?.data.body.data[0].chargeMms}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[0].chargeMms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>알림톡</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[0].chargeAlimtalk}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[0].chargeAlimtalk}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
              </div>
              <div className="priceCont">
                <p>10,000<span>원</span></p>
              </div>
              <div className="btnSelect">
                <Button 
                  style={windowsize?{width:"80%", fontSize : 10, margin :"auto",fontWeight : 600 , marginBottom : 10} : {width:"80%", fontSize : 10, margin :"auto",   fontWeight : 600 , marginBottom : 10}}
                  onClick={firstButtonClick}
                >
                  {first ? "선택됨" : "선택"}
                </Button>
              </div>
            </Items>

            <Items className={`${second ? "clicked" : "noclicked"}`}>
              <PlanName>
                <div className="userNameCont">
                  <img src="/images/sendit_pymn02.png" alt="" className="userIcon"/>
                </div>
                <p style={windowsize? {padding : "2px 2px 0" , fontWeight : 600 , fontSize: 12} : {padding : "4px 2px 0" ,fontWeight : 600 , fontSize: 14}}>
                  {dataGetCharges?.data.body.data[1].chargePlanName}
                </p>
              </PlanName>
              <div className="standardPlanGroup">
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>SMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[1].chargeSms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[1].chargeSms}<span>원</span></>
                      )}
                    </p>   
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>LMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[1].chargeLms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[1].chargeLms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>MMS</PlainTag>
                    <p>
                      {windowsize ? ( 
                        <>{dataGetCharges?.data.body.data[1].chargeMms}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[1].chargeMms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>알림톡</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[1].chargeAlimtalk}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[1].chargeAlimtalk}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>        
              </div>

              <div className="priceCont">
                <p>100,000<span>원</span></p>
                {/* <p style={{margin : 5,fontWeight : 500 ,fontSize : 5 , color : "red"}}>* 15일 후 베이직 상품으로 자동변경</p> */}
              </div>

              <div className="btnSelect">
                <Button 
                  style={windowsize?{width:"80%", fontSize : 10 , margin :"auto",fontWeight : 600 , marginBottom : 10} : {width:"80%", fontSize : 10, margin :"auto",fontWeight : 600 , marginBottom : 10}}
                  onClick={secondButtonClick}
                >
                  {/* <Tooltip 
                                  overlayStyle={{maxWidth: '200px'}}
                                  
                                  title={
                                  <div>
                                    <p>5일 후 basic 요금제로 변경</p>
                                  </div>
                                  }
                                >
                  </Tooltip> */}
                  {second ? "선택됨" : "선택"}
                </Button>
              </div>
            </Items>

            <Items className={`${third ? "clicked" : "noclicked"}`}>
              <PlanName>
                <div className="userNameCont">
                  <img src="/images/sendit_pymn03.png" alt="" className="userIcon"/>
                </div>
                <p style={windowsize? {padding : "2px 2px 0" , fontWeight : 600 , fontSize: 12} : {padding : "4px 2px 0" ,fontWeight : 600 , fontSize: 14}}>
                  {dataGetCharges?.data.body.data[2].chargePlanName}
                </p>
              </PlanName>
              <div className="premiumPlanGroup">
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>SMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[2].chargeSms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[2].chargeSms}<span>원</span></>
                      )}
                    </p> 
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>LMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[2].chargeLms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[2].chargeLms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>MMS</PlainTag>
                    <p>
                      {windowsize ? ( 
                        <>{dataGetCharges?.data.body.data[2].chargeMms}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[2].chargeMms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>알림톡</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[2].chargeAlimtalk}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[2].chargeAlimtalk}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
              </div>

              <div className="priceCont">
                <p>500,000<span>원</span></p>
                {/* <p style={{margin : 5,fontWeight : 500 ,fontSize : 5 , color : "red"}}>* 30일 후 베이직 상품으로 자동변경</p> */}
              </div>

              <div className="btnSelect">
                <Button
                  style={windowsize?{width:"80%", fontSize : 10, margin :"auto",fontWeight : 600 , marginBottom : 10} : {width:"80%", fontSize : 10, margin :"auto",fontWeight : 600 , marginBottom : 10}}
                  onClick={thirdButtonClick}
                > 
                  {/* <Tooltip 
                    overlayStyle={{maxWidth: '200px'}}
                    title={
                    <div>
                      <p>30일 후 basic 요금제로 변경</p>
                    </div>
                  }
                  >
                  </Tooltip> */}
                    {third ? "선택됨" : "선택"}
                </Button>      
              </div>          
              
            </Items>

            <Items className={`${fourth ? "clicked" : "noclicked"}`}>
              <PlanName>
                <div className="userNameCont">
                  <img src="/images/sendit_pymn04.png" alt="" className="userIcon"/>
                </div>
                <p style={windowsize? {padding : "2px 2px 0" , fontWeight : 600 , fontSize: 12} : {padding : "4px 2px 0" ,fontWeight : 600 , fontSize: 14}}>
                {dataGetCharges?.data.body.data[3].chargePlanName}</p>
              </PlanName>

              <div className="premium02PlanGroup">
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>SMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[3].chargeSms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[3].chargeSms}<span>원</span></>
                      )}
                    </p> 
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>LMS</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[3].chargeLms}<span>원</span></> 
                      ) : ( 
                        <>{dataGetCharges?.data.body.data[3].chargeLms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>MMS</PlainTag>
                    <p>
                      {windowsize ? ( 
                        <>{dataGetCharges?.data.body.data[3].chargeMms}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[3].chargeMms}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
                <PlanGroup>
                  <PlanItem>
                    <PlainTag>알림톡</PlainTag>
                    <p>
                      {windowsize ? (
                        <>{dataGetCharges?.data.body.data[3].chargeAlimtalk}<span>원</span></> 
                      ) : (
                        <>{dataGetCharges?.data.body.data[3].chargeAlimtalk}<span>원</span></> 
                      )}
                    </p>
                  </PlanItem>
                </PlanGroup>
              </div>

              <div className="priceCont">
                <p>1,000,000<span>원</span></p>
                {/* <p style={{margin : 5,fontWeight : 500 ,fontSize : 5 , color : "red"}}>* 30일 후 베이직 상품으로 자동변경</p> */}
              </div>    

              <div className="txtSelect">
                <p>* 별도 문의 <span>/</span> API연동지원</p>
                {/* <p style={{margin : 5,fontWeight : 500 ,fontSize : 5 , color : "red",marginBottom : 0}}>* 30일 후 베이직 상품으로 자동변경</p> */}
              </div>
                {/* <Button style={windowsize?{width:"70%", fontSize : 10, margin :"auto",fontWeight : 600 , marginBottom : 10} : {width:"70%", fontSize : 10, margin :"auto",fontWeight : 600 , marginBottom : 10}}
                onClick={
                  fourthButtonClick
                }
                > 
              {fourth ? "선택됨" : "선택"}
              </Button> */}
            </Items>

            </ButtonGroup>
        </Form.Item>
        <Form.Item required label="결제방식" style={{ marginTop: 18 }}>
          <Radio.Group
            buttonStyle="solid"
            value={store.formModalPayment.form.method}
            onChange={onChangeRadio}
          >
            <Radio.Button value="카드">카드</Radio.Button>
            <Radio.Button value="가상계좌">가상계좌</Radio.Button>
            {/* <Radio.Button value="휴대폰">휴대폰</Radio.Button> */}
          </Radio.Group>
        </Form.Item>
        <Form.Item required label="결제예정금액(부가세 포함)" style={{ marginTop: 18 }}>
          <Input
            placeholder="결제예정금액을 입력해주세요"
            name="expectedPayment"
            readOnly
            value={
              Formatter.통화(store.formModalPayment.form.expectedPayment) + "원"
            }
          />
        </Form.Item>
      </Form>

      <Detail>
        {/* <p style={{fontSize : 8}}>{text}</p> */}
        <div className="detailCont">
          <h4>[유의사항]</h4>
          <p style={{color: "red" , fontWeight : 600}}>* 구매하신 상품의 사용가능 기간은 1년입니다.</p>
          <div className="detailList">
            <p>* 카드사 정책에 따라 아래와 같이 구매 금액이 제한됩니다.</p>
            국민: <b>100만원</b> /월 <span className="dot"></span> 농협: <b>50만원</b> /월 <span className="dot"></span> 롯데: <b>50만원</b> /일 <span className="dot"></span> 삼성: <b>50만원</b> /월 <span className="dot"></span> 신한: <b>5만원</b> /일(프리미엄 상품 구매 불가) <span className="dot"></span> 현대: <b>55만원</b> /월 <span className="dot"></span> 기타: 해당사항없음
          </div>

          <h4>[환불]</h4>
          <p style={{color: "red",fontWeight : 600}}>* 구매시 결제한 수단으로만 환불 가능.</p>
          <div className="detailList">
            <p>* 환불가능기간</p>
            <span> - 카드: <b>결제일로 부터 3개월 이내</b></span>
            <br/>
            <span> - 가상계좌: <b>결제일로 부터 12개월 이내</b></span>
          </div>
        </div>
        </Detail>
    </Container>
  );
};

const Container = styled.div`
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: var(--primary-color);
  }
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: var(--primary-color);
  }
  .ant-radio-button-wrapper {
    &.ant-radio-button-wrapper-checked {
      background: var(--primary-color);
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
`;

const WarningText = styled.p`
  display: flex;
  font-size: 12px;
  gap: 6px;
  color: #686868;
`;

const Detail = styled.div`
  white-space: pre-wrap;
  font-size: 12px;
  max-height: 253px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px;
  background-color: #f7f7f7;
  // margin-top: 20px;
  .detailCont {
    h4 {
      font-weight: 600;
      font-size: 11px;
      margin-bottom:4px;
    }
    p {
      font-size: 11px
    }
    .detailList {
      font-size: 12px;
      padding: 2px 5px;
      background: #fff;
      margin: 4px 0 8px;
      p {
        color: #f18631;
        margin-bottom: 6px;
        border-bottom: 1px dotted #e1e1e1;
        padding-bottom: 3px;
      }
      .dot {
        width: 4px;
        height: 4px;
        background: #ff7676;
        display: inline-block;
        margin: 3px 9px;
        border-radius: 12px;
      }
    }
  }
  
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  button {
    width: 100%;
  }
`;
const Items = styled.div`
  // border : solid 1px black;
  border-radius : 14px;
  height : 270px;
  flex: 1;
  width: 100%;
  gap: 4px;
  display : flex;
  flex-direction : column;
  justify-content: space-between;
  border: 1.5px solid #636363;
  .priceCont {
    display: flex;
    align-items:center;
    justify-content:center;
    p {
      font-size:21px;
      font-weight:800;
      color: #292929;
      span {
        font-size:13px;
        font-weight:500;
        color:#f05e29;
        padding-left:2px;
      }
    }
  }

  .btnSelect {
    display: flex;
    align-items: center;
  }
  button {
    border-radius: 16px !important;
  }
  &.clicked {
    Button {
      background-color : var(--sendit-color);
      color : white;
    }
  }
  &.noclicked {
    opacity: 0.6;
  }
  .basicPlanGroup {
    background: #fffdf4;
    padding: 5px 0;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
  }
  .standardPlanGroup {
    background: #fff8f4;
    padding: 5px 0;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
  }
  .premiumPlanGroup {
    background: #fff4f4;
    padding: 5px 0;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
  }
  .premium02PlanGroup {
    background: #fcf4ff;
    padding: 5px 0;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
  }

  
  .txtSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    p {
      font-size: 12px;
      color: #e14444;
      font-weight: 500;
      span {
        color: #666;
        font-weight: 400;
        padding: 0 2px;
        font-size: 10px;
      }
    }
  }

`;
const PlanName = styled.div`
display :flex;
justify-content:center;
border-radius : 6px 6px 0px 0px;
flex-direction: column;
align-items: center;
  .userNameCont {
    padding-top:6px;
    img {
      height: 30px;
    }
  }
  p{
    line-height:1.2;
  }

`;
const PlanGroup = styled.div`
  width: 100%;
  // background-color: #f5f5f5;
  border-radius: 6px;
  padding: 2px 0px;
`;

const PlanItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  p {
    font-size: 15px;
    font-weight:800;
    color:#292929;
    span {
      font-size:10px;
      padding-left:1px;
      font-weight:400;
      color: #333;
    }
  }
`;

const PlainTag = styled.div`
  color: black;
  // background-color: var(--primary-color);
  font-size: 11px;
  font-weight:600;
  white-space: nowrap;
`;

