import { Input } from "antd";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";
import { device } from "../device";
import { privateApi } from "../react-query";
import { useAnalysisSendStore } from "../stores";
import { IComponentProps } from "../types";
import { isSuccess } from "../utils";
import { AntdTable } from "./AntdTable";
import { AntdButton } from "./AntdButton";
import {useState} from "react";

interface IProps extends IComponentProps {}

const fetcherGetSentMessage = (id: string) =>
  privateApi.message.messageControllerReadOne(id);

export const ResultSentMessageModalContent: React.FC<IProps> = ({}) => {
  const store = useAnalysisSendStore((s) => s);
  const [type, settype] = useState(0);

  const { isLoading: loadingGetSentMessage, data: dataGetSentMessage } =
    useQuery(
      [`messageControllerReadOne/${store.selectedSentMessage?.requestId}`],
      () => fetcherGetSentMessage(store.selectedSentMessage?.requestId || ""),
      {
        enabled: Boolean(store.selectedSentMessage?.requestId),
        onSuccess: (e) => {
          if (isSuccess(e.data.header)) {
            console.log("messageControllerReadOne onSuccess: ", e);
          }
        },
        onError: (e) => {
          console.log("messageControllerReadOne onError: ", e);
        },
      }
    );
  return (
      <div style={{display:"flex",flexDirection:"column"}}>
        <div style={{marginBottom : 5}}>
        <AntdButton isPrimary style={{color : "white"}}
        onClick={()=>{
          settype(0)
        }}>전체({dataGetSentMessage?.data.body.data.length})</AntdButton>
        <AntdButton isPrimary style={{marginLeft : 5, backgroundColor : "green" ,borderColor : "green",color : "white"}}
         onClick={()=>{
          settype(1)
        }}
        disabled={dataGetSentMessage?.data.body.data.filter(v=>v.resultMessage === "성공").length === 0}
        >성공({dataGetSentMessage?.data.body.data.filter(v=>v.resultMessage === "성공").length})</AntdButton>
        <AntdButton  isPrimary style={{marginLeft : 5, backgroundColor : "red" ,borderColor : "red",color : "white"}}
         onClick={()=>{
          settype(2)
        }}
        disabled={dataGetSentMessage?.data.body.data.filter(v=>v.resultMessage !== "성공" && "0" ).length=== 0}
        >실패({dataGetSentMessage?.data.body.data.filter(v=>v.resultMessage !== "성공" && "0" ).length})</AntdButton>
        <AntdButton  isPrimary style={{marginLeft : 5, backgroundColor : "orange" ,borderColor : "orange",color : "white"}}
         onClick={()=>{
          settype(3)
        }}
       disabled={dataGetSentMessage?.data.body.data.filter(v=>v.resultMessage === "0").length ===0} 
        >대기({dataGetSentMessage?.data.body.data.filter(v=>v.resultMessage === "0").length})</AntdButton>

        </div>
    <Container>
      <ExtendedAntdTable
        className="result-table"
        loading={loadingGetSentMessage}
        pagination={false}
        rowKey="requestId"
        dataSource={
          type === 0 ? 
          dataGetSentMessage?.data
            ? dataGetSentMessage.data.body.data.map(v => ({
              resultMessage: v.resultMessage,
              recipientNo: v.recipientNo,
              requestId: Math.random(),
            }))
            : [] :
          type === 1?
          dataGetSentMessage?.data
            ? dataGetSentMessage.data.body.data.filter(v=>v.resultMessage === "성공").map(v => ({
              resultMessage: v.resultMessage,
              recipientNo: v.recipientNo,
              requestId: Math.random(),
            }))
            :[] :
          type === 2?
          dataGetSentMessage?.data
            ? dataGetSentMessage.data.body.data.filter(v=>v.resultMessage !== "성공" && "0").map(v => ({
              resultMessage: v.resultMessage,
              recipientNo: v.recipientNo,
              requestId: Math.random(),
            }))
 : [] :
 type === 3?
 dataGetSentMessage?.data
            ? dataGetSentMessage.data.body.data.filter(v=>v.resultMessage === "0").map(v => ({
              resultMessage: v.resultMessage,
              recipientNo: v.recipientNo,
              requestId: Math.random(),
            }))
:[] :[]
        }
        columns={[
          {
            title: "수신자 번호",
            dataIndex: "recipientNo",
            key: "recipientNo",
          },
          {
            title: "발송 결과",
            dataIndex: "resultMessage",
            key: "resultMessage",
            render: (v) => {
              if(v === "0"){
                return <p>대기</p>
              }
              else if(v==="성공")
                return <p>성공</p>
              else 
              return v

              
            }
          },
        ]}
      />
      <Description>
        <Input.TextArea
          disabled
          value={
            dataGetSentMessage?.data
            ? dataGetSentMessage?.data.body.data[0].body
            : ""
          }
        />
      </Description>
    </Container>
          </div>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  @media ${device.tablet.max} {
    flex-flow: column;
  }
`;

const ExtendedAntdTable = styled(AntdTable)`
  /* max-width: 250px; */
  width: 100%;
  flex: 1;
  &.result-table {
    .ant-table-container {
      .ant-table-body {
        height: 200px;
        max-height: initial !important;
      }
    }
  }
  @media ${device.tablet.max} {
    width: 100%;
    max-width: 100%;
  }
`;
const Description = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  white-space: pre-line;
  max-width: 200px;
  @media ${device.tablet.max} {
    max-width: 100%;
    min-height: 120px;
  }
`;
