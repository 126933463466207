import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../device";
import { IComponentProps } from "../types";

interface IProps extends IComponentProps {
  tab: "1" | "2" | "3";
}

const { TabPane } = Tabs;

const enum Tab {
  "메시지발송" = "1",
  "메시지설정" = "2",
  "발송통계" = "3",
}

export const LayoutTab: React.FC<IProps> = ({ tab, ...props }) => {
  const navigate = useNavigate();

  return (
    <Container {...props}>
      <Tabs
        onChange={(e) => {
          if (e === Tab.메시지발송) {
            navigate("/sendMessage");
          } else if (e === Tab.메시지설정) {
            navigate("/setMessage");
          } else {
            navigate("/statistics");
          }
        }}
        defaultActiveKey="1"
        activeKey={tab}
        type="card"
      >
        <TabPane tab="메시지 발송" key="1"></TabPane>
        <TabPane tab="메시지 설정" key="2"></TabPane>
        <TabPane tab="발송 통계" key="3"></TabPane>
      </Tabs>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 20px;
  margin-bottom: 20px;
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-wrap {
      padding: 20px;
      margin-top: -20px;
      margin-left: -20px;
      margin-bottom: -20px;
      .ant-tabs-nav-list {
        box-shadow:  rgb(0 0 0 / 10%) 0px 0px 4px;
        border-radius: 6px;
      }
    }
  }
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      min-width: 120px;
      & > div {
        margin: 0 auto;
      }
      text-align: center;
      border-bottom: 0;
      border-top: 0;
      /* border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; */
      margin-left: 0 !important;
      padding: 10px 25px !important;
      font-size: 13px;
      background-color: white;
      &.ant-tabs-tab-active {
        background-color: #023770;
        & > div {
          color: white;
        }
      }
    }
  }
  .ant-tabs-nav-list {
    border-radius: 6px;
    overflow: hidden;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  @media ${device.laptop.max} {
    position: sticky;
    z-index: 3;
    bottom: 0;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 0;
    margin-bottom: -10px;
    background-color: white;
    /* width: 100vw; */
    margin-left: -10px;
    margin-right: -10px;
    /* border-top: 1px solid #023770; */
    box-shadow:  rgb(0 0 0 / 10%) 0px 0px 4px;
    .ant-tabs-tab-btn {
      font-size: 12px;
      margin: 0 auto;
    }
    .ant-tabs-nav-list {
      border-radius: 0 !important;
    }
    .ant-tabs-nav-wrap .ant-tabs-tab {
      border-top: none;
      padding: 12px !important;
      width: 33.3333vw;
      text-align: center;
      border-radius: 0 !important;
      min-width: auto !important;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-wrap {
        padding: 0;
        margin: 0;
        .ant-tabs-nav-list {
          /* box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px; */
        }
      }
    }
  }
`;
