import { MyPageAccountmaster,MyPageAccountuser,MyPageAccountusermaster } from "./index";
import { useMyPageAccountStore } from "../stores";
import { privateApi } from "../react-query";
import { useQuery } from "@tanstack/react-query";
import { isSuccess } from "../utils";

const fetcherGetMember = () =>
  privateApi.member.memberControllerReadCurrentMember();

export const MyPageAccount = () => {
  const store = useMyPageAccountStore((s) => s);

  const { isLoading: loadingGetMember, data: dataGetMember } = useQuery(
    ["memberControllerReadCurrentMember"],
    fetcherGetMember,
    {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerReadCurrentMember onSuccess: ", e);
          store.formFilter.onChangeForm({
            userrole : e.data.body.data.userRole
          })
         
          console.log(store.formFilter.form.userrole);
          
        }
      },
      onError: (e) => {
        console.log("memberControllerReadCurrentMember onError: ", e);
      },
    }
  );

if(store.formFilter.form.userrole === "900")
  return (
   <MyPageAccountmaster/>
  )
else if(store.formFilter.form.userrole === "100"){
  return(
    <MyPageAccountusermaster/>
  )
}
else if (store.formFilter.form.userrole === "901" || store.formFilter.form.userrole === "101")
return (
  <MyPageAccountuser/>
 )
}
