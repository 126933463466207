import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import {
  IReducerForm,
  IReducerModal,
  reducerForm,
  reducerModal,
} from "../reducers";

interface IFormFilter {
  /** 페이징 */
  page: number;
  /** 이름, 아이디 검색 입력 */
  useranme: string;
  /** UserRole */
  userrole: string;
}

interface IFormAddChildAccount {
  /** 이메일 */
  email: string;
  /** 이름 */
  username: string;
}

interface IFormUpdatePassword {
  /** 패스워드 */
  password: string;
  /** 패스워드 확인 */
  passwordConfirm: string;
}

interface IStore extends State {
  /** 검색 입력폼 */
  formFilter: IReducerForm<IFormFilter>;
  /** 하위계정생성 모달 입력폼 */
  formAddChildAccount: IReducerForm<IFormAddChildAccount>;
  /** 패스워드 변경 모달 입력폼 */
  formUpdatePassword: IReducerForm<IFormUpdatePassword>;
  /** 모달 (하위계정생성) */
  modalAddChildAccount: IReducerModal;
  /** 모달 (패스워드 변경) */
  modalUpdatePassword: IReducerModal;
  onReset: () => void;
}

const initFormFilter: IFormFilter = {
  page: 1,
  useranme: "",
  userrole: "",
};

const initFormAddChildAccount: IFormAddChildAccount = {
  email: "",
  username: "",
};
const initFormUpdatePassword: IFormUpdatePassword = {
  password: "",
  passwordConfirm: "",
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    formFilter: reducerForm<IStore, IFormFilter>(
      set,
      "formFilter",
      initFormFilter
    ),
    formAddChildAccount: reducerForm<IStore, IFormAddChildAccount>(
      set,
      "formAddChildAccount",
      initFormAddChildAccount
    ),
    formUpdatePassword: reducerForm<IStore, IFormUpdatePassword>(
      set,
      "formUpdatePassword",
      initFormUpdatePassword
    ),
    modalAddChildAccount: reducerModal<IStore>(
      set,
      "modalAddChildAccount",
      false,
      () => {
        setTimeout(() => {
          get().formAddChildAccount.onChangeForm(initFormAddChildAccount);
        }, 300);
      }
    ),
    modalUpdatePassword: reducerModal<IStore>(
      set,
      "modalUpdatePassword",
      false,
      () => {
        setTimeout(() => {
          get().formUpdatePassword.onChangeForm(initFormUpdatePassword);
        }, 300);
      }
    ),
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** 내정보 > [계정관리] 페이지 상태관리 */
export const useMyPageAccountStore = create<IStore>()(
  devtools(initialStateCreator)
);
