import create, { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";
import { IReducerForm, reducerForm } from "../reducers";

interface IForm {
  /** 고객 유형 */
  imp_uid: string;
  /** 통신가입증명원 첨부파일 */
  filePhoneCert: {
    /** 실제 업로드된 파일명 */
    currentFileName: string;
    /** 서버로부터 응답받은 파일명 */
    fileName: string;
    // currentFilePhone: string;
  };
  carrierCertFilePhone : string;
  employmentCertFileName : string;
  currentEmployFileName : string;
}

interface IStore extends State {
  /** 회원가입 입력폼 */
  form: IReducerForm<IForm>;
  /** 회원가입 입력폼 초기화 (개인고객/기업고객 변경시) */
  // onChangeType: (currentType: "개인" | "기업") => void;
  onReset: () => void;
}

const initForm: IForm = {
  carrierCertFilePhone : "",
  employmentCertFileName: "",
  currentEmployFileName: "",
  imp_uid:"",
  filePhoneCert: {
    currentFileName: "",
    fileName: "",

  },
};

const initialStateCreator: StateCreator<IStore, any> = (set, get, api) => {
  return {
    form: reducerForm<IStore, IForm>(set, "form", initForm),
    onChangeType: () => {
      get().form.onChangeForm({
        ...initForm,
        
      });
    },
    onReset: () => set(() => initialStateCreator(set, get, api, []), true),
  };
};

/** [회원가입] 상태관리 */
export const useAddSignupStore = create<IStore>()(devtools(initialStateCreator));
