// @ts-ignore
import Fade from "react-reveal/Fade";
import { Form, Input, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AntdButton, LayouRow } from "../components";
import React, { useState, useEffect } from "react";
import { privateApi, publicApi } from "../react-query";
import { useMutation } from "@tanstack/react-query";
import { isSuccess } from "../utils";
import { MemberControllerCheckCodeParams, MemberUpdateByCodeDTO } from "../generated";

interface IForm {
  userId?: string;
  userIdx?: number;
  password: string;
  passwordConfirm: string;
}

const fetcherGetCheckCode = (params: MemberControllerCheckCodeParams) =>
  publicApi.member.memberControllerCheckCode({
    code: params.code,
  });

const fetcherUpdatePassword = (params: MemberUpdateByCodeDTO) =>
  publicApi.member.memberControllerUpdatePasswordByCode({
    code: params.code,
    userId: params.userId,
    userIdx: params.userIdx,
    password: params.password,
  });

export const SignupInvitation = () => {
  const params = useParams() as { code: string };
  const [form, setForm] = useState<IForm>({
    userId: undefined,
    userIdx: undefined,
    password: "",
    passwordConfirm: "",
  });

  const navigate = useNavigate();

  const { isLoading: loadingGetCheckCode, mutate: mutateGetCheckCode } =
    useMutation(fetcherGetCheckCode, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          setForm((prev) => ({
            ...prev,
            userId: e.data.body.data.userId,
            userIdx: e.data.body.data.userIdx,
          }));
          console.log("memberControllerCheckCode onSuccess: ", e);
        } else {
          navigate("/");
        }
      },
      onError: (e) => {
        console.log("memberControllerCheckCode onError: ", e);
      },
    });

  const { isLoading: loadingUpdatePassword, mutate: mutateUpdatePassword } =
    useMutation(fetcherUpdatePassword, {
      onSuccess: (e) => {
        if (isSuccess(e.data.header)) {
          console.log("memberControllerUpdatePasswordByCode onSuccess: ", e);
          message.info('비밀번호가 재설정되었습니다');
          navigate("/");
        }
      },
      onError: (e) => {
        console.log("memberControllerUpdatePasswordByCode onError: ", e);
      },
    });

  const onChangeForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * [확인] 버튼 클릭시
   */
  const onSubmit = () => {
    if (loadingUpdatePassword || !form.userId || !form.userIdx) {
      return;
    }
    const {password, passwordConfirm} = form;
    if(password.length > 1) {
      if(passwordConfirm.length > 1) {
        if(password === passwordConfirm) {
          return mutateUpdatePassword({
            code: params.code,
            userId: form.userId,
            userIdx: form.userIdx,
            password: form.password
          })
        }
        return message.warn("비밀번호와 비밀번호 확인이 일치하지 않습니다");
      }
      return message.warn("비밀번호 확인을 입력해주세요");
    }
    return message.warn("비밀번호를 입력해주세요");
  }


  useEffect(() => {
    if(params.code) {
      mutateGetCheckCode({
        code: params.code,
      });
    }
  }, [params.code]);

  if (!params.code) {
    return <>파라미터 값이 존재하지 않습니다.</>;
  }

  if(loadingGetCheckCode) {
    return <></>
  }


  return (
    <Container>
      <Fade>
        <LayouRow>
          <Wrapper>
            <Logo>
              {/* <AiOutlineSend size={16} /> */}
              비밀번호 재설정
            </Logo>
            <Text>
              초대링크로 가입시, 비밀번호 변경이 필요합니다. <br />
              비밀번호 변경후, 다시 로그인해주세요.
            </Text>
            <Cell>
              <Form>
                <Form.Item>
                  <Input
                    placeholder="패스워드"
                    type="password"
                    name="password"
                    onChange={onChangeForm}
                    onPressEnter={onSubmit}
                  />
                </Form.Item>
                <Form.Item style={{ marginTop: -6 }}>
                  <Input
                    placeholder="패스워드 확인"
                    type="password"
                    name="passwordConfirm"
                    onChange={onChangeForm}
                    onPressEnter={onSubmit}
                  />
                </Form.Item>
                <AntdButton
                  isPrimary
                  loading={loadingUpdatePassword}
                  htmlType="submit"
                  onClick={onSubmit}
                  style={{
                    color: "white",
                    display: "block",
                    marginLeft: "auto",
                  }}
                >
                  확인
                </AntdButton>
              </Form>
            </Cell>
          </Wrapper>
        </LayouRow>
      </Fade>
    </Container>
  );
};

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  height: 100vh;
  min-height: 360px;
  flex-flow: column;
  form {
    max-width: 95%;
    width: 100%;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 25px;
  /* border-bottom: 1px solid var(--primary-color); */
  /* padding-bottom: 6px;
  border-bottom-right-radius: 38%;
  border-top-left-radius: 20%; */
  width: 184px;
  text-align: center;
  justify-content: center;
  flex-flow: row-reverse;
  svg {
    fill: var(--primary-color);
  }
`;

const Text = styled.p`
  color: var(--primary-color);
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  word-break: keep-all;
`;

const Cell = styled.div`
  margin-top: 20px;
  border-radius: 6px;
  width: 100%;
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 4px;
`;
