import { Form, Input, Radio, RadioChangeEvent, Select, Tabs } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useChargePaymentStore } from "../stores";
import { IComponentProps } from "../types";
import { Formatter, Pattern } from "../utils";
import { AntdButton } from "./AntdButton";

interface IProps extends IComponentProps {}

const text = `결제시 유의사항
* 최소 충전 금액은 5천원 입니다.
* 환불은 PG사 정책에 따라 카드 결제시 12개월, 휴대폰 결제시 결제 당월로 제한하고 있음을 숙지하여 주시기 바랍니다.


프로모션 안내
* 준비중  `;

export const AutoPaymentModalContent: React.FC<IProps> = ({}) => {
  const store = useChargePaymentStore((s) => s);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {
        target: { name, value },
      } = e;

      if (name === "payment") {
        store.formModalAutoPayment.onChangeForm({
          [name]: value,
          expectedPayment: Math.floor(+value * 1.1) + "",
        });
      } else {
        store.formModalAutoPayment.onChangeForm({
          [name]: value,
        });
      }
    },
    []
  );

  const onChangeSelect = useCallback((day: string) => {
    store.formModalAutoPayment.onChangeForm({
      day: day as "1",
    });
  }, []);

  /** 표기된 [금액] 선택시 */
  const onClickPayButton = (num: "5000" | "10000" | "50000" | "100000") => {
    const { payment } = store.formModalAutoPayment.form;
    const currentPayment = Pattern.checkOptional(Pattern.숫자).test(payment)
      ? payment || 0
      : 0;
    store.formModalAutoPayment.onChangeForm({
      payment: +currentPayment + +num + "",
      expectedPayment: Math.floor((+currentPayment + +num) * 1.1) + "",
    });
  };

  /** [자동충전방식] 변경시 */
  const onChangeRadio = (e: RadioChangeEvent) => {
    const {
      target: { value },
    } = e;
    store.formModalAutoPayment.onChangeForm({
      type: value,
      day: value === "date" ? "5" : "",
      payment: "5000",
      expectedPayment: "5500",
    });
  };

  return (
    <Container>
      <Form layout="vertical">
        <Form.Item required label="자동충전방식">
          <Radio.Group
            value={store.formModalAutoPayment.form.type}
            onChange={onChangeRadio}
          >
            <Radio value="date">기준일 충전</Radio>
            <Radio value="amount">기준금액 충전</Radio>
          </Radio.Group>
        </Form.Item>
        {store.formModalAutoPayment.form.type === "date" ? (
          <>
            <Form.Item required label="자동충전일">
              <Space>
                <Select
                  placeholder="매월 5일"
                  value={store.formModalAutoPayment.form.day}
                  onChange={onChangeSelect}
                >
                  <Select.Option key="1">매월 1일</Select.Option>
                  <Select.Option key="5">매월 5일</Select.Option>
                  <Select.Option key="10">매월 10일</Select.Option>
                  <Select.Option key="15">매월 15일</Select.Option>
                  <Select.Option key="20">매월 20일</Select.Option>
                  <Select.Option key="25">매월 25일</Select.Option>
                </Select>
                <Text>마다</Text>
              </Space>
              <Space style={{ marginTop: 3 }}>
                <Input
                  width="100%"
                  placeholder="충전금액을 입력해주세요"
                  name="payment"
                  value={store.formModalAutoPayment.form.payment}
                  onChange={onChange}
                />
                <Text>원 충전합니다.</Text>
              </Space>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item required label="자동충전금액">
              <Space>
                <Input
                  width="100%"
                  placeholder="기준금액을 입력해주세요"
                  name="day"
                  value={store.formModalAutoPayment.form.day}
                  onChange={onChange}
                />
                <Text>원 미만이면</Text>
              </Space>
              <Space style={{ marginTop: 3 }}>
                <Input
                  autoFocus
                  placeholder="충전금액을 입력해주세요"
                  name="payment"
                  value={store.formModalAutoPayment.form.payment}
                  onChange={onChange}
                />
                <Text>원 충전합니다.</Text>
              </Space>
            </Form.Item>
            {/* <Form.Item label="" style={{ marginTop: -6 }}>
              <ButtonGroup>
                <AntdButton onClick={() => onClickPayButton("5000")}>
                  5,000원
                </AntdButton>
                <AntdButton onClick={() => onClickPayButton("10000")}>
                  10,000원
                </AntdButton>
                <AntdButton onClick={() => onClickPayButton("50000")}>
                  50,000원
                </AntdButton>
                <AntdButton onClick={() => onClickPayButton("100000")}>
                  100,000원
                </AntdButton>
              </ButtonGroup>
            </Form.Item> */}
          </>
        )}

        <Form.Item required label="결제예정금액(부가세 포함)">
          <Input
            readOnly
            placeholder="결제예정금액을 입력해주세요"
            name="expectedPayment"
            value={
              Formatter.통화(store.formModalAutoPayment.form.expectedPayment) +
              "원"
            }
            onChange={onChange}
          />
        </Form.Item>
      </Form>
      <Detail>{text}</Detail>
    </Container>
  );
};

const Container = styled.div``;

const WarningText = styled.p`
  display: flex;
  font-size: 12px;
  gap: 6px;
  color: #686868;
`;

const Detail = styled.div`
  white-space: pre-line;
  font-size: 12px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 20px 6px;
  background-color: #f7f7f7;
  margin-top: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  button {
    width: 100%;
  }
`;

const Space = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Text = styled.p`
  width: 180px;
  max-width: 180px;
  font-weight: 550;
  font-size: 13px;
`;
